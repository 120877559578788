<div class="popup-container frozen-left frozen-right">
  <ui-popup title="{{ 'purchase-modality-selection.title' | translate }}" (close)="canClosePopup()">
    <div content>
      <div class="filters" *ngIf="filterer">
        <ui-dynamic-filters
          [filters]="filterer.getFilters()"
          [(filterValues)]="filterer.filterValues"
          (apply)="applyFilters()"
          label="{{ 'filters.label' | translate }}"
          minLabel="{{ 'filters.min' | translate }}"
          maxLabel="{{ 'filters.max' | translate }}"
          applyLabel="{{ 'filters.apply' | translate }}"
          deleteLabel="{{ 'filters.delete' | translate }}"
          placeholderPrefix="{{ 'filters.prefix' | translate }} "
        >
        </ui-dynamic-filters>
      </div>

      <div class="warning-messages" *ngIf="warningMessage">
        <fa-icon class="status-icon" [icon]="warnIcon"></fa-icon>
        <div class="status-message">
          <p>
            <strong>{{ warningTitle | translate }}</strong>
          </p>
          <p>{{ warningMessage | translate }}</p>
        </div>
      </div>

      <div class="purchase-modality-table">
        <div [formGroup]="form">
          <ngx-datatable
            #table
            [rows]="rows"
            rowHeight="40"
            class="material"
            footerHeight="50"
            scrollbarH="true"
            columnMode="force"
            trackByProp="pmId"
            headerHeight="auto"
            [rowClass]="getRowClass"
            [limit]="pager.size"
            [offset]="pager.number"
            [externalPaging]="true"
            [externalSorting]="true"
            [count]="pager.totalElements"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down',
            }"
            [sorts]="sorts"
            (sort)="changeSortSettings($event.column.prop, $event.newValue)"
            [messages]="{ emptyMessage: 'purchase-modality-selection.datatable.no-data' | translate }"
          >
            <ngx-datatable-column
              width="50"
              [sortable]="false"
              [frozenLeft]="true"
              [draggable]="false"
              [resizeable]="false"
              [canAutoResize]="false"
              cellClass="center-align frozen-style "
            >
              <ng-template ngx-datatable-header-template>
                <ui-checkbox
                  customClass="no-margin"
                  (change)="onHeaderCheckboxChange()"
                  [formControlName]="'headerCheckbox'"
                >
                </ui-checkbox>
              </ng-template>

              <ng-template ngx-datatable-cell-template let-id="row.pmId">
                <ui-checkbox
                  customClass="no-margin"
                  (change)="onRowCheckboxChange()"
                  [formControlName]="getRowControlName(id)"
                >
                </ui-checkbox>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              prop="itemCategory"
              [draggable]="false"
              [resizeable]="false"
              cellClass="item-category"
              name="{{ 'purchase-modality-selection.datatable.columns.item-category' | translate }}"
            >
              <ng-template let-supplier="row.supplier" ngx-datatable-cell-template let-itemCategory="row.itemCategory">
                <span [class.deactivated]="getLineClass(supplier)">{{ itemCategory }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              prop="itemReference"
              [resizeable]="false"
              cellClass="item-reference"
              name="{{ 'purchase-modality-selection.datatable.columns.item-reference' | translate }}"
            >
              <ng-template
                let-supplier="row.supplier"
                ngx-datatable-cell-template
                let-itemReference="row.itemReference"
              >
                <span [class.deactivated]="getLineClass(supplier)">{{ itemReference }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              prop="supplier"
              [resizeable]="false"
              cellClass="supplier"
              name="{{ 'purchase-modality-selection.datatable.columns.supplier' | translate }}"
            >
              <ng-template let-supplier="row.supplier" ngx-datatable-cell-template>
                <span [class.deactivated]="getLineClass(supplier)">{{ supplier }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              prop="supplierRef"
              [resizeable]="false"
              cellClass="supplier-ref"
              name="{{ 'purchase-modality-selection.datatable.columns.supplier-ref' | translate }}"
            >
              <ng-template let-supplier="row.supplier" ngx-datatable-cell-template let-supplierRef="row.supplierRef">
                <span [class.deactivated]="getLineClass(supplier)">{{ supplierRef }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              prop="itemName"
              [resizeable]="false"
              cellClass="item-name"
              name="{{ 'purchase-modality-selection.datatable.columns.item-name' | translate }}"
            >
              <ng-template let-itemName="row.itemName" let-supplier="row.supplier" ngx-datatable-cell-template>
                <span [class.deactivated]="getLineClass(supplier)">{{ itemName }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              prop="purchaseType"
              [resizeable]="false"
              cellClass="purchase-type"
              name="{{ 'purchase-modality-selection.datatable.columns.purchase-type' | translate }}"
            >
              <ng-template let-purchaseType="row.purchaseType" let-supplier="row.supplier" ngx-datatable-cell-template>
                <span [class.deactivated]="getLineClass(supplier)">{{
                  "purchase-modality-selection.datatable.purchase-type." + purchaseType | translate
                }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              prop="brand"
              [resizeable]="false"
              cellClass="brand"
              name="{{ 'purchase-modality-selection.datatable.columns.brand' | translate }}"
            >
              <ng-template let-brand="row.brand" let-supplier="row.supplier" ngx-datatable-cell-template>
                <span [class.deactivated]="getLineClass(supplier)">{{ brand }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              prop="minQuantity"
              [resizeable]="false"
              cellClass="min-quantity"
              name="{{ 'purchase-modality-selection.datatable.columns.min-quantity' | translate }}"
            >
              <ng-template let-supplier="row.supplier" ngx-datatable-cell-template let-minQuantity="row.minQuantity">
                <span [class.deactivated]="getLineClass(supplier)">{{ minQuantity }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              prop="maxQuantity"
              [resizeable]="false"
              cellClass="max-quantity"
              name="{{ 'purchase-modality-selection.datatable.columns.max-quantity' | translate }}"
            >
              <ng-template let-supplier="row.supplier" ngx-datatable-cell-template let-maxQuantity="row.maxQuantity">
                <span [class.deactivated]="getLineClass(supplier)">{{ maxQuantity }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [sortable]="false"
              [resizeable]="false"
              prop="computedPrice"
              cellClass="computed-price"
              name="{{ 'purchase-modality-selection.datatable.columns.price' | translate }}"
            >
              <ng-template
                let-supplier="row.supplier"
                ngx-datatable-cell-template
                let-computedPrice="row.computedPrice"
              >
                <span *ngIf="computedPrice !== null" [class.deactivated]="getLineClass(supplier)">
                  {{
                    computedPrice | formatCurrency: orderCurrency.codeISO : "symbol" : "0.2-2" : codeLanguage | async
                  }}
                </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              prop="statusLabel"
              [draggable]="false"
              [frozenRight]="true"
              [resizeable]="false"
              [canAutoResize]="false"
              cellClass="status center-align frozen-style "
              name="{{ 'purchase-modality-selection.datatable.columns.status' | translate }}"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <ui-status-label [label]="row.statusLabel" [customClass]="row.statusClass"> </ui-status-label>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-offset="offset"
                let-curPage="curPage"
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-isVisible="isVisible"
                let-selectedCount="selectedCount"
              >
                <div class="page-count">
                  <span
                    [innerHTML]="
                      selectedElementsNumber === 0
                        ? ('global.datatable.no-items' | translate)
                        : (selectedElementsNumber
                          | i18nPlural
                            : {
                                '=1': 'global.datatable.n-selected-items',
                                other: 'global.datatable.n-selected-items_plural',
                              }
                          | translate: { selectedElements: selectedElementsNumber })
                    "
                  >
                  </span>
                </div>
                <datatable-pager
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="changePage($event)"
                  [count]="rowCount"
                  [size]="pageSize"
                  [page]="curPage"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
    <div footer>
      <div class="align-buttons">
        <div class="button">
          <ui-button
            text="{{ 'purchase-modality-selection.buttons.validate' | translate }}"
            customClass="fugu-button-save"
            [primary]="true"
            (click)="submitSelection()"
            [btnIcon]="faCheck"
          ></ui-button>
        </div>
      </div>
    </div>
  </ui-popup>
</div>
