<div class="main-container invoice-customer-form frozen-left">
  <div class="top-container">
    <div class="header">
      <div class="return-link">
        <ui-link
          text="{{ 'credit-note.credit-note-customer-form.back-link' | translate }}"
          (click)="backToPrevious()"
          [linkIcon]="faChevronLeft"
        ></ui-link>
      </div>
      <div class="title" *ngIf="creditNoteCustomer">
        <ui-title
          titleText="{{
            'credit-note.credit-note-customer-details.title' | translate: { ref: creditNoteCustomer?.reference }
          }}"
          subTitleText="{{ creditNoteCustomer ? creditNoteCustomer.customerRef : '' }}"
        ></ui-title>
      </div>
    </div>
  </div>

  <div *ngIf="creditNoteCustomer" class="mb-20">
    <ui-container>
      <div class="grid infos">
        <div class="grid-item">
          <div class="multi-col">
            <p class="title">
              {{ "credit-note.credit-note-customer-form.header.credit-note-date" | translate | uppercase }}
            </p>
            <p class="date">
              {{ creditNoteCustomer.date | dateTimeFormat: false }}
            </p>
          </div>
        </div>
        <div class="grid-item">
          <div class="multi-col">
            <p class="title">{{ "credit-note.credit-note-customer-form.header.comment" | translate | uppercase }}</p>
            <ng-container *ngIf="!creditNoteCustomer.comment">
              <p class="comment">{{ "invoice-form-header.fields.undefined" | translate }}</p>
            </ng-container>
            <ng-container *ngIf="creditNoteCustomer.comment">
              <p class="ellipsis comment" [title]="creditNoteCustomer.comment">{{ creditNoteCustomer.comment }}</p>
            </ng-container>
          </div>
        </div>
      </div>
    </ui-container>
  </div>

  <ng-container *ngIf="creditNoteCustomer && selectedTabId && invoiceCustomerMap?.size > 0">
    <ui-tab-handler
      #tabHandler
      [tabByUrl]="false"
      [independent]="false"
      [closeable]="false"
      (tabClick)="onTabClick($event)"
    >
      <ui-tab
        [active]="tab.key === selectedTabId"
        [id]="tab.key"
        *ngFor="let tab of creditNoteCustomer?.lines | keyvalue"
        [title]="this.invoiceCustomerMap.get(tab.key).reference"
      >
        <ng-template #tabTemplate>
          <div class="filters border-bottom-filters" *ngIf="filterer">
            <ui-dynamic-filters
              [locale]="fetcher.locale"
              [filters]="filterer.getFilters()"
              [(filterValues)]="filterer.filterValues"
              (apply)="applyFilters()"
              [label]="'filters.label' | translate"
              minLabel="{{ 'filters.min' | translate }}"
              maxLabel="{{ 'filters.max' | translate }}"
              applyLabel="{{ 'filters.apply' | translate }}"
              deleteLabel="{{ 'filters.delete' | translate }}"
              placeholderPrefix="{{ 'filters.prefix' | translate }} "
            >
            </ui-dynamic-filters>
          </div>
          <div class="datatable">
            <ngx-datatable
              #table
              [rows]="creditNoteCustomer.tabs.get(tab.key).filteredLines"
              rowHeight="40"
              class="material vertical-scroll"
              columnMode="force"
              headerHeight="auto"
              [rowClass]="getRowClass"
              [scrollbarH]="true"
              [summaryRow]="true"
              [summaryHeight]="45"
              [trackByProp]="'lineNumber'"
              [summaryPosition]="'bottom'"
              [sorts]="sorts"
              [count]="pager.totalElements"
              [offset]="pager.number"
              [limit]="pager.size"
              [cssClasses]="{
                sortAscending: 'fas fa-chevron-up',
                sortDescending: 'fas fa-chevron-down',
              }"
              [messages]="{ emptyMessage: 'global.no-data' | translate }"
              (sort)="changeSortSettings($event.column.prop, $event.newValue, tab.value.id)"
            >
              <ngx-datatable-column
                name="{{ 'credit-note.credit-note-customer-form.datatable.columns.line-number' | translate }}"
                cellClass="center-align frozen-style line-number"
                [resizeable]="false"
                [draggable]="false"
                prop="lineNumber"
                [width]="80"
                [canAutoResize]="false"
                headerClass="frozen-left line-number"
                [frozenLeft]="true"
                [summaryTemplate]="totalSummary"
              >
                <ng-template ngx-datatable-cell-template let-value="value">
                  <div>{{ value }}</div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'credit-note.credit-note-customer-form.datatable.columns.item-supplier-ref' | translate }}"
                cellClass="center-align frozen-style item-customer-ref"
                [resizeable]="false"
                [draggable]="false"
                prop="itemSupplierRef"
                [frozenLeft]="true"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div *ngIf="row.itemSupplierRef">
                    {{ value }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'credit-note.credit-note-customer-form.datatable.columns.item-reference' | translate }}"
                cellClass="item-reference"
                [resizeable]="false"
                [draggable]="false"
                prop="itemRef"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div *ngIf="row.itemRef">{{ value }}</div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                [name]="'credit-note.credit-note-customer-form.datatable.columns.serial-number' | translate"
                [canAutoResize]="false"
                [summaryFunc]="null"
                [resizeable]="false"
                [draggable]="false"
                prop="serialNumber"
                [width]="95"
                cellClass="serial-number ellipsis-counter"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <p class="ellipsis" *ngIf="row.serialNumber">
                    {{ row.serialNumber }}
                  </p>
                  <div *ngIf="row.serialNumber && transformSN(row.serialNumber).length >= 2">
                    <div #serialNumberCounter>
                      <ui-counter [number]="transformSN(row.serialNumber).length"></ui-counter>
                    </div>
                    <ui-tooltip maxWidth="300px" [element]="serialNumberCounter" fallbackPlacement="left">
                      <span style="font-weight: normal !important; white-space: break-spaces">{{
                        row.serialNumber
                      }}</span>
                    </ui-tooltip>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                [name]="'credit-note.credit-note-customer-form.datatable.columns.batch-number' | translate"
                [canAutoResize]="false"
                [summaryFunc]="null"
                [resizeable]="false"
                [draggable]="false"
                prop="batchNumber"
                [width]="95"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div *ngIf="row.batchNumber">{{ value }}</div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'credit-note.credit-note-customer-form.datatable.columns.item-name' | translate }}"
                cellClass="item-name"
                [resizeable]="false"
                [draggable]="false"
                prop="itemName"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div #itemElement class="ellipsis" *ngIf="row.itemName">
                    {{ value }}
                    <ui-tooltip [element]="itemElement">
                      <span style="font-weight: normal !important; white-space: break-spaces">
                        {{ value }}
                      </span>
                    </ui-tooltip>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'credit-note.credit-note-customer-form.datatable.columns.quantity' | translate }}"
                cellClass="quantity"
                [resizeable]="false"
                [draggable]="false"
                [sortable]="false"
                prop="quantity"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'credit-note.credit-note-customer-form.datatable.columns.unit-name' | translate }}"
                cellClass="unit-name"
                [resizeable]="false"
                [draggable]="false"
                [summaryTemplate]="unitNameSummary"
              >
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  <div *ngIf="row.unitName">{{ row.unitName }}</div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'credit-note.credit-note-customer-form.datatable.columns.size-value' | translate }}"
                cellClass="size-value"
                [resizeable]="false"
                [draggable]="false"
                prop="sizeValue"
              >
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  <div *ngIf="row.sizeValue">{{ value }}</div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'credit-note.credit-note-customer-form.datatable.columns.unit-price' | translate }}"
                cellClass="unit-price"
                [resizeable]="false"
                [draggable]="false"
                [sortable]="false"
                prop="unitPrice"
                [summaryFunc]="null"
              >
                <ng-template ngx-datatable-cell-template let-value="value">
                  <div>
                    {{ value | formatCurrency: currency?.codeISO : "symbol" : "0.2-5" : fetcher.locale | async }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'credit-note.credit-note-customer-form.datatable.columns.discount' | translate }}"
                cellClass="discount sticky-right"
                [resizeable]="false"
                [draggable]="false"
                [sortable]="true"
                prop="discount"
                [summaryFunc]="null"
                [width]="95"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <span
                    >{{ value | number: "0.2-5" : fetcher.locale }} {{ displayDiscountUnit(row.discountType) }}</span
                  >
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'credit-note.credit-note-customer-form.datatable.columns.total-gross-price' | translate }}"
                cellClass="total-gross-price"
                [resizeable]="false"
                [draggable]="false"
                prop="totalGrossPrice"
                [summaryTemplate]="priceSummary"
              >
                <ng-template ngx-datatable-cell-template let-value="value">
                  <div>
                    {{ value | formatCurrency: currency?.codeISO : "symbol" : "0.2-5" : fetcher.locale | async }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'credit-note.credit-note-customer-form.datatable.columns.vat-rate' | translate }}"
                cellClass="vat-rate"
                [resizeable]="false"
                [draggable]="false"
                [sortable]="false"
                [width]="95"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <span> {{ row.vatRate | number: "0.2-2" : fetcher.locale }} %</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'credit-note.credit-note-customer-form.datatable.columns.metal-weight' | translate }}"
                cellClass="metalWeight"
                [resizeable]="false"
                [draggable]="false"
                [sortable]="true"
                prop="metalWeight"
                [summaryFunc]="null"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <span>
                    {{ row.metalWeight === undefined || row.metalWeight === null ? "" : row.metalWeight + " g" }}</span
                  >
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'credit-note.credit-note-customer-form.datatable.columns.tare' | translate }}"
                cellClass="tare"
                [resizeable]="false"
                [draggable]="false"
                [sortable]="true"
                prop="tare"
                [summaryFunc]="null"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <span> {{ row.tare === undefined || row.tare === null ? "" : row.tare + " g" }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'credit-note.credit-note-customer-form.datatable.columns.weight' | translate }}"
                cellClass="weight"
                [resizeable]="false"
                [draggable]="false"
                [sortable]="true"
                prop="weight"
                [summaryFunc]="null"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <span> {{ !row.weight ? "" : row.weight + " g" }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'credit-note.credit-note-customer-form.datatable.columns.delivery-ref' | translate }}"
                cellClass="delivery-ref"
                [resizeable]="false"
                [draggable]="false"
                prop="deliveryRef"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div>
                    {{ value }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'credit-note.credit-note-customer-form.datatable.columns.store-name' | translate }}"
                cellClass="store-name"
                [resizeable]="false"
                [draggable]="false"
                prop="store"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <p #storeElement class="ellipsis">
                    {{ value }}
                    <ui-tooltip [element]="storeElement">
                      <span style="font-weight: normal !important; white-space: break-spaces">
                        {{ value }}
                      </span>
                    </ui-tooltip>
                  </p>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'credit-note.credit-note-customer-form.datatable.columns.brand-name' | translate }}"
                cellClass="brand-name"
                [resizeable]="false"
                [draggable]="false"
                prop="brandName"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div>
                    {{ value }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'credit-note.credit-note-customer-form.datatable.columns.supplier-name' | translate }}"
                cellClass="supplier-name"
                [resizeable]="false"
                [draggable]="false"
                prop="supplierName"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div>
                    {{ value }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'credit-note.credit-note-customer-form.datatable.columns.supplier-ref' | translate }}"
                cellClass="supplier-ref"
                [resizeable]="false"
                [draggable]="false"
                prop="supplierRef"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div>
                    {{ value }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ng-template #priceSummary>
                <div class="total-price summary-fields">
                  {{
                    creditNoteCustomer.tabs.get(selectedTabId).totals.totalGrossPrice
                      | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : fetcher.locale
                      | async
                  }}
                </div>
              </ng-template>

              <ng-template #totalSummary>
                <div class="total-summary summary-fields">
                  {{ "credit-note.credit-note-customer-form.datatable.summary.total" | translate }}
                </div>
              </ng-template>

              <ng-template #quantitySummary>
                <div class="total-quantity summary-fields">
                  {{ creditNoteCustomer.tabs.get(selectedTabId).totals.totalQuantity }}
                </div>
              </ng-template>

              <ng-template #unitNameSummary>
                <div>
                  <span *ngIf="!multipleUnit(creditNoteCustomer.tabs.get(selectedTabId).filteredLines)">
                    {{ creditNoteCustomer.lines.get(selectedTabId)[0].unitName }}
                  </span>
                  <span
                    class="summary-fields"
                    *ngIf="multipleUnit(creditNoteCustomer.tabs.get(selectedTabId).filteredLines)"
                  >
                    <span #warningIcon>
                      <fa-icon class="warn-icon" [icon]="faWarning"></fa-icon>
                    </span>

                    <ui-tooltip maxWidth="160px" [element]="warningIcon">
                      <span style="font-weight: normal">{{
                        "credit-note.credit-note-customer-form.datatable.summary.warning-message" | translate
                      }}</span>
                    </ui-tooltip>
                  </span>
                </div>
              </ng-template>

              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-selectedCount="selectedCount"
                  let-curPage="curPage"
                  let-offset="offset"
                  let-isVisible="isVisible"
                >
                  <div class="page-count" *ngIf="rowCount">
                    {{
                      rowCount
                        | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                        | translate: { rowCount: rowCount }
                    }}
                  </div>
                  <datatable-pager
                    [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                    [pagerRightArrowIcon]="'fas fa-chevron-right'"
                    [pagerNextIcon]="'fas fa-chevron-double-right'"
                    [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!(rowCount / pageSize > 1)"
                    (change)="changePage($event)"
                  >
                  </datatable-pager>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </ng-template>
      </ui-tab>
    </ui-tab-handler>
  </ng-container>

  <div *ngIf="this.currency" class="footer-total">
    <ui-container>
      <div class="footer-container">
        <div class="footer-element">
          <div class="label total-gross-price">
            {{ "credit-note.credit-note-customer-form.footer.labels.total-gross-price" | translate }}
          </div>
          <div class="value total-gross-price">
            {{
              this.creditNoteCustomer.totalGrossPrice
                | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : fetcher.locale
                | async
            }}
          </div>
        </div>
        <div class="footer-element">
          <div class="label total-quantity">
            {{ "credit-note.credit-note-customer-form.footer.labels.total-quantity" | translate }}
          </div>
          <div class="value total-quantity">
            {{ this.creditNoteCustomer.totalQuantity }}
            <span class="summary-fields" *ngIf="totalMultipleUnit()">
              <span #warningIcon>
                <fa-icon class="warn-icon" [icon]="faWarning"></fa-icon>
              </span>

              <ui-tooltip maxWidth="160px" [element]="warningIcon">
                <span style="font-weight: normal">{{
                  "credit-note.credit-note-customer-form.datatable.summary.warning-message" | translate
                }}</span>
              </ui-tooltip>
            </span>
          </div>
        </div>
        <div class="footer-element">
          <div class="total-tax-price">
            <div>{{ "credit-note.credit-note-customer-form.footer.labels.total-tax-price" | translate }}</div>
            <div class="total-tax-price value">
              {{
                this.creditNoteCustomer.taxPrice
                  | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : fetcher.locale
                  | async
              }}
            </div>
          </div>
        </div>
        <div class="footer-element">
          <div class="label total-price">
            {{ "credit-note.credit-note-customer-form.footer.labels.total-price" | translate }}
          </div>
          <div class="value total-price">
            {{
              this.creditNoteCustomer.totalPrice
                | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : fetcher.locale
                | async
            }}
          </div>
        </div>
      </div>
    </ui-container>
  </div>
</div>
