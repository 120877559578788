<ng-container>
  <form class="sale-terms" [formGroup]="saleTermsForm" novalidate>
    <div class="item info">
      <div *ngIf="this.editedItem && !this.editedItem.canSold">
        <span class="info-icon" #info><fa-icon [icon]="faInfoCircle"></fa-icon></span>
        <ui-tooltip maxWidth="100px" [element]="info"
          ><span>{{ "item.sale-terms.info" | translate }}</span></ui-tooltip
        >
      </div>
      <div class="field">
        <ui-input-text type="text" label="{{ 'item.sale-terms.reference' | translate }}" formControlName="reference">
        </ui-input-text>
      </div>
    </div>
    <div class="item">
      <ui-input-text
        type="text"
        [maskPattern]="decimalDigitLow"
        label="{{ 'item.sale-terms.sales-rate' | translate }}"
        formControlName="salesRate"
        suffix=" %"
        [thousandSeparator]="' '"
        [status]="saleTermsForm.get('salesRate') | formStatus"
        [errorMessage]="
          saleTermsForm.get('salesRate')
            | formErrorMessage: { customPrefix: 'item.sale-terms.errors.', useCustomPrefixFor: ['min', 'max'] }
            | translate: { digit: LOW_INTEGER }
        "
      >
      </ui-input-text>
    </div>
    <div class="item" *ngIf="this.editedItem?.type === 'STANDARD'">
      <ui-switch label="{{ 'item.sale-terms.tph' | translate }}" formControlName="tph" mandatory="true"> </ui-switch>
    </div>

    <div class="item">
      <ui-list
        label="{{ 'item.sale-terms.public-currency' | translate }}"
        [options]="this.currencyOptions"
        formControlName="selectedCurrencies"
        [status]="saleTermsForm.get('selectedCurrencies') | formStatus"
        [errorMessage]="saleTermsForm.get('selectedCurrencies') | formErrorMessage | translate"
        [mandatory]="true"
        [multiple]="true"
        [maxDisplayedLabel]="6"
      >
      </ui-list>
    </div>

    <div class="item" *ngFor="let currencyId of saleTermsForm.controls.selectedCurrencies.value">
      <ui-input-text
        type="text"
        mandatory="true"
        [maskPattern]="decimalDigitHigh"
        label="{{
          'item.sale-terms.public-price-currency' | translate: { currency: currencies.get(currencyId).symbol }
        }}"
        [formControlName]="'selectedPrices' + this.currencies.get(currencyId).codeISO"
        [suffix]="' ' + currencies.get(currencyId).symbol"
        [thousandSeparator]="' '"
        [status]="saleTermsForm.get('selectedPrices' + currencies.get(currencyId).codeISO) | formStatus"
        [errorMessage]="
          saleTermsForm.get('selectedPrices' + currencies.get(currencyId).codeISO)
            | formErrorMessage: { customPrefix: 'item.sale-terms.errors.', useCustomPrefixFor: ['min', 'max'] }
            | translate: { digit: HIGH_INTEGER }
        "
      >
      </ui-input-text>
    </div>
  </form>
</ng-container>
