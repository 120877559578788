<ng-container>
  <div class="main-container">
    <div class="header">
      <div class="return-link">
        <ui-link
          text="{{ 'purchase-order.buttons.back-to-list-button' | translate }}"
          [linkIcon]="faChevronLeft"
          (click)="goBackToList()"
        ></ui-link>
      </div>

      <div class="title" *ngIf="purchaseOrder">
        <div class="title-text">
          <ui-title
            titleText="{{ 'purchase-order.title.detail' | translate: { orderRef: purchaseOrder.orderRef } }}"
          ></ui-title>
        </div>
        <ui-status-label
          label="{{ 'purchase-order.header.order-status-options.' + purchaseOrder.status | translate }}"
          [customClass]="getStatusClass()"
        ></ui-status-label>
      </div>
    </div>
    <div class="top-buttons">
      <div class="button-edit" *ngIf="canUpdate">
        <ui-button
          text="{{ 'purchase-order.buttons.edit-button' | translate }}"
          *hasPermission="['PURCHASE_ORDER_UPDATE']"
          (click)="goToEditPage()"
          [btnIcon]="faPen"
          [primary]="true"
        ></ui-button>
      </div>
      <div class="button" *ngIf="canLinkFollowUp">
        <ui-button
          text="{{ 'purchase-order.buttons.link-button' | translate }}"
          (click)="goToLinkPage()"
          [btnIcon]="faLink"
          [primary]="true"
        ></ui-button>
      </div>
    </div>

    <div class="purchase-order-body">
      <app-purchase-order-header-detail *ngIf="purchaseOrder" [purchaseOrder]="purchaseOrder">
      </app-purchase-order-header-detail>

      <app-purchase-order-lines-detail #purchaseOrderLinesDetail *ngIf="purchaseOrder" [purchaseOrder]="purchaseOrder">
      </app-purchase-order-lines-detail>
    </div>
  </div>
</ng-container>
