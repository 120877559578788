<ng-container>
  <div class="main-container">
    <div class="header">
      <div class="return-link">
        <ui-link
          text="{{ getBackButtonTranslation() | translate }}"
          (click)="backToPrevious()"
          [linkIcon]="faChevronLeft"
        ></ui-link>
      </div>

      <div class="title">
        <ui-title
          titleText="{{ title | translate: { invoiceNumber: invoiceNumber } }}"
          subTitleText="{{ subTitle ? subTitle : undefined }}"
        ></ui-title>
      </div>
    </div>

    <div class="top-buttons">
      <div class="button-validate" *ngIf="!this.isValidated && !readOnly">
        <ui-button
          text="{{ 'invoice-supplier-form.buttons.validate-invoice-supplier' | translate }}"
          (click)="submitInvoiceSupplierForm(true)"
          [primary]="true"
          type="validate"
        ></ui-button>
      </div>
      <div class="button-validate" *ngIf="this.isValidated && !readOnly">
        <ui-button
          text="{{ 'invoice-supplier-form.buttons.update-invoice-supplier' | translate }}"
          (click)="submitInvoiceSupplierForm(true)"
          [primary]="true"
          type="validate"
        ></ui-button>
      </div>
      <div class="button-save" *ngIf="!this.isValidated && !readOnly">
        <ui-button text="{{ 'global.validate' | translate }}" (click)="submitInvoiceSupplierForm(false)" type="save">
        </ui-button>
      </div>
      <div class="button-edit" *ngIf="editBtnVisible">
        <ui-button
          text="{{ 'invoice-supplier-form.buttons.edit-button' | translate }}"
          (click)="editInvoiceSupplierForm()"
          [btnIcon]="faPen"
          [primary]="true"
          *hasPermission="['INVOICE_SUPPLIER_UPDATE']"
        ></ui-button>
      </div>
    </div>

    <div class="invoice-supplier-form">
      <app-invoice-form-header
        #invoiceHeader
        *ngIf="updatedInvoiceSupplier"
        [editedInvoice]="updatedInvoiceSupplier"
        [quickPaymentType]="this.getCommercialModalityWithAttribute('quickPaymentDiscountType')"
        [quickPaymentPeriod]="this.getCommercialModalityWithAttribute('quickPaymentPeriod')"
        [maxPaymentType]="this.getCommercialModalityWithAttribute('maxPaymentType')"
        [maxPaymentPeriod]="this.getCommercialModalityWithAttribute('maxPaymentPeriod')"
        [readOnly]="readOnly"
        [showReference]="true"
        [showComment]="false"
      >
      </app-invoice-form-header>

      <div class="add-df-btn" *ngIf="!readOnly">
        <ui-button
          text="{{ 'invoice-supplier-form.buttons.add-delivery-form' | translate }}"
          (click)="openPopup()"
          [btnIcon]="faPlus"
        >
        </ui-button>
      </div>

      <ui-tab-handler
        #tabHandler
        *ngIf="this.invoiceSupplierTabs.length > 0 && this.currency"
        [tabByUrl]="false"
        [independent]="false"
        (tabClick)="onTabClick($event)"
        [closeable]="!readOnly"
        (close)="onCloseTab($event)"
      >
        <ui-tab
          [active]="i === 0"
          [id]="i"
          *ngFor="let tab of this.invoiceSupplierTabs; let i = index"
          title="{{ 'invoice-supplier-form.tabs.title' | translate: { ref: getTabName(tab) } }}"
        >
          <ng-template #tabTemplate>
            <div class="filters field border-bottom-filters" *ngIf="filterer">
              <ui-dynamic-filters
                [locale]="locale"
                [filters]="filterer.getFilters()"
                [(filterValues)]="filterer.filterValues"
                (apply)="applyFilters(tab)"
                [label]="'filters.label' | translate"
                minLabel="{{ 'filters.min' | translate }}"
                maxLabel="{{ 'filters.max' | translate }}"
                applyLabel="{{ 'filters.apply' | translate }}"
                deleteLabel="{{ 'filters.delete' | translate }}"
                placeholderPrefix="{{ 'filters.prefix' | translate }} "
              >
              </ui-dynamic-filters>
            </div>

            <div class="{{ getTableClass() }}">
              <div class="add-line-button" *ngIf="!readOnly">
                <ui-button
                  text="{{ 'invoice-supplier-form.buttons.new-line' | translate }}"
                  (click)="openPopup(tab)"
                  type="add"
                  class="add-line-button"
                ></ui-button>
              </div>

              <div class="datatable">
                <ngx-datatable
                  #table
                  [rows]="tab.rows"
                  rowHeight="40"
                  class="material vertical-scroll"
                  columnMode="force"
                  headerHeight="auto"
                  [rowClass]="getRowClass"
                  [scrollbarH]="true"
                  [summaryRow]="true"
                  [summaryHeight]="45"
                  [trackByProp]="'lineNumber'"
                  [summaryPosition]="'bottom'"
                  [sorts]="sorts"
                  [count]="pager.totalElements"
                  [offset]="pager.number"
                  [limit]="pager.size"
                  [cssClasses]="{
                    sortAscending: 'fas fa-chevron-up',
                    sortDescending: 'fas fa-chevron-down',
                  }"
                  [messages]="{ emptyMessage: 'global.no-data' | translate }"
                  (sort)="changeSortSettings($event.column.prop, $event.newValue, tab.id)"
                >
                  <ngx-datatable-column
                    name="{{ 'invoice-supplier-form.datatable.columns.line-number' | translate }}"
                    cellClass="line-number"
                    [summaryTemplate]="totalSummary"
                    [resizeable]="false"
                    [draggable]="false"
                    prop="lineNumber"
                    [width]="80"
                  >
                    <ng-template ngx-datatable-cell-template let-value="value">
                      <div>{{ value }}</div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-supplier-form.datatable.columns.item-supplier-ref' | translate }}"
                    cellClass="item-supplier-ref"
                    [resizeable]="false"
                    [draggable]="false"
                    prop="itemSupplierRef"
                  >
                    <ng-template ngx-datatable-cell-template let-value="value">
                      <div>{{ value }}</div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-supplier-form.datatable.columns.item-reference' | translate }}"
                    cellClass="item-reference"
                    [resizeable]="false"
                    [draggable]="false"
                    prop="itemRef"
                  >
                    <ng-template ngx-datatable-cell-template let-value="value">
                      <div>{{ value }}</div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-supplier-form.datatable.columns.item-name' | translate }}"
                    cellClass="item-name"
                    [resizeable]="false"
                    [draggable]="false"
                    prop="itemName"
                  >
                    <ng-template ngx-datatable-cell-template let-value="value">
                      <div #itemElement class="ellipsis">
                        {{ value }}

                        <ui-tooltip [element]="itemElement">
                          <span style="font-weight: normal !important; white-space: break-spaces">
                            {{ value }}
                          </span>
                        </ui-tooltip>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-supplier-form.datatable.columns.brand-name' | translate }}"
                    cellClass="brand-name"
                    [resizeable]="false"
                    [draggable]="false"
                    prop="brandName"
                  >
                    <ng-template ngx-datatable-cell-template let-value="value">
                      <div>{{ value }}</div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-supplier-form.datatable.columns.quantity' | translate }}"
                    cellClass="quantity"
                    [summaryTemplate]="quantitySummary"
                    [resizeable]="false"
                    [draggable]="false"
                    [sortable]="false"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row">
                      <ui-input-text
                        *ngIf="!readOnly"
                        type="text"
                        class="input-fields quantity-input"
                        [maskPattern]="decimalDigit"
                        [formControl]="row.rowForm.get('quantity')"
                        [status]="row.rowForm.get('quantity') | formStatus"
                        [errorMessage]="row.rowForm.get('quantity') | formErrorMessage | translate"
                        sizeMode="small"
                      >
                      </ui-input-text>
                      <span *ngIf="readOnly">{{ row.quantity }}</span>
                      <span> {{ row.quantityUnit }}</span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-supplier-form.datatable.columns.size-value' | translate }}"
                    cellClass="size-value"
                    [resizeable]="false"
                    [draggable]="false"
                    prop="sizeValue"
                  >
                    <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                      <div *ngIf="!row.rowForm.get('sizeValue') || readOnly">{{ value }}</div>
                      <ui-input-text
                        *ngIf="row.rowForm.get('sizeValue') && !readOnly"
                        type="text"
                        class="input-fields size-value-input"
                        [formControl]="row.rowForm.get('sizeValue')"
                        sizeMode="small"
                      >
                      </ui-input-text>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-supplier-form.datatable.columns.unit-price' | translate }}"
                    cellClass="unit-price"
                    [resizeable]="false"
                    [draggable]="false"
                    [sortable]="readOnly ? true : false"
                    prop="unitPrice"
                    [summaryFunc]="null"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                      <ui-input-text
                        type="text"
                        class="input-fields"
                        [thousandSeparator]="' '"
                        [maskPattern]="decimalDigit"
                        [suffix]="' ' + currency?.symbol"
                        [formControl]="row.rowForm.get('unitPrice')"
                        [status]="row.rowForm.get('unitPrice') | formStatus"
                        [errorMessage]="row.rowForm.get('unitPrice') | formErrorMessage | translate"
                        sizeMode="small"
                        *ngIf="!readOnly"
                      >
                      </ui-input-text>
                      <div *ngIf="readOnly">
                        {{ value | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : locale | async }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-supplier-form.datatable.columns.total-gross-price' | translate }}"
                    cellClass="total-gross-price"
                    [resizeable]="false"
                    [draggable]="false"
                    prop="totalGrossPrice"
                    [summaryTemplate]="priceSummary"
                  >
                    <ng-template ngx-datatable-cell-template let-value="value">
                      <div>{{ value | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : locale | async }}</div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-supplier-form.datatable.columns.vat-rate' | translate }}"
                    cellClass="vat-rate"
                    [resizeable]="false"
                    [draggable]="false"
                    prop="vatRate"
                    [summaryFunc]="null"
                  >
                    <ng-template ngx-datatable-cell-template let-value="value">
                      <span> {{ value | formatCurrency: "%" : "symbol" : "0.2-2" : locale | async }}</span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-supplier-form.datatable.columns.actions' | translate }}"
                    headerClass="center-align"
                    cellClass="center-align frozen-style"
                    [resizeable]="false"
                    [frozenRight]="true"
                    [sortable]="false"
                    [width]="80"
                    *ngIf="!readOnly"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row">
                      <div (click)="$event.stopPropagation()">
                        <ui-menu-actions [menuActions]="menuActions" (actionCalled)="manageActions($event, row, tab)">
                        </ui-menu-actions>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-footer>
                    <ng-template
                      ngx-datatable-footer-template
                      let-rowCount="rowCount"
                      let-pageSize="pageSize"
                      let-selectedCount="selectedCount"
                      let-curPage="curPage"
                      let-offset="offset"
                      let-isVisible="isVisible"
                    >
                      <div class="page-count" *ngIf="rowCount">
                        {{
                          rowCount
                            | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                            | translate: { rowCount: rowCount }
                        }}
                      </div>
                      <datatable-pager
                        [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                        [pagerRightArrowIcon]="'fas fa-chevron-right'"
                        [pagerNextIcon]="'fas fa-chevron-double-right'"
                        [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                        [page]="curPage"
                        [size]="pageSize"
                        [count]="rowCount"
                        [hidden]="!(rowCount / pageSize > 1)"
                        (change)="changePage($event)"
                      >
                      </datatable-pager>
                    </ng-template>
                  </ngx-datatable-footer>
                </ngx-datatable>
              </div>

              <ng-template #priceSummary>
                <div class="total-price summary-fields">
                  {{ tab.totalGrossPrice | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : locale | async }}
                </div>
              </ng-template>

              <ng-template #totalSummary>
                <div class="total-summary summary-fields">
                  {{ "invoice-supplier-form.datatable.summary.total" | translate }}
                </div>
              </ng-template>

              <ng-template #quantitySummary>
                <div class="total-quantity summary-fields">
                  {{ tab.totalQuantity }}
                  <span class="summary-fields" *ngIf="multipleUnit(tab)">
                    <span #warningIcon>
                      <fa-icon class="warn-icon" [icon]="faWarn"></fa-icon>
                    </span>

                    <ui-tooltip maxWidth="160px" [element]="warningIcon">
                      <span style="font-weight: normal">{{
                        "invoice-supplier-form.datatable.summary.warning-message" | translate
                      }}</span>
                    </ui-tooltip>
                  </span>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </ui-tab>
      </ui-tab-handler>

      <div *ngIf="this.currency" class="footer-total">
        <ui-container>
          <div class="footer-container" [formGroup]="form">
            <div class="footer-element">
              <div class="label total-gross-price">
                {{ "invoice-supplier-form.footer.labels.total-gross-price" | translate }}
              </div>
              <div class="value total-gross-price">
                {{ this.totalGrossPrice | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : locale | async }}
              </div>
            </div>
            <div class="footer-element">
              <div class="label total-quantity">
                {{ "invoice-supplier-form.footer.labels.total-quantity" | translate }}
              </div>
              <div class="value total-quantity">
                {{ this.totalQuantity }}
                <span class="summary-fields" *ngIf="totalMultipleUnit()">
                  <span #warningIcon>
                    <fa-icon class="warn-icon" [icon]="faWarn"></fa-icon>
                  </span>

                  <ui-tooltip maxWidth="160px" [element]="warningIcon">
                    <span style="font-weight: normal">{{
                      "invoice-supplier-form.datatable.summary.warning-message" | translate
                    }}</span>
                  </ui-tooltip>
                </span>
              </div>
            </div>
            <div class="footer-element">
              <ui-input-text
                label="{{ 'invoice-supplier-form.footer.labels.total-shipping-fee-price' | translate }}"
                infoLabel="{{ 'invoice-supplier-form.footer.infos.standard-vat-rate' | translate }}"
                type="text"
                class="input-fields"
                [thousandSeparator]="' '"
                [maskPattern]="decimalDigit"
                [suffix]="' ' + currency?.symbol"
                formControlName="shippingFeePrice"
                customClass="no-margin"
                *ngIf="!readOnly"
              >
              </ui-input-text>
              <div class="total-shipping-fee-price" *ngIf="readOnly">
                <div class="footer-label-with-info">
                  <div>{{ "invoice-supplier-form.footer.labels.total-shipping-fee-price" | translate }}</div>
                  <ui-info-field
                    infoLabel="{{ 'invoice-supplier-form.footer.infos.standard-vat-rate' | translate }}"
                  ></ui-info-field>
                </div>
                <div class="total-shipping-fee-price value">
                  {{ this.shippingFeePrice | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : locale | async }}
                </div>
              </div>
            </div>
            <div class="footer-element">
              <ui-input-text
                label="{{ 'invoice-supplier-form.footer.labels.extra-price' | translate }}"
                infoLabel="{{ 'invoice-supplier-form.footer.infos.standard-vat-rate' | translate }}"
                type="text"
                class="input-fields"
                [thousandSeparator]="' '"
                [maskPattern]="decimalDigit"
                [suffix]="' ' + currency?.symbol"
                formControlName="extraPrice"
                customClass="no-margin"
                *ngIf="!readOnly"
              >
              </ui-input-text>
              <div class="extra-price" *ngIf="readOnly">
                <div class="footer-label-with-info">
                  <div>{{ "invoice-supplier-form.footer.labels.extra-price" | translate }}</div>
                  <ui-info-field
                    infoLabel="{{ 'invoice-supplier-form.footer.infos.standard-vat-rate' | translate }}"
                  ></ui-info-field>
                </div>
                <div class="extra-price value">
                  {{ this.extraPrice | formatCurrency: currency?.codeISO : "symbol" : "0.2-5" : locale | async }}
                </div>
              </div>
            </div>
            <div class="footer-element">
              <div class="total-tax-price">
                <div>{{ "invoice-supplier-form.footer.labels.total-tax-price" | translate }}</div>
                <div class="total-tax-price value">
                  {{ this.taxPrice | formatCurrency: currency?.codeISO : "symbol" : "0.2-5" : locale | async }}
                </div>
              </div>
            </div>
            <div class="footer-element">
              <div class="label total-quick-payment-discount">
                {{ "invoice-supplier-form.footer.labels.total-quick-payment-discount" | translate }}
              </div>
              <div class="value total-quick-payment-discount">
                {{
                  this.totalQuickPaymentDiscount
                    | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : locale
                    | async
                }}
              </div>
            </div>
            <div class="footer-element">
              <div class="label total-price">{{ "invoice-supplier-form.footer.labels.total-price" | translate }}</div>
              <div class="value total-price">
                {{ this.totalPrice | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : locale | async }}
              </div>
            </div>
            <div class="footer-element">
              <ui-input-text
                label="{{ 'invoice-supplier-form.footer.labels.total-forwarding-price' | translate }}"
                type="text"
                class="input-fields"
                [thousandSeparator]="' '"
                [maskPattern]="decimalDigit"
                [suffix]="' ' + currency?.symbol"
                formControlName="forwardingPrice"
                customClass="no-margin"
                *ngIf="!readOnly"
              >
              </ui-input-text>
              <div class="total-forwarding-price" *ngIf="readOnly">
                <div>{{ "invoice-supplier-form.footer.labels.total-forwarding-price" | translate }}</div>
                <div class="total-forwarding-price value">
                  {{ this.forwardingPrice | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : locale | async }}
                </div>
              </div>
            </div>
            <div class="footer-element">
              <ui-input-text
                label="{{ 'invoice-supplier-form.footer.labels.extra-cost' | translate }}"
                type="text"
                class="input-fields"
                [thousandSeparator]="' '"
                [maskPattern]="decimalDigit"
                [suffix]="' ' + currency?.symbol"
                formControlName="extraCost"
                customClass="no-margin"
                *ngIf="!readOnly"
              >
              </ui-input-text>
              <div class="extra-cost" *ngIf="readOnly">
                <div>{{ "invoice-supplier-form.footer.labels.extra-cost" | translate }}</div>
                <div class="extra-cost value">
                  {{ this.extraCost | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : locale | async }}
                </div>
              </div>
            </div>
          </div>
        </ui-container>
      </div>
    </div>
  </div>

  <app-invoice-supplier-initiator-popup
    *ngIf="this.invoiceSupplierInitiatorPopupVisible"
    [supplierId]="this.supplierId"
    [currency]="this.currency"
    [deliveryFormIds]="this.deliveryFormIds"
    [tabNameList]="this.tabNameList"
    (close)="this.onCloseInvoiceSupplierInitiatorPopup()"
    (validate)="this.onValidateInvoiceSupplierInitiatorPopup($event)"
    [activeTab]="this.activeTab"
  ></app-invoice-supplier-initiator-popup>
</ng-container>

<div class="popup">
  <ui-popup
    title="{{ 'invoice-supplier-form.delete-popup.title' | translate }}"
    (close)="closeDeleteTabConfirmationPopup()"
    *ngIf="this.deleteTabConfirmationPopupVisible"
  >
    <div content>
      <div
        class="body-message"
        [innerHTML]="
          'invoice-supplier-form.delete-popup.body'
            | translate: { ref: getTabName(this.invoiceSupplierTabs[+this.tabToDelete.id]) }
        "
      ></div>
    </div>
    <div footer>
      <div class="align-buttons">
        <div class="button-popup-validate">
          <div class="button">
            <ui-button
              text="{{ 'invoice-supplier-form.delete-popup.validate' | translate }}"
              [primary]="true"
              type="validate"
              (click)="deleteTab()"
            ></ui-button>
          </div>
        </div>
        <div class="button-popup-refuse">
          <div class="button">
            <ui-button
              text="{{ 'invoice-supplier-form.delete-popup.refuse' | translate }}"
              type="cancel"
              (click)="closeDeleteTabConfirmationPopup()"
            ></ui-button>
          </div>
        </div>
      </div>
    </div>
  </ui-popup>
</div>
