<ng-container>
  <div class="main-container">
    <div class="filters border-bottom-filters" *ngIf="filterer">
      <ui-dynamic-filters
        label="{{ 'filters.label' | translate }}"
        [filters]="filterer.getFilters()"
        [(filterValues)]="filterer.filterValues"
        applyLabel="{{ 'filters.apply' | translate }}"
        (apply)="applyFilters()"
        dateFormat="{{ dateFormat }}"
        minLabel="{{ 'filters.min' | translate }}"
        maxLabel="{{ 'filters.max' | translate }}"
        deleteLabel="{{ 'filters.delete' | translate }}"
        placeholderPrefix="{{ 'filters.prefix' | translate }} "
      >
      </ui-dynamic-filters>
    </div>
    <div></div>

    <div class="add-item-btn" *ngIf="isFree() && canSee('checkbox') && canEdit('delete')">
      <ui-button
        text="{{ 'new-delivery-lines.buttons.add-item' | translate }}"
        type="add"
        (click)="openSelectionPopup()"
      ></ui-button>
    </div>

    <ng-container *ngIf="canSee('checkbox')">
      <div class="headband">
        <div class="selected-count">
          <span
            [innerHTML]="
              selectedRows.length === 0
                ? ('global.datatable.no-items' | translate)
                : (selectedRows.length
                  | i18nPlural
                    : {
                        '=1': 'new-delivery-lines.datatable.n-items',
                        other: 'new-delivery-lines.datatable.n-items_plural',
                      }
                  | translate: { lines: selectedRows.length })
            "
          >
          </span>
        </div>

        <div class="buttons">
          <ng-container *ngIf="canSee('status') && canEdit('status')">
            <ui-button
              text="{{ 'new-delivery-lines.buttons.validate-line' | translate }}"
              (click)="changeRegisterStatus('VALIDATED')"
              [disabled]="!selectedRows.length"
              type="validate"
            >
            </ui-button>

            <ui-button
              text="{{ 'new-delivery-lines.buttons.put-on-hold' | translate }}"
              (click)="changeRegisterStatus('PENDING')"
              [disabled]="!selectedRows.length"
              [btnIcon]="faPause"
            >
            </ui-button>

            <ui-button
              text="{{ 'new-delivery-lines.buttons.refused-line' | translate }}"
              (click)="changeRegisterStatus('REFUSED')"
              [disabled]="!selectedRows.length"
              customClass="fugu-button-cancel"
              [btnIcon]="faBan"
            >
            </ui-button>
          </ng-container>

          <ng-container *ngIf="canEdit('delete')">
            <ui-button
              text="{{ 'global.delete' | translate }}"
              [disabled]="!selectedRows.length"
              (click)="deleteSelectedLines()"
              type="delete"
            >
            </ui-button>
          </ng-container>
        </div>
      </div>

      <div class="separator">
        <div class="pointer"><div class="arrow"></div></div>
        <div class="line"></div>
      </div>
    </ng-container>

    <div #info class="disabled-line-info" *ngIf="showLineFrozenInfo && !readOnly">
      <fa-icon [icon]="faInfo" class="info-icon"></fa-icon>
      {{ "new-delivery-lines.info.some-lines-cannot-be-updated" | translate }}
    </div>

    <div class="frozen-left" [class.frozen-right]="canSee('status') || canSee('actions')">
      <ngx-datatable
        #table
        [sorts]="sorts"
        [rowHeight]="40"
        columnMode="force"
        [footerHeight]="50"
        headerHeight="auto"
        [scrollbarH]="true"
        [summaryRow]="true"
        [summaryHeight]="45"
        [rows]="rows"
        [rowClass]="getRowClass"
        [trackByProp]="'lineNumber'"
        [summaryPosition]="'bottom'"
        class="material vertical-scroll"
        [count]="pager.totalElements"
        [offset]="pager.number"
        [limit]="pager.size"
        [cssClasses]="{
          sortAscending: 'fas fa-chevron-up',
          sortDescending: 'fas fa-chevron-down',
        }"
        [messages]="{ emptyMessage: 'global.no-data' | translate }"
        (sort)="changeSortSettings($event.column.prop, $event.newValue)"
      >
        <ng-container *ngFor="let column of sortByOrder(columns)" [ngSwitch]="true">
          <ng-container *ngSwitchCase="column.property === 'checkbox'">
            <ngx-datatable-column
              cellClass="center-align frozen-style"
              [sortable]="!column.editable"
              [canAutoResize]="false"
              *ngIf="column.visible"
              [width]="column.width"
              [resizeable]="false"
              [frozenLeft]="true"
              [draggable]="false"
            >
              <ng-template ngx-datatable-header-template>
                <div class="green-zone" (click)="$event.stopPropagation()">
                  <ui-checkbox
                    customClass="no-margin"
                    (change)="onHeaderCheckboxChange()"
                    [formControl]="selectionForm.get('header')"
                  ></ui-checkbox>
                </div>
              </ng-template>

              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="green-zone centered" (click)="$event.stopPropagation()">
                  <ui-checkbox
                    [formControl]="selectionForm.get(row.id.toString())"
                    (change)="onRowCheckboxChange()"
                    customClass="no-margin"
                  ></ui-checkbox>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ng-container>

          <ng-container *ngSwitchCase="column.property === 'lineNumber' || column.property === 'itemRef'">
            <ngx-datatable-column
              [cellClass]="getCellClass(column) + 'line-number frozen-style'"
              [summaryTemplate]="column.property === 'lineNumber' ? totalSummary : null"
              [name]="getColumnName(column.property)"
              [sortable]="!column.editable"
              [prop]="column.property"
              *ngIf="column.visible"
              [width]="column.width"
              [resizeable]="false"
              [draggable]="false"
              [frozenLeft]="true"
            >
            </ngx-datatable-column>
          </ng-container>

          <ng-container *ngSwitchCase="column.property === 'status'">
            <ngx-datatable-column
              [cellClass]="getCellClass(column) + 'line-number frozen-style'"
              [name]="getColumnName(column.property)"
              [sortable]="!column.editable"
              [prop]="column.property"
              *ngIf="column.visible"
              [width]="column.width"
              [summaryFunc]="null"
              [resizeable]="false"
              [frozenRight]="true"
              [draggable]="false"
            >
              <ng-template ngx-datatable-cell-template let-value="value">
                <div class="centered">
                  <ui-status-label
                    label="{{ 'new-delivery-lines.datatable.status.' + value | translate }}"
                    [customClass]="getStatusClass(value)"
                  ></ui-status-label>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ng-container>

          <ng-container *ngSwitchCase="column.property === 'actions'">
            <ngx-datatable-column
              [cellClass]="getCellClass(column) + 'line-number frozen-style actions-col'"
              [name]="getColumnName(column.property)"
              [sortable]="!column.editable"
              [prop]="column.property"
              *ngIf="column.visible"
              [width]="column.width"
              [summaryFunc]="null"
              [resizeable]="false"
              [frozenRight]="true"
              [draggable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div
                  *ngIf="menuActions && isStatusValid(row.status)"
                  class="green-zone centered"
                  (click)="$event.stopPropagation()"
                >
                  <ui-menu-actions
                    [menuActions]="isDetailled(row) ? menuActionsDetail : menuActions"
                    (actionCalled)="manageActions($event, row)"
                  >
                  </ui-menu-actions>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ng-container>

          <ng-container
            *ngSwitchCase="column.property === 'realMetalWeightInfo' || column.property === 'totalUnitPriceInfo'"
          >
            <ngx-datatable-column
              [sortable]="!column.editable"
              [canAutoResize]="false"
              *ngIf="column.visible"
              [resizeable]="false"
              headerClass="narrow"
              [draggable]="false"
              width="23"
            >
              <ng-template ngx-datatable-header-template>
                <div #info><fa-icon [icon]="faInfo" class="info-icon"></fa-icon></div>

                <ui-tooltip maxWidth="300px" [element]="info">
                  <span style="font-weight: normal !important">
                    {{ "new-delivery-lines.datatable.info." + convertPropertyName(column.property) | translate }}
                  </span>
                </ui-tooltip>
              </ng-template>
            </ngx-datatable-column>
          </ng-container>

          <ng-container *ngSwitchCase="column.property.includes('Anomaly')">
            <ngx-datatable-column
              [cellClass]="getCellClass(column) + 'warning-value'"
              [sortable]="!column.editable"
              [width]="column.width"
              *ngIf="column.visible"
              [resizeable]="false"
              [draggable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <ng-container *ngIf="!column.editable">
                  <div #warnIcon *ngIf="hasAnomaly(row.id, column.property)">
                    <fa-icon class="warn-icon" [icon]="faWarn"></fa-icon>

                    <ui-tooltip maxWidth="125px" [element]="warnIcon">
                      <span style="font-weight: normal !important">
                        {{ "new-delivery-lines.warning." + convertPropertyName(column.property) | translate }}
                      </span>
                    </ui-tooltip>
                  </div>
                </ng-container>
              </ng-template>
            </ngx-datatable-column>
          </ng-container>

          <ng-container *ngSwitchCase="column.property.includes('QuantityUnit')">
            <ngx-datatable-column
              [cellClass]="getCellClass(column)"
              [sortable]="!column.editable"
              *ngIf="column.visible"
              [width]="column.width"
              [resizeable]="false"
              [draggable]="false"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="quantity-unit">{{ row.purchaseUnit }}</div>
              </ng-template>
            </ngx-datatable-column>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <ngx-datatable-column
              [summaryTemplate]="getSummary(column.property)"
              [cellClass]="getCellClass(column)"
              [name]="getColumnName(column.property)"
              [sortable]="!column.editable"
              [prop]="column.property"
              *ngIf="column.visible"
              [width]="column.width"
              [summaryFunc]="null"
              [resizeable]="false"
              [draggable]="false"
            >
              <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                <ng-container *ngIf="column.editable || column.property === 'onMetalAccount'" [ngSwitch]="true">
                  <ng-container
                    *ngSwitchCase="!row.isStandardItem && fieldsNotExistInServiceItem.includes(column.property)"
                  >
                    <ng-container>
                      <div></div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="column.property === 'onMetalAccount'">
                    <div
                      *ngIf="row.purchaseType !== 'BASIC'"
                      (click)="$event.stopPropagation()"
                      class="with-margin green-zone"
                    >
                      <ui-switch
                        [formControl]="getFormControl(row.id, column.property)"
                        customClass="no-margin"
                      ></ui-switch>
                    </div>
                  </ng-container>

                  <ng-container *ngSwitchCase="row.isStandardItem && column.property === 'destLocationId'">
                    <ui-list
                      [formControl]="getFormControl(row.id, column.property)"
                      [options]="locationsOptions"
                      class="input-fields"
                      sizeMode="small"
                      [search]="true"
                      status="{{ row.rowForm.get('destLocationId') | formStatus }}"
                      errorMessage="{{ row.rowForm.get('destLocationId') | formErrorMessage | translate }}"
                    ></ui-list>
                  </ng-container>

                  <ng-container *ngSwitchCase="column.property === 'realMetalPrice'">
                    <!-- a voir affiche l'input uniquement dans le cas d'une modalité de type coût métal -->
                    <ng-container
                      *ngIf="row.purchaseType !== 'BASIC' && !this.getFormControl(row.id, 'onMetalAccount')?.value"
                    >
                      <ui-input-text
                        [formControl]="getFormControl(row.id, column.property)"
                        [thousandSeparator]="getSeparator(column.property)"
                        [maskPattern]="getMaskPattern(column.property)"
                        [suffix]="getSuffix(column.property)"
                        class="input-fields"
                        sizeMode="small"
                        type="text"
                        status="{{ row.rowForm.get(column.property) | formStatus }}"
                        errorMessage="{{
                          row.rowForm.get(column.property)
                            | formErrorMessage
                              : { customPrefix: 'new-delivery-lines.errors', useCustomPrefixFor: ['min'] }
                            | translate: { digit: highPrecision }
                        }}"
                      >
                      </ui-input-text>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngSwitchCase="column.property === 'realDiscount'">
                    <div class="discount-fields">
                      <ui-input-text
                        [formControl]="getFormControl(row.id, column.property)"
                        [thousandSeparator]="getSeparator(column.property)"
                        [maskPattern]="getMaskPattern(column.property)"
                        [suffix]="getSuffix(column.property)"
                        class="input-fields"
                        sizeMode="small"
                        type="text"
                        status="{{ row.rowForm.get('realDiscount') | formStatus }}"
                        errorMessage="{{
                          row.rowForm.get('realDiscount')
                            | formErrorMessage
                              : {
                                  customPrefix: 'new-delivery-lines.errors.',
                                  useCustomPrefixFor: ['discountGreaterThanPriceOrPercent', 'min'],
                                }
                            | translate: { digit: highPrecision }
                        }}"
                      >
                      </ui-input-text>
                      <ui-list
                        [formControl]="getFormControl(row.id, 'discountType')"
                        [options]="discountOptions"
                        class="input-fields"
                        [search]="false"
                        sizeMode="small"
                        status="{{ row.rowForm.get('discountType') | formStatus }}"
                        errorMessage="{{ row.rowForm.get('discountType') | formErrorMessage | translate }}"
                      ></ui-list>
                    </div>
                  </ng-container>

                  <ng-container *ngSwitchCase="column.property === 'receivedQuantity'">
                    <div *ngIf="value !== null && value !== undefined">
                      <ui-input-text
                        [formControl]="getFormControl(row.id, column.property)"
                        [maskPattern]="getMaskPattern(column.property)"
                        class="input-fields"
                        sizeMode="small"
                        type="text"
                        status="{{ row.rowForm.get(column.property) | formStatus }}"
                        errorMessage="{{
                          row.rowForm.get(column.property)
                            | formErrorMessage
                              : {
                                  customPrefix: 'new-delivery-lines.errors.',
                                  useCustomPrefixFor: ['receivedQuantityGreaterThanExpected', 'min'],
                                }
                            | translate: { digit: highPrecision }
                        }}"
                      >
                        <div class="quantity-template" *ngIf="isFirstOrOnlyRow(row)">
                          <span>{{ value }}</span>
                          <span>{{ row.purchaseUnit }}</span>
                        </div>
                      </ui-input-text>
                    </div>
                  </ng-container>

                  <ng-container *ngSwitchCase="column.property === 'realMetalWeight'">
                    <div *ngIf="value !== null && value !== undefined">
                      <ui-input-text
                        [formControl]="getFormControl(row.id, column.property)"
                        [maskPattern]="getMaskPattern(column.property)"
                        [suffix]="getSuffix(column.property)"
                        class="input-fields"
                        sizeMode="small"
                        type="text"
                        status="{{ row.rowForm.get(column.property) | formStatus }}"
                        errorMessage="{{
                          row.rowForm.get(column.property)
                            | formErrorMessage
                              : {
                                  customPrefix: 'new-delivery-lines.errors.',
                                  useCustomPrefixFor: ['realMetalWeightRequired', 'min'],
                                }
                            | translate: { digit: highPrecision }
                        }}"
                      >
                        {{ value | number: "0.2-2" : locale }}
                      </ui-input-text>
                    </div>
                  </ng-container>

                  <ng-container *ngSwitchCase="column.property === 'totalWeight'">
                    <div *ngIf="value !== null && value !== undefined">
                      <ui-input-text
                        [formControl]="getFormControl(row.id, column.property)"
                        [maskPattern]="getMaskPattern(column.property)"
                        [suffix]="getSuffix(column.property)"
                        class="input-fields"
                        sizeMode="small"
                        type="text"
                        status="{{ row.rowForm.get(column.property) | formStatus }}"
                        errorMessage="{{
                          row.rowForm.get(column.property)
                            | formErrorMessage
                              : {
                                  customPrefix: 'new-delivery-lines.errors.',
                                  useCustomPrefixFor: ['tareGreaterThanTotalWeightError', 'min'],
                                }
                            | translate: { digit: highPrecision }
                        }}"
                      >
                        {{ value | number: "0.2-2" : locale }}
                      </ui-input-text>
                    </div>
                  </ng-container>

                  <ng-container *ngSwitchDefault>
                    <ui-input-text
                      [formControl]="getFormControl(row.id, column.property)"
                      [thousandSeparator]="getSeparator(column.property)"
                      [maskPattern]="getMaskPattern(column.property)"
                      [suffix]="getSuffix(column.property)"
                      class="input-fields"
                      sizeMode="small"
                      type="text"
                      status="{{ row.rowForm.get(column.property) | formStatus }}"
                      errorMessage="{{
                        row.rowForm.get(column.property)
                          | formErrorMessage: { customPrefix: 'new-delivery-lines.errors', useCustomPrefixFor: ['min'] }
                          | translate: { digit: highPrecision }
                      }}"
                    >
                    </ui-input-text>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="!column.editable" [ngSwitch]="true">
                  <ng-container
                    *ngSwitchCase="
                      column.property === 'orderedUnitPricePerWeight' ||
                      column.property === 'realUnitPricePerWeight' ||
                      column.property === 'orderedTotalUnitPrice' ||
                      column.property === 'realTotalUnitPrice' ||
                      column.property === 'orderedUnitPrice' ||
                      column.property === 'realUnitPrice' ||
                      column.property === 'totalPrice'
                    "
                  >
                    {{ value | formatCurrency: currency.codeISO : "symbol" : "0.2-2" : locale | async }}
                  </ng-container>

                  <ng-container
                    *ngSwitchCase="column.property === 'orderedMetalPrice' || column.property === 'realMetalPrice'"
                  >
                    <ng-container
                      *ngIf="row.purchaseType !== 'BASIC' && !this.getFormControl(row.id, 'onMetalAccount')?.value"
                    >
                      <div *ngIf="value">
                        {{ value | formatCurrency: currency.codeISO : "symbol" : "0.2-2" : locale | async }}
                      </div>
                    </ng-container>
                  </ng-container>

                  <ng-container
                    *ngSwitchCase="
                      column.property === 'orderedMetalWeight' ||
                      column.property === 'realMetalWeight' ||
                      column.property === 'totalWeight' ||
                      column.property === 'tare'
                    "
                  >
                    <div *ngIf="value">{{ value | number: "0.2-2" : locale }} g</div>
                  </ng-container>

                  <ng-container
                    *ngSwitchCase="
                      (column.property === 'expectedQuantity' && !canSee('orderedQuantity')) ||
                      column.property === 'orderedQuantity'
                    "
                  >
                    <div class="quantity-template" *ngIf="isFirstOrOnlyRow(row)">
                      <span>{{ value }}</span>
                      <span>{{ row.purchaseUnit }}</span>
                    </div>
                  </ng-container>

                  <ng-container *ngSwitchCase="column.property === 'orderedDiscount'">
                    <div>{{ value | number: "0.2-5" : locale }} %</div>
                  </ng-container>

                  <ng-container *ngSwitchCase="column.property === 'realDiscount'">
                    <div>{{ value | number: "0.2-5" : locale }} {{ getDiscountUnit(row.discountType) }}</div>
                  </ng-container>

                  <ng-container *ngSwitchCase="column.property === 'itemName'">
                    <div #itemElement class="ellipsis">
                      {{ value }}

                      <ui-tooltip [element]="itemElement" *ngIf="itemElement">
                        <span style="font-weight: normal !important; white-space: break-spaces">
                          {{ value }}
                        </span>
                      </ui-tooltip>
                    </div>
                  </ng-container>

                  <ng-container *ngSwitchCase="column.property === 'destLocationId'">
                    {{ getDestLocationName(row.destLocationId) }}
                  </ng-container>

                  <ng-container *ngSwitchCase="column.property === 'unreceivedQuantity'">
                    <div *ngIf="isFirstOrOnlyRow(row)" class="mother">{{ value }}</div>
                    <div #splitIcon *ngIf="!isFirstOrOnlyRow(row)">
                      <fa-icon class="split-icon" [icon]="faSplit"></fa-icon>

                      <ui-tooltip maxWidth="125px" [element]="splitIcon">
                        <span style="font-weight: normal !important">
                          {{ "new-delivery-lines.datatable.info.added-line" | translate }}
                        </span>
                      </ui-tooltip>
                    </div>
                  </ng-container>

                  <ng-container *ngSwitchDefault>
                    {{ value }}
                  </ng-container>
                </ng-container>
              </ng-template>
            </ngx-datatable-column>
          </ng-container>
        </ng-container>

        <ngx-datatable-footer>
          <ng-template
            let-selectedCount="selectedCount"
            ngx-datatable-footer-template
            let-isVisible="isVisible"
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-curPage="curPage"
            let-offset="offset"
          >
            <div class="page-count" *ngIf="rowCount">
              {{
                rowCount
                  | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                  | translate: { rowCount: rowCount }
              }}
            </div>
            <datatable-pager
              [pagerLeftArrowIcon]="'fas fa-chevron-left'"
              [pagerRightArrowIcon]="'fas fa-chevron-right'"
              [pagerNextIcon]="'fas fa-chevron-double-right'"
              [pagerPreviousIcon]="'fas fa-chevron-double-left'"
              [page]="curPage"
              [size]="pageSize"
              [count]="rowCount"
              [hidden]="!(rowCount / pageSize > 1)"
              (change)="changePage($event)"
            >
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>

      <ng-template #totalSummary>
        <div class="total-summary summary-fields">
          {{ "new-delivery-lines.datatable.summary.total" | translate }}
        </div>
      </ng-template>

      <ng-template #priceSummary let-row="row" let-value="value">
        <div class="total-price summary-fields">
          {{ getTotalPrice() | formatCurrency: currency.codeISO : "symbol" : "0.2-2" : locale | async }}
        </div>
      </ng-template>

      <ng-template #quantitySummary let-row="row" let-value="value">
        <div class="total-quantity summary-fields">
          {{ getTotalQuantity() }}
        </div>
      </ng-template>

      <ng-template #quantityExpectedSummary let-row="row" let-value="value">
        <div class="total-expected-quantity summary-fields">
          {{ getTotalExpectedQuantity() }}
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>

<app-split-delivery-line-popup
  *ngIf="splitPopupVisible"
  (close)="this.closePopup('split')"
  (splittedLines)="this.handleSplit($event)"
  [line]="selectedLine"
  [itemName]="selectedItemName"
  [purchaseUnit]="selectedPurchaseUnit"
  [lineNumber]="selectedLineNumber"
>
</app-split-delivery-line-popup>
