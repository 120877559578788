import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IconDefinition, faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { ComponentDirty, ErrorUtil, PopupSaveUtil } from "generic-pages";
import { TranslateService } from "@ngx-translate/core";
import { ColorPickerComponent, MessageService } from "fugu-components";
import { combineLatest, Observable } from "rxjs";
import { DottedPropertyUtil, SubscriptionService } from "fugu-common";
import {
  AbstractCustomer,
  CommercialModality,
  Currency,
  CurrencyService,
  CustomerService,
  CustomerType,
  DocumentService,
  FormErrorService,
  IndividualCustomer,
  JobType,
  ProfessionalCustomer,
  Store,
  StoreService,
} from "center-services";
import { tap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-customer-form",
  templateUrl: "./customer-form.component.html",
  styleUrls: ["./customer-form.component.scss"],
  providers: [SubscriptionService],
})
export class CustomerFormComponent implements OnInit, ComponentDirty {
  @ViewChild("tabHandler") tabHandler: any;
  @ViewChild("generalDatas") generalDatas: any;
  @ViewChild("commercialModality") commercialModality: any;
  @ViewChild("metalAccountActivation") metalAccountActivation: any;
  @ViewChild("customerDocumentView") customerDocumentView: any;

  public title: string;
  public subTitle: string;
  public unsavedFile: any;
  public editedCustomer: AbstractCustomer;
  public updatedCustomer: AbstractCustomer;
  public unsavedCustomer: AbstractCustomer;

  public faChevronLeft: IconDefinition = faChevronLeft;
  public shouldClose: boolean = false;
  public hasError: boolean = false;
  public modifiedFile: any;
  public modifiedDocumentFiles: any = [];

  public jobTypeExternalContact: JobType = JobType.EXTERNAL_CONTACT;
  mainStore: any;
  defaultCurrency: Currency;
  // eslint-disable-next-line no-magic-numbers
  protected readonly PAYLOAD_TOO_LARGE: number = 413;
  private initObservables: Observable<any>[];

  constructor(
    private customerService: CustomerService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private documentService: DocumentService,
    private formErrorService: FormErrorService,
    private route: ActivatedRoute,
    private router: Router,
    private currencyService: CurrencyService,
    private storeService: StoreService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    const customerId = this.route.snapshot.params.id;
    const defaultCommercialModality = new CommercialModality({});
    this.title = customerId ? "customer.title.update" : "customer.title.new";
    if (customerId) {
      this.subscriptionService.subs.push(
        this.customerService.get(customerId).subscribe(
          (customer: AbstractCustomer) => {
            this.editedCustomer =
              customer.customerType === CustomerType.INDIVIDUAL
                ? new IndividualCustomer(customer)
                : new ProfessionalCustomer(customer);
            this.updatedCustomer = this.cloneCustomer(customer);
            this.subTitle = this.getSubtitle(customer);
          },
          () => {
            this.backToCustomerList();
          }
        )
      );
    } else {
      this.initObservables = [];
      this.initObservables.push(this.fetchDefaultCurrency());
      this.initObservables.push(this.fetchMainStore());

      this.subscriptionService.subs.push(
        combineLatest(this.initObservables).subscribe(() => {
          this.updatedCustomer = new ProfessionalCustomer({
            affiliate: false,
            color: ColorPickerComponent.DEFAULT_COLOR,
            currencyId: this.defaultCurrency.id,
            commercialModality: defaultCommercialModality,
            createdByStoreId: this.mainStore.id,
            address: { byDefault: true },
          });
          this.editedCustomer = this.cloneCustomer(this.updatedCustomer);
        })
      );
    }
  }

  isDirty(): boolean {
    if (!this.updatedCustomer) {
      return false;
    }

    let initialFile = null;
    let newFile = null;

    if (this.generalDatas) {
      initialFile = this.generalDatas.previousFile;
      newFile = this.generalDatas.selectedFile;
      this.generalDatas.applyModifications();
    }

    if (
      (this.unsavedCustomer && !this.unsavedCustomer.equals(this.updatedCustomer)) ||
      (this.unsavedFile && newFile && this.unsavedFile !== newFile)
    ) {
      this.shouldClose = false;
    }

    if (
      (((this.editedCustomer && !this.editedCustomer.equals(this.updatedCustomer)) || initialFile !== newFile) &&
        !this.shouldClose) ||
      (!this.editedCustomer && !this.shouldClose)
    ) {
      this.recordDatas();
      return true;
    }

    this.unsavedCustomer = null;
    this.shouldClose = false;
    this.unsavedFile = null;
    return false;
  }

  fetchDefaultCurrency(): Observable<Currency> {
    return this.currencyService.getDefault().pipe(
      tap(
        (currency: Currency) => {
          this.defaultCurrency = currency;
        },
        error => {
          this.sendErrorAlert(`customer.general-datas.errors.get-currency`, error.message);
        }
      )
    );
  }

  fetchMainStore(): Observable<Store> {
    return this.storeService.getMain().pipe(
      tap(
        (mainStore: Store) => {
          this.mainStore = mainStore;
        },
        error => {
          this.sendErrorAlert("new-receipt-list.errors.get-main-store", error.message);
        }
      )
    );
  }

  isAffiliate(): boolean {
    if (this.updatedCustomer === null || !(this.updatedCustomer instanceof ProfessionalCustomer)) {
      return false;
    }
    return (this.updatedCustomer as ProfessionalCustomer).affiliate;
  }

  recordDatas(): void {
    if (this.generalDatas) {
      this.unsavedFile = this.generalDatas.selectedFile;
    }
    this.unsavedCustomer = this.cloneCustomer(this.updatedCustomer);
    this.shouldClose = true;
  }

  onTabClick(tab: any): void {
    if (this.metalAccountActivation) {
      this.metalAccountActivation.savePaginationToSession();
    }
    if (this.updatedCustomer) {
      this.submitCustomer(tab);
    }
  }

  submitCustomer(nextTab: any = null): void {
    this.hasError = false;
    let fileUpdated = false;

    if (this.generalDatas) {
      if (this.generalDatas.updateCustomer() === "commercialOffersErrors") {
        const content = this.translateService.instant("customer.general-datas.errors.commercial-offers.content");
        const title = this.translateService.instant("customer.general-datas.errors.commercial-offers.title");
        this.messageService.error(content, { title });
        return;
      }
      if (this.generalDatas.updateCustomer() === "defaultErrors") {
        this.formErrorService.handleFormError();
        return;
      }

      // handle file
      if (!this.editedCustomer.isIndividual()) {
        this.modifiedFile = this.generalDatas.getFile();
        fileUpdated = this.generalDatas.hasFileChanged();
      }
    }

    if (this.commercialModality) {
      if (!this.commercialModality.isValid()) {
        this.formErrorService.handleFormError();
        return;
      }
    }

    if (this.customerDocumentView && this.customerDocumentView.customerDocumentPopup) {
      this.modifiedDocumentFiles[0] = this.customerDocumentView.customerDocumentPopup.getFiles()[0];
      this.modifiedDocumentFiles[1] = this.customerDocumentView.customerDocumentPopup.getFiles()[1];
      if (!fileUpdated) {
        fileUpdated = this.customerDocumentView.customerDocumentPopup.hasFileChanged();
      }
    }

    if (this.editedCustomer) {
      if (this.editedCustomer.equals(this.updatedCustomer) && !fileUpdated) {
        if (nextTab && this.tabHandler) {
          this.tabHandler.changeTab(nextTab);
        }
        return;
      }
    }

    this.callApi(nextTab);
  }

  callApi(nextTab: any = null): void {
    if (!this.updatedCustomer) {
      return;
    }

    const action = this.editedCustomer && this.editedCustomer.id ? "update" : "create";
    this.saveCustomer(action, nextTab);
  }

  saveCustomer(action: string, nextTab: any): void {
    if (this.editedCustomer.isIndividual()) {
      this.updatedCustomer.reference = null;
      this.editedCustomer.reference = null;
    }
    this.subscriptionService.subs.push(
      this.customerService[action].call(this.customerService, this.updatedCustomer).subscribe(
        responseCustomer => {
          if (!this.editedCustomer.isIndividual()) {
            this.saveLogo(nextTab, responseCustomer);
          }
          this.saveCustomerDocuments(nextTab, responseCustomer);
        },
        error => {
          this.hasError = true;
          this.handleApiError(error);
        }
      )
    );
  }

  saveLogo(nextTab: any, responseCustomer: AbstractCustomer): void {
    if (!this.generalDatas || !this.generalDatas.hasFileChanged()) {
      return;
    }
    this.saveFile(
      nextTab,
      responseCustomer,
      false,
      this.modifiedFile,
      responseCustomer.logoId,
      "customer.general-datas.errors.upload-logo"
    );
  }

  saveCustomerDocuments(nextTab: any, responseCustomer: AbstractCustomer): any {
    if (
      !this.customerDocumentView ||
      !this.customerDocumentView.customerDocumentPopup ||
      !this.customerDocumentView.customerDocumentPopup.hasFileChanged()
    ) {
      this.finishSave(nextTab, responseCustomer);
      return;
    }

    const formData = new FormData();
    formData.set("file", this.modifiedDocumentFiles[0]);
    if (this.modifiedDocumentFiles[0]?.byteLength > 0) {
      this.saveFile(
        nextTab,
        responseCustomer,
        true,
        this.modifiedDocumentFiles[1],
        responseCustomer.customerDocument.documentIds[1],
        "customer-document-history.errors.upload-second-file"
      );
      return;
    }

    this.subscriptionService.subs.push(
      this.documentService.uploadFile(formData, responseCustomer.customerDocument.documentIds[0]).subscribe(
        () => {
          this.saveFile(
            nextTab,
            responseCustomer,
            true,
            this.modifiedDocumentFiles[1],
            responseCustomer.customerDocument.documentIds[1],
            "customer-document-history.errors.upload-second-file"
          );
        },
        (error: HttpErrorResponse) => {
          this.hasError = true;
          const content =
            error.status === this.PAYLOAD_TOO_LARGE
              ? this.translateService.instant("global.form.error.payload-too-large")
              : this.translateService.instant("customer-document-history.errors.upload-first-file");
          const title = this.translateService.instant("message.title.api-errors");
          this.messageService.warn(content, { title });

          // no blocking error when the file can't be uploaded
          this.saveFile(
            nextTab,
            responseCustomer,
            true,
            this.modifiedDocumentFiles[1],
            responseCustomer.customerDocument.documentIds[1],
            "customer-document-history.errors.upload-second-file"
          );
        }
      )
    );
  }

  saveFile(
    nextTab: any,
    responseCustomer: AbstractCustomer,
    canFinish: boolean,
    fileData: any,
    fileId: number,
    errorTrad: string
  ): any {
    const formData = new FormData();
    formData.set("file", fileData);
    if (fileData?.byteLength > 0) {
      if (canFinish) {
        this.finishSave(nextTab, responseCustomer);
      }
      return;
    }
    this.subscriptionService.subs.push(
      this.documentService.uploadFile(formData, fileId).subscribe(
        () => {
          this.generalDatas.previousFile = this.generalDatas.selectedFile;
          if (canFinish) {
            this.finishSave(nextTab, responseCustomer);
          }
        },
        (error: HttpErrorResponse) => {
          this.hasError = true;
          const content =
            error.status === this.PAYLOAD_TOO_LARGE
              ? this.translateService.instant("global.form.error.payload-too-large")
              : this.translateService.instant(errorTrad);
          const title = this.translateService.instant("message.title.api-errors");
          this.messageService.warn(content, { title });

          // no blocking error when the file can't be uploaded
          if (canFinish) {
            this.finishSave(nextTab, responseCustomer);
          }
        }
      )
    );
  }

  finishSave(nextTab: any, responseCustomer: AbstractCustomer): void {
    this.editedCustomer = this.cloneCustomer(responseCustomer);
    this.updatedCustomer = this.cloneCustomer(responseCustomer);

    if (this.route.snapshot.params.id) {
      this.subTitle = this.getSubtitle(responseCustomer);
    }

    const title = this.translateService.instant("message.title.save-success");
    const content = this.translateService.instant("message.content.save-success");
    this.messageService.success(content, { title });
    // Wait for file api to end before closing customerDocument popup
    if (this.customerDocumentView) {
      this.customerDocumentView.onClosePopup(this.editedCustomer.customerDocument);
    }
    if (nextTab) {
      this.tabHandler.changeTab(nextTab);
    }
  }

  backToCustomerList(): void {
    this.router.navigateByUrl("/customer-list");
  }

  handleApiError(error: any): void {
    const attributeTranslations = {
      name: "customer.general-datas.name",
      reference: "customer.general-datas.reference",
    };

    const title = this.translateService.instant("message.title.form-errors");

    const result = ErrorUtil.getTranslationKey(error.error, attributeTranslations, this.translateService);
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }

  getSubtitle(customer: AbstractCustomer): string {
    const subtitleList = [];
    if (customer.reference) {
      subtitleList.push(customer.reference);
    }
    if (!this.editedCustomer.isIndividual()) {
      subtitleList.push(customer.name);
    } else {
      subtitleList.push(`${customer.firstName} ${customer.lastName}`);
    }

    return subtitleList.join(" - ");
  }

  catchEvent(source: string, object: any): void {
    PopupSaveUtil.manageEvent(
      this.editedCustomer,
      this.updatedCustomer,
      object,
      source,
      this.saveCustomerElement.bind(this)
    );
  }

  saveCustomerElement(action: string | number, propName: string): Observable<AbstractCustomer> {
    return this.customerService[action]
      .call(this.customerService, this.editedCustomer)
      .toPromise()
      .then(responseSupplier => {
        const title = this.translateService.instant("message.title.save-success");
        const content = this.translateService.instant("message.content.save-success");
        this.messageService.success(content, { title });
        return DottedPropertyUtil.getDottedProperty(responseSupplier, propName);
      })
      .catch(error => {
        this.hasError = true;
        this.handleApiError(error);
        throw error;
      });
  }

  cloneCustomer(customer: AbstractCustomer): IndividualCustomer | ProfessionalCustomer {
    return customer.isIndividual() ? new IndividualCustomer(customer) : new ProfessionalCustomer(customer);
  }

  private sendErrorAlert(errorType: string, message: string): void {
    const content = this.translateService.instant(errorType, { message });
    const title = this.translateService.instant("message.title.data-errors");
    this.messageService.warn(content, { title });
  }
}
