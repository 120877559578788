<ng-container>
  <form class="stock" [formGroup]="stockForm" novalidate>
    <div class="grid-item">
      <div class="multi-col">
        <div class="main-unit">
          <ui-list
            *ngIf="!editedItem['hasStockEntries']"
            label="{{ 'item.stock.main-unit-label' | translate }}"
            [options]="this.uomOptions"
            [search]="true"
            formControlName="mainUnitId"
            mandatory="true"
            [status]="stockForm.get('mainUnitId') | formStatus"
            [errorMessage]="stockForm.get('mainUnitId') | formErrorMessage | translate"
          >
          </ui-list>

          <ui-info-field
            *ngIf="editedItem['hasStockEntries']"
            [label]="'item.stock.main-unit-label' | translate"
            [value]="getMainUnitName(editedItem.mainUnitId, false)"
          >
          </ui-info-field>
        </div>
        <div class="stock-type">
          <ui-list
            *ngIf="!editedItem['hasStockEntries']"
            label="{{ 'item.stock.stock-type-label' | translate }}"
            [options]="this.stockTypeOptions"
            formControlName="stockType"
            [status]="stockForm.get('stockType') | formStatus"
            [errorMessage]="stockForm.get('stockType') | formErrorMessage | translate"
            mandatory="true"
          >
          </ui-list>

          <ui-info-field
            *ngIf="editedItem['hasStockEntries']"
            [label]="'item.stock.stock-type-label' | translate"
            [value]="'stock-type-options.' + editedItem['stockType'] | translate"
          >
          </ui-info-field>
        </div>
        <div class="stock-location">
          <ui-list
            label="{{ 'item.stock.stock-location-label' | translate }}"
            [options]="this.stockLocationOptions"
            [search]="true"
            formControlName="stockLocation"
          >
          </ui-list>
        </div>
      </div>
    </div>

    <div class="grid-item">
      <div class="multi-col">
        <div class="total-quantity">
          <ui-info-field
            [label]="'item.stock.total-quantity-label' | translate"
            [value]="totalQuantity + ' ' + getMainUnitName(editedItem['mainUnitId'], true)"
            [infoLabel]="'item.stock.total-quantity-tooltip' | translate"
          >
          </ui-info-field>
        </div>

        <div class="wap">
          <ui-info-field
            [label]="'item.stock.wap' | translate"
            value="{{ wapValue | formatCurrency: this.currency?.codeISO : 'symbol' : '0.2-2' : locale | async }}"
          >
          </ui-info-field>
        </div>
      </div>
    </div>
  </form>
  <app-stock-entry-list [itemId]="editedItem.id"> </app-stock-entry-list>
</ng-container>
