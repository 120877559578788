<ng-container>
  <div class="main-container frozen-right">
    <div class="title">
      <ui-title titleText="{{ 'police-book-movements-list.title' | translate }}"></ui-title>
    </div>
    <div class="top-buttons">
      <div class="button-print">
        <ui-button
          text="{{ 'police-book-movements-list.button' | translate }}"
          (click)="printPoliceBookMovements()"
          [disabled]="pager.totalElements === 0"
          [btnIcon]="faPrint"
        >
        </ui-button>
      </div>
    </div>
    <div class="police-book-table">
      <ui-container>
        <div class="filters-field" *ngIf="filterer">
          <ui-dynamic-filters
            #movementFilters
            [filters]="filterer.getFilters()"
            [(filterValues)]="filterer.filterValues"
            [locale]="codeLanguage"
            dateFormat="{{ dateFormat }}"
            [label]="'filters.label' | translate"
            minLabel="{{ 'filters.min' | translate }}"
            maxLabel="{{ 'filters.max' | translate }}"
            applyLabel="{{ 'filters.apply' | translate }}"
            deleteLabel="{{ 'filters.delete' | translate }}"
            placeholderPrefix="{{ 'filters.prefix' | translate }} "
            (apply)="applyFilters()"
            mandatoryErrorMessage="{{ 'police-book-movements-list.errors.filters' | translate }}"
          >
          </ui-dynamic-filters>
        </div>

        <ngx-datatable
          #table
          [rows]="rows"
          [trackByProp]="'id'"
          [class]="'material'"
          [columnMode]="'force'"
          [headerHeight]="'auto'"
          [footerHeight]="50"
          [rowHeight]="40"
          [rowClass]="getRowClass"
          [scrollbarH]="true"
          [externalSorting]="true"
          [externalPaging]="true"
          [count]="pager.totalElements"
          [offset]="pager.number"
          [limit]="pager.size"
          [cssClasses]="{
            sortAscending: 'fas fa-chevron-up',
            sortDescending: 'fas fa-chevron-down',
          }"
          [messages]="{ emptyMessage: 'global.no-data' | translate }"
          [sorts]="sorts"
          (sort)="changeSortSettings($event.column.prop, $event.newValue)"
        >
          <ngx-datatable-column
            [name]="getColumnTranslation('movement-type') | translate"
            prop="type"
            [resizeable]="false"
            [width]="130"
            cellClass="type"
          >
            <ng-template ngx-datatable-cell-template let-row="row" let-tag="row.tag">
              <div class="tag-content">
                <ui-tag [label]="tag.label" [customClass]="tag.className"></ui-tag>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('movement-id') | translate"
            prop="policeId"
            cellClass="movement-id"
            [resizeable]="false"
            [width]="120"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('movement-date') | translate"
            prop="date"
            cellClass="date"
            [resizeable]="false"
            [width]="130"
          >
            <ng-template ngx-datatable-cell-template let-date="row.date">
              <span>{{ date | dateTimeFormat: false }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('contact-source-name') | translate"
            prop="contactSourceName"
            cellClass="source"
            [resizeable]="false"
            [width]="140"
          >
          </ngx-datatable-column>

          <ngx-datatable-column [name]="" cellClass="arrow" [resizeable]="false" [width]="40">
            <ng-template ngx-datatable-cell-template>
              <div class="arrow-content">
                <fa-icon [icon]="faArrow"></fa-icon>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('contact-dest-name') | translate"
            prop="contactDestName"
            cellClass="destination"
            [resizeable]="false"
            [width]="140"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('item-name') | translate"
            prop="itemName"
            cellClass="item-name"
            [resizeable]="false"
            [width]="160"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('serial-number') | translate"
            prop="serialNumber"
            cellClass="serial-number"
            [resizeable]="false"
            [width]="120"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'police-book-movements-list.datatable.columns.batch' | translate }}"
            prop="batchNumber"
            cellClass="batch"
            [resizeable]="false"
            [width]="120"
          >
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'police-book-movements-list.datatable.columns.supplier-ref' | translate }}"
            prop="supplierRef"
            cellClass="supplier-ref"
            [resizeable]="false"
            [width]="120"
          >
          </ngx-datatable-column>
          <ngx-datatable-column
            [name]="getColumnTranslation('quantity') | translate"
            prop="quantity"
            headerClass="align-right"
            cellClass="quantity align-right"
            [resizeable]="false"
            [width]="120"
          >
            <ng-template
              ngx-datatable-cell-template
              let-quantity="row.quantity"
              let-stockEntryUOMShortName="row.stockEntryUOMShortName"
            >
              <div class="align-value-label">
                <div class="right">{{ quantity }}</div>
                <div class="left" title="{{ stockEntryUOMShortName }}">{{ stockEntryUOMShortName }}</div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('alloy-name') | translate"
            prop="alloyName"
            cellClass="alloy-name"
            [width]="140"
            [resizeable]="false"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('weight-in') | translate"
            prop="weightIn"
            headerClass="align-right"
            cellClass="align-right weight-in"
            [resizeable]="false"
            [width]="120"
          >
            <ng-template ngx-datatable-cell-template let-quantity="row.weightIn">
              <span *ngIf="quantity !== null">{{ (this.quantity | number: "0.2-2") + " g" }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('weight-out') | translate"
            prop="weightOut"
            headerClass="align-right"
            cellClass="align-right weight-out"
            [resizeable]="false"
            [width]="120"
          >
            <ng-template ngx-datatable-cell-template let-quantity="row.weightOut">
              <span *ngIf="quantity !== null">{{ (removeMinusSign(quantity) | number: "0.2-2") + " g" }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('document-number') | translate"
            prop="documentNumber"
            cellClass="document-number"
            [resizeable]="false"
            [width]="130"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('invoice-number') | translate"
            prop="invoiceNumber"
            cellClass="invoice-number"
            [resizeable]="false"
            [width]="130"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('comment') | translate"
            prop="comment"
            headerClass="center-align"
            cellClass="comment center-align"
            [resizeable]="false"
          >
            <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id" let-value="value">
              <div class="comment-icon" *ngIf="!row.comment">
                <fa-icon (click)="openCommentPopup(id)" [icon]="faPen"></fa-icon>
              </div>

              <div *ngIf="row.comment">
                <div #commentText class="adjust ellipsis-with-icon" (click)="openCommentPopup(id)">
                  <p class="align-left">{{ value }}</p>
                  <fa-icon (click)="openCommentPopup(id)" [icon]="faPen" class="align-right"></fa-icon>
                </div>
                <ui-tooltip maxWidth="300px" [element]="commentText">
                  <span style="font-weight: normal !important; white-space: pre-wrap !important">{{ value }}</span>
                </ui-tooltip>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('actions') | translate"
            [headerClass]="'center-align'"
            cellClass="actions center-align frozen-style"
            [resizeable]="false"
            [frozenRight]="true"
            [sortable]="false"
            [width]="120"
          >
            <ng-template ngx-datatable-cell-template let-row="row">
              <div *ngIf="!isAlreadyReverted(row.id)" (click)="$event.stopPropagation()">
                <ui-menu-actions [menuActions]="menuActions" (actionCalled)="manageActions($event, row)">
                </ui-menu-actions>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer>
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-selectedCount="selectedCount"
              let-curPage="curPage"
              let-offset="offset"
              let-isVisible="isVisible"
            >
              <div class="page-count" *ngIf="rowCount">
                {{
                  rowCount
                    | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                    | translate: { rowCount: rowCount }
                }}
              </div>
              <datatable-pager
                [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                [pagerRightArrowIcon]="'fas fa-chevron-right'"
                [pagerNextIcon]="'fas fa-chevron-double-right'"
                [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                [page]="curPage"
                [size]="pageSize"
                [count]="rowCount"
                [hidden]="!(rowCount / pageSize > 1)"
                (change)="changePage($event)"
              >
              </datatable-pager>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </ui-container>
    </div>

    <form class="comment-form" [formGroup]="popupForm" novalidate>
      <div class="popup-container">
        <ui-popup title="{{ popupTitle | translate }}" *ngIf="popupVisible" (close)="canClosePopup()">
          <div content>
            <ui-textarea
              label="{{ 'police-book-movements-list.popup.comment' | translate }}"
              formControlName="comment"
              maxLength="1024"
            >
            </ui-textarea>
          </div>
          <div footer>
            <div class="align-buttons">
              <div class="button-popup-validate">
                <div class="button">
                  <ui-button
                    text="{{ 'global.validate' | translate }}"
                    type="save"
                    [primary]="true"
                    (click)="submitEntity()"
                  ></ui-button>
                </div>
              </div>
            </div>
          </div>
        </ui-popup>
      </div>
    </form>
  </div>
</ng-container>
