<ng-container>
  <ui-tab-handler #tabHandler [independent]="true" *ngIf="isLoaded" [tabByUrl]="false">
    <ui-tab title="{{ 'purchase-order-detail.header-title.concerned-entities' | translate }}" active="true">
      <ng-template #tabTemplate>
        <div class="detail-header">
          <div class="grid-item">
            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.supplier" | translate | uppercase }}</p>
              <p>{{ headerData["supplier"] }}</p>
            </div>

            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.brand" | translate | uppercase }}</p>
              <div class="ellipsis-counter">
                <p class="ellipsis" #brandsElement>
                  {{
                    headerData["brands"].length
                      ? headerData["brands"].join(", ")
                      : ("purchase-order-detail.fields.undefined" | translate)
                  }}
                </p>
                <div class="counter" *ngIf="headerData['brands'].length >= 2">
                  <div #brandsCounter>
                    <ui-counter [number]="headerData['brands'].length"></ui-counter>
                  </div>
                  <ui-tooltip [element]="brandsCounter">
                    <span style="font-weight: normal !important; white-space: break-spaces">{{
                      headerData["brands"].join(", ")
                    }}</span>
                  </ui-tooltip>
                </div>
              </div>
            </div>

            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.forwarding-person" | translate | uppercase }}</p>
              <p>
                {{
                  purchaseOrder.forwardingPersonName
                    ? purchaseOrder.forwardingPersonName
                    : ("purchase-order-detail.fields.undefined" | translate)
                }}
              </p>
            </div>
          </div>

          <div class="grid-item">
            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.delivery" | translate | uppercase }}</p>
              <p [innerHTML]="headerData['deliveryStore']"></p>
            </div>
          </div>

          <div class="grid-item">
            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.billing" | translate | uppercase }}</p>
              <p [innerHTML]="headerData['billingCompany']"></p>
            </div>
          </div>
        </div>
      </ng-template>
    </ui-tab>

    <ui-tab title="{{ 'purchase-order-detail.header-title.order-modalities' | translate }}">
      <ng-template #tabTemplate>
        <div class="detail-header">
          <div class="grid-item">
            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.order-type" | translate | uppercase }}</p>
              <p>
                {{
                  !purchaseOrder.orderType
                    ? ("purchase-order-detail.fields.undefined" | translate)
                    : ("purchase-order-detail.order-options." + purchaseOrder.orderType | translate)
                }}
              </p>
            </div>
            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.order-date" | translate | uppercase }}</p>
              <p>
                {{
                  purchaseOrder.createdAt
                    ? (purchaseOrder.createdAt | dateTimeFormat: false)
                    : ("purchase-order-detail.fields.undefined" | translate)
                }}
              </p>
            </div>
            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.delivery-date" | translate | uppercase }}</p>
              <p>
                {{
                  purchaseOrder.deliveryDate
                    ? (purchaseOrder.deliveryDate | dateTimeFormat: false)
                    : ("purchase-order-detail.fields.undefined" | translate)
                }}
              </p>
            </div>
          </div>

          <div class="grid-item">
            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.received-quantity" | translate | uppercase }}</p>

              <div class="quantity" *ngIf="headerData['quantity']">
                <ui-progress-bar
                  max="{{ headerData['quantity'] }}"
                  value="{{ headerData['receivedQuantity'] }}"
                ></ui-progress-bar>
                <p class="{{ quantityColorClass }}">
                  {{ headerData["receivedQuantity"] }}/{{ headerData["quantity"] }}
                </p>
              </div>

              <p *ngIf="!headerData['quantity']">
                {{ "purchase-order-detail.fields.not-applicable" | translate }}
              </p>
            </div>

            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.total-weight" | translate | uppercase }}</p>
              <p>{{ headerData["totalWeight"] ? (headerData["totalWeight"] | number: "0.2-2" : locale) : "-" }} g</p>
            </div>

            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.theoretical-total-weight" | translate | uppercase }}</p>
              <p>
                {{
                  headerData["theoreticalTotalWeight"]
                    ? (headerData["theoreticalTotalWeight"] | number: "0.2-2" : locale)
                    : "-"
                }}
                g
              </p>
            </div>
          </div>

          <div class="grid-item">
            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.payment-type" | translate | uppercase }}</p>
              <p>
                {{
                  !purchaseOrder.paymentType
                    ? ("purchase-order-detail.fields.undefined" | translate)
                    : ("purchase-order-detail.payment-options." + purchaseOrder.paymentType | translate)
                }}
              </p>
            </div>

            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.delivery-delay" | translate | uppercase }}</p>
              <p>
                {{
                  !purchaseOrder.deliveryDelay
                    ? ("purchase-order-detail.fields.undefined" | translate)
                    : ("purchase-order-detail.fields.delivery-time" | translate: { delay: purchaseOrder.deliveryDelay })
                }}
              </p>
            </div>

            <div class="multi-col">
              <p class="title">
                {{ "purchase-order-detail.fields.weight-account-prediction" | translate | uppercase }}
              </p>
              <div class="ellipsis-counter">
                <p class="ellipsis" #mwElement>
                  {{
                    headerData["sumWeightAccountPrediction"].length
                      ? stringifyMetalWeightList(headerData["sumWeightAccountPrediction"])
                      : ("purchase-order-detail.fields.undefined" | translate)
                  }}
                </p>
                <div class="counter" *ngIf="headerData['sumWeightAccountPrediction'].length >= 2">
                  <div #compositionCounter>
                    <ui-counter [number]="headerData['sumWeightAccountPrediction'].length"></ui-counter>
                  </div>
                  <ui-tooltip [element]="compositionCounter">
                    <span style="font-weight: normal !important; white-space: break-spaces">{{
                      stringifyMetalWeightList(headerData["sumWeightAccountPrediction"])
                    }}</span>
                  </ui-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="comment" *ngIf="purchaseOrder.comment">
          <p class="title">{{ "purchase-order-detail.fields.comment" | translate | uppercase }}</p>
          <p class="ellipsis" #commentElement>{{ purchaseOrder.comment }}</p>
          <ui-tooltip *ngIf="checkOverflow(commentElement)" [element]="commentElement" maxWidth="300px">
            <span style="font-weight: normal !important; white-space: break-spaces">
              {{ purchaseOrder.comment }}
            </span>
          </ui-tooltip>
        </div>
      </ng-template>
    </ui-tab>

    <ui-tab title="{{ 'purchase-order-detail.header-title.financial-datas' | translate }}">
      <ng-template #tabTemplate>
        <div class="detail-header">
          <div class="grid-item">
            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.currency" | translate | uppercase }}</p>
              <p class="currency">{{ currency.symbol }}</p>
            </div>

            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.down-payment-price" | translate | uppercase }}</p>
              <p class="currency">
                {{
                  purchaseOrder.downPaymentPrice === null
                    ? "- " + currency.symbol
                    : (purchaseOrder.downPaymentPrice
                      | formatCurrency: currency.codeISO : "symbol" : "0.2-5" : locale
                      | async)
                }}
              </p>
            </div>

            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.price-without-tax" | translate | uppercase }}</p>
              <p class="currency">
                {{
                  purchaseOrder.totalPrice === null
                    ? "- " + currency.symbol
                    : (purchaseOrder.totalPrice
                      | formatCurrency: currency.codeISO : "symbol" : "0.2-5" : locale
                      | async)
                }}
              </p>
            </div>
          </div>

          <div class="grid-item">
            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.hbjoat-tax-price" | translate | uppercase }}</p>
              <p class="currency">
                {{
                  purchaseOrder.hbjoatTaxPrice === null
                    ? "- " + currency.symbol
                    : (purchaseOrder.hbjoatTaxPrice
                      | formatCurrency: currency.codeISO : "symbol" : "0.2-5" : locale
                      | async)
                }}
              </p>
            </div>

            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.shipping-fee-price" | translate | uppercase }}</p>
              <p class="currency">
                {{
                  purchaseOrder.shippingFeePrice === null
                    ? "- " + currency.symbol
                    : (purchaseOrder.shippingFeePrice
                      | formatCurrency: currency.codeISO : "symbol" : "0.2-5" : locale
                      | async)
                }}
              </p>
            </div>

            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.tax-price" | translate | uppercase }}</p>
              <p class="currency">
                {{
                  purchaseOrder.taxPrice === null
                    ? "- " + currency.symbol
                    : (purchaseOrder.taxPrice | formatCurrency: currency.codeISO : "symbol" : "0.2-5" : locale | async)
                }}
              </p>
            </div>

            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.extra-price" | translate | uppercase }}</p>
              <p class="currency">
                {{
                  purchaseOrder.extraPrice === null
                    ? "- " + currency.symbol
                    : (purchaseOrder.extraPrice
                      | formatCurrency: currency.codeISO : "symbol" : "0.2-5" : locale
                      | async)
                }}
              </p>
            </div>
          </div>

          <div class="grid-item">
            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.total-price" | translate | uppercase }}</p>
              <p class="currency">
                {{
                  headerData["orderPrice"] === null
                    ? "- " + currency.symbol
                    : (headerData["orderPrice"]
                      | formatCurrency: currency.codeISO : "symbol" : "0.2-2" : locale
                      | async)
                }}
              </p>
            </div>

            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.forwarding-price" | translate | uppercase }}</p>
              <p class="currency">
                {{
                  purchaseOrder.forwardingPrice === null
                    ? "- " + currency.symbol
                    : (purchaseOrder.forwardingPrice
                      | formatCurrency: currency.codeISO : "symbol" : "0.2-5" : locale
                      | async)
                }}
              </p>
            </div>

            <div class="multi-col">
              <p class="title">{{ "purchase-order-detail.fields.custom-fees-price" | translate | uppercase }}</p>
              <p class="currency">
                {{
                  purchaseOrder.customsFeePrice === null
                    ? "- " + currency.symbol
                    : (purchaseOrder.customsFeePrice
                      | formatCurrency: currency.codeISO : "symbol" : "0.2-5" : locale
                      | async)
                }}
              </p>
            </div>
          </div>
        </div>
      </ng-template>
    </ui-tab>
  </ui-tab-handler>
</ng-container>
