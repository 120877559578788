import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  IconDefinition,
  faArrowRight,
  faChevronLeft,
  faPen,
  faPlus,
  faTrash,
  faWarning,
} from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { InvoiceFormFetcher } from "app/invoice/util/invoice-form-fetcher";
import {
  AbstractCustomer,
  AbstractItem,
  AbstractReceivingLine,
  CaraUserService,
  Currency,
  CustomerService,
  DeliveryLineStatus,
  DeliveryType,
  DiscountType,
  DocumentType,
  InvoiceCustomer,
  InvoiceCustomerLine,
  InvoiceCustomerLineRow,
  InvoiceCustomerService,
  InvoiceCustomerTab,
  InvoiceStatus,
  ItemCategory,
  ItemCategoryService,
  Light,
  LightCustomer,
  PaginatedList,
  Pagination,
  PricingGroup,
  PricingGroupService,
  ProfessionalCustomer,
  PurchaseModality,
  PurchaseOrder,
  PurchaseOrderLine,
  PurchaseOrderService,
  ReceivingForm,
  ReceivingFormService,
  ReceivingFreeLine,
  ReceivingInternalLine,
  ReceivingPOLine,
  RetailItemService,
  StandardItem,
  StockEntry,
  StockEntryService,
  StockType,
  Store,
  StoreService,
  Uom,
  UomService,
  getDiscountUnit,
  parseDiscountType,
} from "center-services";
import {
  CommonValidatorsUtil,
  FilterValue,
  Filterer,
  Option,
  RoundingUtil,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
  patch,
} from "fugu-common";
import { MenuAction, MessageService, TabComponent } from "fugu-components";
import { ErrorUtil, FilteredTableListComponent, PrecisionUtil } from "generic-pages";
import { EMPTY, Observable, combineLatest, forkJoin, of } from "rxjs";
import { mergeMap, tap } from "rxjs/operators";
import { InvoiceCustomerCommonService } from "../../../service/invoice-customer-common.service";
import { FreeInvoiceCustomerInitiatorOutput } from "../free-invoice-customer-initiator-popup/free-invoice-customer-initiator-output";
import { InvoiceCustomerDeliverySelectionComponent } from "../invoice-customer-delivery-selection/invoice-customer-delivery-selection.component";
import Decimal from "decimal.js";
import { StringUtils } from "../../../util/string-utils";

@Component({
  selector: "app-invoice-customer-form",
  templateUrl: "./invoice-customer-form.component.html",
  styleUrls: ["./invoice-customer-form.component.scss"],
  providers: [InvoiceFormFetcher, SubscriptionService],
})
export class InvoiceCustomerFormComponent extends FilteredTableListComponent implements OnInit {
  private static MAX_PAYMENT_TYPE: string = "maxPaymentType";
  private static LINE_NUMBER: string = "lineNumber";
  private static ASC: string = "asc";
  private static ID: string = "id";
  private static LINES_ID: string = "lines.id";
  private static PURCHASE_MODALITIES_ID: string = "purchaseModalities.id";
  private static VALIDATED: string = "VALIDATED";
  private static UPDATE: string = "update";
  private static QUICK_PAYMENT_DISCOUNT: string = "quickPaymentDiscount";
  private static DISCOUNT_TYPE: string = "discountType";
  private static DISCOUNT: string = "discount";
  private static UNIT_PRICE: string = "unitPrice";
  private static VAT_RATE: string = "vatRate";
  private static SHIPPING_FEE_PRICE: string = "shippingFeePrice";
  private static ITEM_SUPPLIER_REF: string = "itemSupplierRef";
  private static ITEM_REF: string = "itemRef";
  private static ITEM_NAME: string = "itemName";
  private static SERIAL_NUMBER: string = "serialNumber";
  private static BATCH_NUMBER: string = "batchNumber";
  private static QUANTITY: string = "quantity";
  private static UNIT_NAME: string = "unitName";
  private static SIZE_VALUE: string = "sizeValue";
  private static TOTAL_GROSS_PRICE: string = "totalGrossPrice";
  private static METAL_WEIGHT: string = "metalWeight";
  private static WEIGHT: string = "weight";
  private static TARE: string = "tare";
  private static BRAND_NAME: string = "brandName";
  private static SUPPLIER_REF: string = "supplierRef";
  private static SUPPLIER_NAME: string = "supplierName";
  private static EXTRA_PRICE: string = "extraPrice";
  private static ALREADY_INVOICED_EXCEPTION: string = "AlreadyInvoicedException";

  @ViewChild("invoiceHeader") invoiceHeader: any;
  @ViewChild("tabHandler") tabHandler: any;
  @ViewChild("table") table: DatatableComponent;

  public readonly decimalDigit: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  public LIST_ID: string = "app-invoice-customer-form.invoice-customer-form";
  public READ_LIST_ID: string = "app-invoice-customer-form.invoice-customer-form-detail";
  digitValidator: ValidatorFn = CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER);
  public HIGH_INTEGER: number = PrecisionUtil.HIGH_INTEGER;
  public listId: string;
  public menuActions: MenuAction[] = [];
  public form: UntypedFormGroup;
  public discountOptions: Option[] = [];
  public invoiceCustomerInitiatorPopupVisible: boolean = false;
  public deleteTabConfirmationPopupVisible: boolean = false;
  public freeInvoiceCustomerInitiatorPopupVisible: boolean = false;
  public validatePopupVisible: boolean = false;
  public batchGeneratePopupVisible: boolean = false;
  public totalQuantity: number;
  public totalGrossPrice: number;
  public totalQuickPaymentDiscount: number;
  public totalPrice: number;
  public shippingFeePrice: number;
  public extraPrice: number;
  public taxPrice: number;
  public contactId: number;
  public tabNameList: string[] = [];
  public sorts: any[] = [];
  public activeFilters: SearchFilter[] = [];
  public filterer: Filterer;
  public tabToDelete: TabComponent;
  public currency: Currency;
  public deliveryFormIds: Set<number> = new Set<number>();
  public initObservables: Observable<any>[] = [];
  public deliveryFormList: ReceivingForm[] = [];
  public selectedDeliveryFormList: ReceivingForm[] = [];
  public deletingDeliveryFormList: ReceivingForm[] = [];
  public stockEntryList: StockEntry[] = [];
  public retailItemList: AbstractItem[] = [];
  public customer: AbstractCustomer;
  public uoms: Uom[];
  public isValidated: boolean = false;
  public shouldValidate: boolean = false;
  public shouldClose: boolean = false;
  public hasError: boolean = false;
  public oneInvoicePerStore: boolean = false;
  public activeTab: InvoiceCustomerTab;
  public title: string;
  public invoiceCustomerNumber: string;
  public subTitle: string;
  public readOnly: boolean;
  public editBtnVisible: boolean;
  public brandList: string[] = [];
  public sizeValueList: string[] = [];
  public invoiceCustomerTabs: InvoiceCustomerTab[] = [];
  public updatedInvoiceCustomer: InvoiceCustomer;
  public unsavedInvoiceCustomer: InvoiceCustomer;
  public editedInvoiceCustomer: InvoiceCustomer;
  public faChevronLeft: IconDefinition = faChevronLeft;
  public faTrash: IconDefinition = faTrash;
  public faWarn: IconDefinition = faWarning;
  public faPlus: IconDefinition = faPlus;
  public faPen: IconDefinition = faPen;
  faArrow: IconDefinition = faArrowRight;
  faplus: IconDefinition = faPlus;
  purchaseOrderList: PurchaseOrder[];
  popupVisible: boolean = false;
  public pager: Pagination = new Pagination({
    number: 0,
    size: 100,
  });
  private readonly creditNoteFormUrl: string = "/credit-note-customer/update/";
  private readonly creditNoteDetailUrl: string = "/credit-note-customer-detail/";
  private minimalValidators: any[] = [Validators.min(0), this.digitValidator];
  private readonly backUrl: string = "/invoice-customer-list";
  // eslint-disable-next-line no-magic-numbers
  private readonly vatDefaultValue: number = 0.2;
  private linesTaxPrice: number;
  private sessionPagination: SessionPagination;
  private currentPricingGroup: PricingGroup = null;
  private store: Store;
  private readonly DELETE_ACTION_ID: number = 0;
  private readonly HUNDRED: number = 100;
  private readonly ZERO: number = 0;
  private tabId: number = 0;

  constructor(
    protected userService: CaraUserService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private invoiceCustomerService: InvoiceCustomerService,
    private purchaseOrderService: PurchaseOrderService,
    private retailItemService: RetailItemService,
    private receivingFormService: ReceivingFormService,
    private stockEntryService: StockEntryService,
    public customerService: CustomerService,
    private uomService: UomService,
    private pricingGroupService: PricingGroupService,
    private itemCategoryService: ItemCategoryService,
    private storeService: StoreService,
    private commonService: InvoiceCustomerCommonService,
    public fetcher: InvoiceFormFetcher,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.readOnly = this.route.snapshot.data.readOnly;
    this.listId = this.readOnly ? this.READ_LIST_ID : this.LIST_ID;
    this.sessionPagination = new SessionPagination(this);
    this.backUrl = this.router.getCurrentNavigation()?.extras?.state?.backUrl ?? "/invoice-customer-list";
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  public getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  public getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  public setFilters(_listId: string, filters: FilterValue[]): void {
    const activeFilters = this.filterer.filterValues.map(fv => {
      const sessionValue = filters.find(filter => filter.filterId === fv.filterId);
      return { ...fv, ...sessionValue };
    });
    this.filterer.filterValues = [...activeFilters];
  }

  public setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  public changeSortSettings(prop: string, dir: string, tabId: number): void {
    this.sorts = [{ prop, dir }];
    this.sessionPagination.saveToSession(`${this.listId}-tab-${tabId}`);
  }

  public ngOnInit(): void {
    this.sorts = [
      {
        prop: InvoiceCustomerFormComponent.LINE_NUMBER,
        dir: InvoiceCustomerFormComponent.ASC,
      },
    ];
    this.form = this.fb.group({
      shippingFeePrice: [0, [this.digitValidator]],
      extraPrice: [0, [this.digitValidator]],
    });
    this.subscriptionService.subs.push(
      this.form.valueChanges.subscribe(() => {
        this.computeTotals();
      })
    );
    this.addMenuActions();
    this.tabId = 0;

    const invoiceCustomerId = this.route.snapshot?.params?.id;
    this.subscriptionService.subs.push(
      this.fetcher.fetchInitialData().subscribe(() => {
        if (invoiceCustomerId) {
          this.subscriptionService.subs.push(
            this.fetchInvoiceCustomer(invoiceCustomerId).subscribe(() => {
              this.subTitle = this.fetcher.customerList.find(
                (customer: Light) => customer.id === this.updatedInvoiceCustomer.contactId
              )?.name;
              if (!this.subTitle) {
                this.subscriptionService.subs.push(
                  this.storeService.get(this.updatedInvoiceCustomer.contactId).subscribe((store: Store) => {
                    this.subTitle = this.fetcher.customerList.find(
                      (customer: Light) => customer.id === store.customerId
                    )?.name;
                    this.completeInit();
                  })
                );
              } else {
                this.completeInit();
              }
            })
          );
        } else {
          this.invoiceCustomerInitiatorPopupVisible = true;
          this.title = "invoice-customer-form.title";
        }
      })
    );
  }
  public displayDiscountUnit(type: DiscountType): string {
    return getDiscountUnit(type, this.currency.symbol);
  }

  public getCommercialModalityWithAttribute(attribute: string): string {
    const customer = this.customer as ProfessionalCustomer;
    if (customer && customer.commercialModality && customer.commercialModality[attribute]) {
      return customer.commercialModality[attribute];
    }
    return null;
  }

  public transformSN(sN: string): string[] {
    if (!sN?.includes(",") || sN === null) {
      return [];
    }
    return sN.split(",");
  }

  public onValidateInvoiceCustomerInitiatorPopup(event: any): void {
    this.invoiceCustomerInitiatorPopupVisible = false;
    const deliveryForms = event[0]?.deliveryForms;
    if (deliveryForms?.length === 0) {
      this.freeInvoiceCustomerInitiatorPopupVisible = true;
      return;
    }
    if (event[0]?.batch) {
      this.selectedDeliveryFormList = deliveryForms;
      this.oneInvoicePerStore = event[0].multipleStoreSelection;
      this.batchGeneratePopupVisible = true;
      return;
    }
    this.manageInvoiceCustomerLinesCreation(deliveryForms);
  }

  public onValidateFreeInvoiceCustomerInitiatorPopup(event: any): void {
    this.freeInvoiceCustomerInitiatorPopupVisible = false;
    this.manageFreeTab(event[0]);
  }

  public onSelectedDeliveryFormsChange(selectedDeliveryForms: ReceivingForm[]): void {
    this.selectedDeliveryFormList = selectedDeliveryForms;
  }

  public onCloseInvoiceCustomerInitiatorPopup(): void {
    this.invoiceCustomerInitiatorPopupVisible = false;
    if (!this.inUpdateState()) {
      this.router.navigateByUrl("/invoice-customer-list");
    }
  }

  public openInvoiceCustomerInitiatorPopup(): void {
    this.invoiceCustomerInitiatorPopupVisible = true;
  }

  public onCloseFreeInvoiceCustomerInitiatorPopup(event: boolean): void {
    this.freeInvoiceCustomerInitiatorPopupVisible = false;
    event ? this.router.navigateByUrl("/invoice-customer-list") : (this.invoiceCustomerInitiatorPopupVisible = true);
  }

  public onCloseBatchGeneratePopup(event: boolean): void {
    this.batchGeneratePopupVisible = false;
    event ? this.router.navigateByUrl("/invoice-customer-list") : (this.invoiceCustomerInitiatorPopupVisible = true);
  }

  public addFreeLine(tab: InvoiceCustomerTab): void {
    const deliveryFormId = tab.deliveryFormId?.toString() ?? this.ZERO.toString();
    const setOfLines: Set<InvoiceCustomerLine> = this.updatedInvoiceCustomer.lines.get(deliveryFormId);
    const lineNumbers = tab.allRows.map((elem: InvoiceCustomerLineRow) => elem.lineNumber);
    const lineToAdd = this.createFreeInvoiceCustomerLine(Math.max(0, ...lineNumbers), tab.deliveryFormId);
    setOfLines.add(lineToAdd);

    this.updatedInvoiceCustomer.lines.set(deliveryFormId, setOfLines);

    const row = this.getLineRow(lineToAdd);

    const tabIndex = this.invoiceCustomerTabs?.findIndex(elem => elem?.deliveryFormId === tab.deliveryFormId);

    this.invoiceCustomerTabs[tabIndex].rows.sort((a, b) =>
      a.lineNumber.toString().localeCompare(b.lineNumber.toString())
    );
    this.invoiceCustomerTabs[tabIndex].rows.push(row);
    this.invoiceCustomerTabs[tabIndex].allRows.sort((a, b) =>
      a.lineNumber.toString().localeCompare(b.lineNumber.toString())
    );
    this.invoiceCustomerTabs[tabIndex].allRows.push(row);

    this.invoiceCustomerTabs[tabIndex].tabForm.addControl(row.lineNumber.toString(), row.rowForm);

    this.invoiceCustomerTabs[tabIndex].rows = [...this.invoiceCustomerTabs[tabIndex].allRows];
    this.initFilters();
    this.sessionPagination.loadFromSession(`${this.listId}-tab-${tab?.id}`);
    this.applyFilters(tab);
  }

  public multipleUnit(tab: InvoiceCustomerTab): boolean {
    const unique = [...new Set(tab.rows.filter(row => row.unitName !== null).map(row => row.unitName))];
    return unique.length > 1;
  }

  public totalMultipleUnit(): boolean {
    const unique = new Set();
    this.invoiceCustomerTabs.forEach((tab: InvoiceCustomerTab) => {
      const uniqueTab = [...new Set(tab.rows.filter(row => row.unitName !== null).map(row => row?.unitName))];
      uniqueTab.forEach(elem => unique.add(elem));
    });
    return unique.size > 1;
  }

  public getTabName(tab: InvoiceCustomerTab): any {
    if (!tab) {
      return null;
    }
    return tab?.deliveryFormId === this.ZERO || tab?.deliveryFormId === null
      ? null
      : this.deliveryFormList?.find(df => df?.id === tab?.deliveryFormId)?.deliveryRef;
  }

  public getRowClass(row: any): any {
    return { inError: row.inError, "not-clickable": true };
  }

  public getTableClass(): any {
    return this.readOnly ? "invoice-customer-line-list" : "invoice-customer-line-list frozen-right";
  }

  public applyFilters(tab: InvoiceCustomerTab): void {
    tab.rows = this.filterer.filterList(tab.allRows);

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.listId
      ).subscribe(() => this.sessionPagination.saveToSession(`${this.listId}-tab-${tab?.id}`))
    );
  }

  updateLines(arr: { [key: string]: InvoiceCustomerLine[] }, newObjects: InvoiceCustomerLine[], mapKey: any): void {
    for (const key of Object.keys(arr)) {
      const item = arr[key];

      if (Array.isArray(item)) {
        for (const newObj of newObjects) {
          const lineNumber = newObj.lineNumber;
          const indexToUpdate = item.findIndex((obj: InvoiceCustomerLine) => obj.lineNumber === lineNumber);
          if (indexToUpdate !== -1) {
            item[indexToUpdate] = newObj;
            this.updatedInvoiceCustomer.lines[mapKey].splice(indexToUpdate, 1);
          }
        }
      }
    }
  }

  public onTabClick(event: any): void {
    if (!event) {
      return;
    }
    if (this.tabHandler) {
      this.tabHandler.changeTab(event);
    }
    const currentTab = this.invoiceCustomerTabs[event.id];
    currentTab.rows = [...currentTab.allRows];
    this.sorts = [];

    this.computeTotals();
    this.buildOptions(currentTab);
    this.initFilters();

    this.sessionPagination.loadFromSession(`${this.listId}-tab-${currentTab?.id}`);
    this.applyFilters(currentTab);
  }

  public onCloseTab(tabToDelete: TabComponent): void {
    this.deleteTabConfirmationPopupVisible = true;
    this.tabToDelete = tabToDelete;
  }

  public closeDeleteTabConfirmationPopup(): void {
    this.deleteTabConfirmationPopupVisible = false;
    this.tabToDelete = null;
  }

  public deleteTab(): void {
    this.deleteTabConfirmationPopupVisible = false;
    const tabFound = this.invoiceCustomerTabs[+this.tabToDelete?.id];

    const deliveryFormIdKey = tabFound?.deliveryFormId ? tabFound.deliveryFormId : 0;

    // update invoice customer lines
    if (this.updatedInvoiceCustomer?.lines.size > 0) {
      const map: Map<any, Set<InvoiceCustomerLine>> = this.updatedInvoiceCustomer?.lines;

      map.delete(deliveryFormIdKey.toString());

      if (deliveryFormIdKey !== 0) {
        const df = this.deliveryFormList.find(df => df.id === deliveryFormIdKey);
        this.deletingDeliveryFormList.push(df);

        this.updatedInvoiceCustomer.deliveryReferences = this.updatedInvoiceCustomer.deliveryReferences.filter(
          ref => ref !== df.deliveryRef
        );

        this.deliveryFormList = this.deliveryFormList.filter(df => df.id !== deliveryFormIdKey);
      }

      this.deliveryFormIds.delete(deliveryFormIdKey);
    }
    // remove filter preferences linked to the tab
    SessionPagination.clear(`${this.listId}-tab-${this.invoiceCustomerTabs[this.tabToDelete.id]?.id}`);

    // update tabs and form controls
    this.invoiceCustomerTabs.splice(+this.tabToDelete?.id, 1);
    this.form.removeControl(this.tabToDelete?.id);
    Object.keys(this.form.controls).forEach(key => {
      if (+key > +this.tabToDelete?.id) {
        const form = this.form.get(key);
        this.form.setControl((+key - 1).toString(), form);
        this.form.removeControl(key);
      }
    });

    const nameIndex = this.tabNameList.findIndex(
      name =>
        name ===
        (this.getTabName(tabFound) === null
          ? this.translateService.instant("invoice-customer-form.tabs.title-free")
          : this.getTabName(tabFound))
    );
    this.tabNameList.splice(nameIndex, 1);

    if (tabFound.deliveryFormId !== 0) {
      const tabIndex = this.selectedDeliveryFormList.findIndex(deliveryForm => deliveryForm.id === deliveryFormIdKey);
      this.selectedDeliveryFormList.splice(tabIndex, 1);
    }

    this.invoiceCustomerTabs = [...this.invoiceCustomerTabs];

    // no line values found in all tabs. Resetting footer values
    this.resetFooterValues();

    if (this.invoiceCustomerTabs?.length > 0 && this.tabToDelete?.active) {
      this.onTabClick(this.tabHandler.tabs.first);
    } else {
      this.computeTotals();
    }
  }

  public submitInvoiceCustomer(validate: boolean, print: boolean): void {
    this.shouldValidate = validate;
    this.hasError = false;

    this.invoiceCustomerTabs.forEach((tab: InvoiceCustomerTab) => {
      tab.allRows.forEach((row: InvoiceCustomerLineRow) => {
        row.inError = row.rowForm.invalid;
      });
      tab.allRows = [...tab.allRows];
      tab.rows = [...tab.rows];
    });

    if (this.checkFormErrors()) {
      return;
    }

    this.resetFooterValues();

    if (this.updatedInvoiceCustomer?.lines?.size === 0 || !this.isMapHasValue() || this.totalPrice <= 0) {
      this.generateNullOrNegativeErrorMessage();
      return;
    }

    if (this.editedInvoiceCustomer && this.editedInvoiceCustomer.equals(this.updatedInvoiceCustomer) && !validate) {
      return;
    }

    this.callApi(print);
  }

  public callApi(print: boolean): void {
    if (!this.updatedInvoiceCustomer) {
      return;
    }

    this.updatedInvoiceCustomer.converMapLinesToObject(this.updatedInvoiceCustomer.lines);

    const action = this.updatedInvoiceCustomer && this.updatedInvoiceCustomer.id ? "update" : "create";
    this.subscriptionService.subs.push(
      this.invoiceCustomerService[action].call(this.invoiceCustomerService, this.updatedInvoiceCustomer).subscribe(
        responseInvoiceCustomer => {
          // set id for new free lines
          Object.entries(this.updatedInvoiceCustomer.lines).forEach(([key, entry]: [string, any]) => {
            const lines: InvoiceCustomerLine[] = entry as InvoiceCustomerLine[];
            lines.forEach((line: InvoiceCustomerLine) => {
              if (!line.id) {
                line.id = responseInvoiceCustomer.lines[key].find(
                  responseLine => responseLine.lineNumber === line.lineNumber
                ).id;
              }
            });
          });
          responseInvoiceCustomer.lines = this.convertObjectToMapLines(this.updatedInvoiceCustomer.lines);
          this.finishSave(responseInvoiceCustomer, action, print);
        },
        (error: any) => {
          this.updatedInvoiceCustomer.lines = this.convertObjectToMapLines(this.updatedInvoiceCustomer.lines);
          this.hasError = true;
          this.handleApiError(error);
        }
      )
    );
  }

  isMapHasValue(): boolean {
    let isValid = true;
    if (this.updatedInvoiceCustomer?.lines?.size === 1) {
      this.updatedInvoiceCustomer?.lines?.forEach((_setOfLines: any, key: any) => {
        if (this.updatedInvoiceCustomer?.lines.get(key).size === 0) {
          isValid = false;
        }
      });
    }
    return isValid;
  }

  public finishSave(responseInvoiceCustomer: InvoiceCustomer, action: string, print: boolean): void {
    this.editedInvoiceCustomer = new InvoiceCustomer(responseInvoiceCustomer);
    this.updatedInvoiceCustomer = new InvoiceCustomer(this.editedInvoiceCustomer);

    if (this.shouldValidate) {
      this.validateInvoice(print);
    } else {
      const content = this.translateService.instant("message.content.save-success");
      const title = this.translateService.instant("message.title.save-success");
      this.messageService.success(content, { title });
    }
    if (action === "create") {
      this.sessionPagination.saveToSession(this.listId);
      if (!this.shouldValidate) {
        this.router.navigateByUrl(`/invoice-customer/update/${this.editedInvoiceCustomer.id}`);

        // redirect only if invoice creation is done, not just save
        if (this.updatedInvoiceCustomer.invoiceStatus === InvoiceStatus.VALIDATED) {
          this.redirectToInvoiceCustomerDetails(this.editedInvoiceCustomer.id);
        }
      }
    }
  }

  public handleApiError(error: any): void {
    const result = ErrorUtil.getTranslationKey(error.error, this.translateService);
    const title = this.translateService.instant("message.title.form-errors");
    let content = this.translateService.instant(result.message, result.params);

    if (
      error?.error?.exception &&
      error?.error?.exception?.name === InvoiceCustomerFormComponent.ALREADY_INVOICED_EXCEPTION
    ) {
      const translationKey =
        error.error.exception.parameters.attribute.length > 1
          ? "invoice-customer-form.errors.already-invoiced-plural"
          : "invoice-customer-form.errors.already-invoiced-singular";

      content = this.translateService.instant(translationKey, {
        ref: error.error.exception.parameters.attribute.join(", "),
      });
    }
    this.messageService.error(content, { title });
  }

  public updateInvoiceCustomer(): boolean {
    if (!this.form.invalid) {
      this.applyModifications();
      return true;
    }
    this.form.markAllAsTouched();
    this.showInvalidFormMessage();
    return false;
  }

  public applyModifications(): void {
    if (!this.updatedInvoiceCustomer) {
      return;
    }
    this.updatedInvoiceCustomer.shippingFeePrice = RoundingUtil.roundHigh(
      this.form.get(InvoiceCustomerFormComponent.SHIPPING_FEE_PRICE)?.value
    );
    this.updatedInvoiceCustomer.extraPrice = RoundingUtil.roundHigh(
      this.form.get(InvoiceCustomerFormComponent.EXTRA_PRICE)?.value
    );

    if (!(this.updatedInvoiceCustomer.lines instanceof Map)) {
      this.updatedInvoiceCustomer.lines = this.convertObjectToMapLines(this.updatedInvoiceCustomer?.lines);
    } else {
      this.updatedInvoiceCustomer.lines.forEach((value, key) => {
        if (this.updatedInvoiceCustomer.lines.get(key)) {
          value.forEach((line: InvoiceCustomerLine) => {
            const lineForm = this.getLineRowForm(line);

            const changes = lineForm.value;
            delete changes.batchNumber;
            delete changes.serialNumber;
            changes.unitPrice = RoundingUtil.roundHigh(lineForm?.get(InvoiceCustomerFormComponent.UNIT_PRICE)?.value);
            changes.discount = RoundingUtil.roundHigh(lineForm?.get(InvoiceCustomerFormComponent.DISCOUNT)?.value);
            changes.discountType = parseDiscountType(lineForm?.get(InvoiceCustomerFormComponent.DISCOUNT_TYPE)?.value);
            if (!line.newDeliveryLineId) {
              changes.quantity = +lineForm?.get(InvoiceCustomerFormComponent.QUANTITY)?.value;
              changes.metalWeight = +lineForm?.get(InvoiceCustomerFormComponent.METAL_WEIGHT)?.value;
              changes.tare = +lineForm?.get(InvoiceCustomerFormComponent.TARE)?.value;

              const tare = changes.tare ? changes.tare : 0;
              const metalWeight = changes.metalWeight ? changes.metalWeight : 0;
              changes.weight = metalWeight + tare;

              const serialNumber = lineForm?.get(InvoiceCustomerFormComponent.SERIAL_NUMBER)?.value;
              const batchNumber = lineForm?.get(InvoiceCustomerFormComponent.BATCH_NUMBER)?.value;
              let stockType = null;
              if (serialNumber) {
                stockType = StockType.SERIAL_NUMBER;
              } else if (batchNumber) {
                stockType = StockType.BATCH;
              }
              changes.stockEntryIds = serialNumber ? serialNumber : batchNumber;
              changes.stockType = stockType;
            }

            patch(line, changes);
          });
        }
      });
    }
  }

  public showInvalidFormMessage(): void {
    const title = this.translateService.instant("message.title.form-errors");
    const tabNames = [];
    this.invoiceCustomerTabs.forEach((tab: InvoiceCustomerTab) => {
      if (tab?.tabForm?.invalid) {
        tabNames.push(tab?.deliveryRef);
      }
    });
    if (tabNames.length > 1) {
      tabNames.join(", ");
    }
    const content = this.translateService.instant("invoice-customer-form.message.form-errors", { tabNames });
    this.messageService.error(content, { title });
  }

  public manageActions(actionId: number, row: InvoiceCustomerLineRow, tab: InvoiceCustomerTab): void {
    switch (actionId) {
      case this.DELETE_ACTION_ID:
        this.removeLine(row, tab);
        break;
      default:
        console.error(`Don't know how to handle action : ${actionId}`);
        break;
    }
  }

  public removeLine(selectedRow: InvoiceCustomerLineRow, activeTab: InvoiceCustomerTab): void {
    // delete line(s)
    activeTab?.allRows.splice(activeTab.allRows.indexOf(selectedRow), 1);

    let map = this.updatedInvoiceCustomer.lines;
    const deliveryFormIdKey = activeTab?.deliveryFormId ? activeTab?.deliveryFormId?.toString() : "0";

    if (this.updatedInvoiceCustomer?.lines instanceof Map) {
      if (!map.has(deliveryFormIdKey)) {
        return;
      }
    } else {
      if (!Object.keys(map).includes(deliveryFormIdKey)) {
        return;
      }
    }

    // update map and object
    let list: InvoiceCustomerLine[] | any[] = [];
    if (this.updatedInvoiceCustomer?.lines instanceof Map) {
      list = Array.from(map.get(deliveryFormIdKey));
    } else {
      if (Object.keys(map).includes(deliveryFormIdKey)) {
        list = Object.values(map).flat();
      }
    }

    list.forEach((item, index) => {
      if (item?.lineNumber === selectedRow?.lineNumber) {
        list.splice(index, 1);
      }
    });

    if (!this.route.snapshot.params.id) {
      activeTab.allRows.forEach(row => {
        if (row.lineNumber > selectedRow.lineNumber) {
          row.lineNumber -= 1;
        }
      });
      list.forEach(line => {
        if (line.lineNumber > selectedRow.lineNumber && line.newDeliveryFormId === activeTab.deliveryFormId) {
          line.lineNumber -= 1;
        }
      });
    }

    list.sort((a, b) => a.lineNumber.toString().localeCompare(b.lineNumber.toString()));

    map = new Map();
    map.set(deliveryFormIdKey, new Set(list));
    map.forEach((setOfLines: InvoiceCustomerLine, key: string) => {
      if (this.updatedInvoiceCustomer?.lines instanceof Map && this.updatedInvoiceCustomer?.lines.has(key)) {
        this.updatedInvoiceCustomer?.lines.set(key, setOfLines);
      } else {
        if (Object.keys(this.updatedInvoiceCustomer?.lines).includes(key)) {
          this.updateLines(this.updatedInvoiceCustomer?.lines, list, key);
        }
      }
    });

    // sort lines
    activeTab.allRows.sort((a, b) => a.lineNumber.toString().localeCompare(b.lineNumber.toString()));
    activeTab.rows.forEach(row => {
      if (selectedRow.lineNumber === row.lineNumber) {
        activeTab.rows.splice(activeTab.rows.indexOf(selectedRow), 1);
      }
    });
    activeTab.rows.sort((a, b) => a.lineNumber.toString().localeCompare(b.lineNumber.toString()));
    activeTab.rows = [...activeTab.rows];

    // remove the form control and update accordingly, depending on the case
    activeTab.tabForm.removeControl(selectedRow?.lineNumber.toString());

    const idxTable = this.invoiceCustomerTabs.findIndex((tab: InvoiceCustomerTab) => tab?.id === activeTab?.id);
    this.invoiceCustomerTabs[idxTable] = activeTab;

    // no line values found. Resetting footer values
    this.resetFooterValues();

    this.computeTotals();
  }

  public isDirty(): boolean {
    if (!this.updatedInvoiceCustomer) {
      return false;
    }
    if (this.invoiceHeader) {
      this.invoiceHeader.applyModifications();
      if (this.fetcher.isConform) {
        this.updatedInvoiceCustomer.date = this.editedInvoiceCustomer.date;
        this.updatedInvoiceCustomer.quickPaymentDate = this.editedInvoiceCustomer.quickPaymentDate;
        this.updatedInvoiceCustomer.maxPaymentDate = this.editedInvoiceCustomer.maxPaymentDate;
      }
    }
    this.applyModifications();
    if (this.unsavedInvoiceCustomer && !this.unsavedInvoiceCustomer.equals(this.updatedInvoiceCustomer)) {
      this.shouldClose = false;
    }
    if (
      (this.editedInvoiceCustomer &&
        !this.editedInvoiceCustomer.equals(this.updatedInvoiceCustomer) &&
        !this.shouldClose) ||
      (!this.editedInvoiceCustomer && !this.shouldClose)
    ) {
      this.unsavedInvoiceCustomer = new InvoiceCustomer(this.updatedInvoiceCustomer);
      this.shouldClose = true;
      return true;
    }
    this.unsavedInvoiceCustomer = null;
    this.shouldClose = false;
    return false;
  }

  public getBackButtonTranslation(): string {
    if (this.backUrl && this.backUrl.startsWith("/invoice-customer-list")) {
      return "invoice-customer-form.buttons.back-to-list";
    }
    return "invoice-customer-form.buttons.back-to-details";
  }

  public backToPrevious(): void {
    if (this.backUrl === "/invoice-customer-list") {
      SessionPagination.clear(this.LIST_ID);
      SessionPagination.clear(this.READ_LIST_ID);
      SessionPagination.clear(InvoiceCustomerDeliverySelectionComponent.LIST_ID);
      // delete filter values in detail page and update page
      this.invoiceCustomerTabs.forEach((tab: InvoiceCustomerTab) => {
        SessionPagination.clear(`${this.LIST_ID}-tab-${tab.id}`);
        SessionPagination.clear(`${this.READ_LIST_ID}-tab-${tab.id}`);
      });
    }
    this.router.navigateByUrl(this.backUrl);
  }

  changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
  }

  openInvoiceCustomer(): void {
    if (this.totalPrice <= 0) {
      this.sendErrorValidate(
        this.translateService.instant("invoice-customer-form.errors.open-validate-pop-up"),
        "error"
      );
      return;
    }
    if (this.checkFormErrors()) {
      return;
    }
    this.validatePopupVisible = true;
  }

  validateInvoice(print: boolean): void {
    this.subscriptionService.subs.push(
      this.invoiceCustomerService.validate(this.editedInvoiceCustomer?.id).subscribe(
        () => {
          const content = this.translateService.instant("message.content.save-success");
          const title = this.translateService.instant("message.title.save-success");
          this.messageService.success(content, { title });
          if (print) {
            this.subscriptionService.subs.push(
              this.invoiceCustomerService
                .get(this.editedInvoiceCustomer?.id)
                .subscribe((invoiceValidated: InvoiceCustomer) => {
                  this.subscriptionService.subs.push(
                    this.invoiceCustomerService.pdf(this.editedInvoiceCustomer?.id).subscribe(
                      response => {
                        const blob = new Blob([response], {
                          type: "application/pdf",
                        });
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.download = invoiceValidated.reference;
                        a.href = url;
                        a.click();
                        this.backToPrevious();
                      },
                      () => {
                        this.messageService.error("global.server-errors.pdf");
                      }
                    )
                  );
                })
            );
          } else {
            this.backToPrevious();
          }
        },
        error => {
          this.hasError = true;
          this.handleApiError(error);
        }
      )
    );
  }

  closeInvoiceCustomerValidation(): void {
    this.validatePopupVisible = false;
  }

  validateInvoiceCustomerEvent(print: boolean): void {
    this.submitInvoiceCustomer(true, print);
  }

  onOneInvoicePerStoreCheckboxChange(oneInvoicePerStore: boolean): void {
    this.oneInvoicePerStore = oneInvoicePerStore;
  }

  inUpdateState(): boolean {
    return this.updatedInvoiceCustomer && this.updatedInvoiceCustomer.contactId !== null;
  }

  maxPaymentType(): string {
    return this.getCommercialModalityWithAttribute(InvoiceCustomerFormComponent.MAX_PAYMENT_TYPE);
  }

  private completeInit(): void {
    this.subscriptionService.subs.push(this.getCustomer(this.editedInvoiceCustomer.customerId).subscribe());
    this.currency = this.fetcher.currencyList.find(cur => cur.id === this.updatedInvoiceCustomer.currencyId);
    this.title = this.readOnly ? "invoice-customer-form.detail.title" : "invoice-customer-form.title";
    this.invoiceCustomerNumber = this.updatedInvoiceCustomer?.reference;

    this.buildDiscountOptions();
    this.buildRows(this.updatedInvoiceCustomer, null);
    this.finalStep();
    this.updatedInvoiceCustomer.equals(this.editedInvoiceCustomer);
    if (
      this.updatedInvoiceCustomer.invoiceStatus === InvoiceCustomerFormComponent.VALIDATED &&
      this.router.routerState.snapshot.url.indexOf(InvoiceCustomerFormComponent.UPDATE) > 0
    ) {
      this.router.navigateByUrl(`/invoice-customer-detail/${this.updatedInvoiceCustomer.id}`);
      return;
    }
  }

  private buildDiscountOptions(): void {
    this.discountOptions = [];
    this.discountOptions.push(new Option(Object.keys(DiscountType).indexOf(DiscountType.PERCENT), "%"));
    this.discountOptions.push(new Option(Object.keys(DiscountType).indexOf(DiscountType.VALUE), this.currency?.symbol));
  }

  private buildOptions(tab: InvoiceCustomerTab): void {
    const numericSorter = new Intl.Collator(this.fetcher.locale, { numeric: true });

    const rawSizeValueList = tab?.allRows
      .filter((obj: InvoiceCustomerLineRow) => obj.sizeValue)
      .map((obj: InvoiceCustomerLineRow) => obj.sizeValue);
    this.sizeValueList = [...new Set(rawSizeValueList)].sort((a, b) => numericSorter.compare(a, b));

    const rawBrandList = tab?.allRows
      .filter((obj: InvoiceCustomerLineRow) => obj.brandName)
      .map((obj: InvoiceCustomerLineRow) => obj.brandName);
    this.brandList = [...new Set(rawBrandList)].sort((a, b) => a.localeCompare(b));
  }

  private getCustomer(id: number): Observable<AbstractCustomer> {
    return this.customerService.get(id).pipe(
      tap(
        (customer: AbstractCustomer) => {
          this.customer = customer;
        },
        error => {
          this.sendErrorAlert("invoice-customer-form.errors.get-customer", error.message);
        }
      )
    );
  }

  private fetchPricingGroup(id: number): Observable<PricingGroup> {
    return this.pricingGroupService.get(id).pipe(
      tap(
        (pricingGroup: PricingGroup) => (this.currentPricingGroup = pricingGroup),
        (error: any) => this.handleApiError(error)
      )
    );
  }

  private fetchLinkedItems(idsObj: {
    pmIds: Set<number>;
    polIds: Set<number>;
  }): Observable<PaginatedList<AbstractItem>> {
    const polIdList = idsObj.polIds;
    const pmIdList = idsObj.pmIds;

    if (polIdList.size > 0) {
      const filters = [
        new SearchFilter(InvoiceCustomerFormComponent.LINES_ID, SearchFilterOperator.IN, [...polIdList]),
      ];
      const pager = new Pagination({
        size: polIdList.size,
        number: 0,
      });

      return this.purchaseOrderService.getAll(pager, [], filters).pipe(
        tap({
          error: () => {
            const title = this.translateService.instant("message.title.data-errors");
            const content = this.translateService.instant("invoice-customer-form.errors.get-purchase-orders");
            this.messageService.warn(content, { title });
          },
        }),
        mergeMap((result: PaginatedList<PurchaseOrder>) => {
          this.purchaseOrderList = result.data;
          this.purchaseOrderList.forEach((purchaseOrder: PurchaseOrder) => {
            purchaseOrder.lines.forEach((pol: PurchaseOrderLine) => {
              pmIdList.add(pol.purchaseModalityId);
            });
          });

          return this.fetchItemsWithIds(pmIdList);
        })
      );
    } else {
      return this.fetchItemsWithIds(pmIdList);
    }
  }

  private fetchItemsWithIds(idList: Set<number>, havePm: boolean = true): Observable<PaginatedList<AbstractItem>> {
    const pager = new Pagination({
      size: idList.size,
      number: 0,
    });

    let filters = null;
    if (havePm) {
      filters = new SearchFilter(InvoiceCustomerFormComponent.PURCHASE_MODALITIES_ID, SearchFilterOperator.IN, [
        ...idList,
      ]);
    } else {
      filters = new SearchFilter(InvoiceCustomerFormComponent.ID, SearchFilterOperator.IN, [...idList]);
    }

    return this.retailItemService.getAll(pager, [], [filters]).pipe(
      tap(
        (result: PaginatedList<AbstractItem>) => {
          result.data.forEach(item => {
            if (!this.retailItemList.includes(item)) {
              this.retailItemList.push(item);
            }
          });
        },
        error => {
          this.sendErrorAlert("invoice-customer-form.errors.get-retail-items", error.message);
        }
      )
    );
  }

  private fetchStockEntries(stockEntryIdList: Set<number>): Observable<PaginatedList<StockEntry>> {
    const pager = new Pagination({
      size: stockEntryIdList.size,
      number: 0,
    });
    const filters = new SearchFilter(InvoiceCustomerFormComponent.ID, SearchFilterOperator.IN, [...stockEntryIdList]);

    return this.stockEntryService.getAll(pager, [], [filters]).pipe(
      tap(
        (result: PaginatedList<StockEntry>) => {
          result.data.forEach(stockEntry => {
            if (!this.stockEntryList.includes(stockEntry)) {
              this.stockEntryList.push(stockEntry);
            }
          });
        },
        (error: any) => this.handleApiError(error)
      )
    );
  }

  private fetchItemCategories(): Observable<ItemCategory[]> {
    return this.itemCategoryService.getAll().pipe(
      tap(
        (categories: ItemCategory[]) => categories,
        (error: any) => this.handleApiError(error)
      )
    );
  }

  private fetchDeliveryForms(): Observable<PaginatedList<ReceivingForm>> {
    const filters = [
      new SearchFilter(InvoiceCustomerFormComponent.ID, SearchFilterOperator.IN, [...this.deliveryFormIds]),
    ];
    const pager = new Pagination({
      size: this.deliveryFormIds.size,
      number: 0,
    });
    if (this.deliveryFormIds.size > 0) {
      return this.receivingFormService.getAll(pager, [], filters).pipe(
        tap(
          (result: PaginatedList<ReceivingForm>) => {
            this.deliveryFormList = result.data;
          },
          error => {
            this.sendErrorAlert("invoice-customer-form.errors.get-delivery-forms", error.message);
          }
        )
      );
    } else {
      return of(null);
    }
  }

  private fetchReceiver(receiverId: number): Observable<Store> {
    return this.storeService.get(receiverId).pipe(
      tap(
        (store: Store) => {
          this.store = store;
        },
        error => {
          this.sendErrorAlert("invoice-customer-form.errors.get-receiver", error.message);
        }
      )
    );
  }

  private buildInvoiceCustomer(deliveryFormFound: ReceivingForm, selectedId: number = null): InvoiceCustomer {
    // update updatedInvoiceCustomer
    if (this.updatedInvoiceCustomer) {
      const lastCreatedMap = this.createInvoiceCustomerLines(deliveryFormFound);
      // avoid duplicates
      const mapOfLines = new Map<string, Set<InvoiceCustomerLine>>(this.updatedInvoiceCustomer.lines);
      // update the lines
      lastCreatedMap.forEach((value, key) => {
        if (mapOfLines.has(key)) {
          const existingSet = mapOfLines.get(key);
          value.forEach((line: InvoiceCustomerLine) => {
            existingSet.forEach((l: InvoiceCustomerLine) => {
              if (l.newDeliveryLineId !== null && l.newDeliveryLineId === line.newDeliveryLineId) {
                existingSet.delete(l);
              }
            });
            existingSet.add(line);
          });
        } else {
          mapOfLines.set(key, value);
        }
      });
      this.updatedInvoiceCustomer.lines = mapOfLines;
    } else {
      const mapOfLines = new Map<string, Set<InvoiceCustomerLine>>();
      const setOfInvoiceCustomerLines: Set<InvoiceCustomerLine> = new Set();
      mapOfLines.set("0", setOfInvoiceCustomerLines);
      this.updatedInvoiceCustomer = new InvoiceCustomer({
        reference: null,
        date: null,
        maxPaymentDate: null,
        deliveryReferences: [],
        quickPaymentDate: null,
        quickPaymentDiscount: this.getCommercialModalityWithAttribute(
          InvoiceCustomerFormComponent.QUICK_PAYMENT_DISCOUNT
        ),
        invoiceStatus: InvoiceStatus.DRAFT,
        contactId: deliveryFormFound ? deliveryFormFound.receiverCustomerId : selectedId,
        currencyId: deliveryFormFound ? deliveryFormFound.currencyId : this.currency.id,
        shippingFeePrice: 0,
        extraPrice: 0,
        totalGrossPrice: 0,
        totalExtraPrice: 0,
        totalPrice: 0,
        quickPaymentPrice: 0,
        comment: null,
        lines: deliveryFormFound ? this.createInvoiceCustomerLines(deliveryFormFound) : mapOfLines,
      });
    }
    return this.updatedInvoiceCustomer;
  }

  private createInvoiceCustomerLine(
    line: AbstractReceivingLine,
    index: number,
    deliveryFormFound: ReceivingForm,
    retailItem: AbstractItem,
    stockEntry?: StockEntry,
    pol?: PurchaseOrderLine
  ): InvoiceCustomerLine {
    const invoiceCustomerline = new InvoiceCustomerLine({
      lineNumber: index + 1,
      discountType: DiscountType.PERCENT,
      discount: this.customer?.commercialModality?.discountRate ?? 0,
      // method to apply margin if it's an EXTERNAL_SUPPLIER type
      unitPrice: this.commonService.computeMarginForUnitPrice(
        this.currentPricingGroup,
        line,
        retailItem as StandardItem
      ),
      newDeliveryFormId: deliveryFormFound?.id,
      newDeliveryLineId: line?.id,
      vatRate: retailItem?.vatRate,
      weight: line?.weight,
      tare: line?.tare,
      quantity: line?.expectedQuantity,
      sizeValue: line?.expectedSizeValue,
      itemSupplierRef: stockEntry !== null ? stockEntry.itemSupplierRef : line.supplierRef,
      itemRef: this.getItemReference(stockEntry, retailItem as StandardItem, pol),
      itemName: this.getItemName(stockEntry, retailItem as StandardItem, pol),
      unitName: this.getUnitName(line, stockEntry, retailItem as StandardItem, pol),
      stockType: this.getStockType(stockEntry, retailItem as StandardItem),
      brandName: this.getBrandName(stockEntry, retailItem as StandardItem, pol),
      supplierName: this.getSupplierName(stockEntry, retailItem as StandardItem, pol, line),
      supplierRef: this.getSupplierRef(stockEntry, retailItem as StandardItem, pol, line),
      stockEntryIds: stockEntry ? [stockEntry.id].toString() : line.getGeneratedStockEntryIds().toString(),
    });
    return invoiceCustomerline;
  }

  private createFreeInvoiceCustomerLine(index: number, deliveryFormId: number): InvoiceCustomerLine {
    const invoiceCustomerline = new InvoiceCustomerLine({
      lineNumber: index + 1,
      discountType: DiscountType.PERCENT,
      discount: null,
      unitPrice: null,
      newDeliveryFormId: deliveryFormId,
      newDeliveryLineId: null,
      vatRate: null,
      weight: null,
      tare: null,
      quantity: null,
      sizeValue: null,
      itemSupplierRef: null,
      itemRef: null,
      itemName: null,
      unitName: null,
      stockType: null,
      brandName: null,
      supplierName: null,
      supplierRef: null,
      stockEntryIds: null,
    });
    return invoiceCustomerline;
  }

  private getItemReference(
    stockEntry?: StockEntry,
    retailItem?: StandardItem,
    purchaseOrderLine?: PurchaseOrderLine
  ): string {
    if (stockEntry !== null) {
      return stockEntry?.itemReference;
    }
    if (retailItem !== null) {
      return retailItem?.reference;
    }
    if (purchaseOrderLine !== null) {
      return purchaseOrderLine?.itemReference;
    }
    return null;
  }

  private getItemName(
    stockEntry?: StockEntry,
    retailItem?: StandardItem,
    purchaseOrderLine?: PurchaseOrderLine
  ): string {
    if (stockEntry !== null) {
      return stockEntry?.itemName;
    }
    if (purchaseOrderLine !== null) {
      return purchaseOrderLine?.itemName;
    }
    if (retailItem !== null) {
      return retailItem?.name;
    }
    return null;
  }

  private getUnitName(
    line: AbstractReceivingLine,
    stockEntry?: StockEntry,
    retailItem?: StandardItem,
    purchaseOrderLine?: PurchaseOrderLine
  ): string {
    if (stockEntry !== null) {
      return this.uoms.find((uom: Uom) => uom.id === stockEntry.uomId)?.longName;
    }
    if (retailItem !== null && line instanceof ReceivingFreeLine) {
      return this.uoms.find(
        (uom: Uom) => uom.id === this.getPurchaseModality(line?.purchaseModalityId, retailItem)?.purchaseUnitId
      )?.longName;
    }
    if (purchaseOrderLine !== null && line instanceof ReceivingPOLine) {
      return this.uoms.find(
        (uom: Uom) =>
          uom.id === this.getPurchaseModality(purchaseOrderLine?.purchaseModalityId, retailItem)?.purchaseUnitId
      )?.longName;
    }
    return null;
  }

  private getStockType(stockEntry?: StockEntry, retailItem?: StandardItem): StockType {
    if (stockEntry !== null) {
      return stockEntry?.stockType;
    }
    if (retailItem !== null) {
      return retailItem?.stockType;
    }
    return null;
  }

  private getBrandName(
    stockEntry?: StockEntry,
    retailItem?: StandardItem,
    purchaseOrderLine?: PurchaseOrderLine
  ): string {
    if (stockEntry !== null) {
      return stockEntry?.itemBrandName;
    }
    if (purchaseOrderLine !== null) {
      return purchaseOrderLine?.brandName;
    }
    if (retailItem !== null) {
      return retailItem?.brandName;
    }
    return null;
  }

  private getSupplierName(
    stockEntry?: StockEntry,
    retailItem?: StandardItem,
    purchaseOrderLine?: PurchaseOrderLine,
    line?: AbstractReceivingLine
  ): string {
    if (stockEntry !== null) {
      return stockEntry?.supplierName;
    }
    if (retailItem !== null && line instanceof ReceivingFreeLine) {
      return this.fetcher.supplierLightList.find(
        (supplier: Light) => supplier.id === this.getPurchaseModality(line?.purchaseModalityId, retailItem)?.supplierId
      )?.name;
    }
    if (purchaseOrderLine !== null && line instanceof ReceivingPOLine) {
      return this.fetcher.supplierLightList.find(
        (supplier: Light) =>
          supplier.id === this.getPurchaseModality(purchaseOrderLine?.purchaseModalityId, retailItem)?.supplierId
      )?.name;
    }
    return null;
  }

  private getSupplierRef(
    stockEntry?: StockEntry,
    retailItem?: StandardItem,
    purchaseOrderLine?: PurchaseOrderLine,
    line?: AbstractReceivingLine
  ): string {
    if (stockEntry !== null) {
      return stockEntry?.supplierRef;
    }
    if (retailItem !== null && line instanceof ReceivingFreeLine) {
      return this.getPurchaseModality(line?.purchaseModalityId, retailItem)?.supplierRef;
    }
    if (purchaseOrderLine !== null) {
      return purchaseOrderLine?.supplierRef;
    }
    return null;
  }

  private updateDiscountMaxValidator(row: InvoiceCustomerLineRow, rowForm: UntypedFormGroup): void {
    if (
      !rowForm.get(InvoiceCustomerFormComponent.DISCOUNT_TYPE) ||
      !rowForm.get(InvoiceCustomerFormComponent.DISCOUNT)
    ) {
      return;
    }

    let discountType = parseDiscountType(rowForm.get(InvoiceCustomerFormComponent.DISCOUNT_TYPE).value);
    discountType = discountType ? discountType : row.discountType;
    rowForm.get(InvoiceCustomerFormComponent.DISCOUNT).clearValidators();
    rowForm.get(InvoiceCustomerFormComponent.DISCOUNT).setErrors({});
    const unitPrice = RoundingUtil.roundHigh(row?.rowForm?.get(InvoiceCustomerFormComponent.UNIT_PRICE)?.value, true);

    switch (discountType) {
      case DiscountType.VALUE:
        rowForm
          .get(InvoiceCustomerFormComponent.DISCOUNT)
          .setValidators(
            this.minimalValidators.concat(
              Validators.required,
              CommonValidatorsUtil.maxDiscountErrorValidator(rowForm, unitPrice)
            )
          );
        break;
      case DiscountType.PERCENT:
        rowForm
          .get(InvoiceCustomerFormComponent.DISCOUNT)
          .setValidators(
            this.minimalValidators.concat(
              Validators.required,
              CommonValidatorsUtil.maxDiscountErrorValidator(rowForm, this.HUNDRED)
            )
          );
        break;
      default:
        break;
    }
    rowForm.get(InvoiceCustomerFormComponent.DISCOUNT).updateValueAndValidity({ emitEvent: false });
  }

  private createInvoiceCustomerLines(deliveryFormFound: ReceivingForm): Map<string, Set<InvoiceCustomerLine>> {
    const maplines: Map<string, Set<InvoiceCustomerLine>> = new Map();
    const setOfInvoiceCustomerLines: Set<InvoiceCustomerLine> = new Set();

    deliveryFormFound?.lines
      .filter(
        elem =>
          !elem.invoiced && elem.status !== DeliveryLineStatus.REFUSED && elem.status !== DeliveryLineStatus.PENDING
      )
      .forEach((line: AbstractReceivingLine, index: number) => {
        let stockEntry = null;

        if (this.stockEntryList?.length > 0 && line instanceof ReceivingInternalLine) {
          stockEntry = this.stockEntryList.find((se: StockEntry) => se?.id === line?.stockEntryId);
        }

        let retailItem = null;
        if (stockEntry && this.retailItemList?.length > 0) {
          retailItem = this.retailItemList.find((item: AbstractItem) => item?.id === stockEntry?.retailItemId);
        }

        let polForPm = null;
        // data from PO-> POL -> PM
        if (this.purchaseOrderList?.length > 0 && line instanceof ReceivingPOLine) {
          const purchaseOrder = this.purchaseOrderList.find((po: PurchaseOrder) =>
            po?.lines.find((pol: PurchaseOrderLine) => pol?.id === line?.purchaseOrderLineId)
          );
          if (purchaseOrder) {
            polForPm = purchaseOrder?.lines.find((pol: PurchaseOrderLine) => pol?.id === line?.purchaseOrderLineId);
            retailItem = this.getItemFromPM(polForPm?.purchaseModalityId);
          }
        }

        // data from PM
        if (line instanceof ReceivingFreeLine) {
          retailItem = this.getItemFromPM(line?.purchaseModalityId);
        }

        const invoiceCustomerline = this.createInvoiceCustomerLine(
          line,
          index,
          deliveryFormFound,
          retailItem,
          stockEntry,
          polForPm
        );
        setOfInvoiceCustomerLines.add(invoiceCustomerline);
      });
    maplines.set(deliveryFormFound.id.toString(), setOfInvoiceCustomerLines);
    return maplines;
  }

  private fillIdsObj(receivingFormFounds: ReceivingForm[]): {
    pmIds: Set<number>;
    polIds: Set<number>;
    stEIds: Set<number>;
  } {
    const pmIds = new Set<number>();
    const polIds = new Set<number>();
    const stEIds = new Set<number>();

    if (receivingFormFounds?.length > 0) {
      receivingFormFounds.forEach((deliveryForm: ReceivingForm) => {
        this.deliveryFormIds.add(deliveryForm?.id);
        this.deliveryFormList.push(deliveryForm);

        deliveryForm.lines.forEach((deliveryLine: AbstractReceivingLine) => {
          if (deliveryLine instanceof ReceivingFreeLine) {
            pmIds.add(deliveryLine?.purchaseModalityId);
          } else if (deliveryLine instanceof ReceivingPOLine) {
            polIds.add(deliveryLine?.purchaseOrderLineId);
          } else if (deliveryLine instanceof ReceivingInternalLine) {
            stEIds.add(deliveryLine?.stockEntryId);
          }
        });
      });
    }
    return { pmIds, polIds, stEIds };
  }

  private buildRows(invoiceCustomer: InvoiceCustomer, deliveryFormFound: ReceivingForm): void {
    if (invoiceCustomer?.lines instanceof Map) {
      invoiceCustomer.lines.forEach((lineSet: Set<InvoiceCustomerLine>, _mapKey: any) => {
        lineSet.forEach((line: InvoiceCustomerLine) => {
          this.buildRow(line, deliveryFormFound);
        });
      });
    } else {
      Object.entries(invoiceCustomer?.lines).forEach((setOfLines, _key) => {
        const lines: InvoiceCustomerLine[] = setOfLines[1] as InvoiceCustomerLine[];
        lines.forEach((line: InvoiceCustomerLine) => {
          this.buildRow(line, deliveryFormFound);
        });
      });
    }
  }

  private buildRow(invoiceCustomerLine: InvoiceCustomerLine, deliveryFormFound: ReceivingForm): void {
    const row = this.getLineRow(invoiceCustomerLine);
    row.rowForm.get(InvoiceCustomerFormComponent.DISCOUNT).markAsTouched();
    this.buildTabs(invoiceCustomerLine, row, deliveryFormFound);
    this.onTabClick(this.tabHandler?.tabs?.first);
  }

  private getReceiverName(deliveryFormFound: ReceivingForm): string {
    let receiver = this.fetcher.storeList?.find(sto => sto.id === deliveryFormFound?.receiverId);
    if (receiver === undefined) {
      receiver = this.fetcher.customerList?.find(
        (customer: LightCustomer) => customer.id === deliveryFormFound?.receiverCustomerId
      );
    }
    return receiver?.name;
  }

  private getSenderName(id: number): string {
    let sender = this.fetcher.supplierLightList?.find(supp => supp.id === id);
    if (sender === undefined) {
      sender = this.fetcher.storeList?.find(store => store.id === id);
    }
    if (sender === undefined) {
      return id.toString();
    }
    return sender?.name;
  }

  private buildTabs(
    invoiceCustomerLine: InvoiceCustomerLine,
    row: InvoiceCustomerLineRow,
    deliveryFormFound?: ReceivingForm
  ): void {
    let tabIndex = this.invoiceCustomerTabs?.findIndex(
      tab => tab?.deliveryFormId === invoiceCustomerLine?.newDeliveryFormId
    );
    deliveryFormFound = this.deliveryFormList.find(df => df.id === invoiceCustomerLine?.newDeliveryFormId);
    if (!this.invoiceCustomerTabs[tabIndex]) {
      const tabFound = new InvoiceCustomerTab();
      tabFound.deliveryFormId = invoiceCustomerLine?.newDeliveryFormId;
      tabFound.deliveryRef = this.getTabName(tabFound);
      if (invoiceCustomerLine?.newDeliveryFormId !== null) {
        tabFound.receiverName = this.getReceiverName(deliveryFormFound);
        tabFound.senderName = this.getSenderName(deliveryFormFound.senderId);
      } else {
        tabFound.senderName = this.fetcher.mainStore.name;
        tabFound.receiverName = this.getFreeReceiverName();
      }
      tabFound.rows = [];
      tabFound.allRows = [];
      tabFound.id = this.tabId++;
      tabFound.tabForm = new UntypedFormGroup({});
      this.invoiceCustomerTabs?.push(tabFound);
      tabIndex = this.invoiceCustomerTabs?.findIndex(
        tab => tab?.deliveryFormId === invoiceCustomerLine?.newDeliveryFormId
      );
      this.form.addControl((this.invoiceCustomerTabs.length - 1).toString(), tabFound.tabForm);

      const tabName: string =
        this.getTabName(tabFound) === null
          ? this.translateService.instant("invoice-customer-form.tabs.title-free")
          : this.getTabName(tabFound);

      this.tabNameList.push(tabName);
      if (
        deliveryFormFound &&
        !this.updatedInvoiceCustomer?.deliveryReferences?.includes(deliveryFormFound.deliveryRef)
      ) {
        this.updatedInvoiceCustomer.deliveryReferences?.push(deliveryFormFound.deliveryRef);
      }
    }

    this.deletingDeliveryFormList = this.deletingDeliveryFormList.filter(df => df.id !== deliveryFormFound?.id);

    // If the tabs is already saved, skip it to prevent lines duplicate
    if (
      this.invoiceCustomerTabs[tabIndex].allRows.find(
        invoiceRow => row.lineNumber && invoiceRow.lineNumber === row.lineNumber
      )
    ) {
      return;
    }

    this.invoiceCustomerTabs[tabIndex].rows.sort((a, b) =>
      a.lineNumber.toString().localeCompare(b.lineNumber.toString())
    );
    this.invoiceCustomerTabs[tabIndex].rows.push(row);
    this.invoiceCustomerTabs[tabIndex].allRows.sort((a, b) =>
      a.lineNumber.toString().localeCompare(b.lineNumber.toString())
    );

    this.invoiceCustomerTabs[tabIndex].allRows.push(row);

    this.invoiceCustomerTabs[tabIndex].tabForm.addControl(row.lineNumber.toString(), row.rowForm);
  }

  private getFreeReceiverName(): string {
    let store;
    const customer = this.fetcher.customerList.find(cus => cus.id === this.updatedInvoiceCustomer.contactId);
    if (customer === undefined) {
      store = this.fetcher.storeList.find(st => st.id === this.updatedInvoiceCustomer.contactId);
    }
    return customer ? customer.name : store.name;
  }

  private getLineRow(invoiceCustomerLine: InvoiceCustomerLine): InvoiceCustomerLineRow {
    const row = new InvoiceCustomerLineRow();
    row.id = invoiceCustomerLine?.id;
    row.lineNumber = invoiceCustomerLine?.lineNumber;
    row.itemSupplierRef = invoiceCustomerLine?.itemSupplierRef;
    row.itemRef = invoiceCustomerLine?.itemRef;
    row.itemName = invoiceCustomerLine?.itemName;
    row.brandName = invoiceCustomerLine?.brandName;
    row.supplierName = invoiceCustomerLine?.supplierName;
    row.supplierRef = invoiceCustomerLine?.supplierRef;
    row.quantity = invoiceCustomerLine?.quantity;
    row.unitName = invoiceCustomerLine?.unitName;
    row.sizeValue = invoiceCustomerLine?.sizeValue;
    row.unitPrice = invoiceCustomerLine?.unitPrice;
    row.weight = invoiceCustomerLine?.weight;
    row.tare = invoiceCustomerLine?.tare;
    row.discount = invoiceCustomerLine?.discount;
    row.discountType = invoiceCustomerLine?.discountType;
    row.metalWeight = this.computeMetalWeight(row);
    row.vatRate = invoiceCustomerLine?.vatRate;
    row.serialNumber =
      StockType.SERIAL_NUMBER === invoiceCustomerLine?.stockType
        ? invoiceCustomerLine?.stockEntryIds?.toString()
        : null;
    row.batchNumber =
      StockType.BATCH === invoiceCustomerLine?.stockType ? invoiceCustomerLine?.stockEntryIds?.toString() : null;

    row.rowForm = this.fb.group({
      unitPrice: [
        RoundingUtil.roundHigh(invoiceCustomerLine?.unitPrice, true),
        [Validators.required, this.digitValidator],
      ],
      discount: [invoiceCustomerLine?.discount ? invoiceCustomerLine.discount : 0, [Validators.required]],
      discountType: [Object.keys(DiscountType).indexOf(row?.discountType), Validators.required],
      vatRate: [
        invoiceCustomerLine?.vatRate ? invoiceCustomerLine.vatRate : 0,
        [Validators.required, Validators.max(this.HUNDRED)],
      ],
    });

    if (!invoiceCustomerLine.newDeliveryLineId) {
      this.initControls(row, invoiceCustomerLine);
    }

    row.totalGrossPrice = RoundingUtil.roundLow(
      new Decimal(invoiceCustomerLine?.unitPrice ?? 0).times(invoiceCustomerLine?.quantity ?? 0).toNumber()
    );

    this.subscriptionService.subs.push(
      row.rowForm.valueChanges.subscribe(() => {
        if (!invoiceCustomerLine.newDeliveryLineId) {
          this.setValuesFromControls(row);
        }
        this.computeTotals();
      })
    );

    return row;
  }

  private initControls(row: InvoiceCustomerLineRow, invoiceCustomerLine: InvoiceCustomerLine): void {
    row.rowForm.addControl(
      "itemSupplierRef",
      new UntypedFormControl(invoiceCustomerLine?.itemSupplierRef ? invoiceCustomerLine?.itemSupplierRef : null)
    );
    row.rowForm.addControl(
      "itemRef",
      new UntypedFormControl(invoiceCustomerLine?.itemRef ? invoiceCustomerLine?.itemRef : null)
    );
    row.rowForm.addControl("serialNumber", new UntypedFormControl(row?.serialNumber ? row?.serialNumber : null));
    row.rowForm.addControl("batchNumber", new UntypedFormControl(row?.batchNumber ? row?.batchNumber : null));
    row.rowForm.addControl(
      "itemName",
      new UntypedFormControl(invoiceCustomerLine?.itemName ? invoiceCustomerLine?.itemName : null)
    );
    row.rowForm.addControl(
      "quantity",
      new UntypedFormControl(invoiceCustomerLine?.quantity ? invoiceCustomerLine?.quantity : null, this.digitValidator)
    );
    row.rowForm.addControl(
      "unitName",
      new UntypedFormControl(invoiceCustomerLine?.unitName ? invoiceCustomerLine?.unitName : null)
    );
    row.rowForm.addControl(
      "sizeValue",
      new UntypedFormControl(invoiceCustomerLine?.sizeValue ? invoiceCustomerLine?.sizeValue : null)
    );
    row.rowForm.addControl(
      "metalWeight",
      new UntypedFormControl(
        invoiceCustomerLine?.metalWeight ? invoiceCustomerLine?.metalWeight : null,
        this.digitValidator
      )
    );
    row.rowForm.addControl(
      "tare",
      new UntypedFormControl(invoiceCustomerLine?.tare ? invoiceCustomerLine?.tare : null, this.digitValidator)
    );
    row.rowForm.addControl(
      "brandName",
      new UntypedFormControl(invoiceCustomerLine?.brandName ? invoiceCustomerLine?.brandName : null)
    );
    row.rowForm.addControl(
      "supplierName",
      new UntypedFormControl(invoiceCustomerLine?.supplierName ? invoiceCustomerLine?.supplierName : null)
    );
    row.rowForm.addControl(
      "supplierRef",
      new UntypedFormControl(invoiceCustomerLine?.supplierRef ? invoiceCustomerLine?.supplierRef : null)
    );
  }

  private setValuesFromControls(row: InvoiceCustomerLineRow): void {
    const changes = row.rowForm.value;
    changes.quantity = +row.rowForm.get(InvoiceCustomerFormComponent.QUANTITY).value;
    changes.unitPrice = RoundingUtil.roundHigh(row.rowForm.get(InvoiceCustomerFormComponent.UNIT_PRICE).value);
    changes.metalWeight = +row.rowForm.get(InvoiceCustomerFormComponent.METAL_WEIGHT).value;
    changes.tare = +row.rowForm.get(InvoiceCustomerFormComponent.TARE).value;
    changes.weight = this.computeWeight(changes);
    changes.unitName = this.isNullOrUndefinedOrEmpty(row.rowForm.get(InvoiceCustomerFormComponent.UNIT_NAME).value)
      ? null
      : row.rowForm.get(InvoiceCustomerFormComponent.UNIT_NAME).value;

    patch(row, changes);
  }

  private computeMetalWeight(row: InvoiceCustomerLineRow): number {
    let metalWeight = 0;
    if (row?.weight && row?.weight !== 0 && row?.tare && row?.tare !== 0) {
      metalWeight = row.weight - row.tare;
    } else {
      metalWeight = row?.weight ?? 0;
    }
    return metalWeight;
  }

  private computeWeight(changes: any): number {
    let weight = 0;
    const tare = changes.tare ? changes.tare : 0;
    const metalWeight = changes.metalWeight ? changes.metalWeight : 0;
    weight = metalWeight + tare;
    return RoundingUtil.roundHigh(weight);
  }

  private manageInvoiceCustomerLinesCreation(invoiceCustomerInitiatorOutput: any): void {
    const deliveryForms: ReceivingForm[] = invoiceCustomerInitiatorOutput;
    const data = this.fillIdsObj(deliveryForms);
    if (this.deliveryFormList?.length > 0) {
      let idx = 0;
      deliveryForms.forEach((deliveryForm: ReceivingForm) => {
        const deliveryFormFound: ReceivingForm = this.deliveryFormList.find(df => df.id === deliveryForm?.id);
        // case 1 :data BL (maintore -> store) & external customer (maintore -> customer)
        if (
          DeliveryType.INTERNAL === deliveryFormFound?.type ||
          DeliveryType.EXTERNAL_CUSTOMER === deliveryFormFound?.type
        ) {
          this.subscriptionService.subs.push(
            combineLatest([
              this.fetchCommonData(deliveryFormFound?.receiverCustomerId),
              this.fetchStockEntries(data.stEIds),
            ]).subscribe(() => {
              if (this.stockEntryList?.length > 0) {
                const retailItemIds = new Set<number>();
                this.stockEntryList.forEach((stockEntry: StockEntry) => {
                  retailItemIds.add(stockEntry?.retailItemId);
                });
                this.subscriptionService.subs.push(
                  this.fetchItemsWithIds(retailItemIds, false).subscribe(() => {
                    idx++;
                    this.manageICCreationRowsAndFinalStep(deliveryFormFound, idx, deliveryForms);
                  })
                );
              }
            })
          );
        }
        // case 2 : data from POL/PM : external supplier (supplier -> store)
        if (DeliveryType.EXTERNAL_SUPPLIER === deliveryFormFound.type) {
          const { ...dataSupplier } = { ...data };
          this.subscriptionService.subs.push(
            combineLatest([
              this.fetchLinkedItems(dataSupplier),
              this.fetcher.fetchSuppliers(),
              this.fetchCommonData(deliveryFormFound?.receiverCustomerId),
              this.fetchReceiver(deliveryForm?.receiverId),
              this.fetchItemCategories(),
            ]).subscribe(() => {
              this.subscriptionService.subs.push(
                this.fetchPricingGroup(this.store?.pricingGroupId).subscribe(() => {
                  idx++;
                  this.manageICCreationRowsAndFinalStep(deliveryFormFound, idx, deliveryForms);
                })
              );
            })
          );
        }
      });
    }
  }

  // several common data fetched for the two cases
  private fetchCommonData(dFRceiverCustomerId: number): Observable<any> {
    const customerObservable = dFRceiverCustomerId ? this.getCustomer(dFRceiverCustomerId) : null;
    const unitOfMeasureObservable: Observable<any> = this.fetchUnitOfMeasure();
    return dFRceiverCustomerId ? forkJoin([customerObservable, unitOfMeasureObservable]) : unitOfMeasureObservable;
  }

  // creation of a free tab when no deliveryforms are selected in the popup of creation
  private manageFreeTab(output: FreeInvoiceCustomerInitiatorOutput): void {
    this.currency = this.fetcher.currencyList.find(cur => cur.byDefault);
    this.subscriptionService.subs.push(this.getCustomer(output.customerId).subscribe());
    const tabFound = new InvoiceCustomerTab();
    tabFound.deliveryFormId = 0;
    tabFound.senderName = this.fetcher.mainStore.name;
    tabFound.receiverName = output.storeId
      ? this.fetcher.storeList.find(store => store.id === output.storeId).name
      : this.fetcher.customerList.find(customer => customer.id === output.customerId).name;
    tabFound.rows = [];
    tabFound.allRows = [];
    tabFound.deliveryRef = this.translateService.instant("invoice-customer-form.tabs.title-free");
    tabFound.id = this.tabId++;
    tabFound.tabForm = new UntypedFormGroup({});
    this.invoiceCustomerTabs?.push(tabFound);
    this.form.addControl((this.invoiceCustomerTabs.length - 1).toString(), tabFound.tabForm);
    this.tabNameList.push(this.translateService.instant("invoice-customer-form.tabs.title-free"));
    this.buildDiscountOptions();
    this.buildInvoiceCustomer(null, output.storeId ? output.storeId : output.customerId);
    this.editedInvoiceCustomer = new InvoiceCustomer(this.updatedInvoiceCustomer);
    this.subTitle = this.fetcher.customerList.find(
      (customer: Light) => customer.id === this.updatedInvoiceCustomer.contactId
    )?.name;
    this.initFilters();
    this.sessionPagination.loadFromSession(`${this.listId}-tab-${tabFound?.id}`);
    this.applyFilters(tabFound);
    if (!this.subTitle) {
      this.subscriptionService.subs.push(
        this.storeService.get(this.updatedInvoiceCustomer.contactId).subscribe((store: Store) => {
          this.subTitle = this.fetcher.customerList.find((customer: Light) => customer.id === store.customerId)?.name;
        })
      );
    }
  }

  // This method combines multiple functions that aim to create and display the object
  private manageICCreationRowsAndFinalStep(
    deliveryFormFound: ReceivingForm,
    idx: number,
    deliveryForms: ReceivingForm[]
  ): void {
    this.buildInvoiceCustomer(deliveryFormFound);
    this.createDisplayOptions(deliveryFormFound);
    this.editedInvoiceCustomer = new InvoiceCustomer(this.updatedInvoiceCustomer);
    if (idx === deliveryForms.length) {
      this.buildRows(this.updatedInvoiceCustomer, deliveryFormFound);
      this.finalStep();
    }
  }

  // after create the object & rows:add some data, compute and manage filters...
  private finalStep(): void {
    if (this.updatedInvoiceCustomer) {
      this.form.controls.shippingFeePrice.patchValue(
        RoundingUtil.roundHigh(this.updatedInvoiceCustomer?.shippingFeePrice)
      );
      this.form.controls.extraPrice.patchValue(RoundingUtil.roundHigh(this.updatedInvoiceCustomer?.extraPrice));
      const firstTab = this.invoiceCustomerTabs[0];
      if (this.invoiceCustomerTabs.length > 0) {
        this.invoiceCustomerTabs.sort((a, b) => a.deliveryFormId - b.deliveryFormId);
      }

      this.computeTotals();
      if (firstTab) {
        this.buildOptions(firstTab);
      }
      this.initFilters();
      this.sessionPagination.loadFromSession(`${this.listId}-tab-${firstTab?.id}`);
      if (firstTab) {
        this.applyFilters(firstTab);
      }
    }
  }

  // title & currency options for html
  private createDisplayOptions(receivingFormFound: ReceivingForm): void {
    if (this.fetcher.customerList?.length > 0) {
      this.subTitle = this.fetcher.customerList.find(elem => elem.id === receivingFormFound?.receiverCustomerId)?.name;
    }
    if (this.fetcher.currencyList?.length > 0) {
      this.currency = this.fetcher.currencyList.find(cur => cur.id === receivingFormFound?.currencyId);
      this.buildDiscountOptions();
    }
  }

  private computeTotals(): void {
    this.computeTabQuantity();
    this.computeUnitPriceAndDiscount();
    this.computeTotalQuantity();
    this.computeTabGrossPrices();
    this.computeTotalGrossPrice();
    this.computeTaxPrice();
    this.computeTotalQuickPaymentDiscount();
    this.computeTotalPrice();
  }

  private computeTabQuantity(): void {
    this.invoiceCustomerTabs.forEach((tab: InvoiceCustomerTab) => {
      tab.totalQuantity = 0;
      tab.rows.forEach(row => {
        const quantity = row?.quantity ?? 0;
        tab.totalQuantity += quantity;
      });
    });
  }

  private computeTotalQuantity(): void {
    this.totalQuantity = 0;
    this.invoiceCustomerTabs.forEach((tab: InvoiceCustomerTab) => {
      let tabTotalQuantity = 0;
      tab.allRows.forEach(row => {
        const quantity = row.quantity ?? 0;
        tabTotalQuantity += quantity;
      });
      this.totalQuantity += tabTotalQuantity;
    });
  }

  private computeUnitPriceAndDiscount(): void {
    this.invoiceCustomerTabs.forEach((tab: InvoiceCustomerTab) => {
      tab.rows.forEach(row => {
        this.updateDiscountMaxValidator(row, row.rowForm);
      });
    });
  }

  private computeTabGrossPrices(): void {
    this.taxPrice = 0;
    this.invoiceCustomerTabs.forEach((tab: InvoiceCustomerTab) => {
      tab.totalGrossPrice = 0;

      tab.rows.forEach(row => {
        const discount = row?.rowForm?.get(InvoiceCustomerFormComponent.DISCOUNT)?.value ?? row?.discount ?? 0;

        const discountType = row?.rowForm?.get(InvoiceCustomerFormComponent.DISCOUNT_TYPE)
          ? Object.values(DiscountType)[row.rowForm.get(InvoiceCustomerFormComponent.DISCOUNT_TYPE)?.value]
          : row?.discountType;

        const unitPrice = row?.rowForm?.get(InvoiceCustomerFormComponent.UNIT_PRICE)?.value ?? 0;

        let grossPrice = unitPrice;

        // apply discount
        if (discountType !== undefined && discountType !== null && unitPrice !== undefined && unitPrice !== null) {
          grossPrice -= discountType === DiscountType.PERCENT ? grossPrice * (discount / this.HUNDRED) : discount;

          const quantity = row.quantity ?? 0;
          row.totalGrossPrice = RoundingUtil.roundLow(new Decimal(grossPrice ?? 0).times(quantity).toNumber());
          tab.totalGrossPrice = new Decimal(tab.totalGrossPrice ?? 0).plus(row.totalGrossPrice).toNumber();
        }
      });
      tab.totalGrossPrice = RoundingUtil.roundLow(tab.totalGrossPrice);
    });
  }

  private computeTotalGrossPrice(): void {
    this.totalGrossPrice = 0;
    this.linesTaxPrice = 0;

    this.invoiceCustomerTabs.forEach((tab: InvoiceCustomerTab) => {
      let tabTotalGrossPrice = 0;

      // eslint-disable-next-line complexity
      tab.allRows.forEach(row => {
        let discount = row?.rowForm?.get(InvoiceCustomerFormComponent.DISCOUNT)?.value;
        if (StringUtils.isNullOrUndefinedOrEmpty(discount)) {
          discount = row?.discount ?? 0;
        }

        const discountType = row?.rowForm?.get(InvoiceCustomerFormComponent.DISCOUNT_TYPE)
          ? Object.values(DiscountType)[row.rowForm.get(InvoiceCustomerFormComponent.DISCOUNT_TYPE)?.value]
          : row.discountType;

        const unitPrice = row?.rowForm?.get(InvoiceCustomerFormComponent.UNIT_PRICE)?.value ?? 0;
        let grossPrice = RoundingUtil.roundLow(unitPrice, true);

        // apply discount
        if (discountType !== undefined && discountType !== null && unitPrice !== undefined && unitPrice !== null) {
          grossPrice = RoundingUtil.roundLow(
            new Decimal(grossPrice ?? 0)
              .minus(
                discountType === DiscountType.PERCENT
                  ? new Decimal(grossPrice ?? 0).times(discount).dividedBy(this.HUNDRED).toNumber()
                  : discount
              )
              .toNumber()
          );
        }

        const quantity = row.quantity ? row.quantity : 0;
        grossPrice = RoundingUtil.roundLow(new Decimal(grossPrice ?? 0).times(quantity).toNumber());
        // apply VatRate
        let vatRate = row?.rowForm?.get(InvoiceCustomerFormComponent.VAT_RATE)?.value;
        if (StringUtils.isNullOrUndefinedOrEmpty(vatRate)) {
          vatRate = row?.vatRate ?? 0;
        }

        // const vatRatePercent = +vatRate / this.HUNDRED;
        const vatRatePercent = new Decimal(vatRate ?? 0).dividedBy(this.HUNDRED).toNumber();

        this.linesTaxPrice = RoundingUtil.roundLow(
          new Decimal(this.linesTaxPrice ?? 0).plus(new Decimal(grossPrice ?? 0).times(vatRatePercent)).toNumber()
        );
        tabTotalGrossPrice = new Decimal(tabTotalGrossPrice ?? 0).plus(grossPrice).toNumber();
      });
      this.totalGrossPrice = new Decimal(this.totalGrossPrice ?? 0).plus(tabTotalGrossPrice).toNumber();
    });
  }

  private computeTaxPrice(): void {
    this.shippingFeePrice =
      this.form?.get(InvoiceCustomerFormComponent.SHIPPING_FEE_PRICE)?.value ??
      this.updatedInvoiceCustomer?.shippingFeePrice ??
      0;
    this.extraPrice =
      this.form?.get(InvoiceCustomerFormComponent.EXTRA_PRICE)?.value ?? this.updatedInvoiceCustomer?.extraPrice ?? 0;

    this.taxPrice = RoundingUtil.roundLow(
      new Decimal(this.linesTaxPrice ?? 0)
        .plus(new Decimal(this.vatDefaultValue ?? 0).times(this.shippingFeePrice))
        .plus(new Decimal(this.vatDefaultValue ?? 0).times(this.extraPrice))
        .toNumber()
    );
  }

  private getItemFromPM(pmId: number): AbstractItem {
    for (const item of this.retailItemList) {
      if (item?.purchaseModalities) {
        for (const pm of item?.purchaseModalities ?? []) {
          if (pm.id === pmId) {
            return item;
          }
        }
      }
    }
    return null;
  }

  private getPurchaseModality(pmId: number, item: StandardItem): PurchaseModality {
    return item?.purchaseModalities?.find(pm => pm.id === pmId);
  }

  private initFilters(): void {
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.listId
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter(
      InvoiceCustomerFormComponent.LINE_NUMBER,
      this.translateService.instant("invoice-customer-form.datatable.columns.line-number"),
      "string"
    );

    this.filterer.addFilter(
      InvoiceCustomerFormComponent.ITEM_SUPPLIER_REF,
      this.translateService.instant("invoice-customer-form.datatable.columns.item-supplier-ref"),
      "string"
    );

    this.filterer.addFilter(
      InvoiceCustomerFormComponent.ITEM_REF,
      this.translateService.instant("invoice-customer-form.datatable.columns.item-reference"),
      "string"
    );

    this.filterer.addFilter(
      InvoiceCustomerFormComponent.SERIAL_NUMBER,
      this.translateService.instant("police-book-movements-list.datatable.columns.serial-number"),
      "string"
    );

    this.filterer.addFilter(
      InvoiceCustomerFormComponent.BATCH_NUMBER,
      this.translateService.instant("police-book-movements-list.datatable.columns.batch"),
      "string"
    );

    this.filterer.addFilter(
      InvoiceCustomerFormComponent.ITEM_NAME,
      this.translateService.instant("invoice-customer-form.datatable.columns.item-name"),
      "string"
    );

    this.filterer.addFilter(
      InvoiceCustomerFormComponent.QUANTITY,
      this.translateService.instant("invoice-customer-form.datatable.columns.quantity"),
      "range"
    );

    this.filterer.addListFilter(
      InvoiceCustomerFormComponent.SIZE_VALUE,
      this.translateService.instant("invoice-customer-form.datatable.columns.size-value"),
      this.sizeValueList.map(sizeValue => {
        return { value: sizeValue, displayValue: sizeValue };
      })
    );

    this.filterer.addFilter(
      InvoiceCustomerFormComponent.TOTAL_GROSS_PRICE,
      this.translateService.instant("invoice-customer-form.datatable.columns.total-gross-price"),
      "range"
    );

    this.filterer.addFilter(
      InvoiceCustomerFormComponent.METAL_WEIGHT,
      this.translateService.instant("invoice-customer-form.datatable.columns.metal-weight"),
      "range"
    );

    this.filterer.addFilter(
      InvoiceCustomerFormComponent.WEIGHT,
      this.translateService.instant("invoice-customer-form.datatable.columns.weight"),
      "range"
    );

    this.filterer.addFilter(
      InvoiceCustomerFormComponent.TARE,
      this.translateService.instant("invoice-customer-form.datatable.columns.tare"),
      "range"
    );

    this.filterer.addListFilter(
      InvoiceCustomerFormComponent.BRAND_NAME,
      this.translateService.instant("invoice-customer-form.datatable.columns.brand-name"),
      this.brandList.map(brand => {
        return { value: brand, displayValue: brand };
      })
    );

    this.filterer.addFilter(
      InvoiceCustomerFormComponent.SUPPLIER_REF,
      this.translateService.instant("invoice-customer-form.datatable.columns.supplier-ref"),
      "string"
    );

    this.filterer.addListFilter(
      InvoiceCustomerFormComponent.SUPPLIER_NAME,
      this.translateService.instant("invoice-customer-form.datatable.columns.supplier-name"),
      this.fetcher.supplierLightList
        .map(supplier => supplier?.name)
        .map(supplierName => {
          return { value: supplierName, displayValue: supplierName };
        })
    );

    if (this.readOnly) {
      this.filterer.addFilter(
        InvoiceCustomerFormComponent.UNIT_PRICE,
        this.translateService.instant("invoice-customer-form.datatable.columns.unit-price"),
        "range"
      );

      this.filterer.addFilter(
        InvoiceCustomerFormComponent.DISCOUNT,
        this.translateService.instant("invoice-customer-form.datatable.columns.discount"),
        "range"
      );

      this.filterer.addFilter(
        InvoiceCustomerFormComponent.VAT_RATE,
        this.translateService.instant("invoice-customer-form.datatable.columns.vat-rate"),
        "range"
      );
    }
  }

  private sendErrorAlert(errorType: string, message: string): void {
    const content = this.translateService.instant(errorType, { message });
    const title = this.translateService.instant("message.title.data-errors");
    this.messageService.warn(content, { title });
  }

  private sendErrorValidate(errorType: string, message: string): void {
    const content = this.translateService.instant(errorType, { message });
    const title = this.translateService.instant("invoice-customer-form.errors.title");
    this.messageService.error(content, { title });
  }

  private fetchUnitOfMeasure(): Observable<Uom[]> {
    return this.uomService.getAll().pipe(
      tap(
        (uoms: Uom[]) => {
          this.uoms = uoms.filter((obj: Uom) => !obj.archived).sort((a, b) => a.longName.localeCompare(b.longName));
        },
        error => {
          this.sendErrorAlert("uoms-list.errors.get-entities", error.message);
        }
      )
    );
  }

  private computeTotalQuickPaymentDiscount(): void {
    this.totalQuickPaymentDiscount = 0;
    this.shippingFeePrice =
      this.form?.get(InvoiceCustomerFormComponent.SHIPPING_FEE_PRICE)?.value ??
      this.updatedInvoiceCustomer?.shippingFeePrice ??
      0;
    if (this.updatedInvoiceCustomer?.quickPaymentDiscount) {
      this.totalQuickPaymentDiscount = RoundingUtil.roundLow(
        new Decimal(this.totalGrossPrice ?? 0)
          .plus(this.taxPrice ?? 0)
          .times(new Decimal(this.updatedInvoiceCustomer?.quickPaymentDiscount ?? 0).dividedBy(this.HUNDRED))
          .toNumber()
      );
    }
  }

  private computeTotalPrice(): void {
    this.totalPrice = 0;

    this.shippingFeePrice =
      this.form?.get(InvoiceCustomerFormComponent.SHIPPING_FEE_PRICE)?.value ??
      this.updatedInvoiceCustomer?.shippingFeePrice ??
      0;
    this.extraPrice = this.form?.get(InvoiceCustomerFormComponent.EXTRA_PRICE)?.value;
    if (StringUtils.isNullOrUndefinedOrEmpty(this.extraPrice.toString())) {
      this.extraPrice = this.updatedInvoiceCustomer?.extraPrice ?? 0;
    }
    this.taxPrice = this.taxPrice ?? 0;
    this.totalPrice = RoundingUtil.roundLow(
      new Decimal(this.totalGrossPrice ?? 0)
        .plus(this.shippingFeePrice ?? 0)
        .plus(this.extraPrice ?? 0)
        .plus(this.taxPrice ?? 0)
        .minus(this.totalQuickPaymentDiscount ?? 0)
        .toNumber()
    );
  }

  private fetchInvoiceCustomer(id: number): Observable<PaginatedList<ReceivingForm>> {
    return this.invoiceCustomerService.get(id).pipe(
      tap({
        error: () => {
          this.router.navigateByUrl("/invoice-customer-list");
        },
      }),
      mergeMap((invoiceCustomer: InvoiceCustomer) => {
        if (invoiceCustomer.documentType === DocumentType.CREDIT_NOTE) {
          const url =
            invoiceCustomer.invoiceStatus === InvoiceStatus.DRAFT
              ? `${this.creditNoteFormUrl}${id}`
              : `${this.creditNoteDetailUrl}${id}`;
          this.router.navigateByUrl(url);
          return EMPTY;
        }
        this.isValidated = invoiceCustomer?.invoiceStatus === InvoiceStatus.VALIDATED;
        invoiceCustomer.lines = this.convertObjectToMapLines(invoiceCustomer?.lines);
        this.editedInvoiceCustomer = new InvoiceCustomer(invoiceCustomer);
        this.updatedInvoiceCustomer = new InvoiceCustomer(this.editedInvoiceCustomer);
        if (this.updatedInvoiceCustomer.id) {
          const invoiceCustomerLineIds = new Set<number>();
          if (this.updatedInvoiceCustomer.lines.size > 0) {
            this.updatedInvoiceCustomer.lines.forEach((_setOfLines: any, key: any) => {
              invoiceCustomerLineIds.add(+key);
            });
            this.deliveryFormIds = invoiceCustomerLineIds;
          }
        }
        return this.fetchDeliveryForms();
      })
    );
  }

  private convertObjectToMapLines(obj: any): Map<any, Set<InvoiceCustomerLine>> {
    const map = new Map<string, Set<InvoiceCustomerLine>>();
    Object.entries(obj).forEach(([key, entry]: [string, any]) => {
      const mapKey = key;
      const lines: InvoiceCustomerLine[] = entry as InvoiceCustomerLine[];
      lines.forEach((line: InvoiceCustomerLine) => {
        if (map.has(mapKey)) {
          const existingSet = map.get(key);
          entry.forEach(() => existingSet.add(line));
        } else {
          const lineSet = new Set<InvoiceCustomerLine>();
          lineSet.add(line);
          map.set(mapKey, lineSet);
        }
      });
    });
    return map;
  }

  // no lines present, cannot save or validate
  private generateNullOrNegativeErrorMessage(): void {
    const title = this.translateService.instant("invoice-customer-form.errors.saving-invoice");
    const currency = this.currency.symbol;
    const content = this.translateService.instant("invoice-customer-form.errors.null-or-negative", { currency });
    this.messageService.error(content, { title });
  }

  private resetFooterValues(): void {
    if (this.updatedInvoiceCustomer?.lines?.size === 0 || !this.isMapHasValue()) {
      this.updatedInvoiceCustomer.shippingFeePrice = 0;
      this.updatedInvoiceCustomer.extraPrice = 0;
      this.form.controls[InvoiceCustomerFormComponent.SHIPPING_FEE_PRICE].patchValue(0);
      this.form.controls[InvoiceCustomerFormComponent.EXTRA_PRICE].patchValue(0);
      return;
    }
  }

  private checkFormErrors(): boolean {
    const isValidTable = this.updateInvoiceCustomer();
    let isValidHeader = true;
    if (this.invoiceHeader) {
      isValidHeader = this.invoiceHeader.updateInvoiceHeader();
    }
    return !isValidTable || !isValidHeader;
  }

  private getLineRowForm(line: InvoiceCustomerLine): UntypedFormGroup {
    const tabFound =
      line?.newDeliveryFormId !== null || line?.newDeliveryFormId !== undefined
        ? this.invoiceCustomerTabs.find(tab => tab?.deliveryFormId === line?.newDeliveryFormId)
        : this.invoiceCustomerTabs.find(
          tab => tab?.deliveryRef === this.deliveryFormList?.find(df => df?.id === tab?.deliveryFormId)?.deliveryRef
        );
    return tabFound?.allRows.find(row => row?.lineNumber === line?.lineNumber)?.rowForm;
  }

  private redirectToInvoiceCustomerDetails(id: number): void {
    this.sessionPagination.saveToSession(this.listId);
    this.router.navigateByUrl(`/invoice-customer-detail/${id}`);
  }

  private addMenuActions(): void {
    this.menuActions = [];
    this.menuActions.push(
      new MenuAction(
        this.DELETE_ACTION_ID,
        this.translateService.instant("invoice-customer-form.actions.delete"),
        this.faTrash
      )
    );
  }

  private isNullOrUndefinedOrEmpty(value: string): boolean {
    return !value || value === "";
  }
}
