import { Component, OnInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import {
  IconDefinition,
  faAbacus,
  faAnalytics,
  faBalanceScale,
  faBook,
  faBoxAlt,
  faBoxFull,
  faBuilding,
  faCalendarStar,
  faCartFlatbed,
  faCloudQuestion,
  faCommentsQuestionCheck,
  faCompressAlt,
  faEuroSign,
  faFileAlt,
  faFileInvoice,
  faGear,
  faGem,
  faGlobeEurope,
  faHouse,
  faIdBadge,
  faInputText,
  faListAlt,
  faMapMarkedAlt,
  faMoneyCheckEdit,
  faNetworkWired,
  faPen,
  faPersonDolly,
  faPowerOff,
  faReceipt,
  faRegistered,
  faRing,
  faScannerGun,
  faShapes,
  faSquareInfo,
  faStore,
  faStoreAlt,
  faTag,
  faTimelineArrow,
  faTruckLoading,
  faUser,
  faUserGear,
  faUserTie,
  faUsers,
  faUsersRectangle,
  faWarehouseAlt,
  faWeightHanging,
} from "@fortawesome/pro-solid-svg-icons";
import dayjs from "dayjs";
import "dayjs/locale/fr";

import { TranslateService } from "@ngx-translate/core";

import { Title } from "@angular/platform-browser";
import {
  AppConfigService,
  AuthService,
  CaraUser,
  CaraUserService,
  CurrencyService,
  MetalPriceService,
  ModuleService,
  StoreLink,
  StoreService,
  ThemeService,
} from "center-services";
import { CollapsedByDefault, MenuItemGroup, MenuItemSelectionEvent } from "fugu-components";
import { Observable, of } from "rxjs";
import { catchError, filter, map, tap } from "rxjs/operators";
import { SubscriptionService } from "fugu-common";

declare let gtag: (command: string, googleId: string, options?: any) => void;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [SubscriptionService],
})
export class AppComponent implements OnInit {
  public menuContext: any = {
    updateOnContextChange: true,
    url: "/",
    lang: null,
  };

  public menuItems: MenuItemGroup[];
  public currentUser?: CaraUser = null;
  public showReleasePopup: boolean = false;

  showSidebar: boolean = false;
  menuMinimal: boolean = false;
  appLogoSrc: string;
  appMiniLogoSrc: string;
  url: string = "";
  storeSelectionPopupVisible: boolean = false;
  readonly faStore: IconDefinition = faStore;
  readonly faPen: IconDefinition = faPen;
  readonly faBuilding: IconDefinition = faBuilding;

  settingsItems: any[] = [];
  helpsItems: any[] = [];
  // eslint-disable-next-line
  appVersion: string = require("app/../../package.json").version;
  selectedStoreId: string;
  selectedStore: StoreLink;
  filteredStores: StoreLink[];
  public showContactSupportPopup: boolean;

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    private userService: CaraUserService,
    private themeService: ThemeService,
    private currencyService: CurrencyService,
    private metalPriceService: MetalPriceService,
    private appConfigService: AppConfigService,
    private moduleService: ModuleService,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private storeService: StoreService,
    private titleService: Title,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    sessionStorage.clear();

    const languages: string[] = ["en", "fr"];
    const theme = this.userService.getTheme();
    this.translateService.addLangs(languages);
    this.translateService.setDefaultLang("fr");
    this.translateService.use("fr");

    this.subscriptionService.subs.push(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.menuContext = { ...this.menuContext, url: event.url };
          this.showSidebar = this.router.routerState.snapshot.url !== "/login";
          this.checkAppVersion();
        }

        const { pageTitleKey, menuEntryKey } = this.route.snapshot.firstChild?.data || {};

        if (pageTitleKey) {
          const translatedPageTitle = this.translateService.instant(pageTitleKey);

          if (menuEntryKey) {
            const translatedMenuEntry = this.translateService.instant(menuEntryKey);
            this.titleService.setTitle(`${translatedPageTitle} - ${translatedMenuEntry} - Neo-Retail`);
          } else {
            this.titleService.setTitle(`${translatedPageTitle} - Neo-Retail`);
          }
        } else {
          this.titleService.setTitle("Neo-Retail");
        }
      })
    );

    this.subscriptionService.subs.push(
      this.themeService.themeChange.subscribe(val => this.themeManagement(val ? "light" : "dark"))
    );
    if (theme) {
      this.themeService.setValue(theme === "light");
    } else {
      this.themeService.setValue(true);
    }

    // on user change callback
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe(() => {
        if (this.userService.connectedUser.value) {
          this.currentUser = this.userService.connectedUser.value;
          this.setSelectedStore();

          // set the new user's language in translationService
          if (languages.includes(this.userService.connectedUser.value.codeLanguage)) {
            this.translateService.use(this.userService.connectedUser.value.codeLanguage);
            dayjs.locale(this.userService.connectedUser.value.codeLanguage);
          } else {
            this.translateService.use("fr");
            dayjs.locale("fr");
          }

          if (this.currentUser.stores) {
            this.filteredStores = this.currentUser.stores.filter(
              store => !store.archived && store.status !== "INACTIVE"
            );
          }

          this.themeService.setValue(this.currentUser.theme === "light");
          this.userService.saveTheme(this.currentUser.theme);

          // rebuild the menu with the new user's right
          this.buildMenu();

          // check for app version
          this.checkAppVersion();
        }
      })
    );

    // rebuild the menu when the translation change
    this.translateService.onLangChange.subscribe(() => {
      if (this.userService.connectedUser.value) {
        this.buildMenu();
      }
    });

    this.subscriptionService.subs.push(
      this.authService.currentToken.subscribe(token => {
        if (token) {
          // if we don't have the default currrency, fetch it
          if (!this.currencyService.defaultCurrency.value) {
            this.currencyService.fetchDefaultCurrency();
          }
          // if we don't have the activated modules, fetch it
          if (!this.moduleService.activatedModules.value) {
            this.moduleService.fetchActivatedModules();
          }
          // if we don't have the metal list price, fetch it
          if (!this.metalPriceService.metalList.value) {
            this.metalPriceService.fetchMetalList();
          }
          // handle appConfig
          this.subscriptionService.subs.push(this.appConfigService.get().subscribe());

          if (this.currentUser?.stores) {
            this.selectedStore = this.currentUser.stores.filter(
              store => store.storeId === this.authService.getContextStoreId()
            )[0];

            this.setSelectedStore();
            this.buildMenu();
          } else {
            this.userService.fetchConnectedUser();
          }
        }
      })
    );

    this.setUpAnalytics();
  }

  onLogoClick(): void {
    this.router.navigateByUrl("/");
  }

  buildMenu(): void {
    this.subscriptionService.subs.push(
      this.isMainStore().subscribe(isConnectedToMainStore => {
        this.buildMenuItems(isConnectedToMainStore);
      })
    );
  }

  buildMenuItems(isConnectedToMainStore: boolean): void {
    this.menuItems = [
      {
        items: [
          {
            icon: faHouse,
            onSelect: this.navigateMenu("/"),
            title: this.translateService.instant("sidebar.menu.home-page"),
            isSelected: (menuContext: any) => menuContext.url === "/",
          },
        ],
      },
      {
        title: this.translateService.instant("sidebar.menu.group.purchase.title"),
        titleMini: this.translateService.instant("sidebar.menu.group.purchase.titleMini"),
        items: [
          {
            icon: faFileAlt,
            disabled: !this.userService.canDo("PURCHASE_ORDER_READ"),
            onSelect: this.navigateMenu("/purchase-order-list"),
            title: this.translateService.instant("sidebar.menu.purchase-orders-list"),
            isSelected: (menuContext: any) =>
              menuContext.url === "/purchase-order-list" ||
              menuContext.url === "/purchase-order/add" ||
              menuContext.url.startsWith("/purchase-order-detail/") ||
              menuContext.url.startsWith("/purchase-order/update"),
          },
          {
            icon: faPersonDolly,
            disabled: !this.userService.canDo("RECEIVING_FORM_GLOBAL"),
            onSelect: this.navigateMenu("/receiving-list"),
            title: this.translateService.instant("sidebar.menu.receiving-list"),
            isSelected: (menuContext: any) => menuContext.url.startsWith("/receiving"),
          },
          {
            icon: faFileInvoice,
            disabled: !(this.userService.canDo("INVOICE_SUPPLIER_READ") && isConnectedToMainStore),
            onSelect: this.navigateMenu("/invoice-supplier-list"),
            title: this.translateService.instant("sidebar.menu.invoice-supplier-list"),
            isSelected: (menuContext: any) =>
              menuContext.url.startsWith("/invoice-supplier-list") ||
              menuContext.url.startsWith("/invoice-supplier/add") ||
              menuContext.url.startsWith("/invoice-supplier/update") ||
              menuContext.url.startsWith("/invoice-supplier-detail"),
          },
        ],
      },

      {
        title: this.translateService.instant("sidebar.menu.group.sales.title"),
        titleMini: this.translateService.instant("sidebar.menu.group.sales.titleMini"),
        items: [
          {
            icon: faCartFlatbed,
            disabled: !this.userService.canDo("SHIPMENT_FORM_READ"),
            onSelect: this.navigateMenu("/shipment-form-list"),
            title: this.translateService.instant("sidebar.menu.shipment-form-list"),
            isSelected: (menuContext: any) => menuContext.url.startsWith("/shipment-form"),
          },
          {
            icon: faReceipt,
            disabled: !(this.userService.canDo("INVOICE_CUSTOMER_READ") && isConnectedToMainStore),
            onSelect: this.navigateMenu("/invoice-customer-list"),
            title: this.translateService.instant("sidebar.menu.invoice-customer-list"),
            isSelected: (menuContext: any) =>
              menuContext.url.startsWith("/invoice-customer-list") ||
              menuContext.url.startsWith("/invoice-customer/add") ||
              menuContext.url.startsWith("/invoice-customer/update") ||
              menuContext.url.startsWith("/invoice-customer-detail"),
          },
        ],
      },

      {
        title: this.translateService.instant("sidebar.menu.group.stock.title"),
        titleMini: this.translateService.instant("sidebar.menu.group.stock.titleMini"),
        items: [
          {
            icon: faWarehouseAlt,
            disabled: !this.userService.canDo("STOCK_ENTRY_READ"),
            onSelect: this.navigateMenu("/stocks-list"),
            title: this.translateService.instant("sidebar.menu.stocks-list"),
            isSelected: (menuContext: any) =>
              menuContext.url === "/stocks-list" || menuContext.url === "/manual-stock-entry/add",
          },
          {
            icon: faTag,
            disabled: !this.userService.canDo("STOCK_ENTRY_LABEL_READ"),
            onSelect: this.navigateMenu("/stock-entry-label-list"),
            title: this.translateService.instant("sidebar.menu.stock-entry-label-list"),
            isSelected: (menuContext: any) => menuContext.url.startsWith("/stock-entry-label-list"),
          },
          {
            icon: faScannerGun,
            disabled: !this.userService.canDo("INVENTORY_READ"),
            onSelect: this.navigateMenu("/inventory-list"),
            title: this.translateService.instant("sidebar.menu.inventory-list"),
            isSelected: (menuContext: any) =>
              menuContext.url === "/inventory-list" || menuContext.url.startsWith("/inventory/update"),
          },
        ],
      },

      {
        title: this.translateService.instant("sidebar.menu.group.datas.title"),
        titleMini: this.translateService.instant("sidebar.menu.group.datas.titleMini"),
        items: [
          {
            icon: faRegistered,
            disabled: !(this.userService.canDo("BRAND_READ") && isConnectedToMainStore),
            onSelect: this.navigateMenu("/brands-list"),
            title: this.translateService.instant("sidebar.menu.brands-list"),
            isSelected: (menuContext: any) =>
              menuContext.url.startsWith("/brands-list") ||
              menuContext.url === "/brand/add" ||
              menuContext.url.startsWith("/brand/update"),
          },

          {
            icon: faUserTie,
            disabled: !this.userService.canDo("CUSTOMER_READ"),
            onSelect: this.navigateMenu("/customer-list"),
            title: this.translateService.instant("sidebar.menu.customers-list"),
            isSelected: (menuContext: any) =>
              menuContext.url.startsWith("/customer-list") ||
              menuContext.url === "/customer/add" ||
              menuContext.url.startsWith("/customer/update"),
          },
          {
            icon: faStore,
            disabled: !(this.userService.canDo("STORE_READ") && isConnectedToMainStore),
            onSelect: this.navigateMenu("/stores-list"),
            title: this.translateService.instant("sidebar.menu.stores-list"),
            isSelected: (menuContext: any) =>
              menuContext.url.startsWith("/stores-list") ||
              menuContext.url === "/store/add" ||
              menuContext.url.startsWith("/store/update"),
          },

          {
            icon: faBoxAlt,
            disabled: !(this.userService.canDo("RETAIL_ITEM_READ") && isConnectedToMainStore),
            onSelect: this.navigateMenu("/retail-item-list"),
            title: this.translateService.instant("sidebar.menu.retail-item-list"),
            isSelected: (menuContext: any) =>
              menuContext.url === "/retail-item-list" ||
              menuContext.url === "/retail-item/import" ||
              menuContext.url === "/retail-item/add" ||
              menuContext.url === "/retail-service/add" ||
              menuContext.url.startsWith("/retail-item/update"),
          },
          {
            icon: faTruckLoading,
            disabled: !(this.userService.canDo("SUPPLIER_READ") && isConnectedToMainStore),
            onSelect: this.navigateMenu("/suppliers-list"),
            title: this.translateService.instant("sidebar.menu.suppliers-list"),
            isSelected: (menuContext: any) =>
              menuContext.url.startsWith("/suppliers-list") ||
              menuContext.url === "/supplier/add" ||
              menuContext.url.startsWith("/supplier/update"),
          },
        ],
      },
      {
        title: this.translateService.instant("sidebar.menu.group.metal.title"),
        titleMini: this.translateService.instant("sidebar.menu.group.metal.titleMini"),
        items: [
          {
            icon: faWeightHanging,
            disabled: !(this.userService.canDo("METAL_ACCOUNT_READ") && isConnectedToMainStore),
            onSelect: this.navigateMenu("/metal-account-general"),
            title: this.translateService.instant("sidebar.menu.metal-account-general"),
            isSelected: (menuContext: any) =>
              menuContext.url === "/metal-account-general" || menuContext.url.startsWith("/metal-account-detail"),
          },
          {
            icon: faBook,
            disabled: !this.userService.canDo("POLICE_BOOK_MOVEMENT_READ"),
            onSelect: this.navigateMenu("/police-book-movements-list"),
            title: this.translateService.instant("sidebar.menu.police-book-movement-list"),
            isSelected: (menuContext: any) => menuContext.url === "/police-book-movements-list",
          },
        ],
      },
    ];

    if (isConnectedToMainStore) {
      this.buildSettingsMenuItems();
      this.menuItems.push({
        titleMini: this.translateService.instant("sidebar.footer.settings-short"),
        title: this.translateService.instant("sidebar.footer.settings"),
        collapsedByDefault: CollapsedByDefault.IF_ITEM_SELECTED,
        items: this.settingsItems,
        collapsable: true,
        bottom: true,
      });
    }
    this.buildHelpMenuItems();
    this.menuItems.push({
      titleMini: this.translateService.instant("sidebar.footer.help"),
      title: this.translateService.instant("sidebar.footer.help"),
      collapsedByDefault: CollapsedByDefault.IF_ITEM_SELECTED,
      items: this.helpsItems,
      collapsable: true,
      bottom: true,
    });

    this.menuItems.push({
      id: "userSettings",
      items: [
        {
          icon: faUserGear,
          onSelect: this.navigateMenu("/profile-management"),
          title: this.translateService.instant("sidebar.footer.profile-management"),
          isSelected: (menuContext: any) => menuContext.url === "/profile-management",
        },
        {
          title: this.translateService.instant("sidebar.footer.param-user-disconnect"),
          icon: faPowerOff,
          isSelected: (): boolean => false,
          onSelect: (): void => {
            this.authService.logout();
            this.router.navigateByUrl("/login");
          },
        },
      ],
      bottom: true,
      collapsable: true,
      collapsedByDefault: CollapsedByDefault.ALWAYS,
    });
  }

  buildSettingsMenuItems(): void {
    this.settingsItems = [];

    const permissionsToItems = [
      {
        permission: "ALLOY_READ",
        method: this.addSubItem,
        args: ["/settings/alloys-list", faShapes, "sidebar.footer.alloys-list"],
      },
      {
        permission: "METAL_READ",
        method: this.addSubItem,
        args: ["/settings/metals-list", faGem, "sidebar.footer.metals-list"],
      },
      {
        permission: "GOD",
        method: this.addSubItem,
        args: ["/settings/network", faNetworkWired, "sidebar.footer.network-config"],
      },
      { permission: "PROFILE_READ", method: this.addMultiPageSubItem, args: ["profiles-list", faIdBadge, "profile"] },
      {
        permission: "GOD",
        method: this.addSubItem,
        args: ["/settings/countries-list", faGlobeEurope, "sidebar.footer.countries-list"],
      },
      {
        permission: "JOB_READ",
        method: this.addSubItem,
        args: ["/settings/jobs-list-employee", faUsersRectangle, "sidebar.footer.jobs-list-employee"],
      },
      {
        permission: "JOB_READ",
        method: this.addSubItem,
        args: ["/settings/jobs-list-external-contact", faUsers, "sidebar.footer.jobs-list-external-contact"],
      },
      {
        permission: "COMPANY_CONFIG_READ",
        method: this.addSubItem,
        args: ["/settings/company-config", faMoneyCheckEdit, "sidebar.footer.company-config"],
      },
      {
        permission: "STORE_BRAND_READ",
        method: this.addSubItem,
        args: ["/settings/store-brand", faStoreAlt, "sidebar.footer.store-brand"],
      },
      {
        permission: "UOM_READ",
        method: this.addSubItem,
        args: ["/settings/uoms-list", faBalanceScale, "sidebar.footer.uoms-list"],
      },
      { permission: "USER_READ", method: this.addMultiPageSubItem, args: ["users-list", faUser, "user"] },
      {
        permission: "TAG_READ",
        method: this.addSubItem,
        args: ["/settings/tags-list", faTag, "sidebar.footer.tags-list"],
      },
      {
        permission: "GOD",
        method: this.addSubItem,
        args: ["/settings/currencies-list", faEuroSign, "sidebar.footer.currencies-list"],
      },
      {
        permission: "SIZE_CATEGORY_READ",
        method: this.addSubItem,
        args: ["/settings/size-categories-list", faRing, "sidebar.footer.size-categories-list"],
      },
      {
        permission: "ITEM_CATEGORY_READ",
        method: this.addSubItem,
        args: ["/settings/item-categories-list", faBoxFull, "sidebar.footer.item-categories-list"],
      },
      {
        permission: "ITEM_UNIVERSE_READ",
        method: this.addSubItem,
        args: ["/settings/item-universes-list", faListAlt, "sidebar.footer.item-universes-list"],
      },
      {
        permission: "CLASSIFICATION_READ",
        method: this.addSubItem,
        args: ["/settings/classifications-list", faAnalytics, "sidebar.footer.classifications-list"],
      },
      {
        permission: "PRICING_GROUP_READ",
        method: this.addSubItem,
        args: ["/settings/pricing-groups-list", faAbacus, "sidebar.footer.pricing-groups-list"],
      },
      {
        permission: "PROMOTION_PERIOD_READ",
        method: this.addSubItem,
        args: ["/settings/promotions-list", faCalendarStar, "sidebar.footer.promotions-list"],
      },
      {
        permission: "STOCK_LOCATION_READ",
        method: this.addSubItem,
        args: ["/settings/stock-location-list", faMapMarkedAlt, "sidebar.footer.stock-location-list"],
      },
      {
        permission: "ITEM_GROUP_READ",
        method: this.addMultiPageSubItem,
        args: ["item-groups-list", faCompressAlt, "item-group"],
      },
      {
        permission: "CUSTOMIZATION_READ",
        method: this.addSubItem,
        args: ["/settings/customization", faInputText, "sidebar.footer.customization"],
      },
      {
        permission: "SALE_DOC_CONFORMITY",
        method: this.addSubItem,
        args: ["/settings/app-config", faGear, "sidebar.footer.app-config"],
      },
      {
        permission: "BIGBROWSER",
        method: this.addSubItem,
        args: ["/history-list", faTimelineArrow, "sidebar.footer.history"],
      },
    ];

    permissionsToItems.forEach(item => {
      if (this.userService.canDo(item.permission)) {
        item.method.apply(this, item.args);
      }
    });
  }

  buildHelpMenuItems(): void {
    this.helpsItems = [];

    this.addSubItem("https://monfugu.fr/", faCloudQuestion, "sidebar.footer.website-help", this.helpsItems, true);
    this.addSubItem(
      "https://monfugu.fr/notes-de-mise-a-jour-center/",
      faSquareInfo,
      "sidebar.footer.last-update-help",
      this.helpsItems,
      true
    );
    this.helpsItems.push({
      icon: faCommentsQuestionCheck,
      onSelect: (): void => {
        this.showHideContactSupportPopup();
      },
      title: this.translateService.instant("sidebar.footer.contact-support"),
      isSelected: (menuContext: any) => menuContext.url === "",
    });
  }

  addMultiPageSubItem(id: string, icon: IconDefinition, name: string): void {
    this.settingsItems.push({
      icon,
      onSelect: this.navigateMenu(`/settings/${id}`),
      title: this.translateService.instant(`sidebar.footer.${id}`),
      isSelected: (menuContext: { url: string }) =>
        menuContext.url.startsWith(`/${id}`) ||
        menuContext.url === `/settings/${id}` ||
        menuContext.url === `/settings/${name}/add` ||
        menuContext.url.startsWith(`/settings/${name}/update`),
    });
  }

  loadLogosByTheme(userTheme: string): void {
    if (userTheme === "light") {
      this.appLogoSrc = "./assets/images/logo_fugu_blue.png";
      this.appMiniLogoSrc = "./assets/images/logo_fugu_blue_small.png";
    } else {
      this.appLogoSrc = "./assets/images/logo_fugu_white.png";
      this.appMiniLogoSrc = "./assets/images/logo_fugu_white_small.png";
    }
  }

  openStoreSelectionPopup(): void {
    if (this.filteredStores && this.filteredStores.length > 1) {
      this.storeSelectionPopupVisible = true;
    }
  }

  onCloseStoreSelectionPopup(): void {
    this.setSelectedStore();
    this.storeSelectionPopupVisible = false;
  }

  public showHideContactSupportPopup(): void {
    this.showContactSupportPopup = !this.showContactSupportPopup;
  }

  public closeReleasePopup(): void {
    this.showReleasePopup = false;
    this.registerAppVersion();
  }

  private setSelectedStore(): void {
    const selectedStoreId = this.authService.getContextStoreId();
    if (selectedStoreId && this.currentUser?.stores) {
      this.selectedStore = this.currentUser.stores.filter(store => store.storeId === selectedStoreId)[0];
    }
  }

  private themeManagement(userTheme: string = "light"): void {
    this.renderer.removeClass(document.body, "theme-dark");
    this.renderer.removeClass(document.body, "theme-light");

    this.renderer.addClass(document.body, `theme-${userTheme}`);
    this.loadLogosByTheme(userTheme);
  }

  private navigateMenu(url: string, openNewTab: boolean = false): (event: MenuItemSelectionEvent) => void {
    return (event: MenuItemSelectionEvent) => {
      if (event.click === "middle" || openNewTab) {
        window.open(url, "_blank");
        return;
      }

      // clear filter values storage
      sessionStorage.clear();

      this.router.navigateByUrl(url);
    };
  }

  private isMainStore(): Observable<boolean> {
    const selectedStoreId = this.authService.getContextStoreId();

    return this.storeService.getMain().pipe(
      map(mainStore => selectedStoreId === mainStore.id),
      tap(isMainStore => {
        return of(isMainStore);
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  private addSubItem(
    path: string,
    icon: IconDefinition,
    titleKey: string,
    itemList: any[] = this.settingsItems,
    openNewTab: boolean = false
  ): void {
    itemList.push({
      icon: icon,
      onSelect: this.navigateMenu(path, openNewTab),
      title: this.translateService.instant(titleKey),
      isSelected: (menuContext: any) => menuContext.url === path,
    });
  }

  private setUpAnalytics(): void {
    this.subscriptionService.subs.push(
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          map(event => event as NavigationEnd)
        )
        .subscribe({
          next: event => {
            gtag("config", "G-1L3037D72R", {
              page_path: event.urlAfterRedirects,
            });
          },
        })
    );
  }

  private getAppVersion(): string {
    return localStorage.getItem("app_version");
  }

  private registerAppVersion(): void {
    localStorage.setItem("app_version", this.appVersion);
  }

  private checkAppVersion(): void {
    if (
      this.getAppVersion() !== this.appVersion &&
      this.userService.connectedUser.value !== undefined &&
      this.router.routerState.snapshot.url !== "/login"
    ) {
      this.showReleasePopup = true;
    }
  }
}
