<ng-container>
  <div class="main-container frozen-right">
    <div class="title">
      <ui-title titleText="{{ 'new-receipt-list.title' | translate }}"></ui-title>
    </div>
    <div class="top-buttons">
      <div class="button-create">
        <ui-button
          text="{{ 'new-receipt-list.add-button' | translate }}"
          type="add"
          [primary]="true"
          *hasPermission="{
            requireAllPermissions: false,
            permissions: ['RECEIVING_FORM_DIRECT_CREATE_UPDATE', 'RECEIVING_FORM_REGISTERING_PREPARE'],
          }"
          (click)="createReceivingForm()"
        ></ui-button>
      </div>
    </div>
    <div class="new-receipt-list">
      <ui-container>
        <div class="filters" *ngIf="filterer">
          <ui-dynamic-filters
            label="{{ 'filters.label' | translate }}"
            [filters]="filterer.getFilters()"
            [(filterValues)]="filterer.filterValues"
            (apply)="applyFilters()"
            [locale]="locale"
            dateFormat="{{ dateFormat }}"
            applyLabel="{{ 'filters.apply' | translate }}"
            minLabel="{{ 'filters.min' | translate }}"
            maxLabel="{{ 'filters.max' | translate }}"
            deleteLabel="{{ 'filters.delete' | translate }}"
            placeholderPrefix="{{ 'filters.prefix' | translate }} "
          >
          </ui-dynamic-filters>
        </div>

        <div
          class="headband"
          *hasPermission="{
            requireAllPermissions: false,
            permissions: ['RECEIVING_FORM_DIRECT_CREATE_UPDATE', 'RECEIVING_FORM_REGISTERING_CREATE_UPDATE'],
          }"
        >
          <div class="selected-count">
            <span
              [innerHTML]="
                selectedReceivingFormList.length === 0
                  ? ('global.datatable.no-items' | translate)
                  : (selectedReceivingFormList.length
                    | i18nPlural
                      : {
                          '=1': 'global.datatable.n-selected-items',
                          other: 'global.datatable.n-selected-items_plural',
                        }
                    | translate: { selectedElements: selectedReceivingFormList.length })
              "
            >
            </span>
          </div>
          <div class="buttons">
            <div class="button-change">
              <ui-button
                text="{{ 'new-receipt-list.datatable.validate-receipts-button' | translate }}"
                type="validate"
                (click)="openValidationPopup()"
                [disabled]="!selectedReceivingFormList.length"
              >
              </ui-button>
            </div>
          </div>
          <div class="tooltip">
            <span class="info-icon" #info><fa-icon [icon]="faInfoCircle"></fa-icon></span>
            <ui-tooltip maxWidth="300px" [element]="info"
              ><span>{{
                this.withRegistering
                  ? ("new-receipt-list.info.with-registering" | translate)
                  : ("new-receipt-list.info.direct" | translate)
              }}</span></ui-tooltip
            >
          </div>
        </div>

        <div
          class="separator"
          *hasPermission="{
            requireAllPermissions: false,
            permissions: ['RECEIVING_FORM_DIRECT_CREATE_UPDATE', 'RECEIVING_FORM_REGISTERING_CREATE_UPDATE'],
          }"
        >
          <div class="pointer">
            <div class="arrow"></div>
          </div>
          <div class="line"></div>
        </div>
        <div [formGroup]="tableControl" class="new-receipt-table">
          <div>
            <ngx-datatable
              #table
              [rows]="rows"
              [trackByProp]="'id'"
              class="material"
              columnMode="force"
              headerHeight="auto"
              footerHeight="50"
              rowHeight="40"
              [cssClasses]="{
                sortAscending: 'fas fa-chevron-up',
                sortDescending: 'fas fa-chevron-down',
              }"
              [messages]="{ emptyMessage: 'global.no-data' | translate }"
              [externalSorting]="true"
              [externalPaging]="true"
              [count]="pager.totalElements"
              [offset]="pager.number"
              [limit]="pager.size"
              scrollbarH="true"
              (activate)="updateReceivingForm($event)"
              [sorts]="sorts"
              (sort)="changeSortSettings($event.column.prop, $event.newValue)"
            >
              <ngx-datatable-column
                width="50"
                [sortable]="false"
                [frozenLeft]="true"
                [draggable]="false"
                [resizeable]="false"
                [canAutoResize]="false"
                cellClass="center-align frozen-style"
                *hasPermission="{
                  requireAllPermissions: false,
                  permissions: ['RECEIVING_FORM_DIRECT_CREATE_UPDATE', 'RECEIVING_FORM_REGISTERING_CREATE_UPDATE'],
                }"
              >
                <ng-template ngx-datatable-header-template>
                  <div class="green-zone" (click)="$event.stopPropagation()">
                    <ui-checkbox
                      customClass="no-margin"
                      (change)="onHeaderCheckboxChange()"
                      [formControlName]="'headerCheckbox'"
                    >
                    </ui-checkbox>
                  </div>
                </ng-template>

                <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
                  <div *ngIf="row.checkable" class="adjust green-zone" (click)="$event.stopPropagation()">
                    <ui-checkbox
                      customClass="no-margin"
                      (change)="onRowCheckboxChange()"
                      [formControlName]="getRowControlName(id)"
                    >
                    </ui-checkbox>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'new-receipt-list.datatable.columns.delivery-reference' | translate }}"
                prop="deliveryRef"
                cellClass="delivery-reference"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div class="ellipsis" #reference>{{ value }}</div>
                  <ui-tooltip [element]="reference" maxWidth="300px">
                    <span style="font-weight: normal !important; white-space: break-spaces">
                      {{ value }}
                    </span>
                  </ui-tooltip>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'new-receipt-list.datatable.columns.document-date' | translate }}"
                prop="documentDate"
                cellClass="document-date"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-date="row.documentDate">
                  <span>{{ date | dateTimeFormat: false }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'new-receipt-list.datatable.columns.order-references' | translate }}"
                prop="orderReferences"
                cellClass="order-references ellipsis-counter"
                [resizeable]="false"
                width="200"
                [sortable]="false"
              >
                <ng-template
                  ngx-datatable-cell-template
                  let-row="row"
                  let-id="row.id"
                  let-orderRefs="row.orderReferences"
                >
                  <ng-container *ngIf="orderRefs">
                    <p class="ellipsis">{{ orderRefs.join(", ") }}</p>
                    <div *ngIf="orderRefs.length >= 2">
                      <div #orderRefsCounter>
                        <ui-counter [number]="orderRefs.length"></ui-counter>
                      </div>
                      <ui-tooltip maxWidth="300px" [element]="orderRefsCounter">
                        <span style="font-weight: normal !important">{{ orderRefs.join(", ") }}</span>
                      </ui-tooltip>
                    </div>
                  </ng-container>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'new-receipt-list.datatable.columns.supplier-ref' | translate }}"
                prop="supplierRef"
                cellClass="supplier-ref"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'new-receipt-list.datatable.columns.supplier-name' | translate }}"
                prop="supplierName"
                cellClass="supplier-name"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'new-receipt-list.datatable.columns.recipient-name' | translate }}"
                prop="recipientName"
                cellClass="recipient-name"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'new-receipt-list.datatable.columns.difference-of-quantity' | translate }}"
                cellClass="quantity"
                [resizeable]="false"
                [sortable]="false"
                [width]="200"
                *ngIf="this.withRegistering"
              >
                <ng-template
                  ngx-datatable-cell-template
                  let-row="row"
                  let-receivedQuantity="row.receivedQuantity"
                  let-quantity="row.quantity"
                >
                  <div class="quantity-wrapper" *ngIf="quantity > 0">
                    <div class="{{ row.quantityClass }}">{{ receivedQuantity }}/{{ quantity }}</div>
                    <ui-progress-bar value="{{ (receivedQuantity / quantity) * 100 }}" max="100"> </ui-progress-bar>
                    <div *ngIf="receivedQuantity > quantity" class="warning">
                      <fa-icon [icon]="exclamationCircle"></fa-icon>
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'new-receipt-list.datatable.columns.type' | translate }}"
                prop="type"
                cellClass="type"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'new-receipt-list.datatable.columns.creation-type' | translate }}"
                prop="creationType"
                cellClass="creation-type"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'new-receipt-list.datatable.columns.differences-count' | translate }}"
                prop="differencesNumber"
                [resizeable]="false"
                *ngIf="this.withRegistering"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <span>
                    <fa-icon class="{{ row.iconClass }}" [icon]="row.icon"></fa-icon>
                  </span>
                  <span
                    [innerHTML]="
                      value === 0
                        ? ('new-receipt-list.datatable.count.no-difference' | translate)
                        : (value
                          | i18nPlural
                            : {
                                '=1': 'new-receipt-list.datatable.count.one',
                                other: 'new-receipt-list.datatable.count.many',
                              }
                          | translate: { selectedValue: value })
                    "
                  ></span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'new-receipt-list.datatable.columns.compliant' | translate }}"
                cellClass="compliant"
                [resizeable]="false"
                [width]="120"
                prop="matchExpectation"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-matchExpectation="row.matchExpectation">
                  <div *ngIf="matchExpectation === true">
                    <span>
                      <fa-icon class="{{ 'check-icon' }}" [icon]="this.checkIcon"></fa-icon>
                    </span>
                    <span [innerHTML]="'new-receipt-list.datatable.columns.yes' | translate"> </span>
                  </div>

                  <div *ngIf="matchExpectation === false">
                    <span>
                      <fa-icon class="{{ 'exclamation-icon' }}" [icon]="this.exclamationCircle"> </fa-icon>
                    </span>
                    <span [innerHTML]="'new-receipt-list.datatable.columns.no' | translate"> </span>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                [sortable]="false"
                [canAutoResize]="false"
                [resizeable]="false"
                headerClass="narrow"
                [draggable]="false"
                [width]="50"
              >
                <ng-template ngx-datatable-header-template>
                  <div #info><fa-icon [icon]="faInfo" class="info-icon"></fa-icon></div>

                  <ui-tooltip maxWidth="300px" [element]="info">
                    <span style="font-weight: normal !important">
                      {{ "new-receipt-list.datatable.columns.match-expectation-info" | translate }}
                    </span>
                  </ui-tooltip>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'new-receipt-list.datatable.columns.order-types' | translate }}"
                prop="orderTypes"
                [cellClass]="'ellipsis-counter'"
                [resizeable]="false"
                [sortable]="false"
              >
                <ng-template ngx-datatable-cell-template let-orderTypes="row.orderTypes">
                  <ng-container *ngIf="orderTypes">
                    <p class="ellipsis" [title]="orderTypes.join(', ')">{{ orderTypes.join(", ") }}</p>
                    <div *ngIf="orderTypes.length >= 2">
                      <div #orderTypesCounter>
                        <ui-counter [number]="orderTypes.length"></ui-counter>
                      </div>
                      <ui-tooltip maxWidth="300px" [element]="orderTypesCounter">
                        <span style="font-weight: normal !important">{{ orderTypes.join(", ") }}</span>
                      </ui-tooltip>
                    </div>
                  </ng-container>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                *hasPermission="['PURCHASING_PRICE']"
                name="{{ 'new-receipt-list.datatable.columns.total-price' | translate }}"
                prop="totalPrice"
                cellClass="total-price"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-currencyIsoCode="row.currencyIsoCode" let-value="value">
                  <div class="adjust">
                    {{ value | formatCurrency: currencyIsoCode : "symbol" : "0.2-2" : locale | async }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'new-receipt-list.datatable.columns.status' | translate }}"
                prop="statusIndex"
                cellClass="statusIndex frozen-style"
                headerClass="left-align"
                [resizeable]="false"
                [frozenRight]="true"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <ui-status-label [label]="row.receiveStatus" [customClass]="row.statusClass"></ui-status-label>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-selectedCount="selectedCount"
                  let-curPage="curPage"
                  let-offset="offset"
                  let-isVisible="isVisible"
                >
                  <div class="page-count" *ngIf="rowCount">
                    {{
                      rowCount
                        | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                        | translate: { rowCount: rowCount }
                    }}
                  </div>
                  <datatable-pager
                    [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                    [pagerRightArrowIcon]="'fas fa-chevron-right'"
                    [pagerNextIcon]="'fas fa-chevron-double-right'"
                    [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!(rowCount / pageSize > 1)"
                    (change)="changePage($event)"
                  >
                  </datatable-pager>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
      </ui-container>
    </div>
  </div>

  <app-delivery-initiator-popup
    *ngIf="initiatorPopupVisible"
    (close)="closeInitiatorPopup()"
  ></app-delivery-initiator-popup>

  <app-receipt-validation-popup
    *ngIf="validationPopupVisible"
    [btnLabel]="'receipt-validation-popup.back-to-list'"
    [multiple]="selectedReceivingFormList.length > 1"
    [warn]="shouldShowWarningMessage()"
    [withRegistering]="this.withRegistering"
    (close)="closeValidationPopup()"
    (validate)="initReceive()"
  ></app-receipt-validation-popup>

  <app-pending-delivery-choice-popup
    *ngIf="pendingDeliveryChoicePopupVisible"
    [receivingForm]="this.pendingReceivingForm"
    (close)="closePendingDeliveryChoicePopup()"
  ></app-pending-delivery-choice-popup>
</ng-container>
