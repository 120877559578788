import { Decimal } from "decimal.js";
import { Location } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { IconDefinition, faChevronLeft, faPen } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  AbstractItem,
  AbstractReceivingLine,
  Action,
  AuthService,
  CaraUserService,
  CategoryType,
  CreationDeliveryType,
  Currency,
  DeliveryLineColumn,
  DeliveryLineRow,
  DeliveryLineStatus,
  DeliveryType,
  DiscountType,
  ItemPurchaseModality,
  PaginatedList,
  Pagination,
  PurchaseModality,
  PurchaseOrder,
  PurchaseOrderLine,
  PurchaseType,
  ReceiveStatus,
  ReceivingForm,
  ReceivingFormService,
  ReceivingFreeLine,
  ReceivingInternalLine,
  ReceivingPOLine,
  RetailItemService,
  ServiceItem,
  StandardItem,
  StockType,
  TotalUnitPriceCalculator,
  Uom,
  UomService,
} from "center-services";
import { RoundingUtil, SearchFilter, SearchFilterOperator, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { ComponentDirty, ErrorUtil } from "generic-pages";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { v4 as uuidv4 } from "uuid";
import { ReceivingFormColumnBuilder } from "../util/receiving-form-column-builder";
import { ReceivingFormFetcher } from "../util/receiving-form-fetcher";

@Component({
  selector: "app-receiving-form",
  templateUrl: "./receiving-form.component.html",
  styleUrls: ["./receiving-form.component.scss"],
  providers: [
    {
      provide: ReceivingFormFetcher,
      useClass: ReceivingFormFetcher,
    },
    SubscriptionService,
  ],
})
export class ReceivingFormComponent implements OnInit, ComponentDirty {
  @ViewChild("newDeliveryFormHeader") newDeliveryFormHeader: any;
  @ViewChild("newDeliveryLines") newDeliveryLines: any;
  @ViewChild("tabHandler") tabHandler: any;
  @ViewChild("newDeliveryFormValidation") newDeliveryFormValidation: any;
  public updatedReceivingForm: ReceivingForm;
  public unsavedReceivingForm: ReceivingForm;
  public editedReceivingForm: ReceivingForm;
  public shouldClose: boolean = false;
  public lineColumns: DeliveryLineColumn[];
  public rows: DeliveryLineRow[];
  public isLoaded: boolean = false;
  public faPen: IconDefinition = faPen;
  public title: string;
  public validateBtn: string;
  public detailHeader: boolean;
  public validationPopupVisible: boolean;
  public receivingFormId: number;
  public receiverId: number;
  public senderId: number;
  public currencyId: number;
  /**
   * PMs passed by the initator popup
   */
  public inputItemPurchaseModalityList: ItemPurchaseModality[] = [];
  public purchaseModalityIdList: number[] = [];
  public action: Action;
  public selectionPopupVisible: boolean = false;
  public updateReceivePopupVisible: boolean = false;
  /**
   *
   * used when withPreparation = true, ReceptionType.WITH_REGISTERING
   * & when the it's validation of preparation
   *
   */
  public isValidationForPreparation: boolean = false;
  /**
   *
   * isValidateButtonDisplayed && isUpdateButtonDisplayed both manege the the validate-button
   * validate button is the same as the update button
   * isUpdateButtonDisplayed isUpdateButtonDisplayed triggers either the validation function or the update function on click
   *
   */
  public isValidateButtonDisplayed: boolean = true;
  public isUpdateButtonDisplayed: boolean = false;
  // just for update lines and headers, it does not change the status of the receiving form
  public displaySaveBtn: boolean = false;
  public faChevronLeft: IconDefinition = faChevronLeft;
  public deliveryCurrency: Currency;
  public poLines: PurchaseOrderLine[];
  public canJustRead: boolean;
  public canCreateAndUpdateDirect: boolean;
  public canCreateAndUpdateWithRegistering: boolean;
  public canPrepareReceiving: boolean;
  public authorities: string[];
  private readonly backUrl: string = "/receiving-list";
  private readonly PERCENT_VALUE: number = 100;

  constructor(
    protected translateService: TranslateService,
    protected messageService: MessageService,
    protected userService: CaraUserService,
    private receivingFormService: ReceivingFormService,
    private uomService: UomService,
    private retailItemService: RetailItemService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private authService: AuthService,
    private titleService: Title,
    public fetcher: ReceivingFormFetcher,
    private subscriptionService: SubscriptionService
  ) {
    this.receivingFormId = this.route.snapshot.params.id;
    this.route.queryParams?.subscribe(params => {
      if (params.action) {
        this.action = params.action;
      }
    });

    const currentNav = this.router.getCurrentNavigation();
    this.backUrl = currentNav.extras.state?.backUrl ?? "/receiving-list";

    if (currentNav.extras.state) {
      const inputPOList = currentNav.extras.state.purchaseOrderList?.sort((po1, po2) => po1.id - po2.id);
      this.fetcher.poList = inputPOList;
      this.inputItemPurchaseModalityList = currentNav.extras.state.itemPurchaseModalityList;
      this.receiverId = currentNav.extras.state.receiverId;
      this.currencyId = currentNav.extras.state.currencyId;
      this.senderId = currentNav.extras.state.senderId;
    } else if (!this.receivingFormId) {
      this.backToPrevious();
    }
  }

  public ngOnInit(): void {
    this.authorities = this.authService.getAuthorities().filter(auth => auth.startsWith("RECEIVING"));
    this.subscriptionService.subs.push(
      this.fetcher.fetchInitialData().subscribe(() => {
        this.subscriptionService.subs.push(
          this.getOrCreateReceivingForm(this.receivingFormId).subscribe(() => {
            this.subscriptionService.subs.push(
              this.fetcher
                .fetchReceivingFormData(
                  this.updatedReceivingForm,
                  this.inputItemPurchaseModalityList?.flatMap(pm => pm.purchaseModalityId) ?? []
                )
                .subscribe(() => {
                  if (!this.receivingFormId) {
                    this.createReceivingFormLines();
                    this.editedReceivingForm = new ReceivingForm(this.updatedReceivingForm);
                  }
                  if (
                    this.updatedReceivingForm.receiveStatus === ReceiveStatus.REFUSED &&
                    this.router.routerState.snapshot.url.indexOf("update") > 0
                  ) {
                    this.router.navigateByUrl(`/receiving-form-detail/${this.updatedReceivingForm.id}`);
                    return;
                  }
                  this.buildDatas();
                  const translatedMenuEntry = this.translateService.instant("sidebar.menu.receiving-list");
                  let translatedTitle: string;
                  if (this.title === "receiving-form.title.read-receipt") {
                    translatedTitle = this.translateService.instant("receiving-form.title.detail-receipt");
                  } else {
                    translatedTitle = this.translateService.instant(this.title);
                  }
                  this.titleService.setTitle(`${translatedTitle} - ${translatedMenuEntry} - Neo-Retail`);
                })
            );
          })
        );
      })
    );
  }

  public detailLines(lines: AbstractReceivingLine[]): void {
    const abstractReceivingLines = lines.map(line => new ReceivingPOLine(line));
    this.updatedReceivingForm.lines.push(...abstractReceivingLines);
    this.buildRows();
  }

  public getBackButtonTranslation(): string {
    if (this.backUrl && this.backUrl.startsWith("/receiving-list")) {
      return "receiving-form.buttons.back-to-list";
    }
    return "receiving-form.buttons.back-to-details";
  }

  public backToPrevious(): void {
    this.router.navigateByUrl(this.backUrl);
  }

  public isDirty(): boolean {
    if (!this.updatedReceivingForm) {
      return false;
    }
    if (this.updatedReceivingForm.receiveStatus === ReceiveStatus.REFUSED) {
      return false;
    }

    this.newDeliveryLines.applyModifications();

    if (this.unsavedReceivingForm && !this.unsavedReceivingForm.equals(this.updatedReceivingForm)) {
      this.shouldClose = false;
    }
    if (this.editedReceivingForm && !this.editedReceivingForm.equals(this.updatedReceivingForm) && !this.shouldClose) {
      this.saveReceivingFormState();
      return true;
    } else if (!this.editedReceivingForm && !this.shouldClose) {
      this.saveReceivingFormState();
      return true;
    }

    this.unsavedReceivingForm = null;
    this.shouldClose = false;
    return false;
  }

  // save the receivingForm
  public submitReceivingForm(): void {
    if (this.cantSubmit()) {
      return;
    }
    this.subscriptionService.subs.push(
      this.saveReceivingForm().subscribe((receivingForm: ReceivingForm) => {
        if (receivingForm) {
          this.editedReceivingForm = new ReceivingForm(this.updatedReceivingForm);
          this.saveReceivingFormState();
          this.displayMessageSuccess();
          this.buildRows();
        }
      })
    );
  }

  // manage saving and/or manage validatation of preparation/receiving
  public submit(toValidate: boolean = false): void {
    if (!toValidate || this.cantSubmit()) {
      return;
    }
    this.subscriptionService.subs.push(
      this.saveReceivingForm().subscribe(() => {
        this.subscriptionService.subs.push(
          this.validatePreparationOrReceive().subscribe(() => {
            this.displayMessageSuccess();
            this.router.navigateByUrl("/receiving-list");
          })
        );
      })
    );
  }

  cantSubmit(): boolean {
    return this.hasErrors() || !this.editedReceivingForm;
  }

  // Method used to give row ids to new-delivery-lines component
  public getRowIds(po: PurchaseOrder): number[] {
    return po.lines.map(line => line.id);
  }

  // Method used to navigate between purchase orders tabs
  public onTabClick(event: any): void {
    if (!event) {
      return;
    }
    if (event && this.tabHandler) {
      this.tabHandler.changeTab(event);
    }
  }

  openSelectionPopup(): void {
    this.selectionPopupVisible = true;
  }

  closeSelectionPopup(): void {
    this.selectionPopupVisible = false;
  }

  submitSelectionPopup(selectedItemsAndPms: any): void {
    // make a list of PM ids...
    let receivedPurchaseModalityIdList = [];
    const itemToAddIdList = [];
    const pmToAddIdList = [];
    for (const [itemId, pmIds] of selectedItemsAndPms) {
      // list all PM ids received
      receivedPurchaseModalityIdList = receivedPurchaseModalityIdList.concat(pmIds);
      // check if we need to fetch new items depending on what we received from the popup and what we have already
      pmIds.forEach(id => {
        if (!this.purchaseModalityIdList.includes(id)) {
          // add the item id
          if (!this.fetcher.retailItemList.find(item => item.id === itemId) && !itemToAddIdList.includes(itemId)) {
            itemToAddIdList.push(itemId);
          }
          // create a list of new PM to add
          pmToAddIdList.push(id);
          // update pm id list
          this.purchaseModalityIdList.push(id);
        } else {
          this.updatedReceivingForm.lines.push(this.createReceivingFreeLine(id));
          this.buildRows();
        }
      });
    }

    this.newDeliveryLines.applyModifications();

    // add line
    if (itemToAddIdList.length > 0) {
      this.fetchNewItems(pmToAddIdList);
    } else {
      pmToAddIdList.forEach(id => this.updatedReceivingForm.lines.push(this.createReceivingFreeLine(id)));
      this.buildRows();
    }
  }

  fetchNewItems(pmToAddIdList: any[]): void {
    const pager = new Pagination({
      size: pmToAddIdList.length,
      number: 0,
    });
    const filters = new SearchFilter("purchaseModalities.id", SearchFilterOperator.IN, pmToAddIdList);

    this.subscriptionService.subs.push(
      this.retailItemService.getAll(pager, [], [filters]).subscribe(
        (result: PaginatedList<AbstractItem>) => {
          result.data
            .map(item => {
              item.convertDataTo(
                this.uomService,
                this.fetcher.purchaseUnitList,
                this.fetcher.purchaseUnitList.find(uom => "g" === uom.shortName).id
              );
              return item;
            })
            .forEach(item => {
              if (!this.fetcher.retailItemList.includes(item)) {
                this.fetcher.retailItemList.push(item);
              }
            });
          pmToAddIdList.forEach(id => this.updatedReceivingForm.lines.push(this.createReceivingFreeLine(id)));
          this.buildRows();
        },
        error => {
          this.sendErrorAlert("retail-item-list.errors.get-retail-items", error.message);
        }
      )
    );
  }

  // After a row is deleted, we need to rebuild so the LineNumber make sense
  rowDeleted(): void {
    this.buildRows();
  }

  // ------ Validation Popup -------
  closeValidationPopup(): void {
    this.validationPopupVisible = false;
  }

  submitValidationPopup(): void {
    const toValidate = true;
    this.submit(toValidate);
    this.validationPopupVisible = false;
  }

  openValidationPopup(): void {
    if (!this.hasErrors()) {
      this.validationPopupVisible = true;
    }
    // ------ Validation Popup -------
  }

  // ------ Update receive Popup -------
  closeUpdateReceivePopup(): void {
    this.updateReceivePopupVisible = false;
  }

  openUpdateReceivePopup(): void {
    if (!this.hasErrors()) {
      if (this.isUpdateButtonDisplayed && !this.editedReceivingForm.equals(this.updatedReceivingForm)) {
        this.updateReceivePopupVisible = true;
        return;
      }
    }
  }

  updateReceive(): void {
    this.editedReceivingForm = new ReceivingForm(this.updatedReceivingForm);

    this.router.navigateByUrl("/receiving-list");
  }

  editAllowedAsMainStore(): boolean {
    const selectedStoreId = this.authService.getContextStoreId();
    const storeForbiddenToModify =
      selectedStoreId === this.fetcher.mainStore.id && selectedStoreId !== this.updatedReceivingForm.receiverId;
    const receivingStatus = this.updatedReceivingForm.receiveStatus;
    const inProgress = receivingStatus === ReceiveStatus.RECEIVE_IN_PROGRESS;
    const toReceive = receivingStatus === ReceiveStatus.TO_RECEIVE;
    const received = receivingStatus === ReceiveStatus.RECEIVED;
    return !((inProgress || toReceive || received) && storeForbiddenToModify);
  }

  isNotGoingToUpdatePageForInternalBL(): boolean {
    return (
      this.updatedReceivingForm.type === DeliveryType.INTERNAL &&
      this.updatedReceivingForm.receiveStatus === ReceiveStatus.RECEIVED &&
      this.router.routerState.snapshot.url.indexOf("update") > 0
    );
  }

  private buildDatas(): void {
    this.setRights();
    const columnBuilder = new ReceivingFormColumnBuilder(
      this.updatedReceivingForm,
      this.action,
      this.fetcher.receptionType,
      false,
      this.authService.getContextStoreId(),
      this.fetcher.mainStore.id,
      this.userService
    );

    this.lineColumns = columnBuilder.buildColumns();
    this.buildRows();
    this.handleDisplay();
    if (!this.editAllowedAsMainStore() || this.isNotGoingToUpdatePageForInternalBL()) {
      this.router.navigateByUrl(`/receiving-form-detail/${this.updatedReceivingForm.id}`, { replaceUrl: true });
      // set to null to avoid error in dirty check
      this.updatedReceivingForm = null;
      return;
    }
    this.setReadOnlyHeader();
    this.isLoaded = true;
  }

  private setRights(): void {
    this.canCreateAndUpdateDirect = this.userService.canDo("RECEIVING_FORM_DIRECT_CREATE_UPDATE");
    this.canCreateAndUpdateWithRegistering = this.userService.canDo("RECEIVING_FORM_REGISTERING_CREATE_UPDATE");
    this.canJustRead = this.userService.canDo("RECEIVING_FORM_GLOBAL");
    this.canPrepareReceiving = this.userService.canDo("RECEIVING_FORM_REGISTERING_PREPARE");
  }

  private getOrCreateReceivingForm(id: number = null): Observable<ReceivingForm> {
    return id ? this.fetchReceivingForm(id) : this.createReceivingForm();
  }

  private createReceivingFormLines(): void {
    this.updatedReceivingForm.lines =
      this.updatedReceivingForm.creationType === CreationDeliveryType.FREE
        ? this.createReceivingFreeLines()
        : this.createReceivingPOLines();
  }

  private fetchReceivingForm(id: number): Observable<ReceivingForm> {
    return this.receivingFormService.get(id).pipe(
      tap(receivingForm => {
        this.updatedReceivingForm = receivingForm;
        this.editedReceivingForm = new ReceivingForm(this.updatedReceivingForm);
        this.receiverId = receivingForm.receiverId;
        this.senderId = receivingForm.senderId;
        this.currencyId = receivingForm.currencyId;
      })
    );
  }

  private createReceivingForm(): Observable<ReceivingForm> {
    this.updatedReceivingForm = new ReceivingForm({
      currencyId: this.currencyId,
      senderId: this.senderId,
      receiverId: this.receiverId,
      expectedDeliveryDate: this.fetcher.poList?.length === 1 ? this.fetcher.poList[0].deliveryDate : null,
      receiveStatus: ReceiveStatus.TO_PROCESS,
      type: DeliveryType.EXTERNAL_SUPPLIER,
      creationType:
        this.inputItemPurchaseModalityList?.length > 0 ? CreationDeliveryType.FREE : CreationDeliveryType.MANUAL,
      lines: [],
    });
    return of(this.updatedReceivingForm);
  }

  private createReceivingPOLines(): ReceivingPOLine[] {
    const lines = [];
    this.fetcher.poList.forEach((po: PurchaseOrder) => {
      po.lines
        .filter(poLine => poLine.deliveryStoreId === this.receiverId && !poLine.canceled)
        .forEach(poLine => {
          lines.push(
            new ReceivingPOLine({
              expectedSizeValue: poLine.sizeValue,
              receivedSizeValue: this.fetcher.withPreparation ? null : poLine.sizeValue,
              destLocationId: this.getDefaultStockLocation(this.getItem(null, poLine.itemReference)),
              expectedQuantity: Math.max(0, poLine.quantity - poLine.receivedQuantity),
              receivedQuantity: this.fetcher.withPreparation
                ? null
                : Math.max(0, poLine.quantity - poLine.receivedQuantity),
              weight: poLine.weight ? poLine.weight * Math.max(0, poLine.quantity - poLine.receivedQuantity) : null,
              unitPricePerWeight: poLine.unitPricePerWeight,
              unitPrice: poLine.unitPrice,
              tare: poLine.tare ? poLine.tare * Math.max(0, poLine.quantity - poLine.receivedQuantity) : null,
              metalPrice: poLine.metalPrice,
              useMetalAccount: poLine.purchaseType === PurchaseType.WITH_METAL_ACCOUNT,
              status: DeliveryLineStatus.TO_PROCESS,
              type: "ReceivingPOLine",
              purchaseOrderLineId: poLine.id,
              supplierTraceabilityNumber: poLine.supplierTraceabilityNumber,
            })
          );
        });
    });
    return lines;
  }

  private createReceivingFreeLines(): ReceivingFreeLine[] {
    const lines = [];
    this.inputItemPurchaseModalityList.forEach((itemPM: ItemPurchaseModality) => {
      lines.push(this.createReceivingFreeLine(itemPM.purchaseModalityId));
    });
    return lines;
  }

  private createReceivingFreeLine(purchaseModalityId: number): ReceivingFreeLine {
    const item = this.getItem(purchaseModalityId);
    const purchaseModality = item.purchaseModalities?.find(pm => pm.id === purchaseModalityId);

    let defaultSizeValue: string = null;
    let metalPrice = 0;

    if (item instanceof StandardItem && item.sizeCategory && item.sizeCategory.elements.length > 0) {
      for (const value of item.sizeCategory.elements) {
        if (value.byDefault) {
          defaultSizeValue = this.getSizeValue(value.sizeValueId, item.sizeCategory?.sizeCategoryId);
          break;
        }
      }
    }
    if (purchaseModality.purchaseType !== PurchaseType.BASIC && purchaseModality.metalPrices !== undefined) {
      purchaseModality?.metalPrices.forEach(price => {
        const quantity = purchaseModality.metalWeights.find(weight => {
          return weight.metalId === price.metalId;
        })?.weight;
        metalPrice = new Decimal(metalPrice ?? 0).add(new Decimal(quantity ?? 0).mul(price.price ?? 0)).toNumber();
      });
      metalPrice = RoundingUtil.roundLow(metalPrice);
    }

    return new ReceivingFreeLine({
      expectedSizeValue: defaultSizeValue,
      receivedSizeValue: this.fetcher.withPreparation ? null : defaultSizeValue,
      expectedQuantity: 1,
      receivedQuantity: this.fetcher.withPreparation ? null : 1,
      metalPrice,
      weight: item instanceof StandardItem ? item.weight : null,
      tare: item instanceof StandardItem ? item.tare : null,
      unitPrice: purchaseModality.unitPrice,
      unitPricePerWeight: purchaseModality.unitPricePerWeight,
      useMetalAccount: purchaseModality.purchaseType === PurchaseType.WITH_METAL_ACCOUNT,
      status: DeliveryLineStatus.VALIDATED,
      type: "ReceivingFreeLine",
      purchaseModalityId,
      destLocationId: this.getDefaultStockLocation(item),
    });
  }

  private getItem(pmId: number = null, itemRef: string = null): AbstractItem {
    if (this.fetcher.retailItemList?.length > 0) {
      for (const item of this.fetcher.retailItemList) {
        if (itemRef && item.reference === itemRef) {
          return item;
        }
        if (pmId) {
          for (const pm of item.purchaseModalities) {
            if (pm.id === pmId) {
              return item;
            }
          }
        }
      }
    }
    return null;
  }

  private getPurchaseModality(pmId: number, item: AbstractItem): PurchaseModality {
    return item.purchaseModalities?.find(pm => pm.id === pmId);
  }

  private getSizeValue(valueId: number, sizeCatId: number): string {
    return this.fetcher.sizeCategoryList.find(sC => sC.id === sizeCatId).elements.find(e => e.id === valueId).value;
  }

  private handleDisplay(): void {
    const selectedStoreId = this.authService.getContextStoreId();

    const centralUserNotAsReceiver =
      this.updatedReceivingForm.receiverId !== selectedStoreId && selectedStoreId === this.fetcher.mainStore.id;
    if (this.updatedReceivingForm.type === DeliveryType.EXTERNAL_SUPPLIER) {
      this.handleExternalSupplierDisplay(centralUserNotAsReceiver);
    } else if (this.updatedReceivingForm.type === DeliveryType.INTERNAL) {
      this.handleInternalDisplay();
    }
  }

  private handleInternalDisplay(): void {
    if (
      this.fetcher.withPreparation &&
      (this.updatedReceivingForm.receiveStatus === ReceiveStatus.RECEIVED || !this.canCreateAndUpdateWithRegistering)
    ) {
      this.isValidateButtonDisplayed = false;
      this.title = "receiving-form.title.read-receipt";
      this.validateBtn = "";
      this.displaySaveBtn = false;
    } else {
      this.title = "receiving-form.title.create-receipt";
      this.validateBtn = "receiving-form.buttons.validate-reception";
      this.displaySaveBtn = true;
    }
  }

  private handleExternalSupplierDisplay(centralUserNotAsReceiver: boolean): void {
    const receivingFormGlobalRightOnly = this.canJustRead && !this.canCreateAndUpdateDirect;

    switch (this.updatedReceivingForm.receiveStatus) {
      case ReceiveStatus.TO_PROCESS:
        this.handleToProcessStatus();
        break;
      case ReceiveStatus.RECEIVE_IN_PROGRESS:
        this.handleReceiveInProgressStatus(receivingFormGlobalRightOnly);
        break;
      case ReceiveStatus.TO_RECEIVE:
        this.handleToReceiveStatus();
        break;
      case ReceiveStatus.PREPARATION_IN_PROGRESS:
        this.handlePreparationInProgressStatus();
        break;
      case ReceiveStatus.RECEIVED:
        this.handleReceivedStatus(receivingFormGlobalRightOnly);
        break;
      case ReceiveStatus.REFUSED:
        this.handleRefusedStatus();
        break;
      case ReceiveStatus.PENDING:
        this.handlePendingStatus(centralUserNotAsReceiver);
        break;
      default:
        console.error(`Don't know how to handle receiveStatus : ${this.updatedReceivingForm.receiveStatus}`);
        break;
    }
  }

  private handleReceiveInProgressStatus(receivingFormGlobalRightOnly: boolean): void {
    if (!this.fetcher.withPreparation && receivingFormGlobalRightOnly) {
      this.title = "receiving-form.title.read-receipt";
    } else {
      this.title = "receiving-form.title.create-receipt";
      this.displaySaveBtn = true;
    }
    this.validateBtn = "receiving-form.buttons.validate-reception";
    if (this.fetcher.withPreparation && !this.canCreateAndUpdateWithRegistering) {
      this.isValidateButtonDisplayed = false;
    }
    if (!this.canCreateAndUpdateDirect && !this.canPrepareReceiving) {
      return;
    }
  }

  private handleToReceiveStatus(): void {
    if (this.isNullOrUndefinedOrEmpty(this.action)) {
      this.title = "receiving-form.title.create-receipt";
      this.validateBtn = "receiving-form.buttons.validate-reception";
      this.displaySaveBtn = true;
    } else {
      switch (this.action) {
        case Action.RECEIVE:
          this.title = "receiving-form.title.create-receipt";
          this.validateBtn = "receiving-form.buttons.validate-reception";
          this.displaySaveBtn = true;
          break;
        case Action.UPDATE_RECEPTION:
          this.isUpdateButtonDisplayed = true;
          this.title = "receiving-form.title.update-receipt";
          this.validateBtn = "receiving-form.buttons.update-reception";
          break;
        default:
          console.error(`Don't know how to handle action : ${this.action}`);
          break;
      }
    }
    if (!this.canCreateAndUpdateWithRegistering) {
      this.isValidateButtonDisplayed = false;
    }
  }

  private handleToProcessStatus(): void {
    this.title = this.fetcher.withPreparation
      ? "receiving-form.title.prepare-receipt"
      : "receiving-form.title.create-receipt";
    this.isValidationForPreparation = this.fetcher.withPreparation;
    this.validateBtn = this.fetcher.withPreparation
      ? "receiving-form.buttons.validate-preparation"
      : "receiving-form.buttons.validate-reception";
    this.displaySaveBtn = true;
    if (this.receiverId !== this.authService.getContextStoreId() && !this.fetcher.withPreparation) {
      this.isValidateButtonDisplayed = false;
    }
  }

  private handlePreparationInProgressStatus(): void {
    this.isValidationForPreparation = true;
    this.title = "receiving-form.title.prepare-receipt";
    this.validateBtn = "receiving-form.buttons.validate-preparation";
    this.displaySaveBtn = true;
  }

  private handleReceivedStatus(receivingFormGlobalRightOnly: boolean): void {
    if (!this.fetcher.withPreparation && receivingFormGlobalRightOnly) {
      this.title = "receiving-form.title.read-receipt";
    } else {
      this.title = "receiving-form.title.update-receipt";
      this.isUpdateButtonDisplayed = true;
    }
    this.validateBtn = "receiving-form.buttons.update-reception";
  }

  private handleRefusedStatus(): void {
    this.isValidateButtonDisplayed = false;
    this.title = "receiving-form.title.update-receipt";
    this.validateBtn = "";
  }

  private handlePendingStatus(centralUserNotAsReceiver: boolean): void {
    if (this.isNullOrUndefinedOrEmpty(this.action)) {
      this.isValidationForPreparation = true;
      this.title = "receiving-form.title.prepare-receipt";
      this.validateBtn = "receiving-form.buttons.validate-preparation";
      this.displaySaveBtn = false;
    } else {
      switch (this.action) {
        case Action.PREPARE:
          this.isValidationForPreparation = true;
          this.title = "receiving-form.title.prepare-receipt";
          this.validateBtn = "receiving-form.buttons.validate-preparation";
          break;
        case Action.RECEIVE:
          this.title = "receiving-form.title.create-receipt";
          this.validateBtn = "receiving-form.buttons.validate-reception";
          this.displaySaveBtn = true;
          if (this.fetcher.withPreparation && (!this.canCreateAndUpdateWithRegistering || centralUserNotAsReceiver)) {
            this.isValidateButtonDisplayed = false;
          }
          break;
        case Action.UPDATE_RECEPTION:
          this.isUpdateButtonDisplayed = true;
          this.title = "receiving-form.title.update-receipt";
          this.validateBtn = "receiving-form.buttons.update-reception";
          break;
        default:
          console.error(`Don't know how to handle action : ${this.action}`);
          break;
      }
    }
  }

  private setReadOnlyHeader(): void {
    const hasReceivedLine = this.updatedReceivingForm.lines.some(line => line.status === DeliveryLineStatus.RECEIVED);
    this.detailHeader = hasReceivedLine || this.updatedReceivingForm.type === DeliveryType.INTERNAL;
  }

  private sendErrorAlert(errorType: string, message: string): void {
    const content = this.translateService.instant(errorType, { message });
    const title = this.translateService.instant("message.title.data-errors");
    this.messageService.warn(content, { title });
  }

  private saveReceivingFormState(): void {
    this.unsavedReceivingForm = new ReceivingForm(this.updatedReceivingForm);
    this.shouldClose = true;
  }

  // Method used to save the current receiving form
  private saveReceivingForm(): Observable<ReceivingForm> {
    if (!this.updatedReceivingForm || this.editedReceivingForm.equals(this.updatedReceivingForm)) {
      return of(null);
    }

    this.updatedReceivingForm.lines.forEach((line: AbstractReceivingLine) => {
      if (typeof line.id === "string") {
        line.id = null;
      }
    });
    const action = this.updatedReceivingForm.id ? "update" : "create";
    return this.receivingFormService[action].call(this.receivingFormService, this.updatedReceivingForm).pipe(
      tap(
        (response: ReceivingForm) => {
          this.finishSave(response, action);
        },
        (error: any) => {
          this.handleApiError(error);
        }
      )
    );
  }

  private showErrorInTabMessage(): void {
    const references = this.fetcher.poList
      .filter(po => {
        const inErrorRows = this.rows.filter(row => row.inError);
        return po.lines.some(line => inErrorRows.find(row => row.polId === line.id));
      })
      .map(po => po.orderRef)
      .join(", ");
    const title = this.translateService.instant("message.title.form-errors");
    const content = this.translateService.instant("receiving-form.errors.pos-in-error", { references });
    this.messageService.error(content, { title });
  }

  private hasErrors(): boolean {
    let isValidHeader = true;
    let isValidLines = true;

    for (const row of this.rows) {
      if (row.rowForm.invalid) {
        row.inError = true;
        isValidLines = false;
      } else {
        row.inError = false;
      }
    }
    this.rows = [...this.rows];

    if (this.fetcher.poList?.length > 0 && !isValidLines) {
      this.showErrorInTabMessage();
    }

    if (this.newDeliveryLines) {
      this.newDeliveryLines.applyModifications();
    }
    if (this.newDeliveryFormHeader) {
      isValidHeader = this.newDeliveryFormHeader.isFormValid();
    }
    return !isValidHeader || !isValidLines;
  }

  private validatePreparationOrReceive(): Observable<ReceivingForm> {
    const action = this.isValidationForPreparation ? "validatePreparation" : "receive";
    return this.receivingFormService[action].call(this.receivingFormService, this.updatedReceivingForm.id).pipe(
      tap({
        error: (error: any) => {
          this.sendErrorAlert(
            "new-delivery-form-validation-popup.errors.validate-preparation-or-receive",
            error.message
          );
        },
      })
    );
  }

  private finishSave(response: ReceivingForm, action: string): void {
    this.editedReceivingForm = response;
    this.updatedReceivingForm = new ReceivingForm(this.editedReceivingForm);
    if (action === "create") {
      this.location.replaceState(`/receiving-form/update/${response.id}`);
    }
  }

  private displayMessageSuccess(): void {
    // pas de success si le validate preparation receive n'a pas marché.
    const content = this.translateService.instant("message.content.save-success");
    const title = this.translateService.instant("message.title.save-success");
    this.messageService.success(content, { title });
  }

  private handleApiError(error: any): void {
    const title = this.translateService.instant("message.title.form-errors");

    const result = ErrorUtil.getTranslationKey(error.error, this.translateService);
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }

  // Method used to build rows used by the new-delivery-lines component
  private buildRows(): void {
    this.setDeliveryCurrency();
    this.setPoLines();
    this.rows = [];
    for (const line of this.updatedReceivingForm.lines) {
      if (this.shouldSkipLine(line)) {
        continue;
      }

      const row = this.createRow(line);
      this.populateRow(line, row);

      if (row.canceled) {
        continue;
      }
      // Fill row prices using ReceivingLine
      row.realTotalUnitPrice = TotalUnitPriceCalculator.computeTotalUnitPrice(
        line,
        row.purchaseType,
        row.discountType,
        row.realDiscount,
        this.PERCENT_VALUE
      );
      row.hasInvoice = line.invoiceSupplierLineId !== null && line.invoiceSupplierLineId !== undefined;
      row.realPriceWithoutDiscount = TotalUnitPriceCalculator.computeTotalUnitPrice(
        line,
        row.purchaseType,
        null,
        null,
        this.PERCENT_VALUE
      );
      row.realUnitPricePerWeight = line.unitPricePerWeight;
      row.useMetalAccount = line.useMetalAccount;
      row.realMetalPrice = line.metalPrice;
      row.realUnitPrice = line.unitPrice;

      // Fill row quantities using ReceivingLine
      if (line.status === DeliveryLineStatus.REFUSED) {
        row.receivedQuantity = 0;
      } else {
        row.receivedQuantity = line.receivedQuantity === null ? line.expectedQuantity : line.receivedQuantity;
      }
      row.expectedQuantity = line.expectedQuantity;

      if (this.isDefined(row.totalWeight) || this.isDefined(row.tare)) {
        const weight = this.isDefined(row.totalWeight) ? row.totalWeight : 0;
        const tare = this.isDefined(row.tare) ? row.tare : 0;
        row.realMetalWeight = new Decimal(weight ?? 0).minus(tare).toNumber();
      } else {
        row.realMetalWeight = null;
      }

      // Fill row size values using ReceivingLine
      row.expectedSizeValue = line.expectedSizeValue;
      row.receivedSizeValue = line.receivedSizeValue;

      // Fill others data using ReceivingLine
      row.supplierName = row.supplierName ? row.supplierName : this.fetcher.sender.name;
      row.status = line.status;
      row.frozen = line.frozen;

      // Compute total price based on receivedQuantity column visibility
      const receivedQuantityColumn = this.lineColumns.find(column => column.property === "receivedQuantity");
      row.totalPrice = RoundingUtil.roundLow(
        new Decimal(row.realTotalUnitPrice ?? 0)
          .times(new Decimal((receivedQuantityColumn.visible ? row.receivedQuantity : row.expectedQuantity) ?? 0))
          .toNumber()
      );
      // Only load TraceabilityNumber from Purchase Order on Creation
      if (row.supplierTraceabilityNumber === undefined) {
        row.supplierTraceabilityNumber = line.supplierTraceabilityNumber;
      }

      this.rows.push(row);
    }
  }

  private setDeliveryCurrency(): void {
    this.deliveryCurrency = this.fetcher.currencies.find(
      (currency: Currency) => currency.id === this.updatedReceivingForm.currencyId
    );
  }

  private setPoLines(): void {
    this.poLines =
      !this.fetcher.poList || !this.fetcher.poList.length
        ? []
        : []
          .concat(...this.fetcher.poList.map((po: PurchaseOrder) => po.lines))
          .filter((poLine: PurchaseOrderLine) => poLine.deliveryStoreId === this.receiverId);
  }

  private shouldSkipLine(line: AbstractReceivingLine): boolean {
    return this.action === Action.PREPARE && line.status !== DeliveryLineStatus.PENDING;
  }

  private createRow(line: AbstractReceivingLine): DeliveryLineRow {
    const row = new DeliveryLineRow();
    row.id = line.id ? line.id : uuidv4();
    if (!line.id) {
      line.id = row.id;
    }
    return row;
  }

  private populateRow(line: AbstractReceivingLine, row: DeliveryLineRow): void {
    switch (line.type) {
      case "ReceivingFreeLine":
        this.populateFreeLineRow(row, line as ReceivingFreeLine);
        break;
      case "ReceivingPOLine":
        this.populatePOLineRow(row, line as ReceivingPOLine);
        break;
      case "ReceivingInternalLine":
        this.populateInternalLineRow(row, line as ReceivingInternalLine);
        break;
      default:
        break;
    }
  }

  // Method used to fill the row using the linked PurchaseModality and RetailItem
  private populateFreeLineRow(row: DeliveryLineRow, line: ReceivingFreeLine): void {
    const item: AbstractItem = this.getItem((line as ReceivingFreeLine).purchaseModalityId);
    row.isStandardItem = item.type === CategoryType.STANDARD;

    const defaultUnitId = this.fetcher.purchaseUnitList.find(uom => "g" === uom.shortName).id;
    const pm: PurchaseModality = this.getPurchaseModality(line.purchaseModalityId, item);
    const pmUnit = this.fetcher.purchaseUnitList.find(
      (purchaseUnit: Uom) => purchaseUnit.id === pm.purchaseUnitId
    ).longName;

    row.lineNumber = this.updatedReceivingForm.lines.indexOf(line) + 1;
    row.destLocationId = line.destLocationId ? line.destLocationId : this.getDefaultStockLocation(item);

    row.discountType = line.discountType ? line.discountType : DiscountType.PERCENT;
    row.realDiscount = line.discount ?? 0;

    // Fill row data using the PurchaseModality
    row.orderedTotalUnitPrice = pm.computedPrice;
    row.purchaseType = pm.purchaseType;
    row.supplierRef = pm.supplierRef;
    row.supplierTraceabilityNumber = line.supplierRef;
    row.purchaseUnit = pmUnit;

    // Fill row data using the RetailItem
    row.brandName = item.brandName;
    row.itemRef = item.reference;
    row.itemName = item.name;

    // Fill row weights using ReceivingLine
    if (typeof line.id !== "string") {
      row.tare = this.isDefined(line.tare) ? line.tare : null;
      row.totalWeight = this.isDefined(line.weight) ? line.weight : null;
    } else {
      row.totalWeight = this.isDefined(line.weight)
        ? this.uomService.convertWeightTo(this.fetcher.purchaseUnitList, line.weight, item.weightUnitId, defaultUnitId)
        : null;

      row.tare = this.isDefined(line.tare)
        ? this.uomService.convertWeightTo(this.fetcher.purchaseUnitList, line.tare, item.tareUnitId, defaultUnitId)
        : null;
    }
    this.computeQuantityWithConversionFactor(pm, row);
  }

  // Method used to fill the row using the linked POLine
  private populatePOLineRow(row: DeliveryLineRow, line: ReceivingPOLine): void {
    const poLine = this.poLines.find((purchaseOrderLine: PurchaseOrderLine) => {
      return purchaseOrderLine.id === line.purchaseOrderLineId;
    });

    // find the PM through the POL
    const item: AbstractItem = this.getItem(poLine.purchaseModalityId, poLine.itemReference);
    if (item) {
      row.isStandardItem = item.type === CategoryType.STANDARD;
    }

    const purchaseUnit = this.fetcher.purchaseUnitList.find((unit: Uom) => {
      return unit.id === poLine.purchaseUnitId;
    }).longName;

    if (this.isDefined(line.discount)) {
      row.realDiscount = line.discount;
    } else if (this.isDefined(poLine.percentDiscount)) {
      row.realDiscount = poLine.percentDiscount;
    } else {
      row.realDiscount = 0;
    }

    row.discountType = line.discountType ? line.discountType : DiscountType.PERCENT;

    // Fill quantities data using POLine
    const unreceivedQuantity = poLine.quantity - poLine.receivedQuantity;
    row.unreceivedQuantity = unreceivedQuantity > 0 ? unreceivedQuantity : 0;
    row.orderedQuantity = poLine.quantity;

    // Fill prices data using POLine
    row.orderedUnitPricePerWeight = poLine.unitPricePerWeight ?? 0;
    row.orderedDiscount = poLine.percentDiscount ?? 0;
    row.orderedMetalPrice = poLine.metalPrice;
    row.orderedUnitPrice = poLine.unitPrice;
    row.orderedTotalUnitPrice = TotalUnitPriceCalculator.computeTotalUnitPrice(
      poLine,
      poLine.purchaseType,
      DiscountType.PERCENT,
      poLine.percentDiscount,
      this.PERCENT_VALUE
    );
    row.purchaseType = poLine.purchaseType;
    row.purchaseUnit = purchaseUnit;

    // Fill others data using POLine
    row.destLocationId = line.destLocationId ? line.destLocationId : this.getDefaultStockLocation(item);
    row.orderedMetalWeight = poLine.weight * poLine.quantity - poLine.tare * poLine.quantity;
    row.orderedSizeValue = poLine.sizeValue;
    row.supplierTraceabilityNumber = line.supplierRef;
    row.supplierRef = poLine.supplierRef;
    row.lineNumber = poLine.lineNumber;
    row.itemRef = poLine.itemReference;
    row.brandName = poLine.brandName;
    row.itemName = poLine.itemName;
    row.polId = poLine.id;
    row.canceled = poLine.canceled;
    // Fill row weights using ReceivingLine
    row.tare = this.isDefined(line.tare) ? line.tare : null;
    row.totalWeight = this.isDefined(line.weight) ? line.weight : null;
  }

  // Method used to fill the row using the linked and RetailItem
  private populateInternalLineRow(row: DeliveryLineRow, line: ReceivingInternalLine): void {
    const se = this.fetcher.stockEntries.find(stockEntry => stockEntry.id === line.stockEntryId);
    const pmUnit = this.fetcher.purchaseUnitList.find((purchaseUnit: Uom) => purchaseUnit.id === se.uomId).longName;

    row.lineNumber = this.updatedReceivingForm.lines.indexOf(line) + 1;

    const item = this.getItem(null, row.itemRef);

    // if main store set the default location as the item's default location
    if (this.fetcher.receiver.mainStore) {
      row.destLocationId = line.destLocationId ? line.destLocationId : this.getDefaultStockLocation(item);
    } else {
      // else set the root location of the receiver
      row.destLocationId = line.destLocationId ? line.destLocationId : this.fetcher.receiver.originLocationId;
    }

    // Fill row data using the PurchaseModality
    row.orderedTotalUnitPrice = se.computedUnitPrice;
    row.supplierRef = se.itemSupplierRef;
    row.supplierTraceabilityNumber = line.supplierRef;
    row.supplierName = se.supplierName;
    // Fill row data using the RetailItem
    row.brandName = se.itemBrandName;
    row.itemRef = se.itemReference;
    row.itemName = se.itemName;
    if (se.stockType === StockType.BATCH) {
      row.batchNumber = se.id;
    } else if (se.stockType === StockType.SERIAL_NUMBER) {
      row.serialNumber = se.id;
    }
    row.purchaseUnit = pmUnit;

    // Fill row weights using ReceivingLine
    row.tare = this.isDefined(line.tare) ? line.tare : null;
    row.totalWeight = this.isDefined(line.weight) ? line.weight : null;
  }

  // Method used to get the default stock location of a line
  private getDefaultStockLocation(item: AbstractItem): number {
    if (item instanceof ServiceItem) {
      return null;
    }

    return this.isItemOrStoreDefaultLocation(item) ? item.defaultLocationId : this.fetcher.receiver.originLocationId;
  }

  private isItemOrStoreDefaultLocation(item: AbstractItem): boolean {
    return (
      item instanceof StandardItem &&
      item &&
      item.defaultLocationId &&
      this.fetcher.flatStockLocations.some(elt => elt.id === item.defaultLocationId)
    );
  }

  // ------ Update receive Popup -------

  private isNullOrUndefinedOrEmpty(value: string): boolean {
    return !value || value === "";
  }

  private isDefined(value: number): boolean {
    return value !== null && value !== undefined;
  }

  private computeQuantityWithConversionFactor(pm: PurchaseModality, row: any): void {
    const conversionFactor = pm?.conversionFactor;
    if (this.isDefined(conversionFactor) && typeof row.id === "string") {
      row.tare = row.tare * conversionFactor;
      row.totalWeight = row.totalWeight * conversionFactor;
    }
  }
}
