<div class="main-container invoice-customer-form frozen-left" [formGroup]="headerForm">
  <div class="top-container">
    <div class="header">
      <div class="return-link">
        <ui-link
          text="{{ 'credit-note.credit-note-customer-form.back-link' | translate }}"
          (click)="backToPrevious()"
          [linkIcon]="faChevronLeft"
        ></ui-link>
      </div>
      <div class="title">
        <ui-title
          titleText="{{ 'credit-note.credit-note-customer-form.title' | translate }}"
          subTitleText="{{ creditNoteCustomer ? creditNoteCustomer.customerRef : '' }}"
        ></ui-title>
      </div>
    </div>

    <div class="top-buttons">
      <div class="button-validate">
        <ui-button
          text="{{ 'credit-note.credit-note-customer-form.validate-invoice-customer' | translate }}"
          (click)="openCreditNoteValidationPopup()"
          type="validate"
          [primary]="true"
        >
        </ui-button>
      </div>
      <div class="button-save">
        <ui-button
          text="{{ 'global.validate' | translate }}"
          (click)="submitCreditNoteCustomer(false, false)"
          type="save"
        >
        </ui-button>
      </div>
    </div>
  </div>

  <div *ngIf="creditNoteCustomer">
    <ui-container>
      <div class="fugu-container infos">
        <ui-date-picker
          *ngIf="!this.fetcher.isConform"
          [locale]="locale"
          [format]="dateFormat"
          formControlName="date"
          mandatory="true"
          [status]="headerForm.get('date') | formStatus"
          [errorMessage]="headerForm.get('date') | formErrorMessage | translate"
          label="{{ 'credit-note.credit-note-customer-form.header.credit-note-date' | translate }}"
        >
        </ui-date-picker>
        <ui-info-field
          *ngIf="this.fetcher.isConform"
          [label]="'credit-note.credit-note-customer-form.header.credit-note-date' | translate"
          [class]="'date'"
          [value]="creditNoteCustomer.date | dateTimeFormat: false"
        >
        </ui-info-field>

        <div class="comment">
          <div class="label">{{ "credit-note.credit-note-customer-form.header.comment" | translate }}</div>

          <div class="value">
            <div *ngIf="!creditNoteCustomer.comment">
              <fa-icon [icon]="faPen" class="comment-icon clickable" (click)="openCommentPopup()"></fa-icon>
            </div>

            <div *ngIf="creditNoteCustomer.comment">
              <div class="ellipsis-with-icon">
                <p class="ellipsis" #commentText>{{ creditNoteCustomer.comment }}</p>
                <fa-icon class="icon clickable" [icon]="faPen" (click)="openCommentPopup()"></fa-icon>

                <ui-tooltip maxWidth="300px" [element]="commentText">
                  <span style="font-weight: normal !important; white-space: pre-wrap !important">
                    {{ creditNoteCustomer.comment }}
                  </span>
                </ui-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ui-container>
  </div>

  <div class="popup-container">
    <ui-popup
      title="{{ 'invoice-form-header.popup.title' | translate }}"
      *ngIf="commentPopupVisible"
      (close)="closeCommentPopup()"
    >
      <div content>
        <form class="invoice-header-form">
          <ui-textarea
            label="{{ 'credit-note.credit-note-customer-form.header.comment' | translate }}"
            [formControl]="this.headerForm.controls.comment"
            maxLength="1024"
          ></ui-textarea>
        </form>
      </div>

      <div footer>
        <div class="align-buttons">
          <div class="button-popup-validate">
            <ui-button
              text="{{ 'global.validate' | translate }}"
              (click)="saveComment()"
              [primary]="true"
              type="save"
            ></ui-button>
          </div>
        </div>
      </div>
    </ui-popup>
  </div>

  <div class="add-document-btn">
    <div class="button">
      <ui-button
        text="{{ 'credit-note.credit-note-customer-form.add-invoice' | translate }} "
        [primary]="false"
        (click)="openInvoiceCustomerSelectorPopup()"
        [btnIcon]="faPlus"
      ></ui-button>
    </div>
  </div>

  <ng-container *ngIf="creditNoteCustomer && selectedTabId && invoiceCustomerMap?.size > 0">
    <form #tableForm="ngForm">
      <ui-tab-handler
        #tabHandler
        [tabByUrl]="false"
        [independent]="false"
        [closeable]="true"
        (tabClick)="onTabClick($event)"
        (close)="onCloseTab($event)"
      >
        <ui-tab
          [active]="tab.key === selectedTabId"
          [id]="tab.key"
          *ngFor="let tab of creditNoteCustomer?.lines | keyvalue"
          [title]="this.invoiceCustomerMap.get(tab.key).reference"
        >
          <ng-template #tabTemplate>
            <div class="filters border-bottom-filters" *ngIf="filterer">
              <ui-dynamic-filters
                [locale]="fetcher.locale"
                [filters]="filterer.getFilters()"
                [(filterValues)]="filterer.filterValues"
                (apply)="applyFilters()"
                [label]="'filters.label' | translate"
                minLabel="{{ 'filters.min' | translate }}"
                maxLabel="{{ 'filters.max' | translate }}"
                applyLabel="{{ 'filters.apply' | translate }}"
                deleteLabel="{{ 'filters.delete' | translate }}"
                placeholderPrefix="{{ 'filters.prefix' | translate }} "
              >
              </ui-dynamic-filters>
            </div>

            <div class="headband">
              <div class="selected-count">
                <span
                  [innerHTML]="
                    selectedRows.length === 0
                      ? ('global.datatable.no-items' | translate)
                      : (selectedRows.length
                        | i18nPlural
                          : {
                              '=1': 'global.datatable.n-selected-items',
                              other: 'global.datatable.n-selected-items_plural',
                            }
                        | translate: { selectedElements: selectedRows.length })
                  "
                >
                </span>
              </div>
              <div class="buttons">
                <ui-button
                  text="{{ 'shipment-form.buttons.delete' | translate }}"
                  [disabled]="!selectedRows.length"
                  (click)="removeLines()"
                  type="delete"
                >
                </ui-button>
              </div>
            </div>

            <div class="separator">
              <div class="pointer">
                <div class="arrow"></div>
              </div>
              <div class="line"></div>
            </div>

            <div class="datatable">
              <ngx-datatable
                #table
                [rows]="creditNoteCustomer.tabs.get(tab.key).filteredLines"
                rowHeight="40"
                class="material vertical-scroll"
                columnMode="force"
                headerHeight="auto"
                [rowClass]="getRowClass"
                [scrollbarH]="true"
                [summaryRow]="true"
                [summaryHeight]="45"
                [trackByProp]="'lineNumber'"
                [summaryPosition]="'bottom'"
                [sorts]="sorts"
                [count]="pager.totalElements"
                [offset]="pager.number"
                [limit]="pager.size"
                [cssClasses]="{
                  sortAscending: 'fas fa-chevron-up',
                  sortDescending: 'fas fa-chevron-down',
                }"
                [messages]="{ emptyMessage: 'global.no-data' | translate }"
                (sort)="changeSortSettings($event.column.prop, $event.newValue, tab.value.id)"
              >
                <ngx-datatable-column
                  width="50"
                  [sortable]="false"
                  [frozenLeft]="true"
                  [draggable]="false"
                  [resizeable]="false"
                  [canAutoResize]="false"
                  cellClass="center-align frozen-style "
                >
                  <ng-template ngx-datatable-header-template>
                    <ui-checkbox
                      customClass="no-margin"
                      [(ngModel)]="creditNoteCustomer.tabs.get(this.selectedTabId).headerCheck"
                      (ngModelChange)="onHeaderCheckboxChange()"
                      #headerCheckCheckedNgModel="ngModel"
                      control
                      name="headerCheckbox-{{ selectedTabId }}"
                    >
                    </ui-checkbox>
                  </ng-template>

                  <ng-template ngx-datatable-cell-template let-row="row">
                    <ui-checkbox
                      customClass="no-margin"
                      [(ngModel)]="row.actionCheckboxChecked"
                      (ngModelChange)="onRowCheckboxChange()"
                      #actionCheckboxCheckedNgModel="ngModel"
                      control
                      name="lineCheckbox-{{ selectedTabId }}-{{ row.lineNumber }}"
                    >
                    </ui-checkbox>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column
                  name="{{ 'credit-note.credit-note-customer-form.datatable.columns.line-number' | translate }}"
                  cellClass="center-align frozen-style line-number"
                  [resizeable]="false"
                  [draggable]="false"
                  prop="lineNumber"
                  [width]="80"
                  [canAutoResize]="false"
                  headerClass="frozen-left line-number"
                  [frozenLeft]="true"
                  [summaryTemplate]="totalSummary"
                >
                  <ng-template ngx-datatable-cell-template let-value="value">
                    <div>{{ value }}</div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'credit-note.credit-note-customer-form.datatable.columns.item-supplier-ref' | translate }}"
                  cellClass="center-align frozen-style item-customer-ref"
                  [resizeable]="false"
                  [draggable]="false"
                  prop="itemSupplierRef"
                  [frozenLeft]="true"
                >
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <div *ngIf="row.itemSupplierRef">
                      {{ value }}
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'credit-note.credit-note-customer-form.datatable.columns.item-reference' | translate }}"
                  cellClass="item-reference"
                  [resizeable]="false"
                  [draggable]="false"
                  prop="itemRef"
                >
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <div *ngIf="row.itemRef">{{ value }}</div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [name]="'credit-note.credit-note-customer-form.datatable.columns.serial-number' | translate"
                  [canAutoResize]="false"
                  [summaryFunc]="null"
                  [resizeable]="false"
                  [draggable]="false"
                  prop="serialNumber"
                  [width]="95"
                  cellClass="serial-number ellipsis-counter"
                >
                  <ng-template ngx-datatable-cell-template let-row="row">
                    <p class="ellipsis" *ngIf="row.serialNumber">
                      {{ row.serialNumber }}
                    </p>
                    <div *ngIf="row.serialNumber && transformSN(row.serialNumber).length >= 2">
                      <div #serialNumberCounter>
                        <ui-counter [number]="transformSN(row.serialNumber).length"></ui-counter>
                      </div>
                      <ui-tooltip maxWidth="300px" [element]="serialNumberCounter" fallbackPlacement="left">
                        <span style="font-weight: normal !important; white-space: break-spaces">{{
                          row.serialNumber
                        }}</span>
                      </ui-tooltip>
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  [name]="'credit-note.credit-note-customer-form.datatable.columns.batch-number' | translate"
                  [canAutoResize]="false"
                  [summaryFunc]="null"
                  [resizeable]="false"
                  [draggable]="false"
                  prop="batchNumber"
                  [width]="95"
                >
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <div *ngIf="row.batchNumber">{{ value }}</div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'credit-note.credit-note-customer-form.datatable.columns.item-name' | translate }}"
                  cellClass="item-name"
                  [resizeable]="false"
                  [draggable]="false"
                  prop="itemName"
                >
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <div #itemElement class="ellipsis" *ngIf="row.itemName">
                      {{ value }}
                      <ui-tooltip [element]="itemElement">
                        <span style="font-weight: normal !important; white-space: break-spaces">
                          {{ value }}
                        </span>
                      </ui-tooltip>
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'credit-note.credit-note-customer-form.datatable.columns.quantity' | translate }}"
                  cellClass="quantity"
                  [resizeable]="false"
                  [draggable]="false"
                  [sortable]="false"
                  [summaryTemplate]="quantitySummary"
                >
                  <ng-template ngx-datatable-cell-template let-row="row">
                    <ui-input-text
                      type="text"
                      class="input-fields"
                      sizeMode="small"
                      [thousandSeparator]="' '"
                      [maskPattern]="decimalDigit"
                      [(ngModel)]="row.quantity"
                      (ngModelChange)="computeTotals()"
                      [max]="getMaxValue(row, 'quantity')"
                      #quantityNgModel="ngModel"
                      [status]="row.quantityError ? 'error' : (quantityNgModel.control | formStatus)"
                      [errorMessage]="
                        quantityNgModel.control
                          | formErrorMessage
                            : {
                                customPrefix: 'credit-note.credit-note-customer-form.datatable.errors.quantity.',
                                useCustomPrefixFor: ['max'],
                              }
                          | translate: { digit: HIGH_INTEGER }
                      "
                      [disabled]="false"
                      required
                      control
                      name="quantity-{{ selectedTabId }}-{{ row.lineNumber }}"
                    >
                    </ui-input-text>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'credit-note.credit-note-customer-form.datatable.columns.unit-name' | translate }}"
                  cellClass="unit-name"
                  [resizeable]="false"
                  [draggable]="false"
                  [summaryTemplate]="unitNameSummary"
                >
                  <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                    <div *ngIf="row.unitName">{{ row.unitName }}</div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'credit-note.credit-note-customer-form.datatable.columns.size-value' | translate }}"
                  cellClass="size-value"
                  [resizeable]="false"
                  [draggable]="false"
                  prop="sizeValue"
                >
                  <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                    <div *ngIf="row.sizeValue">{{ value }}</div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'credit-note.credit-note-customer-form.datatable.columns.unit-price' | translate }}"
                  cellClass="unit-price"
                  [resizeable]="false"
                  [draggable]="false"
                  [sortable]="false"
                  prop="unitPrice"
                  [summaryFunc]="null"
                >
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <ui-input-text
                      type="text"
                      class="input-fields"
                      sizeMode="small"
                      [thousandSeparator]="' '"
                      [maskPattern]="decimalDigit"
                      [allowNegative]="false"
                      [suffix]="' ' + currency?.symbol"
                      [(ngModel)]="row.unitPrice"
                      (ngModelChange)="computeTotals()"
                      [min]="getRealDiscountValue(row)"
                      [max]="getMaxValue(row, 'unitPrice')"
                      #unitPriceNgModel="ngModel"
                      [status]="row.unitPriceError ? 'error' : (unitPriceNgModel.control | formStatus)"
                      [errorMessage]="
                        unitPriceNgModel.control
                          | formErrorMessage
                            : {
                                customPrefix: 'credit-note.credit-note-customer-form.datatable.errors.unit-price.',
                                useCustomPrefixFor: ['max', 'min'],
                              }
                          | translate: { digit: HIGH_INTEGER }
                      "
                      required
                      control
                      name="unitPrice-{{ selectedTabId }}-{{ row.lineNumber }}"
                    >
                    </ui-input-text>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'credit-note.credit-note-customer-form.datatable.columns.discount' | translate }}"
                  cellClass="discount sticky-right"
                  [resizeable]="false"
                  [draggable]="false"
                  [sortable]="true"
                  prop="discount"
                  [summaryFunc]="null"
                  [width]="95"
                >
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <span
                      >{{ value | number: "0.2-5" : fetcher.locale }} {{ displayDiscountUnit(row.discountType) }}</span
                    >
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'credit-note.credit-note-customer-form.datatable.columns.total-gross-price' | translate }}"
                  cellClass="total-gross-price"
                  [resizeable]="false"
                  [draggable]="false"
                  prop="totalGrossPrice"
                  [summaryTemplate]="priceSummary"
                >
                  <ng-template ngx-datatable-cell-template let-value="value">
                    <div>
                      {{ value | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : fetcher.locale | async }}
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'credit-note.credit-note-customer-form.datatable.columns.vat-rate' | translate }}"
                  cellClass="vat-rate"
                  [resizeable]="false"
                  [draggable]="false"
                  [sortable]="false"
                  [width]="95"
                >
                  <ng-template ngx-datatable-cell-template let-row="row">
                    <span> {{ row.vatRate | number: "0.2-2" : fetcher.locale }} %</span>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'credit-note.credit-note-customer-form.datatable.columns.metal-weight' | translate }}"
                  cellClass="metalWeight"
                  [resizeable]="false"
                  [draggable]="false"
                  [sortable]="true"
                  prop="metalWeight"
                  [summaryFunc]="null"
                >
                  <ng-template ngx-datatable-cell-template let-row="row">
                    <span>
                      {{
                        row.metalWeight === undefined || row.metalWeight === null ? "" : row.metalWeight + " g"
                      }}</span
                    >
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'credit-note.credit-note-customer-form.datatable.columns.tare' | translate }}"
                  cellClass="tare"
                  [resizeable]="false"
                  [draggable]="false"
                  [sortable]="true"
                  prop="tare"
                  [summaryFunc]="null"
                >
                  <ng-template ngx-datatable-cell-template let-row="row">
                    <span> {{ row.tare === undefined || row.tare === null ? "" : row.tare + " g" }}</span>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'credit-note.credit-note-customer-form.datatable.columns.weight' | translate }}"
                  cellClass="weight"
                  [resizeable]="false"
                  [draggable]="false"
                  [sortable]="true"
                  prop="weight"
                  [summaryFunc]="null"
                >
                  <ng-template ngx-datatable-cell-template let-row="row">
                    <span> {{ !row.weight ? "" : row.weight + " g" }}</span>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'credit-note.credit-note-customer-form.datatable.columns.delivery-ref' | translate }}"
                  cellClass="delivery-ref"
                  [resizeable]="false"
                  [draggable]="false"
                  prop="deliveryRef"
                >
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <div>
                      {{ value }}
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'credit-note.credit-note-customer-form.datatable.columns.store-name' | translate }}"
                  cellClass="store-name"
                  [resizeable]="false"
                  [draggable]="false"
                  prop="store"
                >
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <p #storeElement class="ellipsis">
                      {{ value }}
                      <ui-tooltip [element]="storeElement">
                        <span style="font-weight: normal !important; white-space: break-spaces">
                          {{ value }}
                        </span>
                      </ui-tooltip>
                    </p>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'credit-note.credit-note-customer-form.datatable.columns.brand-name' | translate }}"
                  cellClass="brand-name"
                  [resizeable]="false"
                  [draggable]="false"
                  prop="brandName"
                >
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <div>
                      {{ value }}
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'credit-note.credit-note-customer-form.datatable.columns.supplier-name' | translate }}"
                  cellClass="supplier-name"
                  [resizeable]="false"
                  [draggable]="false"
                  prop="supplierName"
                >
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <div>
                      {{ value }}
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'credit-note.credit-note-customer-form.datatable.columns.supplier-ref' | translate }}"
                  cellClass="supplier-ref"
                  [resizeable]="false"
                  [draggable]="false"
                  prop="supplierRef"
                >
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <div>
                      {{ value }}
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column
                  name="{{ 'invoice-customer-form.datatable.columns.actions' | translate }}"
                  headerClass="center-align"
                  cellClass="center-align frozen-style"
                  [resizeable]="false"
                  [frozenRight]="true"
                  [sortable]="false"
                  [width]="80"
                >
                  <ng-template ngx-datatable-cell-template let-row="row">
                    <div (click)="$event.stopPropagation()">
                      <ui-menu-actions [menuActions]="menuActions" (actionCalled)="manageActions($event, row)">
                      </ui-menu-actions>
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <ng-template #priceSummary>
                  <div class="total-price summary-fields">
                    {{
                      creditNoteCustomer.tabs.get(selectedTabId).totals.totalGrossPrice
                        | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : fetcher.locale
                        | async
                    }}
                  </div>
                </ng-template>

                <ng-template #totalSummary>
                  <div class="total-summary summary-fields">
                    {{ "credit-note.credit-note-customer-form.datatable.summary.total" | translate }}
                  </div>
                </ng-template>

                <ng-template #quantitySummary>
                  <div class="total-quantity summary-fields">
                    {{ creditNoteCustomer.tabs.get(selectedTabId).totals.totalQuantity }}
                  </div>
                </ng-template>

                <ng-template #unitNameSummary>
                  <div>
                    <span *ngIf="!multipleUnit(creditNoteCustomer.tabs.get(selectedTabId).filteredLines)">
                      {{ creditNoteCustomer.lines.get(selectedTabId)[0].unitName }}
                    </span>
                    <span
                      class="summary-fields"
                      *ngIf="multipleUnit(creditNoteCustomer.tabs.get(selectedTabId).filteredLines)"
                    >
                      <span #warningIcon>
                        <fa-icon class="warn-icon" [icon]="faWarning"></fa-icon>
                      </span>

                      <ui-tooltip maxWidth="160px" [element]="warningIcon">
                        <span style="font-weight: normal">{{
                          "credit-note.credit-note-customer-form.datatable.summary.warning-message" | translate
                        }}</span>
                      </ui-tooltip>
                    </span>
                  </div>
                </ng-template>

                <ngx-datatable-footer>
                  <ng-template
                    ngx-datatable-footer-template
                    let-rowCount="rowCount"
                    let-pageSize="pageSize"
                    let-selectedCount="selectedCount"
                    let-curPage="curPage"
                    let-offset="offset"
                    let-isVisible="isVisible"
                  >
                    <div class="page-count" *ngIf="rowCount">
                      {{
                        rowCount
                          | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                          | translate: { rowCount: rowCount }
                      }}
                    </div>
                    <datatable-pager
                      [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                      [pagerRightArrowIcon]="'fas fa-chevron-right'"
                      [pagerNextIcon]="'fas fa-chevron-double-right'"
                      [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                      [page]="curPage"
                      [size]="pageSize"
                      [count]="rowCount"
                      [hidden]="!(rowCount / pageSize > 1)"
                      (change)="changePage($event)"
                    >
                    </datatable-pager>
                  </ng-template>
                </ngx-datatable-footer>
              </ngx-datatable>
            </div>
          </ng-template>
        </ui-tab>
      </ui-tab-handler>
    </form>
  </ng-container>

  <div *ngIf="this.currency" class="footer-total">
    <ui-container>
      <div class="footer-container">
        <div class="footer-element">
          <div class="label total-gross-price">
            {{ "credit-note.credit-note-customer-form.footer.labels.total-gross-price" | translate }}
          </div>
          <div class="value total-gross-price">
            {{
              this.creditNoteCustomer.totalGrossPrice
                | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : fetcher.locale
                | async
            }}
          </div>
        </div>
        <div class="footer-element">
          <div class="label total-quantity">
            {{ "credit-note.credit-note-customer-form.footer.labels.total-quantity" | translate }}
          </div>
          <div class="value total-quantity">
            {{ this.creditNoteCustomer.totalQuantity }}
            <span class="summary-fields" *ngIf="totalMultipleUnit()">
              <span #warningIcon>
                <fa-icon class="warn-icon" [icon]="faWarning"></fa-icon>
              </span>

              <ui-tooltip maxWidth="160px" [element]="warningIcon">
                <span style="font-weight: normal">{{
                  "credit-note.credit-note-customer-form.datatable.summary.warning-message" | translate
                }}</span>
              </ui-tooltip>
            </span>
          </div>
        </div>
        <div class="footer-element">
          <div class="total-tax-price">
            <div>{{ "credit-note.credit-note-customer-form.footer.labels.total-tax-price" | translate }}</div>
            <div class="total-tax-price value">
              {{
                this.creditNoteCustomer.taxPrice
                  | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : fetcher.locale
                  | async
              }}
            </div>
          </div>
        </div>
        <div class="footer-element">
          <div class="label total-price">
            {{ "credit-note.credit-note-customer-form.footer.labels.total-price" | translate }}
          </div>
          <div class="value total-price">
            {{
              this.creditNoteCustomer.totalPrice
                | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : fetcher.locale
                | async
            }}
          </div>
        </div>
      </div>
    </ui-container>
  </div>
</div>

<div class="popup" *ngIf="invoiceCustomerSelectorPopupVisible">
  <app-invoice-customer-selector-popup
    [defaultCustomer]="this.defaultCustomer"
    [alreadySelectedInvoices]="selectedInvoices"
    (validate)="validateInvoiceCustomerSelectorPopup($event)"
    (close)="closeInvoiceCustomerSelectorPopup()"
  >
  </app-invoice-customer-selector-popup>
</div>

<div class="popup">
  <ui-popup
    title="{{ 'invoice-customer-form.delete-popup.title' | translate }}"
    (close)="closeDeleteTabConfirmationPopup()"
    *ngIf="this.deleteTabConfirmationPopupVisible"
  >
    <div content>
      <div
        class="body-message"
        [innerHTML]="
          'invoice-customer-form.delete-popup.body-credit-note'
            | translate
              : {
                  ref:
                    ('invoice-customer-form.tabs.credit-note'
                    | translate: { ref: this.invoiceCustomerMap.get(this.tabToDelete?.id).reference }),
                }
        "
      ></div>
    </div>
    <div footer>
      <div class="align-buttons">
        <div class="button-popup-validate">
          <div class="button">
            <ui-button
              text="{{ 'invoice-customer-form.delete-popup.validate' | translate }}"
              [primary]="true"
              type="validate"
              (click)="deleteTab()"
            ></ui-button>
          </div>
        </div>
        <div class="button-popup-refuse">
          <div class="button">
            <ui-button
              text="{{ 'invoice-customer-form.delete-popup.refuse' | translate }}"
              type="cancel"
              (click)="closeDeleteTabConfirmationPopup()"
            ></ui-button>
          </div>
        </div>
      </div>
    </div>
  </ui-popup>
</div>

<div class="popup" *ngIf="validatePopupVisible">
  <app-credit-note-validation (validate)="validateCreditNoteEvent($event)" (close)="closeCreditNoteValidation()">
  </app-credit-note-validation>
</div>
