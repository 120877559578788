import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import {
  IconDefinition,
  faArrowRightFromBracket,
  faHandHoldingBox,
  faInfoCircle,
  faPen,
  faPersonCarryBox,
  faPrint,
} from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { NotificationHandlerService } from "app/service/notification-handler.service";
import { EnumTranslationUtil } from "app/util/enum-translation-util";
import {
  AsynchronousTaskCreation,
  AsynchronousTaskService,
  AuthService,
  Brand,
  CaraUser,
  CaraUserService,
  CategoryType,
  Currency,
  CurrencyService,
  ItemCategory,
  ItemCategoryService,
  Light,
  LightService,
  Metal,
  MetalComposition,
  MetalService,
  MovementType,
  PaginatedList,
  Pagination,
  PublicPriceCurrency,
  Sort,
  StockEntryLocation,
  StockEntryService,
  StockLocation,
  StockLocationService,
  StockType,
  Store,
  StoreService,
  Uom,
  UomService,
} from "center-services";
import {
  FilterValue,
  Filterer,
  Option,
  PaginableComponent,
  RoundingUtil,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MenuAction, MessageService, ToastMessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import dayjs from "dayjs";
import { Observable, combineLatest } from "rxjs";
import { filter, tap } from "rxjs/operators";
import hash from "string-hash";

@Component({
  selector: "app-stock-list",
  templateUrl: "./stock-list.component.html",
  styleUrls: ["./stock-list.component.scss"],
  providers: [SubscriptionService],
})
export class StockListComponent
  extends FilteredTableListComponent
  implements OnInit, AfterViewInit, PaginableComponent {
  @ViewChild("stockFilters") stockFilters: any;
  @ViewChild("table") table: DatatableComponent;

  public LIST_ID: string = "app-stock-list.stock-table";

  public stockEntryLocationList: StockEntryLocation[] = [];
  public lightSupplierList: Light[] = [];
  public mainStore: Store;
  public uomList: Uom[] = [];
  public metalList: Metal[] = [];
  public storeList: Store[] = [];
  public brandList: Brand[] = [];
  public itemCategories: ItemCategory[] = [];
  public supplierOptions: Option[] = [];
  public menuActions: MenuAction[] = [];
  public selectedStockEntryLocation: StockEntryLocation = null;
  public selectedSELForMovement: StockEntryLocation = null;
  public editPopupVisible: boolean = false;
  public detailsVisible: boolean = false;
  public createSMPopupVisible: boolean = false;
  public outOfStockPopupVisible: boolean = false;
  public isConnectedToMainStore: boolean = false;
  public contextStore: Store = null;
  public codeLanguage: string;
  public dateFormat: string;
  public defaultCurrency: Currency;
  public faInfo: IconDefinition = faInfoCircle;
  public rows: any[] = [];
  public sorts: any[] = [{ prop: "entryDate", dir: "desc" }];
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  public tableControl: UntypedFormGroup;
  public currentRowsId: number[] = [];
  public selectedRows: StockEntryLocation[] = [];
  public activeFilters: SearchFilter[] = [];
  public filterer: Filterer;
  public faStockEntry: IconDefinition = faPersonCarryBox;
  public printSettingsPopupVisible: boolean = false;
  faPrint: IconDefinition = faPrint;
  oldFilterValues: FilterValue[] = [];
  stockLocations: StockLocation[] = [];
  public canReadPrices: boolean;
  public canUpdate: boolean;
  protected readonly EDIT_ACTION_ID: number = 0;
  protected readonly STOCK_MOVEMENT_ACTION_ID: number = 1;
  protected readonly STOCK_OUT_ACTION_ID: number = 2;
  private movementTypeTranslations: { [key: string]: string } = {};
  private readonly MAX_RATE: number = 1000;
  private readonly MAX_LOST_RATE: number = 100;
  private sessionPagination: SessionPagination;
  private currencyList: Currency[] = [];

  constructor(
    protected userService: CaraUserService,
    protected translateService: TranslateService,
    private stockEntryService: StockEntryService,
    protected messageService: MessageService,
    private currencyService: CurrencyService,
    private uomService: UomService,
    private metalService: MetalService,
    private lightService: LightService,
    private storeService: StoreService,
    private itemCategoryService: ItemCategoryService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private stockLocationService: StockLocationService,
    private authService: AuthService,
    private asynchronousTaskService: AsynchronousTaskService,
    private notificationHandlerService: NotificationHandlerService,
    private toastMessageService: ToastMessageService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);

    EnumTranslationUtil.buildTranslations(
      this.translateService,
      MovementType,
      "stock-entry-list.datatable.last-movement-type",
      this.movementTypeTranslations
    );

    this.sessionPagination = new SessionPagination(this);
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  ngOnInit(): void {
    this.canUpdate = this.userService.canDo("STOCK_ENTRY_UPDATE");
    this.initSelectFormControl();
    this.addMenuActions();

    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe((user: CaraUser) => {
        this.codeLanguage = user?.codeLanguage;
        this.dateFormat = user?.dateFormat;
        this.canReadPrices = this.userService.canDo("PURCHASING_PRICE");
      })
    );

    this.subscriptionService.subs.push(
      combineLatest([
        this.fetchDefaultCurrency(),
        this.fetchUoms(),
        this.fetchMetals(),
        this.fetchStoreList(),
        this.fetchItemCategoryList(),
        this.fetchBrandList(),
        this.fetchMainStore(),
        this.fetchSuppliers(),
        this.fetchCurrencyList(),
      ]).subscribe(() => {
        const selectedStoreId = this.authService.getContextStoreId();
        this.contextStore = this.storeList.find((store: Light) => store.id === selectedStoreId);
        this.isConnectedToMainStore = this.contextStore.id === this.mainStore.id;

        if (this.mainStore) {
          this.initFilters();
          this.sessionPagination.loadFromSession(this.LIST_ID);
          this.computeSearchFilters();
          this.updateLocationsList();
        }

        this.fetchSEL();
      })
    );

    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.addMenuActions();
      })
    );
  }

  public ngAfterViewInit(): void {
    this.table.sorts = this.sorts;
    this.table.offset = this.pager.number;
  }

  fetchDefaultCurrency(): Observable<Currency> {
    const observable = this.currencyService.defaultCurrency.pipe(filter(cur => cur !== undefined));

    this.subscriptionService.subs.push(
      observable.subscribe(currency => {
        this.defaultCurrency = currency;
      })
    );
    return observable;
  }

  fetchStoreList(): Observable<Light[]> {
    return this.lightService.getStores().pipe(
      tap(
        (stores: Store[]) => {
          this.storeList = stores.filter((obj: Store) => !obj.archived).sort((a, b) => a.name.localeCompare(b.name));
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-stores`, error.message);
        }
      )
    );
  }

  fetchItemCategoryList(): Observable<ItemCategory[]> {
    return this.itemCategoryService.getAll(["withRetailItem"]).pipe(
      tap(
        (itemCategories: ItemCategory[]) => {
          this.itemCategories = itemCategories
            .filter((obj: ItemCategory) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name));
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-item-categories`, error.message);
        }
      )
    );
  }

  fetchBrandList(): Observable<Light[]> {
    return this.lightService.getBrands().pipe(
      tap(
        (brands: Brand[]) => {
          this.brandList = brands.filter((obj: Brand) => !obj.archived).sort((a, b) => a.name.localeCompare(b.name));
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-brands`, error.message);
        }
      )
    );
  }

  fetchMainStore(): Observable<Store> {
    return this.storeService.getMain().pipe(
      tap(
        (mainStore: Store) => {
          this.mainStore = mainStore;
          this.setDefaultFilters();
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-main-store`, error.message);
        }
      )
    );
  }

  fetchStockLocations(ids: number[]): void {
    this.stockLocations = [];
    ids.forEach(id => {
      this.subscriptionService.subs.push(
        this.stockLocationService.getAll(["withChildren", "onlyOrigin"], id).subscribe(
          (stockLocations: StockLocation[]) => {
            let locations: StockLocation[] = this.stockLocationService.getFlattenedLocations(stockLocations);
            const mainName = this.storeList.find(sl => sl.id === id).name;
            locations = locations.map(location => {
              location.name += ` (${mainName})`;
              return location;
            });
            this.stockLocations = this.stockLocations.concat(locations);
            this.filterer.updateListFilterOptions(
              "stockLocation.id",
              this.stockLocations.map(stockLocation => {
                return { value: stockLocation.id.toString(), displayValue: stockLocation.name };
              })
            );
          },
          error => {
            this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-main-store`, error.message);
          }
        )
      );
    });
  }

  fetchCurrencyList(): Observable<Currency[]> {
    return this.currencyService.getAll().pipe(
      tap(
        (currencies: Currency[]) => (this.currencyList = currencies),
        error => {
          this.sendErrorAlert("retail-item-list.errors.get-currencies", error.message);
        }
      )
    );
  }

  public getCurrency(currencyId: number): Currency {
    return this.currencyList.find(currency => currency.id === currencyId);
  }

  addRow(stockEntryLocation: StockEntryLocation): void {
    const row = {
      id: stockEntryLocation.id,
      itemCategory: stockEntryLocation.stockEntry.itemCategoryName,
      itemReference: stockEntryLocation.stockEntry.itemReference,
      location: stockEntryLocation.locationName,
      itemName: stockEntryLocation.stockEntry.itemName,
      serialNumber:
        stockEntryLocation.stockEntry.stockType === StockType.SERIAL_NUMBER ? stockEntryLocation.stockEntry.id : null,
      batchNumber: [StockType.BATCH, StockType.BULK].includes(stockEntryLocation.stockEntry.stockType)
        ? stockEntryLocation.stockEntry.id
        : null,
      quantity: stockEntryLocation.quantity,
      sizeValue: stockEntryLocation.stockEntry.sizeValue,
      entrySupplierRef: stockEntryLocation.stockEntry.supplierRef,
      brandName: stockEntryLocation.stockEntry.itemBrandName,
      supplierName: stockEntryLocation.stockEntry?.supplierName,
      itemSupplierRef: stockEntryLocation.stockEntry?.itemSupplierRef,
      metal: [],
      itemWeight: stockEntryLocation.weight,
      entryDate: stockEntryLocation.lastMovementDate,
      deliveryReference: stockEntryLocation.stockEntry.deliveryReference,
      invoiceDate: stockEntryLocation.stockEntry.invoiceDate,
      invoiceReference: stockEntryLocation.stockEntry.invoiceReference,
      creationDate: stockEntryLocation.firstMovementDate,
      unit: this.uomList.find(uom => uom.id === stockEntryLocation.stockEntry.uomId).shortName,
      publicPricesCurrencies: stockEntryLocation.stockEntry.publicPricesCurrencies.map(
        (publicPriceCurrency: PublicPriceCurrency) =>
          `${publicPriceCurrency.price.toFixed(RoundingUtil.TWO_DIGITS)} ${this.getCurrency(publicPriceCurrency.currencyId).symbol}`
      ),
      computedUnitPrice: stockEntryLocation.currentUnitPrice,
      costPrice: null,
      stockValuation: null,
      wap: stockEntryLocation.wap,
      movementType: stockEntryLocation.lastMovementType,
      typeClass: this.getMovementTypeClass(stockEntryLocation.lastMovementType),
      actionnable: stockEntryLocation.lastMovementType !== MovementType.TRANSIT,
    };

    const composition = stockEntryLocation.stockEntry.composition;
    const weight = stockEntryLocation.weight;
    const tare = stockEntryLocation.tare;

    if (!this.isNullOrEmpty(composition) && !this.isNullOrEmpty(weight)) {
      this.getMetalWeightComposition(row, weight, tare, composition);
    }

    this.rows.push(row);
    this.tableControl.addControl(this.getRowControlName(row.id.toString()), new UntypedFormControl(false));
  }

  isNullOrEmpty(value: any): boolean {
    return value === null || value === undefined || value === "" || value.length === 0;
  }

  getMetalWeightComposition(row: any, weight: number, tare: number, composition: MetalComposition[]): void {
    const itemMetalWeight = weight - (tare ? tare : 0);
    const metalWeightList: Map<string, number> = new Map();
    composition.forEach(compo => {
      const rate = compo.rate ? compo.rate : 0;
      const lostRate = compo.lostRate ? compo.lostRate : 0;
      const metalName = this.metalList.find(metal => metal.id === compo.metalId).name;
      const metalWeight = (rate / this.MAX_RATE) * itemMetalWeight * (1 + lostRate / this.MAX_LOST_RATE);

      if (metalWeightList.has(metalName)) {
        metalWeightList.set(metalName, metalWeightList.get(metalName) + metalWeight);
      } else {
        metalWeightList.set(metalName, metalWeight);
      }
    });

    metalWeightList.forEach((value, key) => {
      row.metal.push(`${key}: ${value.toFixed(RoundingUtil.TWO_DIGITS)} g`);
    });
  }

  changeSortSettings(prop: string, direction: string): void {
    if (prop === "batchNumber") {
      this.sorts = [
        {
          prop: "stockEntry.stockType",
          dir: "desc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    } else if (prop === "serialNumber") {
      this.sorts = [
        {
          prop: "stockEntry.stockType",
          dir: "asc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    } else {
      this.sorts = [
        {
          prop,
          dir: direction,
        },
      ];
    }
    this.fetchSEL();
  }

  public getRowClass(row: any): any {
    return { disabled: !row.actionnable, "not-clickable": !this.isConnectedToMainStore };
  }

  changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchSEL();
  }

  getTranslationPrefix(): string {
    return "stocks-list";
  }

  getColumnTranslation(columnName: any): string {
    return `${this.getTranslationPrefix()}.datatable.columns.${columnName}`;
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    const date = new Date();
    const m = date.getMonth();
    const y = date.getFullYear();
    const lastDay = new Date(y, m + 1, 0);
    const firstDay = new Date(y, m, 1);
    const defaultValue = {
      from: dayjs(firstDay),
      to: dayjs(lastDay),
    };

    this.filterer.addListFilter(
      "contextContact.id",
      this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.perimeter`),
      this.isConnectedToMainStore
        ? this.storeList.map(store => {
          return { value: store.id.toString(), displayValue: store.name };
        })
        : [{ value: this.contextStore.id.toString(), displayValue: this.contextStore.name }],
      true,
      true,
      this.isConnectedToMainStore ? [this.mainStore.id.toString()] : [this.contextStore.id.toString()],
      null,
      true,
      null,
      null,
      true
    );

    this.filterer.addListFilter(
      "stockEntry.retailItem.category.id",
      this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.item-category`),
      this.itemCategories
        .filter(cat => cat.type === CategoryType.STANDARD)
        .map(itemCateogory => {
          return { value: itemCateogory.id.toString(), displayValue: itemCateogory.name };
        })
    );

    this.filterer.addFilter(
      "stockEntry.retailItem.reference",
      this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.item-reference`),
      "string"
    );

    this.filterer.addListFilter(
      "stockLocation.id",
      this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.location`),
      this.stockLocations.map(stockLocation => {
        return { value: stockLocation.id.toString(), displayValue: stockLocation.name };
      }),
      null,
      null,
      null,
      this.translateService.instant(`${this.getTranslationPrefix()}.info.location-filter`),
      true,
      null,
      true
    );

    this.filterer.addFilter(
      "stockEntry.retailItem.name",
      this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.item-name`),
      "string"
    );

    this.filterer.addFilter(
      "serial.stockEntry.id",
      this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.serial-number`),
      "string"
    );

    this.filterer.addFilter(
      "batch.stockEntry.id",
      this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.batch-number`),
      "string"
    );

    this.filterer.addFilter(
      "quantity",
      this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.quantity`),
      "range"
    );

    this.filterer.addFilter(
      "stockEntry.sizeValue",
      this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.size-value`),
      "string"
    );
    if (this.canReadPrices) {
      this.filterer.addFilter(
        "currentUnitPrice",
        this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.unit-price`),
        "range"
      );

      this.filterer.addFilter(
        "wap",
        this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.wap`),
        "range"
      );
    }
    this.filterer.addFilter(
      "stockEntry.supplierRef",
      this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.entry-supplier-ref`),
      "string"
    );

    this.filterer.addListFilter(
      "stockEntry.retailItem.brand.name",
      this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.brand-name`),
      this.brandList.map(brand => {
        return { value: brand.name, displayValue: brand.name };
      }),
      null,
      null,
      null,
      null,
      true
    );

    this.filterer.addListFilter(
      "stockEntry.supplier.name",
      this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.supplier-name`),
      this.lightSupplierList.map(supplier => {
        return { value: supplier.name, displayValue: supplier.name };
      })
    );

    this.filterer.addFilter(
      "stockEntry.itemSupplierRef",
      this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.supplier-reference`),
      "string"
    );

    this.filterer.addFilter(
      "weight",
      this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.item-weight`),
      "range"
    );

    this.filterer.addFilter(
      "lastMovementDate",
      this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.entry-date`),
      "date",
      false,
      false,
      defaultValue
    );

    this.filterer.addFilter(
      "stockEntry.deliveryReference",
      this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.delivery-reference`),
      "string"
    );

    this.filterer.addFilter(
      "stockEntry.invoiceDate",
      this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.invoice-date`),
      "date",
      false,
      false,
      defaultValue
    );

    this.filterer.addFilter(
      "stockEntry.invoiceReference",
      this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.invoice-reference`),
      "string"
    );

    this.filterer.addFilter(
      "firstMovementDate",
      this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.creation-date`),
      "date",
      false,
      false,
      defaultValue
    );

    this.filterer.addListFilter(
      "movementType",
      this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.movement-type`),
      Object.keys(MovementType).map(key => ({ value: key, displayValue: this.movementTypeTranslations[key] }))
    );
  }

  applyFilters(): void {
    this.pager.number = 0;

    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.LIST_ID
      ).subscribe(() => {
        this.fetchSEL();
      })
    );
  }

  showDetails(event: any): void {
    if (!this.isConnectedToMainStore) {
      return;
    }
    if (event.type === "click") {
      this.selectedStockEntryLocation = this.stockEntryLocationList.find(
        stockEntryLocation => stockEntryLocation.id === event.row.id
      );
      this.detailsVisible = true;
    }
  }

  closeDetails(): void {
    this.detailsVisible = false;
    this.selectedStockEntryLocation = null;
  }

  closePopup(type: string): void {
    switch (type) {
      case "in":
        this.createSMPopupVisible = false;
        break;
      case "out":
        this.outOfStockPopupVisible = false;
        break;
      case "edit":
        this.editPopupVisible = false;
        break;
      default:
        break;
    }
    this.selectedSELForMovement = null;
    this.fetchSEL();
  }

  addMenuActions(): void {
    this.menuActions = [];
    if (this.userService.canDo("STOCK_ENTRY_UPDATE")) {
      this.menuActions.push(
        new MenuAction(this.EDIT_ACTION_ID, this.translateService.instant("stocks-list.actions.edit"), faPen),
        new MenuAction(
          this.STOCK_MOVEMENT_ACTION_ID,
          this.translateService.instant("stocks-list.actions.manual-stock-movement"),
          faHandHoldingBox
        ),
        new MenuAction(
          this.STOCK_OUT_ACTION_ID,
          this.translateService.instant("stocks-list.actions.out-of-stock"),
          faArrowRightFromBracket
        )
      );
    }
  }

  manageActions(actionId: number, rowId: any): void {
    this.openMovementPopup(rowId, actionId);
  }

  public openMovementPopup(rowId: number, actionId: number): void {
    const solFound = this.stockEntryLocationList.find(sel => sel.id === rowId);
    if (solFound) {
      this.selectedSELForMovement = solFound;
      switch (actionId) {
        case this.EDIT_ACTION_ID:
          this.editPopupVisible = true;
          break;
        case this.STOCK_MOVEMENT_ACTION_ID:
          this.createSMPopupVisible = true;
          break;
        case this.STOCK_OUT_ACTION_ID:
          this.outOfStockPopupVisible = true;
          break;
        default:
          break;
      }
    }
  }

  public getStoreName(storeId: number): string {
    const storeFound: Light = this.storeList.find(so => so.id === storeId);
    return storeFound ? `${storeFound.name}` : "";
  }

  public createManualStockEntry(): void {
    if (!this.userService.canDo("STOCK_ENTRY_CREATE")) {
      return;
    }
    this.sessionPagination.saveToSession(this.LIST_ID);
    this.router.navigateByUrl("/manual-stock-entry/add");
  }

  public getUnit(uomId: number): Record<string, string> {
    const uomFound = this.uomList.find(uom => uom.id === uomId);
    return uomFound ? { long: uomFound.longName, short: uomFound.shortName } : null;
  }

  public getRowControlName(id: number | string): string {
    return `checked_${id}`;
  }

  public onRowCheckboxChange(): void {
    this.updateHeaderPageCheckbox();
    this.updateSelection();
  }

  public onHeaderCheckboxChange(): void {
    this.updateRowsPageCheckbox();
    this.updateSelection();
  }

  updateHeaderPageCheckbox(): void {
    const currentPageRowsCheckedIds = this.currentRowsId.filter(id => {
      return this.tableControl.get(this.getRowControlName(id)).value;
    });
    const isSelected = this.currentRowsId.length > 0 && this.currentRowsId.length === currentPageRowsCheckedIds.length;
    this.tableControl.controls.headerCheckbox.patchValue(isSelected);
  }

  updateRowsPageCheckbox(): void {
    const controls = this.tableControl.controls;
    const isHeaderSelected = this.tableControl.controls.headerCheckbox.value;
    this.currentRowsId.forEach(id => {
      controls[this.getRowControlName(id)].patchValue(isHeaderSelected);
    });
  }

  public updateSelection(): void {
    this.rows.forEach(row => {
      const rowChecked: boolean = this.tableControl.controls[this.getRowControlName(row.id)].value;
      const stockEntryLocation: StockEntryLocation = this.stockEntryLocationList.find(elem => elem.id === row.id);
      const isPOSelected: boolean = this.selectedRows.findIndex(elem => elem.id === row.id) !== -1;

      if (!isPOSelected && rowChecked) {
        this.selectedRows.push(stockEntryLocation);
      } else if (isPOSelected && !rowChecked) {
        const poIndex = this.selectedRows.findIndex(elem => elem.id === stockEntryLocation.id);
        this.selectedRows.splice(poIndex, 1);
      }
    });
  }

  showPrintSELPopup(): void {
    this.printSettingsPopupVisible = true;
  }

  validateSettingsPopup(firstLabelIndex: number): void {
    this.print(firstLabelIndex);
    this.printSettingsPopupVisible = false;
    const controls = this.tableControl.controls;
    controls.headerCheckbox.patchValue(false);
    this.selectedRows.forEach(row => {
      controls[this.getRowControlName(row.id)].patchValue(false);
    });
    this.selectedRows = [];
  }

  closeSettingsPopup(): void {
    this.printSettingsPopupVisible = false;
  }

  print(firstLabelIndex: number): void {
    const search: SearchFilter = new SearchFilter(
      "stockEntryId",
      SearchFilterOperator.IN,
      this.selectedRows.map(row => row.stockEntry.id).join("~")
    );

    const asyncCreationTask = {
      type: "generatePdf",
      params: `stock-entry-label;${search.toQuery()};${firstLabelIndex};`,
    };
    this.asynchronousTaskService.create(new AsynchronousTaskCreation(asyncCreationTask)).subscribe({
      next: () => {
        this.toastMessageService.generateMessage(
          "info",
          "task-notification.message.on-going-title",
          "task-notification.message.on-going-message"
        );
        this.notificationHandlerService.showHandler();
      },
      error: () =>
        this.toastMessageService.generateMessage("error", "message.title.api-errors", "message.content.data-errors"),
    });
  }

  filterValuesChange(newFilterValues: FilterValue[]): void {
    this.updateLocationsList(newFilterValues);
  }

  updateLocationsList(newFilterValues?: FilterValue[]): void {
    if (newFilterValues) {
      this.filterer.filterValues = newFilterValues;
    }
    const old = this.oldFilterValues
      ?.filter((f: FilterValue) => f.filterId === hash("contextContact.id"))
      .map((f: FilterValue) => f.equal)[0] as number[];
    const actual = this.filterer.filterValues
      ?.filter((f: FilterValue) => f.filterId === hash("contextContact.id"))
      .map((f: FilterValue) => f.equal)[0] as number[];
    if (old?.length !== actual?.length) {
      const ids = actual.map(id => +this.filterer.optionsMap["contextContact.id"][id]);
      this.fetchStockLocations(ids);
    }
    this.oldFilterValues = JSON.parse(JSON.stringify(this.filterer.filterValues));
  }

  protected computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
    this.activeFilters.forEach((sf, index) => {
      const filterValue = this.filterer.filterValues.find(fv => fv.filterId === hash(sf.key));
      switch (sf.key) {
        case "serial.stockEntry.id": {
          this.applyStockType(filterValue, StockType.SERIAL_NUMBER, index);
          break;
        }

        case "batch.stockEntry.id": {
          this.applyStockType(filterValue, [StockType.BATCH, StockType.BULK], index);
          break;
        }

        default: {
          console.warn(`${sf.key} unknwon filter id!`);
          break;
        }
      }
    });
  }

  private fetchSEL(): void {
    this.subscriptionService.subs.push(
      this.stockEntryService.getStockEntryLocations(this.pager, null, this.getSorter(), this.activeFilters).subscribe(
        (result: PaginatedList<StockEntryLocation>) => {
          this.rows = [];
          this.stockEntryLocationList = result.data;
          this.pager = result.page;
          this.currentRowsId = result.data.map((po: StockEntryLocation) => po.id);
          result.data.forEach((stockEntryLocation: StockEntryLocation) => {
            this.addRow(stockEntryLocation);
          });
          this.rows = [...this.rows];
          if (this.table) {
            this.table.sorts = this.sorts;
            this.table.offset = this.pager.number;
          }

          this.updateSelection();
          this.updateHeaderPageCheckbox();
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-entities`, error.message);
        }
      )
    );
  }

  private fetchUoms(): Observable<Uom[]> {
    return this.uomService.getAll().pipe(
      tap(
        (uomList: Uom[]) => {
          this.uomList = uomList;
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-uoms`, error.message);
        }
      )
    );
  }

  private fetchMetals(): Observable<Metal[]> {
    return this.metalService.getAll().pipe(
      tap(
        (metalList: Metal[]) => {
          this.metalList = metalList;
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-metals`, error.message);
        }
      )
    );
  }

  private fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap(
        (suppliers: Light[]) => {
          this.lightSupplierList = suppliers;
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-suppliers`, error.message);
        }
      )
    );
  }

  private getMovementTypeClass(stockMovementType: string): string {
    switch (stockMovementType) {
      case MovementType.IN:
        return "in";
      case MovementType.OUT:
        return "out";
      case MovementType.INTERNAL:
        return "internal";
      case MovementType.TRANSIT:
        return "transit";
      default:
        console.error(`${stockMovementType} is an unknown movement type !`);
        return "#FFF";
    }
  }

  private getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  private propToDto(prop: string): string {
    const propMapping: { [key: string]: string } = {
      itemCategory: "stockEntry.retailItem.category",
      itemReference: "stockEntry.retailItem.reference",
      location: "stockLocation.name",
      itemName: "stockEntry.retailItem.name",
      serialNumber: "stockEntry.id",
      batchNumber: "stockEntry.id",
      entrySupplierRef: "stockEntry.supplierRef",
      sizeValue: "stockEntry.sizeValue",
      computedUnitPrice: "currentUnitPrice",
      brandName: "stockEntry.retailItem.brand.name",
      entryDate: "lastMovementDate",
      creationDate: "firstMovementDate",
      type: "movementType",
      sellPrice: "stockEntry.sellPrice",
      itemWeight: "weight",
      supplierName: "stockEntry.supplierName",
      itemSupplierRef: "stockEntry.itemSupplierRef",
      deliveryReference: "stockEntry.deliveryReference",
      invoiceDate: "stockEntry.invoiceDate",
      invoiceReference: "stockEntry.invoiceReference",
    };
    return propMapping[prop] || prop;
  }

  private setDefaultFilters(): void {
    this.activeFilters.push(new SearchFilter("contextContactId", SearchFilterOperator.EQUAL, [this.mainStore.id]));
  }

  private applyStockType(filterValue: FilterValue, stockType: any, index: number): void {
    let sf;
    if (filterValue.equal === null) {
      return;
    }
    // return no line if user types letters
    if (isNaN(+filterValue.equal)) {
      filterValue.equal = "0";
    }

    if (filterValue.equal !== "") {
      sf = new SearchFilter(
        "stockEntry.id",
        SearchFilterOperator.LIKE,
        filterValue.equal.toString(),
        filterValue.filterId
      );
      this.activeFilters.splice(index, 1, sf);

      this.activeFilters.push(
        new SearchFilter("stockEntry.stockType", SearchFilterOperator.IN, stockType, filterValue.filterId)
      );
    }
  }

  // handle select checkbox
  private initSelectFormControl(): void {
    const control = new UntypedFormControl(false);
    this.tableControl = this.fb.group({
      headerCheckbox: control,
    });
  }
}
