import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { SearchFilter, SubscriptionService } from "fugu-common";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "fugu-components";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import {
  Uom,
  Pagination,
  StockEntryService,
  UomService,
  PaginatedList,
  StockEntryLocation,
  StockType,
  MovementType,
  Sort,
} from "center-services";
import { DatatableComponent } from "@siemens/ngx-datatable";

@Component({
  selector: "app-stock-entry-list",
  templateUrl: "./stock-entry-list.component.html",
  styleUrls: ["./stock-entry-list.component.scss"],
  providers: [SubscriptionService],
})
export class StockEntryListComponent implements OnChanges, OnInit {
  @ViewChild("table") table: DatatableComponent;

  @Input() itemId: number;

  public rows: any[] = [];
  public uoms: Uom[] = [];

  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });

  public activeFilters: SearchFilter[] = [];
  public stockEntriesList: any[] = [];
  public sorts: any[] = [
    {
      prop: "stockEntryDate",
      dir: "asc",
    },
  ];
  private initObservables: Observable<any>[];

  constructor(
    private stockEntryService: StockEntryService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private uomService: UomService,
    private subscriptionService: SubscriptionService
  ) {}

  // the arrow function bellow is used to return the rows class
  getRowClass: any = (): any => ({ "not-clickable": true });

  ngOnInit(): void {
    this.initObservables = [];
    this.initObservables.push(this.fetchUnitOfMeasure());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.fetchStockEntryLocations();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemId && !changes.itemId.firstChange) {
      this.fetchStockEntryLocations();
    }
  }

  fetchUnitOfMeasure(): Observable<Uom[]> {
    return this.uomService.getAll().pipe(
      tap(
        (uoms: Uom[]) => {
          this.uoms = uoms;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("uoms-list.errors.get-entities");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  getMovementTypeClass(stockMovementType: string): string {
    switch (stockMovementType) {
      case MovementType.IN:
        return "in";
      case MovementType.OUT:
        return "out";
      case MovementType.INTERNAL:
        return "internal";
      case MovementType.TRANSIT:
        return "transit";
      default:
        console.error(`${stockMovementType} is an unknown movement type !`);
        return "#FFF";
    }
  }

  changeSortSettings(prop: string, direction: string): void {
    if (prop === "batchNumber") {
      this.sorts = [
        {
          prop: "stockEntry.stockType",
          dir: "desc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    } else if (prop === "serialNumber") {
      this.sorts = [
        {
          prop: "stockEntry.stockType",
          dir: "asc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    } else {
      this.sorts = [
        {
          prop,
          dir: direction,
        },
      ];
    }

    this.fetchStockEntryLocations();
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchStockEntryLocations();
  }

  getMainUnitLongName(id: number): string {
    const purchaseUnitCurr = this.uoms.find((purchaseUnit: Uom) => {
      return purchaseUnit.id === id;
    });

    return purchaseUnitCurr ? purchaseUnitCurr.longName : null;
  }

  private fetchStockEntryLocations(): void {
    this.subscriptionService.subs.push(
      this.stockEntryService
        .getStockEntryLocations(this.pager, this.itemId, this.getSorter(), this.activeFilters)
        .subscribe(
          (result: PaginatedList<StockEntryLocation>) => {
            this.stockEntriesList = result.data;
            this.pager = result.page;
            this.rows = [];
          },
          error => {
            const title = this.translateService.instant("message.title.data-errors");
            const content = this.translateService.instant("stock-entry-list.errors.get-stock-entries", {
              message: error.message,
            });
            this.messageService.warn(content, { title });
          },
          () => {
            this.stockEntriesList.forEach((stockEntryLocation: StockEntryLocation) => this.addRow(stockEntryLocation));
            if (this.table) {
              this.table.sorts = this.sorts;
              this.table.offset = this.pager.number;
            }
          }
        )
    );
  }

  private addRow(stockEntryLocation: StockEntryLocation): void {
    const row = {
      id: stockEntryLocation.id,

      serialNumber: null,
      batchNumber: null,
      quantity: stockEntryLocation.quantity,
      sizeValue: stockEntryLocation.stockEntry.sizeValue,
      stockEntryDate: stockEntryLocation.firstMovementDate,
      lastMovementType: stockEntryLocation.lastMovementType,
      expirationDate: stockEntryLocation.stockEntry.expirationDate,
      lastMovementDate: stockEntryLocation.lastMovementDate,
      location: stockEntryLocation.locationName,
      uom: this.getMainUnitLongName(stockEntryLocation.stockEntry.uomId),
    };

    switch (stockEntryLocation.stockEntry.stockType) {
      case StockType.SERIAL_NUMBER:
        row.serialNumber = stockEntryLocation.stockEntry.id;
        break;
      case StockType.BATCH:
      case StockType.BULK:
        row.batchNumber = stockEntryLocation.stockEntry.id;
        break;
      default:
        console.error(`${stockEntryLocation.stockEntry.stockType} is an unknown stock type !`);
    }

    this.rows.push(row);
    this.rows = [...this.rows];
  }

  private getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  private propToDto(prop: string): string {
    switch (prop) {
      case "location":
        return "stockLocation.name";
      case "lastMovementType":
        return "movementType";
      case "stockEntryDate":
        return "firstMovementDate";
      case "batchNumber":
        return "stockEntry.id";
      case "serialNumber":
        return "stockEntry.id";
      case "sizeValue":
        return "stockEntry.sizeValue";
      default:
        return prop;
    }
  }
}
