<div class="invoice-supplier-pm-selection-table">
  <div class="subtitle">{{ "invoice-supplier-pm-selection.subtitle" | translate }}<span> *</span></div>
  <div class="filters" *ngIf="filterer">
    <ui-dynamic-filters
      label="{{ 'invoice-supplier-pm-selection.datatable.filter-title' | translate }}"
      [filters]="filterer.getFilters()"
      [(filterValues)]="filterer.filterValues"
      (apply)="applyFilters()"
      applyLabel="{{ 'invoice-supplier-pm-selection.datatable.filter-label' | translate }}"
      minLabel="{{ 'filters.min' | translate }}"
      maxLabel="{{ 'filters.max' | translate }}"
      deleteLabel="{{ 'filters.delete' | translate }}"
      placeholderPrefix="{{ 'filters.prefix' | translate }} "
      [btnIcon]="faSearch"
      btnType=""
    >
    </ui-dynamic-filters>
  </div>

  <div [formGroup]="tableControl" class="pm-selection-table frozen-left frozen-right">
    <div>
      <ngx-datatable
        #table
        [rows]="rows"
        [trackByProp]="'pmId'"
        class="material"
        columnMode="force"
        headerHeight="auto"
        footerHeight="50"
        rowHeight="40"
        [rowClass]="getRowClass"
        [cssClasses]="{
          sortAscending: 'fas fa-chevron-up',
          sortDescending: 'fas fa-chevron-down',
        }"
        [messages]="{ emptyMessage: 'global.no-data' | translate }"
        [externalSorting]="true"
        [externalPaging]="true"
        [count]="pager.totalElements"
        [offset]="pager.number"
        [limit]="pager.size"
        scrollbarH="true"
        [sorts]="sorts"
        (sort)="changeSortSettings($event.column.prop, $event.newValue)"
      >
        <ngx-datatable-column
          width="50"
          [sortable]="false"
          [draggable]="false"
          [resizeable]="false"
          [canAutoResize]="false"
          [frozenLeft]="true"
          cellClass="center-align frozen-style"
        >
          <ng-template ngx-datatable-header-template>
            <div (click)="$event.stopPropagation()">
              <ui-checkbox
                customClass="no-margin"
                (change)="onHeaderCheckboxChange()"
                [formControlName]="'headerCheckbox'"
              >
              </ui-checkbox>
            </div>
          </ng-template>

          <ng-template ngx-datatable-cell-template let-id="row.pmId">
            <div class="adjust" (click)="$event.stopPropagation()">
              <ui-checkbox
                customClass="no-margin"
                (change)="onRowCheckboxChange()"
                [formControlName]="getRowControlName(id)"
              >
              </ui-checkbox>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'invoice-supplier-pm-selection.datatable.columns.item-category' | translate }}"
          prop="itemCategory"
          cellClass="item-category"
          [resizeable]="false"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'invoice-supplier-pm-selection.datatable.columns.item-ref' | translate }}"
          prop="itemRef"
          cellClass="item-ref"
          [resizeable]="false"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'invoice-supplier-pm-selection.datatable.columns.supplier-name' | translate }}"
          prop="supplierName"
          cellClass="supplier-name"
          [resizeable]="false"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'invoice-supplier-pm-selection.datatable.columns.supplier-ref' | translate }}"
          prop="supplierRef"
          cellClass="supplier-ref"
          [resizeable]="false"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'invoice-supplier-pm-selection.datatable.columns.item-name' | translate }}"
          prop="itemName"
          cellClass="item-name"
          [resizeable]="false"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'invoice-supplier-pm-selection.datatable.columns.purchase-type' | translate }}"
          prop="purchaseType"
          cellClass="purchase-type"
          [resizeable]="false"
        >
          <ng-template let-purchaseType="row.purchaseType" ngx-datatable-cell-template>
            <span>{{ "invoice-supplier-pm-selection.datatable.purchase-type." + purchaseType | translate }}</span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'invoice-supplier-pm-selection.datatable.columns.brand-name' | translate }}"
          prop="brandName"
          cellClass="brand-name"
          [resizeable]="false"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'invoice-supplier-pm-selection.datatable.columns.min-quantity' | translate }}"
          prop="minQuantity"
          cellClass="min-quantity"
          [resizeable]="false"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'invoice-supplier-pm-selection.datatable.columns.max-quantity' | translate }}"
          prop="maxQuantity"
          cellClass="max-quantity"
          [resizeable]="false"
          width="120"
        >
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'invoice-supplier-pm-selection.datatable.columns.computed-price' | translate }}"
          prop="computedPrice"
          cellClass="computed-price"
          [resizeable]="false"
          [sortable]="false"
          width="120"
        >
          <ng-template ngx-datatable-cell-template let-currencyIsoCode="row.currencyIsoCode" let-value="value">
            <span class="adjust">
              {{ value | formatCurrency: currencyIsoCode : "symbol" : "0.2-2" : locale | async }}
            </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          prop="statusLabel"
          [frozenRight]="true"
          [resizeable]="false"
          cellClass="status center-align frozen-style"
          name="{{ 'invoice-supplier-pm-selection.datatable.columns.status' | translate }}"
        >
          <ng-template ngx-datatable-cell-template let-row="row">
            <ui-status-label [label]="row.statusLabel" [customClass]="row.statusClass"> </ui-status-label>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-selectedCount="selectedCount"
            let-curPage="curPage"
            let-offset="offset"
            let-isVisible="isVisible"
          >
            <div class="page-count">
              <span
                [innerHTML]="
                  selectedPMs.length === 0
                    ? ('global.datatable.no-items' | translate)
                    : (selectedPMs.length
                      | i18nPlural
                        : {
                            '=1': 'global.datatable.n-selected-items',
                            other: 'global.datatable.n-selected-items_plural',
                          }
                      | translate: { selectedElements: selectedPMs.length })
                "
              >
              </span>
            </div>
            <datatable-pager
              [pagerLeftArrowIcon]="'fas fa-chevron-left'"
              [pagerRightArrowIcon]="'fas fa-chevron-right'"
              [pagerNextIcon]="'fas fa-chevron-double-right'"
              [pagerPreviousIcon]="'fas fa-chevron-double-left'"
              [page]="curPage"
              [size]="pageSize"
              [count]="rowCount"
              [hidden]="!(rowCount / pageSize > 1)"
              (change)="changePage($event)"
            >
            </datatable-pager>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
</div>
