<ng-container>
  <div class="main-container frozen-right">
    <div class="purchase-order-lines-detail-list">
      <ui-container>
        <div class="filters no-margin" *ngIf="filterer">
          <ui-dynamic-filters
            label="{{ 'filters.label' | translate }}"
            [filters]="filterer.getFilters()"
            [(filterValues)]="filterer.filterValues"
            (apply)="applyFilters()"
            dateFormat="{{ dateFormat }}"
            applyLabel="{{ 'filters.apply' | translate }}"
            minLabel="{{ 'filters.min' | translate }}"
            maxLabel="{{ 'filters.max' | translate }}"
            deleteLabel="{{ 'filters.delete' | translate }}"
            placeholderPrefix="{{ 'filters.prefix' | translate }} "
          >
          </ui-dynamic-filters>
        </div>
        <div class="custom-title">
          <span>{{ "purchase-order-lines-list.title" | translate }}</span>
        </div>
        <div *ngIf="purchaseOrder.status !== 'DRAFT' && purchaseOrder.status !== 'CONFIRMED'" class="info-message">
          <fa-icon [icon]="faInfoCircle" class="info-icon"></fa-icon>
          <div class="status-message">
            <p>{{ "purchase-order-lines-list.message.info" | translate }}</p>
          </div>
        </div>
        <div class="purchase-order-lines-list">
          <div [formGroup]="tableControl">
            <ngx-datatable
              #table
              [rows]="rows"
              [trackByProp]="'id'"
              class="material vertical-scroll"
              columnMode="force"
              headerHeight="auto"
              [footerHeight]="50"
              [rowHeight]="40"
              [scrollbarH]="true"
              [rowClass]="getRowClass"
              [count]="pager.totalElements"
              [offset]="pager.number"
              [limit]="pager.size"
              [cssClasses]="{
                sortAscending: 'fas fa-chevron-up',
                sortDescending: 'fas fa-chevron-down',
              }"
              [messages]="{ emptyMessage: 'global.no-data' | translate }"
              [sorts]="sorts"
              (sort)="changeSortSettings($event.column.prop, $event.newValue)"
            >
              <ngx-datatable-column
                name="{{ 'purchase-order-lines-list.datatable.columns.line-number' | translate }}"
                prop="lineNumber"
                cellClass="line-number no-strikethrough"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  {{ value }} <span *ngIf="row.canceled" class="cancel-label"> {{ cancelLabel }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-lines-list.datatable.columns.store' | translate }}"
                prop="store"
                cellClass="store"
                [resizeable]="false"
                width="250"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-lines-list.datatable.columns.item-ref' | translate }}"
                prop="itemRef"
                cellClass="item-ref ellipsis-counter"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-lines-list.datatable.columns.supplier-ref' | translate }}"
                prop="supplierRef"
                cellClass="supplier-ref"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-lines-list.datatable.columns.supplier-traceability-number' | translate }}"
                prop="supplierTraceabilityNumber"
                cellClass="supplier-traceability-number"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-lines-list.datatable.columns.name' | translate }}"
                prop="itemName"
                cellClass="name"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-lines-list.datatable.columns.brand' | translate }}"
                prop="brand"
                cellClass="brand"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-lines-list.datatable.columns.delivery-date' | translate }}"
                prop="deliveryDate"
                cellClass="delivery-date "
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-date="row.deliveryDate">
                  <span *ngIf="date">{{ date | dateTimeFormat: false }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-lines-list.datatable.columns.size-value' | translate }}"
                prop="sizeValue"
                cellClass="size-value"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-lines-list.datatable.columns.quantity' | translate }}"
                cellClass="quantity"
                [resizeable]="false"
                [sortable]="false"
                [width]="170"
              >
                <ng-template
                  ngx-datatable-cell-template
                  let-row="row"
                  let-receivedQuantity="row.receivedQuantity"
                  let-quantity="row.quantity"
                  let-number="row.lineNumber"
                  let-itemReference="row.itemRef"
                  let-deliveryStoreId="row.deliveryStoreId"
                >
                  <div class="quantity-wrapper" *ngIf="quantity > 0 || receivedQuantity > 0">
                    <div class="{{ row.quantityClass }}">{{ receivedQuantity }}/{{ quantity }}</div>
                    <ui-progress-bar value="{{ (receivedQuantity / quantity) * 100 }}" max="100"></ui-progress-bar>
                    <div *ngIf="receivedQuantity > quantity" class="warning">
                      <fa-icon [icon]="faExclamationCircle"></fa-icon>
                    </div>

                    <div class="tooltip" *ngIf="shouldDisplayReceivedQuantityTooltip(number)">
                      <span class="info-icon" #info>
                        <fa-icon [icon]="faInfoCircle"></fa-icon>
                      </span>
                      <ui-tooltip maxWidth="300px" [element]="info">
                        <div
                          style="
                            display: grid;
                            grid-template-columns: auto auto;
                            column-gap: 5px;
                            font-weight: normal !important;
                            white-space: nowrap !important;
                          "
                        >
                          <div>
                            {{ "purchase-order-lines-list.datatable.tooltip.quantity" | translate
                            }}{{ this.getStoreName(number) }}:
                          </div>
                          <div>{{ this.getQuantityForStore(itemReference, deliveryStoreId) }}</div>
                          <div *ngIf="shouldShowTotal(itemReference)">
                            {{ "purchase-order-lines-list.datatable.tooltip.total-quantity" | translate }}:
                          </div>
                          <div *ngIf="shouldShowTotal(itemReference)">{{ getQuantityTotalForItem(itemReference) }}</div>
                        </div>
                      </ui-tooltip>
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-lines-list.datatable.columns.engraving' | translate }}"
                prop="engraving"
                headerClass="center-align"
                cellClass="engraving center-align"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
                  <ui-checkbox
                    *ngIf="row.engraving"
                    customClass="checkbox-margin"
                    [formControl]="tableControl.get('engraved_' + id)"
                  >
                  </ui-checkbox>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-lines-list.datatable.columns.comment' | translate }}"
                prop="comment"
                headerClass="center-align"
                cellClass="comment center-align"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-number="row.lineNumber" let-value="value">
                  <div #commentText class="adjust ellipsis">
                    {{ row.comment }}
                  </div>
                  <ui-tooltip maxWidth="300px" [element]="commentText">
                    <span style="font-weight: normal !important; white-space: pre-wrap !important">{{
                      row.tooltipComment
                    }}</span>
                  </ui-tooltip>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-lines-list.datatable.columns.total-weight' | translate }}"
                prop="totalWeight"
                cellClass="total-weight"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div *ngIf="value !== 0">{{ value | number: "0.2-2" : locale }} g</div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-lines-list.datatable.columns.theoretical-metal-weight' | translate }}"
                prop="theoreticalMetalWeight"
                cellClass="theoretical-metal-weight"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div *ngIf="value && value !== 0">{{ value | number: "0.2-2" : locale }} g</div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-lines-list.datatable.columns.unit-price' | translate }}"
                prop="unitPrice"
                cellClass="unit-price"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  {{ value | formatCurrency: purchaseOrderCurrency.codeISO : "symbol" : "0.2-5" : locale | async }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-lines-list.datatable.columns.unit-price-per-weight' | translate }}"
                prop="unitPricePerWeight"
                cellClass="unit-price-per-weight"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  {{ value | formatCurrency: purchaseOrderCurrency.codeISO : "symbol" : "0.2-5" : locale | async }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-lines-list.datatable.columns.metal-price' | translate }}"
                prop="metalPrice"
                cellClass="metal-price"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div *ngIf="row.purchaseType === 'WITH_METAL_PRICE'">
                    {{ value | formatCurrency: purchaseOrderCurrency.codeISO : "symbol" : "0.2-5" : locale | async }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-lines-list.datatable.columns.gross-unit-price' | translate }}"
                prop="grossUnitPrice"
                cellClass="gross-unit-price"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div>
                    {{ value | formatCurrency: purchaseOrderCurrency.codeISO : "symbol" : "0.2-5" : locale | async }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-lines-list.datatable.columns.discount-percent' | translate }}"
                prop="discountPercent"
                cellClass="discount-percent"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div>{{ value }}%</div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-lines-list.datatable.columns.discount' | translate }}"
                prop="discount"
                cellClass="discount"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div>
                    {{ value | formatCurrency: purchaseOrderCurrency.codeISO : "symbol" : "0.2-5" : locale | async }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'purchase-order-lines-list.datatable.columns.gross-price-amount' | translate }}"
                prop="grossPriceAmount"
                cellClass="gross-price-amount"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <div>
                    {{ value | formatCurrency: purchaseOrderCurrency.codeISO : "symbol" : "0.2-2" : locale | async }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                *ngIf="purchaseOrder.status !== 'DRAFT' && purchaseOrder.status !== 'CONFIRMED'"
                name="{{ 'purchase-order-lines-list.datatable.columns.actions' | translate }}"
                headerClass="center-align"
                cellClass="center-align  frozen-style"
                [resizeable]="false"
                [frozenRight]="true"
                [sortable]="false"
                [width]="100"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <ui-menu-actions
                    *ngIf="canCancel(row)"
                    [menuActions]="row.actions"
                    (actionCalled)="manageActions($event, row)"
                  >
                  </ui-menu-actions>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-selectedCount="selectedCount"
                  let-curPage="curPage"
                  let-offset="offset"
                  let-isVisible="isVisible"
                >
                  <div class="page-count" *ngIf="rowCount">
                    {{
                      rowCount
                        | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                        | translate: { rowCount: rowCount }
                    }}
                  </div>
                  <datatable-pager
                    [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                    [pagerRightArrowIcon]="'fas fa-chevron-right'"
                    [pagerNextIcon]="'fas fa-chevron-double-right'"
                    [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!(rowCount / pageSize > 1)"
                    (change)="changePage($event)"
                  >
                  </datatable-pager>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
      </ui-container>
    </div>
  </div>
</ng-container>
