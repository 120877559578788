<div class="content">
  <p class="stock-entry-title">{{ selectedItem.reference }} - {{ selectedItem.brandName }} - {{ selectedItem.name }}</p>

  <div class="stock-entry-form" [formGroup]="stockForm">
    <div class="grid-item">
      <div class="item-data">
        <ui-info-field
          [label]="'manual-stock-entry.form.stock-type' | translate"
          [value]="'stock-type-options.' + selectedItem.stockType | translate"
        >
        </ui-info-field>
      </div>

      <div class="quantity">
        <div class="multi-col">
          <ui-input-text
            label="{{ 'manual-stock-entry.form.quantity' | translate }}"
            [errorMessage]="
              stockForm.get('quantity')
                | formErrorMessage: { customPrefix: 'manual-stock-entry.errors.', useCustomPrefixFor: ['min'] }
                | translate: { digit: HIGH_INTEGER }
            "
            [status]="stockForm.get('quantity') | formStatus"
            [maskPattern]="decimalDigit"
            formControlName="quantity"
            [mandatory]="true"
            type="text"
          ></ui-input-text>
          <div class="main-unit-label" *ngIf="mainUnit">
            <label>{{ mainUnit.longName }}</label>
          </div>
        </div>
      </div>

      <ui-input-text
        label="{{ 'manual-stock-entry.form.price' | translate }}"
        [errorMessage]="
          stockForm.get('price')
            | formErrorMessage: { customPrefix: 'manual-stock-entry.errors.', useCustomPrefixFor: ['min'] }
            | translate: { digit: HIGH_INTEGER }
        "
        [suffix]="' ' + defaultCurrency?.symbol"
        [status]="stockForm.get('price') | formStatus"
        [maskPattern]="decimalDigit"
        formControlName="price"
        [mandatory]="true"
        type="text"
      ></ui-input-text>
      <ui-info-field
        [label]="'manual-stock-entry.form.total-price' | translate"
        [value]="
          getTotalPrice()
            | formatCurrency: defaultCurrency?.codeISO : defaultCurrency?.symbol : '0.2-2' : locale
            | async
        "
        [customClass]="'total-price'"
      >
      </ui-info-field>

      <ui-input-text
        label="{{ 'manual-stock-entry.form.size-value' | translate }}"
        [errorMessage]="stockForm.get('sizeValue') | formErrorMessage | translate"
        [status]="stockForm.get('sizeValue') | formStatus"
        *ngIf="selectedItem.sizeCategory"
        formControlName="sizeValue"
        type="text"
      ></ui-input-text>
    </div>

    <div class="grid-item">
      <ui-list
        label="{{ 'manual-stock-entry.form.supplier' | translate }}"
        [errorMessage]="stockForm.get('supplierId') | formErrorMessage | translate"
        [status]="stockForm.get('supplierId') | formStatus"
        [options]="this.supplierOptions"
        formControlName="supplierId"
        mandatory="true"
        [search]="true"
      ></ui-list>

      <ui-input-text
        label="{{ 'manual-stock-entry.form.supplier-ref' | translate }}"
        formControlName="supplierRef"
        type="text"
      ></ui-input-text>

      <ui-list
        *ngIf="selectedItem.sizeCategory"
        label="{{ 'manual-stock-entry.form.location' | translate }}"
        [errorMessage]="stockForm.get('stockLocationId') | formErrorMessage | translate"
        [status]="stockForm.get('stockLocationId') | formStatus"
        formControlName="stockLocationId"
        [options]="this.stockOptions"
        [search]="true"
        mandatory="true"
      ></ui-list>

      <ui-list
        *ngIf="!selectedItem.sizeCategory"
        label="{{ 'manual-stock-entry.form.location' | translate }}"
        [errorMessage]="stockForm.get('stockLocationId') | formErrorMessage | translate"
        [status]="stockForm.get('stockLocationId') | formStatus"
        formControlName="stockLocationId"
        [options]="this.stockOptions"
        [search]="true"
        mandatory="true"
      ></ui-list>

      <ui-input-text
        [infoLabel]="selectedItem.weight !== null ? ('manual-stock-entry.tooltip.weight-required' | translate) : null"
        [mandatory]="selectedItem.weight !== null"
        label="{{ 'manual-stock-entry.form.total-weight' | translate }} ({{ defaultUnit?.shortName }})"
        [infoLabel]="'manual-stock-entry.tooltip.weight-required' | translate"
        [errorMessage]="
          stockForm.get('weight')
            | formErrorMessage: { customPrefix: 'manual-stock-entry.errors.', useCustomPrefixFor: ['low', 'min'] }
            | translate: { digit: HIGH_INTEGER }
        "
        [status]="stockForm.get('weight') | formStatus"
        [suffix]="' ' + defaultUnit?.shortName"
        [maskPattern]="decimalDigit"
        formControlName="weight"
        type="text"
      >
      </ui-input-text>

      <ui-input-text
        [infoLabel]="selectedItem.tare !== null ? ('manual-stock-entry.tooltip.tare-required' | translate) : null"
        [mandatory]="selectedItem.tare !== null"
        label="{{ 'manual-stock-entry.form.tare' | translate }} ({{ defaultUnit?.shortName }})"
        [infoLabel]="'manual-stock-entry.tooltip.tare-required' | translate"
        [suffix]="' ' + defaultUnit?.shortName"
        [errorMessage]="
          stockForm.get('tare')
            | formErrorMessage: { customPrefix: 'manual-stock-entry.errors.', useCustomPrefixFor: ['min'] }
            | translate: { digit: HIGH_INTEGER }
        "
        [status]="stockForm.get('tare') | formStatus"
        [maskPattern]="decimalDigit"
        formControlName="tare"
        type="text"
      ></ui-input-text>
    </div>

    <div class="grid-item">
      <ui-textarea
        label="{{ 'manual-stock-entry.form.comment' | translate }}"
        [errorMessage]="stockForm.get('comment') | formErrorMessage | translate"
        [status]="stockForm.get('comment') | formStatus"
        formControlName="comment"
        [mandatory]="true"
      ></ui-textarea>

      <ui-checkbox
        *ngIf="this.allowCheckbox && !hasSameTareAndWeight()"
        customClass="no-margin"
        formControlName="policeBook"
        [labelPosition]="'right'"
        [label]="'manual-stock-entry.police-book.label' | translate"
        [infoLabel]="'manual-stock-entry.police-book.tooltip' | translate"
      >
      </ui-checkbox>
      <div class="warning" *ngIf="!this.allowCheckbox">
        <fa-icon class="warn-icon" [icon]="faWarn"></fa-icon>
        <div class="text">
          <div class="title">{{ "manual-stock-entry.form.warning-title" | translate }}</div>
          <div class="message">{{ "manual-stock-entry.form.warning-message" | translate }}</div>
        </div>
      </div>
      <div class="warning" *ngIf="this.allowCheckbox && hasSameTareAndWeight()">
        <fa-icon class="warn-icon" [icon]="faWarn"></fa-icon>
        <div class="text">
          <div class="title">{{ "manual-stock-entry.form.warning-title" | translate }}</div>
          <div class="message">{{ "manual-stock-entry.form.warning-message-quantity-tare-equals" | translate }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
