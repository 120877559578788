import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { IconDefinition, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  AbstractItem,
  CategoryType,
  Currency,
  CurrencyService,
  PublicPriceCurrency,
  StandardItem,
} from "center-services";
import { CommonValidatorsUtil, Option, SubscriptionService } from "fugu-common";
import { MessageService } from "fugu-components";
import { PrecisionUtil } from "generic-pages";

@Component({
  selector: "app-retail-item-sale-terms",
  templateUrl: "./retail-item-sale-terms.component.html",
  styleUrls: ["./retail-item-sale-terms.component.scss"],
  providers: [SubscriptionService],
})
export class RetailItemSaleTermsComponent implements OnChanges, AfterViewChecked {
  @Input() editedItem: AbstractItem;
  @Output() editedItemChange: EventEmitter<AbstractItem> = new EventEmitter<AbstractItem>();

  public readonly decimalDigitLow: string = `separator.${PrecisionUtil.LOW_DECIMAL}`;
  public readonly decimalDigitHigh: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  public HIGH_INTEGER: PrecisionUtil = PrecisionUtil.HIGH_INTEGER;
  public LOW_INTEGER: PrecisionUtil = PrecisionUtil.LOW_INTEGER;
  public saleTermsForm: UntypedFormGroup;
  public currencies: Map<number, Currency> = new Map<number, Currency>();
  public currencyOptions: Option[] = [];
  public faInfoCircle: IconDefinition = faInfoCircle;
  private readonly MAX_PERCENTAGE: number = 100;

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private currencyService: CurrencyService,
    private cd: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.editedItem.previousValue !== changes.editedItem.currentValue) {
      this.prepareForm();
    }
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  prepareForm(): void {
    this.saleTermsForm = this.fb.group({
      reference: [null],
      selectedCurrencies: [[], [Validators.required]],
      salesRate: [
        null,
        [
          CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.LOW_INTEGER),
          Validators.min(0),
          Validators.max(this.MAX_PERCENTAGE),
        ],
      ],
    });

    if (!this.saleTermsForm.controls["tph"] && this.editedItem.type === CategoryType.STANDARD) {
      this.saleTermsForm.addControl("tph", new UntypedFormControl(false, [Validators.required]));
    }

    this.saleTermsForm.controls.selectedCurrencies.valueChanges.subscribe(() => this.updateForm());
    this.fetchCurrencies();
  }

  updateForm(): void {
    this.saleTermsForm.controls.selectedCurrencies.value.sort((a, b) => a - b);
    const selectedCurrenciesNames: string[] = this.saleTermsForm.controls.selectedCurrencies.value.map(
      (currencyId: number) => `selectedPrices${this.currencies.get(currencyId).codeISO}`
    );
    const selectedPricesNames: string[] = Object.keys(this.saleTermsForm.controls).filter((key: string) =>
      key.startsWith("selectedPrices")
    );
    const toCreate: string[] = selectedCurrenciesNames.filter(item => selectedPricesNames.indexOf(item) < 0);
    const toDelete: string[] = selectedPricesNames.filter(item => selectedCurrenciesNames.indexOf(item) < 0);
    if (toCreate.length > 0) {
      toCreate.forEach((selectedPrice: string) =>
        this.saleTermsForm.addControl(
          selectedPrice,
          new FormControl(null, [
            Validators.required,
            CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER),
            Validators.min(0),
          ])
        )
      );
    }
    if (toDelete.length > 0) {
      toDelete.forEach((selectedPrice: string) => this.saleTermsForm.removeControl(selectedPrice));
    }
  }

  fetchCurrencies(): void {
    this.subscriptionService.subs.push(
      this.currencyService.getAll().subscribe({
        next: (currencies: Currency[]) => {
          currencies.forEach((currency: Currency) => this.currencies.set(currency.id, currency));
          this.currencyOptions = currencies
            .filter((obj: Currency) => !obj.archived)
            .map((obj: Currency) => new Option(obj.id, obj.symbol));

          this.loadEditedData();
        },
        error: () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("currencies-list.errors.get-entities");
          this.messageService.warn(content, { title });
        },
      })
    );
  }

  loadEditedData(): void {
    if (this.editedItem.publicPricesCurrencies) {
      this.editedItem.publicPricesCurrencies.forEach((ppc: PublicPriceCurrency) => {
        const control: FormControl = new FormControl({ value: ppc.price, disabled: !this.editedItem.canSold }, [
          Validators.required,
          CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER),
          Validators.min(0),
        ]);
        this.saleTermsForm.addControl(`selectedPrices${this.currencies.get(ppc.currencyId).codeISO}`, control);
      });
      this.saleTermsForm.controls.selectedCurrencies.setValue(
        this.editedItem.publicPricesCurrencies.map(
          (pubicPriceCurrency: PublicPriceCurrency) => pubicPriceCurrency.currencyId
        )
      );
    }
    this.saleTermsForm.controls.reference.setValue(this.editedItem.accountingSellRef);
    this.saleTermsForm.controls.salesRate.setValue(this.editedItem.salesRate);
    if (this.editedItem?.type === CategoryType.STANDARD) {
      this.saleTermsForm.controls.tph.setValue((this.editedItem as StandardItem).tphActivated);
    }

    if (!this.editedItem.canSold) {
      this.saleTermsForm.controls.selectedCurrencies.disable();
      this.saleTermsForm.controls.reference.disable();
      this.saleTermsForm.controls.salesRate.disable();

      if (this.editedItem.type === CategoryType.STANDARD) {
        this.saleTermsForm.controls.tph.disable();
      }
    }
  }

  applyModifications(): void {
    this.editedItem.accountingSellRef = this.saleTermsForm.value.reference;
    this.editedItem.salesRate = this.saleTermsForm.value.salesRate;
    if (this.editedItem.type === CategoryType.STANDARD) {
      (this.editedItem as StandardItem).tphActivated = this.saleTermsForm.value.tph;
    }

    this.editedItem.publicPricesCurrencies = [];
    this.saleTermsForm.value.selectedCurrencies.forEach((currencyId: number) => {
      this.editedItem.publicPricesCurrencies.push(
        new PublicPriceCurrency({
          currencyId: currencyId,
          price: this.saleTermsForm.controls[`selectedPrices${this.currencies.get(currencyId).codeISO}`].value,
        })
      );
    });
    this.editedItemChange.emit(this.editedItem);
  }

  updateItem(): boolean {
    if (this.saleTermsForm.invalid) {
      this.saleTermsForm.markAllAsTouched();
      return false;
    }
    this.applyModifications();
    return true;
  }
}
