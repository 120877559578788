import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { faCheck, faExclamationTriangle, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  Filterer,
  FilterValue,
  Option,
  RoundingUtil,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { StockEntryLocationLine } from "./stock-entry-location-line";
import hash from "string-hash";
import {
  Currency,
  Uom,
  StockEntryLocation,
  Pagination,
  ItemCategory,
  Light,
  Brand,
  StockLocation,
  StockEntryService,
  UomService,
  CaraUserService,
  ItemCategoryService,
  LightService,
  StockLocationService,
  MovementType,
  PaginatedList,
  CaraUser,
  StockType,
  Sort,
  PublicPriceCurrency,
  CurrencyService,
} from "center-services";

@Component({
  selector: "app-shipment-stock-entry-selection-popup",
  templateUrl: "./shipment-stock-entry-selection-popup.component.html",
  styleUrls: ["./shipment-stock-entry-selection-popup.component.scss"],
  providers: [SubscriptionService],
})
export class ShipmentStockEntrySelectionPopupComponent extends FilteredTableListComponent implements OnInit {
  public static LIST_ID: string = "app-shipment-stock-entry-selection-popup";
  @Input() senderId: number;
  @Input() disabledLines: StockEntryLocationLine[];
  @Input() orderCurrency: Currency;

  @ViewChild("table") table: any;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() selectedStockEntryLocation: EventEmitter<any> = new EventEmitter();

  public rows: any[] = [];
  public uoms: Uom[];
  public currentRowsId: number[] = [];
  public selectedSEL: StockEntryLocation[] = [];

  public codeLanguage: string;
  faCheck: IconDefinition = faCheck;
  public faWarn: IconDefinition = faExclamationTriangle;
  public pager: Pagination = new Pagination({
    number: 0,
    size: 7,
  });
  public locale: string;
  public dateFormat: string;
  public filterer: Filterer;
  public activeFilters: SearchFilter[] = [];
  public brandList: Brand[] = [];
  public stockEntriesLocationList: StockEntryLocation[] = [];
  public sorts: any[] = [
    {
      prop: "location",
      dir: "asc",
    },
  ];
  public form: UntypedFormGroup;
  selectedElementsNumber: number;
  mapChanged: boolean = false;
  stockLocations: StockLocation[];
  rootLocation: StockLocation;
  public stockOptions: Option[] = [];
  sessionPagination: SessionPagination;
  private initObservables: Observable<any>[] = [];
  private itemCategoryList: ItemCategory[] = [];
  private supplierList: Light[] = [];
  private currencyList: Currency[] = [];

  constructor(
    protected translateService: TranslateService,
    protected messageService: MessageService,
    private stockEntryService: StockEntryService,
    private uomService: UomService,
    protected userService: CaraUserService,
    private fb: UntypedFormBuilder,
    private itemCategoryService: ItemCategoryService,
    private lightService: LightService,
    private stockLocationService: StockLocationService,
    private subscriptionService: SubscriptionService,
    private currencyService: CurrencyService
  ) {
    super(userService, translateService, messageService);
    this.sessionPagination = new SessionPagination(this);
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  ngOnInit(): void {
    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    }

    this.initSelectFormControl();
    this.initObservables = [];
    this.initObservables.push(this.fetchUnitOfMeasure());
    this.initObservables.push(this.fetchCodeLanguage());
    this.initObservables.push(this.fetchItemCategoryList());
    this.initObservables.push(this.fetchSuppliers());
    this.initObservables.push(this.fetchBrands());
    this.initObservables.push(this.fetchStockLocations(this.senderId));
    this.initObservables.push(this.fetchCurrencyList());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.initFilters();
        this.sessionPagination.loadFromSession(ShipmentStockEntrySelectionPopupComponent.LIST_ID);
        this.computeSearchFilters();
        this.fetchStockEntryLocations();
      })
    );
  }

  submit(): void {
    this.updateSelection();
    this.selectedStockEntryLocation.emit(this.selectedSEL);
    this.close.emit();
  }

  closePopup(): void {
    if (this.mapChanged && this.selectedSEL.length !== 0) {
      this.mapChanged = false;
      const title = this.translateService.instant("global.errors.unsaved-title");
      const content = this.translateService.instant("global.errors.unsaved-popin-content");
      this.messageService.info(content, { title });
    } else {
      this.close.emit();
    }
  }

  fetchUnitOfMeasure(): Observable<Uom[]> {
    return this.uomService.getAll().pipe(
      tap(
        (uoms: Uom[]) => {
          this.uoms = uoms;
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant(
            "shipment-stock-entry-selection.datatable.errors.unit-of-measure"
          );
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchItemCategoryList(): Observable<ItemCategory[]> {
    return this.itemCategoryService.getAll().pipe(
      tap(
        (itemCategories: ItemCategory[]) => {
          this.itemCategoryList = itemCategories
            .filter((obj: ItemCategory) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant(
            "shipment-stock-entry-selection.datatable.errors.item-category"
          );
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchBrands(): Observable<Light[]> {
    return this.lightService.getBrands().pipe(
      tap(
        (brands: Brand[]) => {
          this.brandList = brands.filter((obj: Brand) => !obj.archived).sort((a, b) => a.name.localeCompare(b.name));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("shipment-stock-entry-selection.datatable.errors.brands");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  fetchStockLocations(id: number): Observable<StockLocation[]> {
    return this.stockLocationService.getAll(["withChildren", "onlyOrigin"], id).pipe(
      tap(
        (stockLocations: StockLocation[]) => {
          this.stockLocations = this.stockLocationService.getFlattenedLocations(stockLocations);
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("shipment-stock-entry-selection.datatable.errors.locations");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  applyFilters(): void {
    this.pager.number = 0;
    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        ShipmentStockEntrySelectionPopupComponent.LIST_ID
      ).subscribe(() => {
        this.fetchStockEntryLocations();
      })
    );
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === ShipmentStockEntrySelectionPopupComponent.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addListFilter(
      "stockEntry.retailItem.category.name",
      this.translateService.instant("shipment-stock-entry-selection.datatable.columns.itemCategoryName"),
      this.itemCategoryList
        .map(cat => cat.name)
        .map(itemCategoryName => {
          return { value: itemCategoryName, displayValue: itemCategoryName };
        })
    );

    this.filterer.addFilter(
      "serial.stockEntry.id",
      this.translateService.instant("shipment-stock-entry-selection.datatable.columns.serialNumber"),
      "string"
    );

    this.filterer.addFilter(
      "batch.stockEntry.id",
      this.translateService.instant("shipment-stock-entry-selection.datatable.columns.batchNumber"),
      "string"
    );

    this.filterer.addFilter(
      "stockEntry.supplierRef",
      this.translateService.instant("shipment-stock-entry-selection.datatable.columns.supplierRef"),
      "string"
    );

    this.filterer.addListFilter(
      "stockEntry.supplierName",
      this.translateService.instant("shipment-stock-entry-selection.datatable.columns.supplierName"),
      this.supplierList.map(supplier => {
        return { value: supplier.name, displayValue: supplier.name };
      })
    );

    this.filterer.addFilter(
      "stockEntry.retailItem.reference",
      this.translateService.instant("shipment-stock-entry-selection.datatable.columns.itemReference"),
      "string"
    );

    this.filterer.addFilter(
      "quantity",
      this.translateService.instant("shipment-stock-entry-selection.datatable.columns.quantity"),
      "range"
    );

    this.filterer.addFilter(
      "stockEntry.sizeValue",
      this.translateService.instant("shipment-stock-entry-selection.datatable.columns.sizeValue"),
      "string"
    );

    this.filterer.addFilter(
      "stockEntry.retailItem.name",
      this.translateService.instant("shipment-stock-entry-selection.datatable.columns.itemName"),
      "string"
    );

    this.filterer.addListFilter(
      "stockEntry.retailItem.brand.id",
      this.translateService.instant("shipment-stock-entry-selection.datatable.columns.itemBrandName"),
      this.brandList.map(brand => {
        return { value: brand.id.toString(), displayValue: brand.name };
      })
    );

    this.filterer.addListFilter(
      "stockLocation.id",
      this.translateService.instant("shipment-stock-entry-selection.datatable.columns.location"),
      this.stockLocations.map(location => {
        return { value: location.id.toString(), displayValue: location.name };
      }),
      null,
      null,
      null,
      null,
      true
    );
  }

  getMainUnitLongName(id: number): string {
    if (this.uoms) {
      const purchaseUnitCurr = this.uoms.find((purchaseUnit: Uom) => {
        return purchaseUnit.id === id;
      });
      return purchaseUnitCurr.longName;
    }
    return null;
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchStockEntryLocations();
  }

  changeSortSettings(prop: string, direction: string): void {
    if (prop === "serial.stockEntry.id") {
      this.sorts = [
        {
          prop: "stockEntry.stockType",
          dir: "asc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    } else if (prop === "batch.stockEntry.id") {
      this.sorts = [
        {
          prop: "stockEntry.stockType",
          dir: "desc",
        },
        {
          prop,
          dir: direction,
        },
      ];
    } else {
      this.sorts = [
        {
          prop,
          dir: direction,
        },
      ];
    }

    this.fetchStockEntryLocations();
  }

  // the arrow function bellow is used to return the rows class
  getRowClass: any = (): any => ({ "not-clickable": true });

  public onRowCheckboxChange(): void {
    this.updateHeaderPageCheckbox();
    this.updateSelection();
  }

  public onHeaderCheckboxChange(): void {
    this.updateRowsPageCheckbox();
    this.updateSelection();
  }

  public getRowControlName(id: number | string): string {
    return `checked_${id}`;
  }

  fetchCurrencyList(): Observable<Currency[]> {
    return this.currencyService.getAll().pipe(
      tap(
        (currencies: Currency[]) => (this.currencyList = currencies),
        error => {
          this.sendErrorAlert("retail-item-list.errors.get-currencies", error.message);
        }
      )
    );
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  public getCurrency(currencyId: number): Currency {
    return this.currencyList.find(currency => currency.id === currencyId);
  }

  protected computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
    this.activeFilters.forEach((sf, index) => {
      const filterValue = this.filterer.filterValues.find(fv => fv.filterId === hash(sf.key));
      switch (sf.key) {
        case "serial.stockEntry.id": {
          this.applyStockType(filterValue, StockType.SERIAL_NUMBER, index);
          break;
        }

        case "batch.stockEntry.id": {
          this.applyStockType(filterValue, [StockType.BATCH, StockType.BULK], index);
          break;
        }

        default: {
          console.warn(`${sf.key} unknwon filter id!`);
          break;
        }
      }
    });
    this.sessionPagination.saveToSession(ShipmentStockEntrySelectionPopupComponent.LIST_ID);
  }

  private fetchStockEntryLocations(): void {
    const selFilter = [
      new SearchFilter("contextContactId", SearchFilterOperator.EQUAL, [this.senderId]),
      new SearchFilter("movementType", SearchFilterOperator.NOT_EQUAL, [MovementType.TRANSIT]),
    ];
    this.subscriptionService.subs.push(
      this.stockEntryService
        .getStockEntryLocations(this.pager, null, this.getSorter(), selFilter.concat(this.activeFilters), true)
        .subscribe(
          (result: PaginatedList<StockEntryLocation>) => {
            this.stockEntriesLocationList = result.data;
            this.pager = result.page;
            this.rows = [];
            this.currentRowsId = this.stockEntriesLocationList.map(stockEntriesLocation => stockEntriesLocation.id);
            this.stockEntriesLocationList.forEach((stockEntryLocation: StockEntryLocation) =>
              this.addRow(stockEntryLocation)
            );
          },
          error => {
            const title = this.translateService.instant("message.title.data-errors");
            const content = this.translateService.instant(
              "shipment-stock-entry-selection.datatable.errors.stock-entry-locations",
              { message: error.message }
            );
            this.messageService.warn(content, { title });
          },
          () => {
            this.rows = [...this.rows];
            if (this.table) {
              this.table.sorts = this.sorts;
              this.table.offset = this.pager.number;
            }
            this.onRowCheckboxChange();
          }
        )
    );
  }

  private fetchCodeLanguage(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.codeLanguage = connectedUser.codeLanguage;
      })
    );
  }

  private fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap(
        (lightSuppliers: Light[]) => {
          this.supplierList = lightSuppliers
            .filter((light: Light) => !light.archived)
            .sort((a, b) => a.name.localeCompare(b.name));
        },
        () => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("shipment-stock-entry-selection.datatable.errors.suppliers");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private applyStockType(filterValue: FilterValue, stockType: any, index: number): void {
    let sf;
    if (filterValue.equal === null) {
      return;
    }
    // return no line if user types letters
    if (isNaN(+filterValue.equal)) {
      filterValue.equal = "0";
    }

    if (filterValue.equal !== "") {
      sf = new SearchFilter(
        "stockEntry.id",
        SearchFilterOperator.LIKE,
        filterValue.equal.toString(),
        filterValue.filterId
      );
      this.activeFilters.splice(index, 1, sf);

      this.activeFilters.push(
        new SearchFilter("stockEntry.stockType", SearchFilterOperator.IN, stockType, filterValue.filterId)
      );
    }
  }

  private getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  private propToDto(prop: string): string {
    switch (prop) {
      case "location":
        return "stockLocation.name";
      case "serial.stockEntry.id":
        return "stockEntry.id";
      case "batch.stockEntry.id":
        return "stockEntry.id";
      default:
        return prop;
    }
  }

  private addRow(stockEntryLocation: StockEntryLocation): void {
    const alreadySelected =
      this.disabledLines.findIndex(
        disabledLine =>
          disabledLine.stockLocationId === stockEntryLocation.locationId &&
          disabledLine.stockEntryId === stockEntryLocation.stockEntry.id
      ) !== -1;

    const row = {
      id: stockEntryLocation.id,
      stockLocationId: stockEntryLocation.locationId,
      stockEntryId: stockEntryLocation.stockEntry.id,
      itemCategoryName: stockEntryLocation.stockEntry.itemCategoryName,
      serialNumber: null,
      batchNumber: null,
      supplierRef: stockEntryLocation.stockEntry.supplierRef,
      supplierName: stockEntryLocation.stockEntry.supplierName,
      itemReference: stockEntryLocation.stockEntry.itemReference,
      quantity: stockEntryLocation.quantity,
      uom: this.getMainUnitLongName(stockEntryLocation.stockEntry.uomId),
      sizeValue: stockEntryLocation.stockEntry.sizeValue,
      itemName: stockEntryLocation.stockEntry.itemName,
      itemBrandName: stockEntryLocation.stockEntry.itemBrandName,
      location: stockEntryLocation.locationName,
      publicPricesCurrencies: stockEntryLocation.stockEntry.publicPricesCurrencies.map(
        (publicPriceCurrency: PublicPriceCurrency) =>
          `${publicPriceCurrency.price.toFixed(RoundingUtil.TWO_DIGITS)} ${this.getCurrency(publicPriceCurrency.currencyId).symbol}`
      ),
      disabled: alreadySelected,
      shipmentRefs: stockEntryLocation.shipmentRefs ? stockEntryLocation.shipmentRefs.map(sr => ` ${sr}`) : [],
    };

    switch (stockEntryLocation.stockEntry.stockType) {
      case StockType.SERIAL_NUMBER:
        row.serialNumber = stockEntryLocation.stockEntry.id;
        break;
      case StockType.BATCH:
        row.batchNumber = stockEntryLocation.stockEntry.id;
        break;
      case StockType.BULK:
        row.quantity = stockEntryLocation.quantity;
        row.batchNumber = stockEntryLocation.stockEntry.id;
        break;
      default:
        console.error(`${stockEntryLocation.stockEntry.stockType} is an unknown stock type !`);
    }

    this.rows.push(row);
    this.rows = [...this.rows];
    this.initRowFormControl(row.id, alreadySelected);
  }

  private initSelectFormControl(): void {
    const control = new UntypedFormControl(false);
    this.form = this.fb.group({
      headerCheckbox: control,
    });
  }

  private initRowFormControl(id: number, isAlreadySelected: boolean): void {
    this.form.addControl(
      this.getRowControlName(id),
      !isAlreadySelected
        ? new UntypedFormControl(false)
        : new UntypedFormControl({
          value: true,
          disabled: true,
        })
    );
  }

  private updateHeaderPageCheckbox(): void {
    const currentPageRowsDisabledIds = this.currentRowsId.filter(id => {
      return this.form.get(this.getRowControlName(id)).disabled && !this.form.get(this.getRowControlName(id)).value;
    });
    if (this.currentRowsId.length === currentPageRowsDisabledIds.length) {
      this.form.controls.headerCheckbox.patchValue(false);
      if (!this.form.controls.headerCheckbox.disabled) {
        this.form.controls.headerCheckbox.disable();
      }
      return;
    }
    this.form.controls.headerCheckbox.enable();

    const currentPageRowsCheckedIds = this.currentRowsId.filter(id => {
      return this.form.get(this.getRowControlName(id)).value || currentPageRowsDisabledIds.includes(id);
    });
    const isSelected = this.currentRowsId.length === currentPageRowsCheckedIds.length;
    this.form.controls.headerCheckbox.patchValue(isSelected);
  }

  private updateRowsPageCheckbox(): void {
    const controls = this.form.controls;
    const isHeaderSelected = this.form.controls.headerCheckbox.value;
    this.currentRowsId.forEach(id => {
      if (!controls[this.getRowControlName(id)].disabled) {
        controls[this.getRowControlName(id)].patchValue(isHeaderSelected);
      }
    });
  }

  private updateSelection(): void {
    Object.keys(this.form.controls).forEach(key => {
      if (key !== "headerCheckbox" && !this.form.controls[key].disabled) {
        const value = this.form.controls[key].value;
        const id = +key.split("_")[1];
        this.updateList(id, value);
      }
    });
    this.selectedElementsNumber = this.disabledLines.length + this.selectedSEL.length;
  }

  private updateList(id: number, value: boolean): void {
    this.mapChanged = true;
    const index: number = this.selectedSEL.findIndex(sel => sel.id === id);
    if (index === -1 && value) {
      const stockEntriesLocation: StockEntryLocation = this.stockEntriesLocationList.find(
        (myStockEntriesLocation: StockEntryLocation) => myStockEntriesLocation.id === id
      );
      this.selectedSEL.push(stockEntriesLocation);
    } else if (index > -1 && !value) {
      this.selectedSEL.splice(index, 1);
    }
  }
}
