import { HttpErrorResponse } from "@angular/common/http";
import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Data, Router } from "@angular/router";
import { IconDefinition, faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  AbstractItem,
  Country,
  CountryService,
  CurrencyService,
  DocumentService,
  FormErrorService,
  ItemFactory,
  ItemStatusType,
  PublicPriceCurrency,
  RetailItemService,
  ServiceItem,
  StandardItem,
} from "center-services";
import { SessionPagination } from "fugu-common";
import { MessageService } from "fugu-components";
import { ComponentDirty, ErrorUtil, PopupSaveUtil } from "generic-pages";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { RetailItemPurchaseModalitiesListComponent } from "../retail-item-purchase-modalities/retail-item-purchase-modalities-list/retail-item-purchase-modalities-list.component";

@Component({
  selector: "app-retail-item-form",
  templateUrl: "./retail-item-form.component.html",
  styleUrls: ["./retail-item-form.component.scss"],
})
export class RetailItemFormComponent implements OnInit, ComponentDirty, AfterContentChecked {
  @ViewChild("tabHandler") tabHandler: any;
  @ViewChild("generalDatas") generalDatas: any;
  @ViewChild("saleTerms") saleTerms: any;
  @ViewChild("purchaseModalities") purchaseModalities: any;

  public title: string;
  public subTitle: string;
  public unsavedFile: any;
  public editedItem: AbstractItem;
  public unsavedItem: AbstractItem;
  public updatedItem: AbstractItem;

  public faChevronLeft: IconDefinition = faChevronLeft;
  public shouldClose: boolean = false;
  public hasError: boolean = false;
  public modifiedFile: any;

  public type: string;
  public countryVatRate: number;
  protected readonly MAX_PERCENT: number = 100;
  // eslint-disable-next-line no-magic-numbers
  protected readonly PAYLOAD_TOO_LARGE: number = 413;

  constructor(
    private retailItemService: RetailItemService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private documentService: DocumentService,
    private currencyService: CurrencyService,
    private formErrorService: FormErrorService,
    private route: ActivatedRoute,
    private router: Router,
    private factory: ItemFactory,
    private countryService: CountryService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const itemId = this.route.snapshot.params.id;

    if (itemId) {
      this.retailItemService.get(itemId).subscribe(
        (item: AbstractItem) => {
          this.editedItem = this.factory.getType(item);
          this.updatedItem = this.factory.getType(item);
          this.subTitle = this.getSubtitle(item);
          this.title = `item.${item.type.toLowerCase()}.update`;
        },
        () => {
          this.router.navigateByUrl("/retail-item-list");
        }
      );
    } else {
      this.type = (this.route.data as Data).value.type;
      this.title = `item.${this.type}.new`;
      switch (this.type) {
        case "standard":
          this.updatedItem = new StandardItem();
          break;
        case "service":
          this.updatedItem = new ServiceItem();
          break;
        default:
          break;
      }
      this.getDefaultCountry().subscribe(() => {
        this.updatedItem.vatRate = this.countryVatRate;
        if (this.editedItem) {
          this.editedItem.vatRate = this.countryVatRate;
        }
      });
      this.updatedItem.status = ItemStatusType.ACTIVE;
      this.editedItem = this.factory.getType(this.updatedItem);
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  isDirty(): boolean {
    if (!this.updatedItem) {
      return false;
    }

    let initialFile = null;
    let newFile = null;

    if (this.generalDatas) {
      initialFile = this.generalDatas.previousFile;
      newFile = this.generalDatas.selectedFile;
      this.generalDatas.applyModifications();
    }

    if (this.saleTerms) {
      this.saleTerms.applyModifications();
    }

    if (
      (this.unsavedItem && !this.unsavedItem.equals(this.updatedItem)) ||
      (this.unsavedFile && newFile && this.unsavedFile !== newFile)
    ) {
      this.shouldClose = false;
    }

    if (
      ((this.editedItem && !this.editedItem.equals(this.updatedItem)) || initialFile !== newFile) &&
      !this.shouldClose
    ) {
      this.recordDatas();
      return true;
    } else if (!this.editedItem && !this.shouldClose) {
      this.recordDatas();
      return true;
    }

    this.unsavedItem = null;
    this.shouldClose = false;
    this.unsavedFile = null;
    return false;
  }

  recordDatas(): void {
    if (this.generalDatas) {
      this.unsavedFile = this.generalDatas.selectedFile;
    }
    this.unsavedItem = this.factory.getType(this.updatedItem);
    this.shouldClose = true;
  }

  onTabClick(tab: any): void {
    if (this.purchaseModalities) {
      this.purchaseModalities.savePaginationToSession();
    }
    if (this.updatedItem) {
      this.submitItem(tab);
    }
  }

  catchEvent(source: string, object: any): void {
    PopupSaveUtil.manageEvent(this.editedItem, this.updatedItem, object, source, this.saveItemElement.bind(this));
  }

  submitItem(nextTab: any = null): void {
    this.hasError = false;
    let fileUpdated = false;

    // handle generalDatas tab
    if (this.generalDatas) {
      if (!this.generalDatas.updateItem()) {
        this.formErrorService.handleFormError();
        return;
      }

      // handle file
      this.modifiedFile = this.generalDatas.getFile();
      fileUpdated = this.generalDatas.hasFileChanged();
    }

    // handle sale terms tab
    if (this.saleTerms && !this.saleTerms.updateItem()) {
      this.formErrorService.handleFormError();
      return;
    }

    // handle metal management error (alloy composition)
    this.checkMetalManagement();

    if (this.hasError) {
      return;
    }

    if (this.editedItem) {
      // if nothing has changed, no need to call the API to save the item
      if (this.editedItem.equals(this.updatedItem) && !fileUpdated) {
        if (nextTab && this.tabHandler) {
          this.tabHandler.changeTab(nextTab);
        }
        return;
      }
    }

    this.callApi(nextTab);
  }

  callApi(nextTab: any = null): void {
    if (!this.updatedItem) {
      return;
    }

    // when form is valid, create or update item
    const action = this.editedItem && this.editedItem.id ? "update" : "create";

    if (action === "create" && this.updatedItem.canSold) {
      this.updatedItem.publicPricesCurrencies = [
        new PublicPriceCurrency({
          currencyId: this.currencyService.defaultCurrency.value.id,
          price: 0,
        }),
      ];
    }

    this.saveItem(action, nextTab);
  }

  saveItem(action: string, nextTab: any): void {
    this.retailItemService[action].call(this.retailItemService, this.updatedItem).subscribe(
      responseItem => {
        this.saveLogo(nextTab, responseItem);
      },
      error => {
        this.hasError = true;
        this.handleApiError(error);
      }
    );
  }

  saveItemElement(action: string | number, propName: string | number): void {
    return this.retailItemService[action]
      .call(this.retailItemService, this.editedItem)
      .toPromise()
      .then(responseItem => {
        const title = this.translateService.instant("message.title.save-success");
        const content = this.translateService.instant("message.content.save-success");
        this.messageService.success(content, { title });
        return responseItem[propName];
      })
      .catch(error => {
        this.hasError = true;
        this.handleApiError(error);
      });
  }

  saveLogo(nextTab: any, responseItem: AbstractItem): void {
    if (!this.generalDatas || !this.generalDatas.hasFileChanged()) {
      this.finishSave(nextTab, responseItem);
      return;
    }

    const formData = new FormData();
    formData.set("file", this.modifiedFile);
    this.documentService.uploadFile(formData, responseItem.logoId).subscribe(
      () => {
        this.generalDatas.previousFile = this.generalDatas.selectedFile;
        this.finishSave(nextTab, responseItem);
      },
      (error: HttpErrorResponse) => {
        this.hasError = true;
        const res =
          error.status === this.PAYLOAD_TOO_LARGE
            ? this.translateService.instant("global.form.error.payload-too-large")
            : this.translateService.instant("item.general-datas.errors.upload-logo");
        const title = this.translateService.instant("message.title.api-errors");
        this.messageService.warn(res, { title });

        // no blocking error when the file can't be uploaded
        this.finishSave(nextTab, responseItem);
      }
    );
  }

  finishSave(nextTab: any, responseItem: AbstractItem): void {
    this.editedItem = this.factory.getType(responseItem);
    this.updatedItem = this.factory.getType(responseItem);
    if (this.route.snapshot.params.id) {
      this.subTitle = this.getSubtitle(responseItem);
    }

    const title = this.translateService.instant("message.title.save-success");
    const content = this.translateService.instant("message.content.save-success");
    this.messageService.success(content, { title });
    if (nextTab) {
      this.tabHandler.changeTab(nextTab);
    }
  }

  backToItemList(): void {
    SessionPagination.clear(RetailItemPurchaseModalitiesListComponent.LIST_ID);

    this.router.navigateByUrl("/retail-item-list");
  }

  handleApiError(error: any): void {
    const attributeTranslations = {
      name: "item.general-datas.item-name",
      reference: "item.general-datas.reference",
    };

    const title = this.translateService.instant("message.title.form-errors");

    if (
      error.error.exception &&
      (error.error.exception.name === "InvalidReferenceException" ||
        error.error.exception.name === "InvalidReferenceIdException")
    ) {
      const value = error.error.exception.parameters.value;
      let attribute;

      switch (error.error.exception.parameters.attribute) {
        case "previousReference":
          attribute = this.translateService.instant("item.general-datas.previous-reference");
          break;
        case "nextReference":
          attribute = this.translateService.instant("item.general-datas.next-reference");
          break;
        case "caseReference":
          attribute = this.translateService.instant("item.general-datas.case-reference");
          break;
        case "bagReference":
          attribute = this.translateService.instant("item.general-datas.bag-reference");
          break;
        default:
          attribute = error.error.exception.parameters.attribute;
          break;
      }
      const content = this.translateService.instant("item.general-datas.errors.invalid-reference", {
        value,
        attribute,
      });
      this.messageService.error(content, { title });
    } else if (error.error.exception && error.error.exception.name === "NextPreviousIdRefDuplicateException") {
      let attribute;

      switch (error.error.exception.parameters.attribute) {
        case "previousItemId":
          attribute = this.translateService.instant("item.general-datas.previous-reference");
          break;
        case "nextItemId":
          attribute = this.translateService.instant("item.general-datas.next-reference");
          break;
        default:
          attribute = error.error.exception.parameters.attribute;
          break;
      }
      const content = this.translateService.instant("item.general-datas.errors.duplicate-external-reference", {
        attribute,
      });
      this.messageService.error(content, { title });
    } else if (error.error.exception && error.error.exception.name === "InvalidDataException") {
      const key = error.error.message.includes("nomenclature")
        ? "item.nomenclatures.errors.cannot-change-size-category"
        : "item.metal-management.errors.invalid-composition";

      const message = this.translateService.instant(key);
      this.messageService.error(message, { title });
    } else {
      const result = ErrorUtil.getTranslationKey(error.error, attributeTranslations, this.translateService);
      const content = this.translateService.instant(result.message, result.params);
      this.messageService.error(content, { title });
    }
  }

  getSubtitle(item: AbstractItem): string {
    const subtitleList = [];
    if (item.reference) {
      subtitleList.push(item.reference);
    }
    subtitleList.push(item.name);

    return subtitleList.join(" - ");
  }

  checkMetalManagement(): void {
    if (
      this.updatedItem instanceof StandardItem &&
      this.updatedItem.composition &&
      this.updatedItem.composition.length > 0
    ) {
      let sum = 0.0;
      this.updatedItem.composition.forEach(alloyComposition => {
        const lossRate = alloyComposition.lossRate;
        const rate = alloyComposition.rate;
        if (rate !== null) {
          sum += alloyComposition.rate;
        }
        // check lossRate and rate
        if (rate === null || lossRate < 0 || lossRate > this.MAX_PERCENT || rate < 0 || rate > this.MAX_PERCENT) {
          this.hasError = true;
          return;
        }
      });
      // check rate sum
      if (sum !== this.MAX_PERCENT) {
        const title = this.translateService.instant("message.title.form-errors");
        const content = this.translateService.instant("item.metal-management.errors.invalid-composition");
        this.messageService.error(content, { title });
        this.hasError = true;
        return;
      }
    }
  }

  getDefaultCountry(): Observable<Country> {
    return this.countryService.getDefault().pipe(
      tap(
        (country: Country) => {
          this.countryVatRate = country.taxRates[0] ? country.taxRates[0].value : null;
        },
        error => {
          const title = this.translateService.instant("message.title.data-errors");
          const content = this.translateService.instant("countries-list.errors.get-entities", {
            message: error.message,
          });
          this.messageService.warn(content, { title });
        }
      )
    );
  }
}
