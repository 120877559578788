<ng-container>
  <div class="main-container frozen-right">
    <div class="title">
      <ui-title titleText="{{ 'inventory-list.title' | translate }}"></ui-title>
    </div>
    <div class="top-buttons">
      <div class="button-create">
        <ui-button
          text="{{ 'inventory-list.create-button' | translate }}"
          [primary]="true"
          customClass="fugu-button-add"
          [btnIcon]="faCircleArrowRight"
          *hasPermission="['INVENTORY_CREATE']"
          (click)="createInventory()"
        ></ui-button>
      </div>
    </div>
    <div class="inventory-table">
      <ui-container>
        <div class="filters-field" *ngIf="filterer">
          <ui-dynamic-filters
            #inventoryFilters
            [filters]="filterer?.getFilters()"
            [(filterValues)]="filterer.filterValues"
            (apply)="applyFilters()"
            dateFormat="{{ dateFormat }}"
            [label]="'filters.label' | translate"
            minLabel="{{ 'filters.min' | translate }}"
            maxLabel="{{ 'filters.max' | translate }}"
            applyLabel="{{ 'filters.apply' | translate }}"
            deleteLabel="{{ 'filters.delete' | translate }}"
            placeholderPrefix="{{ 'filters.prefix' | translate }} "
            mandatoryErrorMessage="{{ 'inventory-list.errors.filters' | translate }}"
          >
          </ui-dynamic-filters>
        </div>

        <ngx-datatable
          #table
          [rows]="rows"
          [trackByProp]="'id'"
          [class]="'material'"
          [columnMode]="'force'"
          [headerHeight]="'auto'"
          [footerHeight]="50"
          [rowHeight]="40"
          [rowClass]="getRowClass"
          [externalSorting]="true"
          [externalPaging]="true"
          [count]="pager.totalElements"
          [offset]="pager.number"
          [limit]="pager.size"
          scrollbarH="true"
          (activate)="updateInventory($event)"
          [cssClasses]="{
            sortAscending: 'fas fa-chevron-up',
            sortDescending: 'fas fa-chevron-down',
          }"
          [messages]="{ emptyMessage: 'global.no-data' | translate }"
          [sorts]="sorts"
          (sort)="changeSortSettings($event.column.prop, $event.newValue)"
        >
          <ngx-datatable-column
            [name]="getColumnTranslation('inventory-number') | translate"
            cellClass="inventory-number"
            [resizeable]="false"
            [draggable]="false"
            prop="inventoryNumber"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('internal-code') | translate"
            prop="internalCode"
            [resizeable]="false"
            cellClass="internal-code"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('name') | translate"
            prop="name"
            [resizeable]="false"
            cellClass="name"
            width="300"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('perimeter') | translate"
            prop="perimeter"
            [resizeable]="false"
            cellClass="perimeter"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('begin-date') | translate"
            prop="beginDate"
            [resizeable]="false"
            cellClass="begin-date"
          >
            <ng-template ngx-datatable-cell-template let-date="row.beginDate">
              <span>{{ date | dateTimeFormat: false }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('end-date') | translate"
            prop="endDate"
            [resizeable]="false"
            cellClass="end-date"
          >
            <ng-template ngx-datatable-cell-template let-date="row.endDate">
              <span>{{ date | dateTimeFormat: false }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('quantity-inventory') | translate"
            prop="quantityInventory"
            [resizeable]="false"
            cellClass="quantity-inventory"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="getColumnTranslation('quantity-stock') | translate"
            prop="quantityStock"
            [resizeable]="false"
            cellClass="quantityStock"
          >
          </ngx-datatable-column>

          <ngx-datatable-column
            *hasPermission="['INVENTORY_PRICE_DIFF_READ']"
            [name]="getColumnTranslation('inventory-difference') | translate"
            prop="inventoryDifference"
            [resizeable]="false"
            cellClass="inventory-difference"
          >
            <ng-template ngx-datatable-cell-template let-row="row">
              <span *ngIf="row.inventoryDifference !== null">
                {{
                  row.inventoryDifference
                    | formatCurrency: defaultCurrency.codeISO : "symbol" : "0.2-2" : codeLanguage
                    | async
                }}
              </span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            prop="statusLabel"
            [draggable]="false"
            [resizeable]="false"
            [canAutoResize]="false"
            [frozenRight]="true"
            cellClass="status center-align frozen-style"
            name="{{ 'purchase-modality-selection.datatable.columns.status' | translate }}"
            [width]="150"
          >
            <ng-template ngx-datatable-cell-template let-row="row">
              <ui-status-label [label]="row.statusLabel" [customClass]="row.statusClass"> </ui-status-label>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer>
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-selectedCount="selectedCount"
              let-curPage="curPage"
              let-offset="offset"
              let-isVisible="isVisible"
            >
              <div class="page-count" *ngIf="rowCount">
                {{
                  rowCount
                    | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                    | translate: { rowCount: rowCount }
                }}
              </div>
              <datatable-pager
                [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                [pagerRightArrowIcon]="'fas fa-chevron-right'"
                [pagerNextIcon]="'fas fa-chevron-double-right'"
                [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                [page]="curPage"
                [size]="pageSize"
                [count]="rowCount"
                [hidden]="!(rowCount / pageSize > 1)"
                (change)="changePage($event)"
              >
              </datatable-pager>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </ui-container>
    </div>
  </div>

  <!-- initiate inventory creation popup -->
  <app-inventory-initiator-popup
    *ngIf="initiatorPopupVisible"
    (close)="closeInitiatorPopup()"
  ></app-inventory-initiator-popup>
</ng-container>
