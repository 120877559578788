<div class="purchase-order-summary-table">
  <ngx-datatable
    #table
    [rows]="rows"
    [trackByProp]="'id'"
    [class]="'material'"
    [columnMode]="'force'"
    [headerHeight]="'auto'"
    [footerHeight]="50"
    [rowHeight]="40"
    [limit]="15"
    [rowClass]="getRowClass"
    [cssClasses]="{
      sortAscending: 'fas fa-chevron-up',
      sortDescending: 'fas fa-chevron-down',
    }"
    [messages]="{ emptyMessage: 'global.no-data' | translate }"
    [sorts]="sorts"
    (sort)="changeSortSettings($event.column.prop, $event.newValue)"
    [summaryRow]="true"
    [summaryPosition]="'bottom'"
    [summaryHeight]="60"
    scrollbarH="true"
  >
    <ngx-datatable-column
      name="{{ 'purchase-order.summary.datatable.columns.line-number' | translate }}"
      prop="lineNumber"
      [resizeable]="false"
      [cellClass]="'line-number'"
      [draggable]="false"
      [summaryTemplate]="totalSummary"
    >
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'purchase-order.summary.datatable.columns.item-reference' | translate }}"
      prop="itemReference"
      [resizeable]="false"
      [cellClass]="'item-reference'"
      [draggable]="false"
    >
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'purchase-order.summary.datatable.columns.supplier-ref' | translate }}"
      prop="supplierRef"
      [resizeable]="false"
      [cellClass]="'supplier-ref'"
      [draggable]="false"
    >
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'purchase-order.summary.datatable.columns.supplier-traceability-number' | translate }}"
      prop="supplierTraceabilityNumber"
      [resizeable]="false"
      [cellClass]="'supplier-traceability-number'"
      [draggable]="false"
    >
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'purchase-order.summary.datatable.columns.item-name' | translate }}"
      prop="itemName"
      [resizeable]="false"
      [cellClass]="'item-name'"
      [draggable]="false"
    >
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'purchase-order.summary.datatable.columns.quantity' | translate }}"
      prop="quantity"
      [resizeable]="false"
      [cellClass]="'quantity'"
      [draggable]="false"
      [summaryTemplate]="quantitySummary"
    >
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
        <div class="quantity-template">
          <span class="quantity-template-value">{{ value }}</span>
          <span class="align-right"> {{ row.purchaseUnitName }}</span>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'purchase-order.summary.datatable.columns.unit-price-without-tax' | translate }}"
      prop="unitPriceWithoutTax"
      [resizeable]="false"
      [cellClass]="'unit-price-without-tax'"
      [draggable]="false"
      [summaryFunc]="null"
    >
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
        <div class="unit-price-template">
          <span class="unit-price-template-value">
            {{ value | formatCurrency: purchaseOrderCurrency.codeISO : "symbol" : "0.2-2" : locale | async }}</span
          >
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'purchase-order.summary.datatable.columns.total-gross-price' | translate }}"
      prop="totalGrossPrice"
      [resizeable]="false"
      [cellClass]="'total-gross-price'"
      [draggable]="false"
      [summaryTemplate]="totalGrossPriceSummary"
    >
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
        <div class="total-gross-price-template">
          <span class="total-gross-price-template-value">
            {{ value | formatCurrency: purchaseOrderCurrency.codeISO : "symbol" : "0.2-2" : locale | async }}</span
          >
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'purchase-order.summary.datatable.columns.total-weight' | translate }}"
      prop="totalWeight"
      [resizeable]="false"
      [cellClass]="'total-weight'"
      [draggable]="false"
      [summaryTemplate]="totalWeightSummary"
    >
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
        <div *ngIf="value" class="metal-weight-template">
          <span class="total-weight-template-value">{{ value | number: "0.2-2" : locale }} g</span>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'purchase-order.summary.datatable.columns.theoretical-metal-weight' | translate }}"
      prop="theoreticalWeight"
      [resizeable]="false"
      [cellClass]="'metal-weight'"
      [draggable]="false"
      [summaryTemplate]="theoreticalWeightSummary"
    >
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
        <div *ngIf="value" class="metal-weight-template">
          <span class="metal-weight-template-value">{{ value | number: "0.2-2" : locale }} g</span>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [name]="'purchase-order.summary.datatable.columns.weight-account-prediction' | translate"
      [prop]="'weightAccountPrediction'"
      [resizeable]="false"
      [cellClass]="'weight-account-prediction ellipsis-counter'"
      [draggable]="false"
      [summaryTemplate]="weightAccountPredictionSummary"
    >
      <ng-template
        let-weightAccountPrediction="row.weightAccountPrediction"
        let-purchaseType="row.purchaseType"
        let-row="row"
        ngx-datatable-cell-template
      >
        <ng-container *ngIf="purchaseType === 'WITH_METAL_ACCOUNT' && weightAccountPrediction">
          <p class="ellipsis composition">{{ stringifyMetalWeightList(weightAccountPrediction) }}</p>
          <div *ngIf="weightAccountPrediction.length" title="{{ row.counter }}">
            <div #compositionCounter>
              <ui-counter [number]="weightAccountPrediction.length"></ui-counter>
            </div>
            <ui-tooltip maxWidth="300px" [element]="compositionCounter">
              <span style="font-weight: normal !important; white-space: break-spaces">{{
                stringifyMetalWeightList(weightAccountPrediction)
              }}</span>
            </ui-tooltip>
          </div>
        </ng-container>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer>
      <ng-template
        ngx-datatable-footer-template
        let-rowCount="rowCount"
        let-pageSize="pageSize"
        let-selectedCount="selectedCount"
        let-curPage="curPage"
        let-offset="offset"
        let-isVisible="isVisible"
      >
        <datatable-pager
          [pagerLeftArrowIcon]="'fas fa-chevron-left'"
          [pagerRightArrowIcon]="'fas fa-chevron-right'"
          [pagerNextIcon]="'fas fa-chevron-double-right'"
          [pagerPreviousIcon]="'fas fa-chevron-double-left'"
          [page]="curPage"
          [size]="pageSize"
          [count]="rowCount"
          [hidden]="!(rowCount / pageSize > 1)"
          (change)="table.onFooterPage($event)"
        >
        </datatable-pager>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>

  <ng-template #totalSummary let-row="row" let-value="value">
    <div class="total-summary blue">Total</div>
  </ng-template>

  <ng-template #quantitySummary let-row="row" let-value="value">
    <span class="quantity-summary blue">
      {{ sumQuantity }}
    </span>
    <span *ngIf="multiplePurchaseUnit()" class="warning">
      <span #warningIcon>
        <fa-icon class="status-icon" [icon]="warnIcon"></fa-icon>
      </span>

      <ui-tooltip maxWidth="160px" [element]="warningIcon">
        <span style="font-weight: normal">{{ "purchase-order.summary.datatable.warningMessage" | translate }}</span>
      </ui-tooltip>
    </span>
  </ng-template>

  <ng-template #theoreticalWeightSummary>
    <div class="metal-weight-summary blue">{{ sumTheoreticalWeight | number: "0.2-2" : locale }} g</div>
  </ng-template>

  <ng-template #totalWeightSummary>
    <div class="total-weight-summary blue">{{ sumTotalWeight | number: "0.2-2" : locale }} g</div>
  </ng-template>

  <ng-template #totalGrossPriceSummary>
    <div class="total-gross-price-summary blue">
      {{ sumTotalGrossPrice | formatCurrency: purchaseOrderCurrency.codeISO : "symbol" : "0.2-2" : locale | async }}
    </div>
  </ng-template>

  <ng-template #weightAccountPredictionSummary let-row="row" ngx-datatable-cell-template>
    <ng-container *ngIf="sumWeightAccountPrediction">
      <p class="ma-prediction-summary ellipsis composition">
        {{ stringifyMetalWeightList(sumWeightAccountPrediction) }}
      </p>
      <div *ngIf="sumWeightAccountPrediction.length >= 1" title="{{ row.counter }}">
        <div #compositionCounter>
          <ui-counter [number]="sumWeightAccountPrediction.length"></ui-counter>
        </div>
        <ui-tooltip maxWidth="300px" [element]="compositionCounter">
          <span style="font-weight: normal !important; white-space: break-spaces">{{
            stringifyMetalWeightList(sumWeightAccountPrediction)
          }}</span>
        </ui-tooltip>
      </div>
    </ng-container>
  </ng-template>
</div>
