<ng-container>
  <form class="purchase-order-summary" [formGroup]="summaryForm" novalidate>
    <div class="upper-part">
      <div class="payment-type">
        <ui-list
          label="{{ 'purchase-order.summary.paymentType' | translate }}"
          [options]="this.paymentTypeOptions"
          formControlName="paymentType"
        >
        </ui-list>
      </div>

      <div class="delivery-delay">
        <ui-input-text
          type="text"
          label="{{ 'purchase-order.summary.deliveryDelay' | translate }}"
          suffix=" {{ 'unit.day' | translate }}"
          maskPattern="separator.0"
          formControlName="deliveryDelay"
          [status]="summaryForm.get('deliveryDelay') | formStatus"
          [errorMessage]="summaryForm.get('deliveryDelay') | formErrorMessage | translate"
        >
        </ui-input-text>
      </div>
      <div class="forwarding-person-name">
        <ui-list
          label="{{ 'purchase-order.summary.forwardingPersonName' | translate }}"
          [options]="this.forwardingAgentOptions"
          [search]="true"
          formControlName="forwardingPersonName"
          [status]="summaryForm.get('forwardingPersonName') | formStatus"
          [errorMessage]="summaryForm.get('forwardingPersonName') | formErrorMessage | translate"
        >
        </ui-list>
      </div>
      <div class="down-payment-price">
        <ui-input-text
          type="text"
          [maskPattern]="decimalDigit"
          [thousandSeparator]="' '"
          [suffix]="' ' + purchaseOrderCurrency?.symbol"
          label="{{ 'purchase-order.summary.downPaymentPrice' | translate }}"
          formControlName="downPaymentPrice"
          [status]="summaryForm.get('downPaymentPrice') | formStatus"
          [errorMessage]="summaryForm.get('downPaymentPrice') | formErrorMessage | translate: { digit: HIGH_INTEGER }"
        >
        </ui-input-text>
      </div>
    </div>

    <div class="lower-part">
      <div class="table" *ngIf="orderSupplier">
        <app-purchase-order-summary-list [purchaseOrder]="editedPurchaseOrder" [orderSupplier]="orderSupplier">
        </app-purchase-order-summary-list>
      </div>

      <div class="computing">
        <div *ngIf="sumGrossPrice" class="ht price">
          <div class="ht-label">{{ "purchase-order.summary.gross-price" | translate }}</div>
          <div class="ht-value">
            {{ sumGrossPrice | formatCurrency: purchaseOrderCurrency.codeISO : "symbol" : "0.2-2" : locale | async }}
          </div>
        </div>

        <div class="hbjoat-tax price">
          <div class="hbjoat-tax-label">{{ "purchase-order.summary.hbjoatTaxPrice" | translate }}</div>
          <div class="field">
            <ui-input-text
              type="text"
              [maskPattern]="decimalDigit"
              [thousandSeparator]="' '"
              [suffix]="' ' + purchaseOrderCurrency?.symbol"
              formControlName="hbjoatTaxPrice"
              [status]="summaryForm.get('hbjoatTaxPrice') | formStatus"
              [errorMessage]="summaryForm.get('hbjoatTaxPrice') | formErrorMessage | translate: { digit: HIGH_INTEGER }"
            >
            </ui-input-text>
          </div>
        </div>

        <div class="shipping-fee price">
          <div class="shipping-fee-label">{{ "purchase-order.summary.shippingFeePrice" | translate }}</div>
          <div class="field">
            <ui-input-text
              type="text"
              [maskPattern]="decimalDigit"
              [thousandSeparator]="' '"
              [suffix]="' ' + purchaseOrderCurrency?.symbol"
              formControlName="shippingFeePrice"
              [status]="summaryForm.get('shippingFeePrice') | formStatus"
              [errorMessage]="
                summaryForm.get('shippingFeePrice') | formErrorMessage | translate: { digit: HIGH_INTEGER }
              "
            >
            </ui-input-text>
          </div>
        </div>

        <div class="extra-price price">
          <div class="extra-price-label">{{ "purchase-order.summary.extraPrice" | translate }}</div>
          <div class="field">
            <ui-input-text
              type="text"
              [maskPattern]="decimalDigit"
              [thousandSeparator]="' '"
              [suffix]="' ' + purchaseOrderCurrency?.symbol"
              formControlName="extraPrice"
              [status]="summaryForm.get('extraPrice') | formStatus"
              [errorMessage]="summaryForm.get('extraPrice') | formErrorMessage | translate: { digit: HIGH_INTEGER }"
            >
            </ui-input-text>
          </div>
        </div>

        <div class="tax price">
          <div class="tax-label">{{ "purchase-order.summary.taxPrice" | translate }}</div>
          <div class="field">
            <ui-input-text
              type="text"
              [maskPattern]="decimalDigit"
              [thousandSeparator]="' '"
              [suffix]="' ' + purchaseOrderCurrency?.symbol"
              formControlName="taxPrice"
              [status]="summaryForm.get('taxPrice') | formStatus"
              [errorMessage]="summaryForm.get('taxPrice') | formErrorMessage | translate: { digit: HIGH_INTEGER }"
            >
            </ui-input-text>
          </div>
        </div>

        <div *ngIf="totalPrice" class="tt price">
          <div class="tt-label">{{ "purchase-order.summary.total-price" | translate }}</div>
          <div class="tt-value">
            {{ totalPrice | formatCurrency: purchaseOrderCurrency.codeISO : "symbol" : "0.2-2" : locale | async }}
          </div>
        </div>

        <div class="customs-fee price">
          <div class="customs-fee-label">{{ "purchase-order.summary.customsFeePrice" | translate }}</div>
          <div class="field">
            <ui-input-text
              type="text"
              [maskPattern]="decimalDigit"
              [thousandSeparator]="' '"
              [suffix]="' ' + purchaseOrderCurrency?.symbol"
              formControlName="customsFeePrice"
              [status]="summaryForm.get('customsFeePrice') | formStatus"
              [errorMessage]="
                summaryForm.get('customsFeePrice') | formErrorMessage | translate: { digit: HIGH_INTEGER }
              "
            >
            </ui-input-text>
          </div>
        </div>

        <div class="forwarding price">
          <div class="forwarding-label">{{ "purchase-order.summary.forwardingPrice" | translate }}</div>
          <div class="field">
            <ui-input-text
              type="text"
              [maskPattern]="decimalDigit"
              [thousandSeparator]="' '"
              [suffix]="' ' + purchaseOrderCurrency?.symbol"
              formControlName="forwardingPrice"
              [status]="summaryForm.get('forwardingPrice') | formStatus"
              [errorMessage]="
                summaryForm.get('forwardingPrice') | formErrorMessage | translate: { digit: HIGH_INTEGER }
              "
            >
            </ui-input-text>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>

<ui-popup
  *ngIf="confirmationPopupVisible"
  title="{{ 'purchase-order.summary.confirmation-popup.title' | translate }}"
  [cbIcon]="null"
>
  <div content>
    <p>{{ "purchase-order.summary.confirmation-popup.question-line1" | translate }}</p>
    <p>{{ "purchase-order.summary.confirmation-popup.question-line2" | translate }}</p>
  </div>
  <div footer>
    <div class="align-buttons">
      <div class="button-popup-validate">
        <div class="button">
          <ui-button
            text="{{ 'purchase-order.summary.confirmation-popup.yes' | translate }}"
            [primary]="true"
            type="save"
            (click)="validateConfirmationPopup()"
          ></ui-button>
        </div>
      </div>
      <div class="button-popup-cancel">
        <div class="button second-button">
          <ui-button
            text="{{ 'purchase-order.summary.confirmation-popup.no' | translate }}"
            type="cancel"
            (click)="closeConfirmationPopup()"
          ></ui-button>
        </div>
      </div>
    </div>
  </div>
</ui-popup>
