<ng-container>
  <div class="main-container">
    <div class="title">
      <ui-title titleText="{{ 'pricing-groups-list.title' | translate }}"></ui-title>
    </div>
    <div class="button">
      <ui-button
        text="{{ 'global.new' | translate }}"
        type="add"
        [primary]="true"
        *hasPermission="['PRICING_GROUP_CREATE']"
        (click)="onNewEntityClick()"
      ></ui-button>
    </div>
    <div class="pricing-groups-table">
      <ui-container>
        <div [formGroup]="tableControl" class="frozen-right">
          <ngx-datatable
            #table
            [rows]="rows"
            [trackByProp]="'id'"
            class="material"
            columnMode="force"
            headerHeight="auto"
            footerHeight="50"
            rowHeight="40"
            scrollbarH="true"
            [limit]="15"
            (activate)="onTableActivate($event)"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down',
            }"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
            [sorts]="sorts"
            (sort)="changeSortSettings($event.column.prop, $event.newValue)"
          >
            <ngx-datatable-column
              name="{{ 'pricing-groups-list.datatable.columns.name' | translate }}"
              prop="name"
              [resizeable]="false"
            >
            </ngx-datatable-column>

            <ng-container *ngFor="let itemCategory of allItemCategoryList">
              <ngx-datatable-column
                [ngSwitch]="itemCategory.name"
                [name]="itemCategory.name"
                [prop]="itemCategory.name"
                [resizeable]="false"
                headerClass="align-right"
                cellClass="align-right"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
                  <div class="margin">{{ (row[itemCategory.name] | number: "0.2-2" : codeLanguage) + " %" }}</div>
                </ng-template>
              </ngx-datatable-column>
            </ng-container>

            <ngx-datatable-column
              name="{{ 'pricing-groups-list.datatable.columns.activated' | translate }}"
              prop="activated"
              headerClass="center-align"
              cellClass="center-align frozen-style"
              [resizeable]="false"
              [frozenRight]="true"
            >
              <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
                <div class="green-zone" (click)="$event.stopPropagation()">
                  <ui-switch
                    customClass="no-margin"
                    (change)="changeEntityActivation(id)"
                    [formControl]="tableControl.get('activated_' + id)"
                  >
                  </ui-switch>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count" *ngIf="rowCount">
                  {{
                    rowCount
                      | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                      | translate: { rowCount: rowCount }
                  }}
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="table.onFooterPage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </ui-container>
    </div>

    <div class="popup-container">
      <form class="pricing-group-form" [formGroup]="popupForm" novalidate *ngIf="popupVisible">
        <ui-popup title="{{ popupTitle | translate }}" (close)="canClosePopup()">
          <div content>
            <div class="grid-container label">
              <div class="grid-item">
                <ui-input-text
                  type="text"
                  label="{{ 'pricing-groups-list.datatable.columns.name' | translate }}"
                  formControlName="name"
                  [status]="popupForm.get('name') | formStatus"
                  [errorMessage]="popupForm.get('name') | formErrorMessage | translate"
                  mandatory="true"
                  infoLabel="{{ 'global.unique' | translate }}"
                >
                </ui-input-text>
              </div>
            </div>

            <div class="item-category-subtitle">
              <label>
                <b>{{ "pricing-groups-list.popup.subtitle" | translate }} : </b>
              </label>
            </div>

            <div class="grid-container categories">
              <div class="grid-item" *ngFor="let itemCategory of allItemCategoryList">
                <ui-input-text
                  type="text"
                  [maskPattern]="decimalDigit"
                  [suffix]="' %'"
                  label="{{ itemCategory.name }}"
                  [formControlName]="itemCategory.name"
                  [status]="popupForm.get(itemCategory.name) | formStatus"
                  [errorMessage]="popupForm.get(itemCategory.name) | formErrorMessage | translate"
                  mandatory="true"
                >
                </ui-input-text>
              </div>
            </div>
          </div>
          <div footer>
            <div class="align-buttons">
              <div class="button-popup-validate">
                <div class="button">
                  <ui-button
                    text="{{ 'global.validate' | translate }}"
                    type="save"
                    [primary]="true"
                    (click)="submitEntity()"
                  ></ui-button>
                </div>
              </div>
            </div>
          </div>
        </ui-popup>
      </form>
    </div>
  </div>
</ng-container>
