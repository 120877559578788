import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IconDefinition, faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  JobType,
  CurrencyService,
  DocumentService,
  FormErrorService,
  CommercialModality,
  Currency,
  Address,
  BaseEntity,
  Supplier,
  SupplierService,
} from "center-services";
import { DottedPropertyUtil, SessionPagination, SubscriptionService } from "fugu-common";
import { ColorPickerComponent, MessageService } from "fugu-components";
import { ComponentDirty, PopupSaveUtil, ErrorUtil } from "generic-pages";
import { SupplierBrandsComponent } from "../supplier-brands/supplier-brands.component";
import { SupplierStoresComponent } from "../supplier-stores/supplier-stores.component";

@Component({
  selector: "app-supplier-form",
  templateUrl: "./supplier-form.component.html",
  styleUrls: ["./supplier-form.component.scss"],
  providers: [SubscriptionService],
})
export class SupplierFormComponent implements OnInit, ComponentDirty {
  @ViewChild("tabHandler") tabHandler: any;
  @ViewChild("generalDatas") generalDatas: any;
  @ViewChild("supplierBrands") supplierBrands: any;
  @ViewChild("supplierStores") supplierStores: any;
  @ViewChild("metalAccountActivation") metalAccountActivation: any;
  @ViewChild("commercialModality") commercialModality: any;

  public title: string;
  public subTitle: string;
  public unsavedFile: any;
  public editedSupplier: Supplier;
  public unsavedSupplier: Supplier;
  public updatedSupplier: Supplier;

  public shouldClose: boolean = false;

  public modifiedFile: any;

  public jobTypeExternalContact: JobType = JobType.EXTERNAL_CONTACT;
  public faChevronLeft: IconDefinition = faChevronLeft;
  public hasError: boolean = false;

  public STORE_LIST_ID: string = "app-supplier-stores.stores-table";
  public BRAND_LIST_ID: string = "app-supplier-brands.brands-table";

  // eslint-disable-next-line no-magic-numbers
  protected readonly PAYLOAD_TOO_LARGE: number = 413;

  constructor(
    private supplierService: SupplierService,
    private currencyService: CurrencyService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private documentService: DocumentService,
    private formErrorService: FormErrorService,
    private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    const defaultCommercialModality = new CommercialModality({
      id: null,
      vatActivated: true,
      discountRate: null,
      paymentType: null,
      comment: null,
      minOrderPrice: null,
      marginCoef: null,
      deliveryDelay: null,
      quickPaymentDiscountType: null,
      quickPaymentPeriod: null,
      quickPaymentDiscount: null,
      maxPaymentType: null,
      maxPaymentPeriod: null,
      importation: null,
      currencyId: null,
      forwardingPersonId: null,
      forwardingPriceCurrencyId: null,
      forwardingPrice: null,
      downPaymentPercent: null,
      shippingFeeType: null,
      shippingFeeRanges: [],
      happyNewYearDiscounts: [],
    });

    const supplierId = this.route.snapshot.params.id;
    this.title = supplierId ? "supplier.title.update" : "supplier.title.new";
    if (supplierId) {
      this.subscriptionService.subs.push(
        this.supplierService.get(supplierId).subscribe(
          (supplier: Supplier) => {
            if (!supplier.commercialModality) {
              supplier.commercialModality = defaultCommercialModality;
            }

            this.editedSupplier = supplier;
            this.updatedSupplier = new Supplier(this.editedSupplier);
            this.subTitle = this.getSubtitle(supplier);
          },
          () => {
            this.router.navigateByUrl("/suppliers-list");
          }
        )
      );
    } else {
      this.subscriptionService.subs.push(
        this.currencyService.getDefault().subscribe((currency: Currency) => {
          this.updatedSupplier = new Supplier({
            name: null,
            reference: null,
            email: null,
            phone: null,
            website: null,
            intranet: null,
            codeRCS: null,
            accountingNumber: null,
            accountingNumberBis: null,
            codeVAT: null,
            comment: null,
            logoId: null,
            archived: false,
            whiteLabel: false,
            address: new Address({ byDefault: true }),
            color: ColorPickerComponent.DEFAULT_COLOR,
            manageRemainder: false,
            tagIds: [],
            commercialModality: defaultCommercialModality,
            currencyId: null,
          });

          this.updatedSupplier.currencyId = currency.id;

          this.editedSupplier = new Supplier(this.updatedSupplier);
        })
      );
    }
  }

  isDirty(): boolean {
    if (!this.updatedSupplier) {
      return false;
    }

    let initialFile = null;
    let newFile = null;

    if (this.generalDatas) {
      initialFile = this.generalDatas.previousFile;
      newFile = this.generalDatas.selectedFile;
      this.generalDatas.applyModifications();
    }
    if (
      (this.unsavedSupplier && !this.unsavedSupplier.equals(this.updatedSupplier)) ||
      (this.unsavedFile && newFile && this.unsavedFile !== newFile)
    ) {
      this.shouldClose = false;
    }

    if (
      ((this.editedSupplier && !this.editedSupplier.equals(this.updatedSupplier)) || initialFile !== newFile) &&
      !this.shouldClose
    ) {
      this.recordDatas();
      return true;
    } else if (!this.editedSupplier && !this.shouldClose) {
      this.recordDatas();
      return true;
    }

    this.unsavedSupplier = null;
    this.shouldClose = false;
    this.unsavedFile = null;
    return false;
  }

  recordDatas(): void {
    if (this.generalDatas) {
      this.unsavedFile = this.generalDatas.selectedFile;
    }
    this.unsavedSupplier = new Supplier(this.updatedSupplier);
    this.shouldClose = true;
  }

  getComponentError(entities: BaseEntity[], componentName: string): void {
    const defaultElements = entities.filter((entity: any) => entity.byDefault);
    let componentError = null;

    if (!defaultElements.length) {
      componentError = "no-default";
    } else if (defaultElements.length > 1) {
      componentError = "multiple-default";
    } else if (defaultElements[0].archived) {
      componentError = "archived-default";
    } else {
      return;
    }
    this.hasError = true;
    const title = this.translateService.instant("message.title.form-errors");
    const content = this.translateService.instant(`supplier.errors.${componentName}.${componentError}`);
    this.messageService.error(content, { title });
  }

  onTabClick(tab: any): void {
    if (this.supplierBrands) {
      this.supplierBrands.savePaginationToSession();
    }
    if (this.supplierStores) {
      this.supplierStores.savePaginationToSession();
    }
    if (this.metalAccountActivation) {
      this.metalAccountActivation.savePaginationToSession();
    }
    if (this.updatedSupplier) {
      this.submitSupplier(tab);
    }
  }

  catchEvent(source: string, object: any): void {
    PopupSaveUtil.manageEvent(
      this.editedSupplier,
      this.updatedSupplier,
      object,
      source,
      this.saveSupplierElement.bind(this)
    );
  }

  submitSupplier(nextTab: any = null): void {
    this.hasError = false;
    let fileUpdated = false;

    // handle generalDatas tab
    if (this.generalDatas) {
      if (!this.generalDatas.updateSupplier()) {
        this.formErrorService.handleFormError();
        return;
      }

      // handle file
      this.modifiedFile = this.generalDatas.getFile();
      fileUpdated = this.generalDatas.hasFileChanged();
    }

    if (this.updatedSupplier.deliveryAddresses && this.updatedSupplier.deliveryAddresses.length > 0) {
      this.getComponentError(this.updatedSupplier.deliveryAddresses, "delivery-addresses");
    }
    if (this.updatedSupplier.banks && this.updatedSupplier.banks.length > 0) {
      this.getComponentError(this.updatedSupplier.banks, "banks");
    }

    if (this.hasError) {
      return;
    }

    if (this.commercialModality) {
      if (!this.commercialModality.isValid()) {
        this.formErrorService.handleFormError();
        return;
      }
    }

    if (this.editedSupplier) {
      // if nothing has changed, no need to call the API to save the supplier
      if (this.editedSupplier.equals(this.updatedSupplier) && !fileUpdated) {
        if (nextTab && this.tabHandler) {
          this.tabHandler.changeTab(nextTab);
        }
        return;
      }
    }

    this.callApi(nextTab);
  }

  callApi(nextTab: any = null): void {
    if (!this.updatedSupplier) {
      return;
    }

    // when form is valid, create or update supplier
    const action = this.editedSupplier && this.editedSupplier.id ? "update" : "create";
    this.saveSupplier(action, nextTab);
  }

  saveSupplier(action: string, nextTab: any): void {
    this.subscriptionService.subs.push(
      this.supplierService[action].call(this.supplierService, this.updatedSupplier).subscribe(
        responseSupplier => {
          this.saveLogo(nextTab, responseSupplier);
        },
        error => {
          this.hasError = true;
          this.handleApiError(error);
        }
      )
    );
  }

  saveSupplierElement(action: string | number, propName: string): void {
    return this.supplierService[action]
      .call(this.supplierService, this.editedSupplier)
      .toPromise()
      .then(responseSupplier => {
        const title = this.translateService.instant("message.title.save-success");
        const content = this.translateService.instant("message.content.save-success");
        this.messageService.success(content, { title });
        return DottedPropertyUtil.getDottedProperty(responseSupplier, propName);
      })
      .catch(error => {
        this.hasError = true;
        this.handleApiError(error);
        throw error;
      });
  }

  saveLogo(nextTab: any, responseSupplier: Supplier): void {
    if (!this.generalDatas || !this.generalDatas.hasFileChanged()) {
      this.finishSave(nextTab, responseSupplier);
      return;
    }

    const formData = new FormData();
    formData.set("file", this.modifiedFile);
    this.subscriptionService.subs.push(
      this.documentService.uploadFile(formData, responseSupplier.logoId).subscribe(
        () => {
          this.generalDatas.previousFile = this.generalDatas.selectedFile;
          this.finishSave(nextTab, responseSupplier);
        },
        (error: HttpErrorResponse) => {
          this.hasError = true;
          const res =
            error.status === this.PAYLOAD_TOO_LARGE
              ? this.translateService.instant("global.form.error.payload-too-large")
              : this.translateService.instant("supplier.errors.upload-logo");
          const title = this.translateService.instant("message.title.api-errors");
          this.messageService.warn(res, { title });
          // no blocking error when the file can't be uploaded
          this.finishSave(nextTab, responseSupplier);
        }
      )
    );
  }

  finishSave(nextTab: any, responseSupplier: Supplier): void {
    this.editedSupplier = responseSupplier;
    this.updatedSupplier = new Supplier(this.editedSupplier);
    if (this.route.snapshot.params.id) {
      this.subTitle = this.getSubtitle(responseSupplier);
    }

    const title = this.translateService.instant("message.title.save-success");
    const content = this.translateService.instant("message.content.save-success");
    this.messageService.success(content, { title });
    if (nextTab) {
      this.tabHandler.changeTab(nextTab);
    }
  }

  backToSupplierList(): void {
    SessionPagination.clear(SupplierBrandsComponent.LIST_ID);
    SessionPagination.clear(SupplierStoresComponent.LIST_ID);

    this.router.navigateByUrl("/suppliers-list");
  }

  handleApiError(error: any): void {
    const attributeTranslations = {
      name: "supplier.general-datas.name",
      reference: "supplier.general-datas.reference",
    };

    const title = this.translateService.instant("message.title.form-errors");

    const result = ErrorUtil.getTranslationKey(error.error, attributeTranslations, this.translateService);
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }

  getSubtitle(supplier: Supplier): string {
    const subtitleList = [];
    if (supplier.reference) {
      subtitleList.push(supplier.reference);
    }
    subtitleList.push(supplier.name);

    return subtitleList.join(" - ");
  }
}
