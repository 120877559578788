import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { IconDefinition, faClone, faPen, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { DatatableComponent } from "@siemens/ngx-datatable";
import { PurchaseModalityService } from "app/service/purchase-modality.service";
import {
  PurchaseOrder,
  Currency,
  PurchaseOrderLine,
  Supplier,
  Brand,
  Uom,
  AbstractItem,
  Light,
  SizeCategory,
  LightService,
  RetailItemService,
  SizeCategoryService,
  UomService,
  CaraUserService,
  CurrencyService,
  SupplierService,
  BrandService,
  PurchaseOrderStatus,
  PurchaseModality,
  StandardItem,
  CaraUser,
  PaginatedList,
  Pagination,
  PurchaseType,
  TheoreticalMetalWeight,
  PurchaseOrderUtil,
} from "center-services";
import Decimal from "decimal.js";
import {
  CommonValidatorsUtil,
  FilterValue,
  Filterer,
  DayjsUtil,
  Option,
  PaginableComponent,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
  RoundingUtil,
} from "fugu-common";
import { MenuAction, MessageService } from "fugu-components";
import { FilteredTableListComponent, PrecisionUtil } from "generic-pages";
import { Observable, combineLatest, merge, of } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-purchase-order-lines",
  templateUrl: "./purchase-order-lines.component.html",
  styleUrls: ["./purchase-order-lines.component.scss"],
  providers: [SubscriptionService],
})
export class PurchaseOrderLinesComponent
  extends FilteredTableListComponent
  implements OnInit, AfterViewChecked, PaginableComponent {
  public static LIST_ID: string = "app-purchase-order-lines.purchase-order-lines-table";

  @Input() editedPurchaseOrder: PurchaseOrder;
  @Output() editedPurchaseOrderChange: EventEmitter<PurchaseOrder> = new EventEmitter<PurchaseOrder>();

  @ViewChild("table") table: DatatableComponent;
  @ViewChild("purchaseModalitySelection") purchaseModalitySelection: any;

  faPen: IconDefinition = faPen;
  faTrashAlt: IconDefinition = faTrashAlt;
  faClone: IconDefinition = faClone;

  public tableControl: UntypedFormGroup;
  public rows: any[];
  public allRows: any[] = [];
  public filteredRows: any[] = [];
  public purchaseOrderCurrency: Currency;
  public selectedPurchaseOrderLine: PurchaseOrderLine;
  public engravingPopupVisible: boolean = false;
  public selectionPopupVisible: boolean = false;
  public duplicationPopupVisible: boolean = false;
  public selectedMaxEngravingLength: number;

  public purchaseModalityIdList: number[] = [];
  public duplicatePOL: PurchaseOrderLine;
  public supplierList: Supplier[] = [];
  public brandList: Brand[] = [];
  public purchaseUnitIdList: Set<number> = new Set();
  public purchaseUnitList: Uom[] = [];
  public retailItemList: AbstractItem[] = [];
  public sizeValueOptions: any = {};

  public popInSizeValueOptions: Option[] = [];

  public storeOptions: Option[];
  public storeList: Light[];
  public sizeCategoryList: SizeCategory[];

  public menuActions: MenuAction[] = [];
  public locale: string;
  public dateFormat: string;
  public sorts: any[] = [{ prop: "lineNumber", dir: "asc" }];
  public activeFilters: SearchFilter[] = [];
  public filterer: Filterer;
  public purchaseOrderSupplier: Supplier = null;
  public readonly decimalDigit: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  public HIGH_INTEGER: PrecisionUtil = PrecisionUtil.HIGH_INTEGER;
  public pager: Pagination = new Pagination({
    number: 0,
    size: 100,
  });
  protected readonly MAX_PERCENT: number = 100;
  private initObservables: Observable<any>[] = [];
  private sessionPagination: SessionPagination;
  // manage menu actions
  private readonly DUPLICATE_ACTION_ID: number = 0;
  private readonly REMOVE_ACTION_ID: number = 1;

  constructor(
    private lightService: LightService,
    private retailItemService: RetailItemService,
    private sizeCategoryService: SizeCategoryService,
    private uomService: UomService,
    protected userService: CaraUserService,
    private currencyService: CurrencyService,
    private supplierService: SupplierService,
    private brandService: BrandService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    private fb: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private purchaseModalityService: PurchaseModalityService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.sessionPagination = new SessionPagination(this);
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  savePaginationToSession(): void {
    this.sessionPagination.saveToSession(PurchaseOrderLinesComponent.LIST_ID);
  }

  ngOnInit(): void {
    this.editedPurchaseOrder.lines.forEach((line: PurchaseOrderLine) => {
      // get all the purchase modality ids from each line
      this.purchaseModalityIdList.push(line.purchaseModalityId);
      // get all the purchase unit ids...
      this.purchaseUnitIdList.add(line.purchaseUnitId);
    });
    if (this.editedPurchaseOrder.lines.length === 0) {
      this.selectionPopupVisible = true;
    }

    this.tableControl = new UntypedFormGroup({});
    this.addMenuActions();
    this.subscriptionService.subs.push(
      this.translateService.onLangChange.subscribe(() => {
        this.addMenuActions();
      })
    );
    // initiate all the fetches for necessary data
    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    } else {
      this.initObservables.push(this.fetchConnectedUserDetails());
    }
    this.initObservables.push(this.fetchStores());
    this.initObservables.push(this.fetchPOSupplier());
    this.initObservables.push(this.fetchSizeCategories());
    if (this.purchaseModalityIdList.length > 0) {
      this.initObservables.push(this.fetchItems(this.purchaseModalityIdList));
    }
    this.initObservables.push(this.fetchPurchaseOrderCurrency(this.editedPurchaseOrder.currencyId));
    this.purchaseUnitIdList.forEach(id => {
      this.initObservables.push(this.fetchPurchaseUnits(id));
    });

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.rows = [];
        this.editedPurchaseOrder.lines.forEach((line: PurchaseOrderLine) => {
          this.addRow(line);
        });
        this.allRows = [...this.rows];
        this.initFilters();
        this.sessionPagination.loadFromSession(PurchaseOrderLinesComponent.LIST_ID);
        this.applyFilters();
        if (
          this.editedPurchaseOrder.status === PurchaseOrderStatus.CONFIRMED ||
          this.editedPurchaseOrder.status === PurchaseOrderStatus.DRAFT
        ) {
          this.checkForChanges();
        }
      })
    );
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  getSupplier(supplierId: number): Supplier {
    return this.supplierList.find((supplier: Supplier) => supplier.id === supplierId);
  }

  computeNewLineMetalPrice(pm: PurchaseModality, item: AbstractItem, line: PurchaseOrderLine): void {
    if (item instanceof StandardItem) {
      this.subscriptionService.subs.push(
        this.purchaseModalityService
          .getTheoreticalMetalWeight(
            item.theoreticalWeight,
            item.composition,
            this.purchaseOrderSupplier ? this.purchaseOrderSupplier.alloys : []
          )
          .subscribe(itemTheoreticalWeights => {
            line.metalPrice = this.purchaseModalityService.computeMetalPrice(pm, itemTheoreticalWeights);
            this.tableControl.get(`${line.lineNumber}.metalPrice`).patchValue(line.metalPrice);
          })
      );
    }
  }

  checkForChanges(): void {
    for (const line of this.editedPurchaseOrder.lines) {
      const retailItem = this.getItem(line.purchaseModalityId);
      const pm = retailItem.purchaseModalities?.find(
        purchaseModality => purchaseModality.id === line.purchaseModalityId
      );

      // handle brand
      if (retailItem.brandName !== line.brandName) {
        this.sendNotificationMessage();
        break;
      }

      // handle engraving boolean (if engravingLength, means the item is engraveable)
      if (retailItem instanceof StandardItem && !retailItem.engravingLength && line.engraving) {
        this.sendNotificationMessage();
        break;
      }

      // handle engraving length
      if (
        retailItem instanceof StandardItem &&
        retailItem.engravingLength !== null &&
        line.engravingText &&
        line.engravingText.length > retailItem.engravingLength
      ) {
        this.sendNotificationMessage();
        break;
      }

      // handle metrics
      if (this.sizeValueOptions[line.lineNumber]) {
        const sizeValueArray = this.sizeValueOptions[line.lineNumber].map(opt => opt.label);
        if (line.sizeValue && !sizeValueArray.includes(line.sizeValue)) {
          this.sendNotificationMessage();
          break;
        }
      }

      // handle purchase unit
      if (pm.purchaseUnitId !== line.purchaseUnitId) {
        this.sendNotificationMessage();
        break;
      }
    }
  }

  sendNotificationMessage(): void {
    const title = this.translateService.instant("purchase-order.lines.datatable.message.warning-title");
    const content = this.translateService.instant("purchase-order.lines.datatable.message.modifications");
    this.messageService.warn(content, { title });
  }

  addMenuActions(): void {
    this.menuActions = [];
    this.menuActions.push(
      new MenuAction(
        this.DUPLICATE_ACTION_ID,
        this.translateService.instant("purchase-order.lines.datatable.actions.duplicate"),
        faClone
      )
    );
    this.menuActions.push(
      new MenuAction(
        this.REMOVE_ACTION_ID,
        this.translateService.instant("purchase-order.lines.datatable.actions.remove"),
        faTrashAlt
      )
    );
  }

  manageActions(actionId: number, row: any): void {
    switch (actionId) {
      case this.DUPLICATE_ACTION_ID:
        this.openDuplicatationPopup(row);
        break;
      case this.REMOVE_ACTION_ID:
        this.removeLine(row);
        break;
      default:
        console.error(`Don't know how to handle action : ${actionId}`);
        break;
    }
  }

  removeLine(selectedRow: any): void {
    this.allRows.forEach(row => {
      if (selectedRow.lineNumber === row.lineNumber) {
        this.allRows.splice(this.allRows.indexOf(row), 1);
      }
    });

    if (this.editedPurchaseOrder.status === PurchaseOrderStatus.DRAFT) {
      this.allRows.forEach(row => {
        if (row.lineNumber > selectedRow.lineNumber) {
          row.lineNumber -= 1;
        }
      });
    }

    this.rows.forEach(row => {
      if (selectedRow.lineNumber === row.lineNumber) {
        this.rows.splice(this.rows.indexOf(row), 1);
      }
    });

    this.rows = [...this.rows];

    // remove sizeValueOptions
    delete this.sizeValueOptions[selectedRow.lineNumber];
    if (this.editedPurchaseOrder.status === PurchaseOrderStatus.DRAFT) {
      for (const [key, value] of Object.entries(this.sizeValueOptions)) {
        if (+key > selectedRow.lineNumber) {
          this.sizeValueOptions[(+key - 1).toString()] = value;
          delete this.sizeValueOptions[key];
        }
      }
    }

    // remove form control
    this.tableControl.removeControl(selectedRow.lineNumber.toString());
    if (this.editedPurchaseOrder.status === PurchaseOrderStatus.DRAFT) {
      Object.keys(this.tableControl.controls).forEach(key => {
        if (+key > selectedRow.lineNumber) {
          const form = this.tableControl.get(key);
          this.tableControl.setControl((+key - 1).toString(), form);
          this.tableControl.removeControl(key);
        }
      });
    }

    // remove purchaseOrderLine
    let index = this.editedPurchaseOrder.lines.findIndex(line => line.lineNumber === selectedRow.lineNumber);
    this.editedPurchaseOrder.lines.splice(index, 1);

    // reassign line numbers to superior lines
    if (this.editedPurchaseOrder.status === PurchaseOrderStatus.DRAFT) {
      this.editedPurchaseOrder.lines.forEach(line => {
        if (line.lineNumber > selectedRow.lineNumber) {
          line.lineNumber -= 1;
        }
      });
    }

    // remove purchase modality id from ids list
    index = this.purchaseModalityIdList.findIndex(id => id === selectedRow.purchaseModalityId);
    this.purchaseModalityIdList.splice(index, 1);

    this.updateFilters();
  }

  fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.locale = connectedUser.codeLanguage;
        this.dateFormat = connectedUser.dateFormat;
      })
    );
  }

  fetchStores(): Observable<Light[]> {
    return this.lightService.getStores().pipe(
      tap(
        (lightStores: Light[]) => {
          this.storeOptions = lightStores
            .filter(obj => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(obj => new Option(obj.id, obj.name));

          const editedStore = lightStores.find(store => store.id === this.editedPurchaseOrder.deliveryStoreId);

          // for duplicate popup @input
          this.storeList = lightStores.filter(obj => !obj.archived).sort((a, b) => a.name.localeCompare(b.name));

          if (editedStore && editedStore.archived) {
            this.storeOptions.push(new Option(editedStore.id, editedStore.name));
            const title = this.translateService.instant("message.title.api-errors");
            const content = this.translateService.instant("purchase-order.header.message.store-archived");
            this.messageService.warn(content, { title });
          }
        },
        error => {
          this.sendErrorAlert("stores-list.errors.get-stores", error.message);
        }
      )
    );
  }

  fetchItems(purchaseModalityIds: any[]): Observable<PaginatedList<AbstractItem>> {
    const pager = new Pagination({
      size: purchaseModalityIds.length,
      number: 0,
    });
    const filters = new SearchFilter("purchaseModalities.id", SearchFilterOperator.IN, purchaseModalityIds);

    return this.retailItemService.getAll(pager, [], [filters]).pipe(
      tap(
        (result: PaginatedList<AbstractItem>) => {
          // remove duplicates from item list
          const ids = result.data.map(obj => obj.id);
          this.retailItemList = result.data.filter(({ id }, index) => !ids.includes(id, index + 1));
        },
        error => {
          this.sendErrorAlert("retail-item-list.errors.get-retail-items", error.message);
        }
      )
    );
  }

  fetchNewItems(pmToAddIdList: any[]): void {
    const pager = new Pagination({
      size: pmToAddIdList.length,
      number: 0,
    });
    const filters = new SearchFilter("purchaseModalities.id", SearchFilterOperator.IN, pmToAddIdList);

    this.subscriptionService.subs.push(
      this.retailItemService.getAll(pager, [], [filters]).subscribe(
        (result: PaginatedList<AbstractItem>) => {
          result.data.forEach(item => {
            if (!this.retailItemList.includes(item)) {
              this.retailItemList.push(item);
            }
          });
          pmToAddIdList.forEach(id => this.addNewLine(id));
        },
        error => {
          this.sendErrorAlert("retail-item-list.errors.get-retail-items", error.message);
        }
      )
    );
  }

  getNewLineSizeValue(sizeCategory: SizeCategory): string {
    if (sizeCategory) {
      const byDefault = sizeCategory.elements.find(elem => elem.byDefault);
      if (byDefault) {
        return this.getSizeValue(byDefault.sizeValueId, sizeCategory.sizeCategoryId);
      }
    }
    return null;
  }

  fetchSizeCategories(): Observable<SizeCategory[]> {
    return this.sizeCategoryService.getAll().pipe(
      tap(
        (sizeCategories: SizeCategory[]) => {
          this.sizeCategoryList = sizeCategories;
        },
        error => {
          this.sendErrorAlert("size-categories-list.errors.get-size-categories", error.message);
        }
      )
    );
  }

  fetchPurchaseUnits(id: number): Observable<Uom> {
    return this.uomService.get(id).pipe(
      tap(
        (uom: Uom) => {
          this.purchaseUnitList.push(uom);
        },
        error => {
          this.sendErrorAlert("uoms-list.errors.get-entities", error.message);
        }
      )
    );
  }

  fetchPurchaseOrderCurrency(id: number): Observable<Currency> {
    return this.currencyService.get(id).pipe(
      tap(
        (currency: Currency) => {
          this.purchaseOrderCurrency = currency;
        },
        error => {
          this.sendErrorAlert("purchase-order.lines.datatable.errors.get-currency", error.message);
        }
      )
    );
  }

  fetchSupplier(id: number): Observable<Supplier> {
    return this.supplierService.get(id).pipe(
      tap(
        (supplier: Supplier) => {
          this.supplierList.push(supplier);
        },
        error => {
          this.sendErrorAlert("suppliers-list.errors.get-suppliers", error.message);
        }
      )
    );
  }

  fetchPOSupplier(): Observable<Supplier> {
    return this.supplierService.get(this.editedPurchaseOrder.supplierId).pipe(
      tap(
        (supplier: Supplier) => {
          this.purchaseOrderSupplier = supplier;
        },
        error => {
          this.sendErrorAlert("suppliers-list.errors.get-suppliers", error.message);
        }
      )
    );
  }

  fetchBrand(id: number): Observable<Brand> {
    return this.brandService.get(id).pipe(
      tap(
        (brand: Brand) => {
          this.brandList.push(brand);
        },
        error => {
          this.sendErrorAlert("brands-list.errors.get-brands", error.message);
        }
      )
    );
  }

  getItem(pmId: number): AbstractItem {
    for (const item of this.retailItemList) {
      if (item.purchaseModalities) {
        for (const pm of item.purchaseModalities) {
          if (pm.id === pmId) {
            return item;
          }
        }
      }
    }
    return null;
  }

  addNewLine(purchaseModalityId: number): void {
    this.rows.sort((a, b) => a.lineNumber - b.lineNumber);

    const item = this.getItem(purchaseModalityId);
    const pm = item.purchaseModalities?.find(purchaseModality => purchaseModality.id === purchaseModalityId);
    const obs: Observable<any>[] = [];

    if (pm.supplierId && !this.supplierList.find(supplier => supplier.id === pm.supplierId)) {
      obs.push(this.fetchSupplier(pm.supplierId));
    }
    if (item.brandId && !this.brandList.find(brand => brand.id === item.brandId)) {
      obs.push(this.fetchBrand(item.brandId));
    }

    if (!this.purchaseUnitIdList.has(pm.purchaseUnitId)) {
      obs.push(this.fetchPurchaseUnits(pm.purchaseUnitId));
    }

    if (obs.length > 0) {
      this.subscriptionService.subs.push(
        combineLatest(obs).subscribe(() => {
          this.createAndAddLine(purchaseModalityId, item, pm);
          this.updateFilters();
        })
      );
    } else {
      this.createAndAddLine(purchaseModalityId, item, pm);
    }
  }

  createAndAddLine(purchaseModalityId: number, item: AbstractItem, pm: PurchaseModality): void {
    const brand = this.brandList.find(elem => elem.id === item.brandId);
    const supplier = this.supplierList.find(elem => elem.id === pm.supplierId);
    const lineLength = this.editedPurchaseOrder.lines.length;

    const line = new PurchaseOrderLine({
      id: null,
      lineNumber: lineLength > 0 ? this.editedPurchaseOrder.lines[lineLength - 1].lineNumber + 1 : 1,
      itemReference: item.reference,
      supplierRef: pm.supplierRef,
      brandName: item.brandName,
      brandRef: brand?.reference,
      purchaseModalityId,
      deliveryStoreId: this.editedPurchaseOrder.deliveryStoreId,
      itemName: item.name,
      sizeValue: this.getNewLineSizeValue(item.sizeCategory),
      quantity: 1,
      purchaseUnitId: pm.purchaseUnitId,
      minQuantity: pm.minQuantity,
      maxQuantity: pm.maxQuantity,
      unitPrice: pm.unitPrice ?? 0,
      unitPricePerWeight: pm.unitPricePerWeight,
      metalPrice: null,
      metalWeight: null,
      metal: null,
      tare: null,
      purchaseType: pm.purchaseType,
      percentDiscount: 0,
      deliveryDate: this.getDeliveryDate(brand, supplier),
      engraving: false,
      engravingText: null,
      engravingFont: null,
      engravingLocation: null,
      comment: null,
      canceled: false,
      supplierTraceabilityNumber: null,
    });
    this.addRowParametersForAddLineFunction(line);

    if (line.purchaseType === PurchaseType.WITH_METAL_PRICE) {
      this.computeNewLineMetalPrice(pm, item, line);
    }
  }

  duplicateAndAddLine(lineToDuplicate: PurchaseOrderLine): void {
    const lineLength = this.editedPurchaseOrder.lines.length;
    const line = new PurchaseOrderLine(lineToDuplicate);

    line.lineNumber = lineLength > 0 ? this.editedPurchaseOrder.lines[lineLength - 1].lineNumber + 1 : 1;
    line.id = null;

    this.addRowParametersForAddLineFunction(line);
  }

  addRowParametersForAddLineFunction(line: PurchaseOrderLine): void {
    this.editedPurchaseOrder.lines.push(line);

    this.addRow(line);
    this.rows = [...this.rows];
    this.allRows = [...this.allRows];
  }

  addRow(line: PurchaseOrderLine): void {
    const itemLine = this.findItem(line);
    const digitValidator: ValidatorFn = CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER);

    const newLine = {
      id: line.id,
      lineNumber: line.lineNumber,
      itemReference: line.itemReference,
      supplierRef: line.supplierRef,
      brandName: line.brandName,
      brandRef: line.brandRef,
      purchaseModalityId: line.purchaseModalityId,
      deliveryStoreId: line.deliveryStoreId,
      itemName: line.itemName,
      sizeValue: line.sizeValue,
      quantity: line.quantity,
      purchaseUnitName: this.getPurchaseUnitName(line.purchaseUnitId),
      minQuantity: line.minQuantity,
      maxQuantity: line.maxQuantity,
      purchaseType: line.purchaseType,
      percentDiscount: line.percentDiscount,
      unitPrice: line.unitPrice ?? 0,
      unitPricePerWeight: line.unitPricePerWeight,
      metalPrice: line.metalPrice,
      discount: null,
      totalGrossPrice: 0,
      deliveryDate: line.deliveryDate,
      engravingLength: itemLine instanceof StandardItem ? itemLine.engravingLength : null,
      engraving: itemLine instanceof StandardItem && !itemLine.engravingLength ? null : line.engraving,
      engravingText: line.engravingText,
      engravingFont: line.engravingFont,
      engravingLocation: line.engravingLocation,
      comment: line.comment,
      duplicated: false,
      supplierTraceabilityNumber: line.supplierTraceabilityNumber,
    };

    this.rows.push(newLine);
    this.allRows.push(newLine);

    if (!this.sizeValueOptions[line.lineNumber]) {
      this.buildSizeValueOptions(itemLine, line);
    }
    const rowForm = this.fb.group({
      deliveryStore: new UntypedFormControl(line.deliveryStoreId, Validators.required),
      supplierTraceabilityNumber: new UntypedFormControl(line.supplierTraceabilityNumber),
      itemName: new UntypedFormControl(line.itemName, Validators.required),
      quantity: new UntypedFormControl(line.quantity, [Validators.required, digitValidator]),
      minQuantity: new UntypedFormControl(line.minQuantity, digitValidator),
      maxQuantity: new UntypedFormControl(line.maxQuantity, digitValidator),
      percentDiscount: new UntypedFormControl(this.getPercentDiscount(itemLine, line), [
        Validators.min(0),
        Validators.max(this.MAX_PERCENT),
      ]),
      deliveryDate: new UntypedFormControl(DayjsUtil.dayjsOrNull(line.deliveryDate, true), Validators.required),
      engraving: new UntypedFormControl(line.engraving),
    });
    this.tableControl.addControl(line.lineNumber.toString(), rowForm);

    if (itemLine instanceof StandardItem && itemLine.sizeCategory) {
      rowForm.addControl(
        "sizeValue",
        new UntypedFormControl(this.getLineSizeValueId(line.sizeValue, line.lineNumber), Validators.required)
      );
    }

    rowForm.addControl(
      "unitPrice",
      new UntypedFormControl(line.unitPrice, [
        Validators.required,
        CommonValidatorsUtil.positiveNumberValidator(),
        digitValidator,
      ])
    );
    rowForm.addControl(
      "unitPricePerWeight",
      new UntypedFormControl(line.unitPricePerWeight, [Validators.min(0), digitValidator])
    );

    if (line.purchaseType === PurchaseType.WITH_METAL_PRICE) {
      rowForm.addControl("metalPrice", new UntypedFormControl(line.metalPrice, [Validators.min(0), digitValidator]));
    }

    rowForm.setValidators([this.rangeValidator()]);

    const controls = rowForm.controls;
    this.subscriptionService.subs.push(
      merge(
        controls.unitPrice.valueChanges,
        controls.unitPricePerWeight.valueChanges,
        controls.metalPrice ? controls.metalPrice.valueChanges : of()
      ).subscribe(() => {
        this.computeUnitPriceWithoutTax(line);
      })
    );

    this.subscriptionService.subs.push(
      merge(
        controls.quantity.valueChanges,
        controls.unitPrice.valueChanges,
        controls.unitPricePerWeight.valueChanges,
        controls.metalPrice ? controls.metalPrice.valueChanges : of(),
        controls.percentDiscount.valueChanges
      ).subscribe(() => {
        this.computeDiscount(line);
        this.computeTotalGrossPrice(line);
      })
    );

    this.computeUnitPriceWithoutTax(line);
    this.computeDiscount(line);
    this.computeTotalGrossPrice(line);
  }

  getSizeValueValidator(itemReference: string): any[] {
    const itemLine = this.retailItemList.find(item => item.reference === itemReference);
    if (itemLine instanceof StandardItem && itemLine.sizeCategory && itemLine.sizeCategory.elements.length > 0) {
      return [Validators.required];
    }
    return [];
  }

  isNullOrEmpty(value: string): boolean {
    return value === null || value === undefined || value === "";
  }

  rangeValidator(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      const min = group.controls.minQuantity;
      const max = group.controls.maxQuantity;
      const quantity = group.controls.quantity;

      if (this.isNullOrEmpty(quantity.value)) {
        return;
      }
      if (this.isNullOrEmpty(min.value) && this.isNullOrEmpty(max.value)) {
        return;
      }

      if (this.isNullOrEmpty(min.value)) {
        if (+quantity.value > +max.value) {
          quantity.setErrors({ outOfRange: true });
        }
        return;
      }

      if (this.isNullOrEmpty(max.value)) {
        if (+quantity.value < +min.value) {
          quantity.setErrors({ outOfRange: true });
        }
        return;
      }

      if (+max.value < +min.value) {
        max.setErrors({ ...max.errors, badMax: true });
        return;
      }
      if (+quantity.value < +min.value || +quantity.value > +max.value) {
        quantity.setErrors({ outOfRange: true });
        return;
      }
    };
  }

  checkInErrorRows(fromStepTwoToOne: boolean = false): void {
    let haveDuplicate = false; // true if there is at least one duplicate
    let inError = false;
    this.allRows.forEach(line => {
      const rowControls = (this.tableControl.controls[line.lineNumber] as UntypedFormGroup).controls;
      let sizeValue = null;
      let duplicated = false;

      if (rowControls.sizeValue) {
        sizeValue = rowControls.sizeValue.value;
      }

      this.allRows.forEach(line2 => {
        const rowControls2 = (this.tableControl.controls[line2.lineNumber] as UntypedFormGroup).controls;
        let sizeValue2 = null;

        if (rowControls2.sizeValue) {
          sizeValue2 = rowControls2.sizeValue.value;
        }

        if (
          line.lineNumber !== line2.lineNumber &&
          this.findItem(line).reference === this.findItem(line2).reference &&
          rowControls.deliveryStore.value === rowControls2.deliveryStore.value &&
          sizeValue === sizeValue2
        ) {
          duplicated = true;
          haveDuplicate = true;
          rowControls.deliveryStore.setErrors({ ...rowControls.deliveryStore.errors, duplicated: true });
          if (sizeValue) {
            rowControls.sizeValue.setErrors({ ...rowControls.sizeValue.errors, duplicated: true });
          }
        }
      });
      line.duplicated = duplicated;
      if (!duplicated) {
        const store = rowControls.deliveryStore.value;
        if (store) {
          rowControls.deliveryStore.setErrors(null);
        }
        if (sizeValue) {
          rowControls.sizeValue.setErrors(null);
        }
      }

      const rowControlsInvalid = this.tableControl.controls[line.lineNumber].invalid;
      line.inError = rowControlsInvalid && !duplicated;
      if (rowControlsInvalid && !duplicated) {
        inError = true;
      }
    });

    if (haveDuplicate && !fromStepTwoToOne) {
      const title = this.translateService.instant("purchase-order.lines.datatable.message.duplicate-title");
      const content = this.translateService.instant("purchase-order.lines.datatable.message.duplicate");
      this.messageService.error(content, { title });
    }

    if (inError && !fromStepTwoToOne) {
      const title = this.translateService.instant("message.title.form-errors");
      const content = this.translateService.instant("purchase-order.lines.datatable.message.errors");
      this.messageService.error(content, { title });
    }
    this.allRows = [...this.allRows];
    this.rows = [...this.rows];
  }

  getRowClass(row: any): any {
    return { inError: row.duplicated || row.inError, "not-clickable": true };
  }

  computeUnitPriceWithoutTax(line: PurchaseOrderLine): void {
    const lineItem = this.findItem(line);
    const unitPrice = +this.tableControl.get(`${line.lineNumber}`).get("unitPrice").value;
    if (lineItem instanceof StandardItem) {
      const itemWeight = lineItem.theoreticalWeight ?? 0;
      let unitPricePerWeight = this.tableControl.get(`${line.lineNumber}.unitPricePerWeight`).value;
      if (unitPricePerWeight === null || unitPricePerWeight === undefined || unitPricePerWeight === "") {
        unitPricePerWeight = 0;
      }

      let price = new Decimal(unitPrice ?? 0).plus(new Decimal(unitPricePerWeight).times(itemWeight)).toNumber();
      if (line.purchaseType === PurchaseType.WITH_METAL_PRICE) {
        const metalPrice = +this.tableControl.get(`${line.lineNumber}.metalPrice`).value;
        price += metalPrice;
      }
      const row = this.rows.find(r => r.lineNumber === line.lineNumber);
      row.unitPriceWithoutTax = RoundingUtil.roundLow(price);
    } else {
      const row = this.rows.find(r => r.lineNumber === line.lineNumber);
      row.unitPriceWithoutTax = RoundingUtil.roundLow(unitPrice);
    }
    this.rows = [...this.rows];
  }

  computeDiscount(line: PurchaseOrderLine): void {
    const quantity = +this.tableControl.get(`${line.lineNumber}.quantity`).value;
    const percentDiscount = +this.tableControl.get(`${line.lineNumber}.percentDiscount`).value;
    const row = this.rows.find(r => r.lineNumber === line.lineNumber);
    const unitPriceWithoutTax = row.unitPriceWithoutTax;
    const discount = RoundingUtil.roundLow(
      new Decimal(quantity ?? 0)
        .times(percentDiscount ?? 0)
        .times(unitPriceWithoutTax ?? 0)
        .dividedBy(this.MAX_PERCENT)
        .toNumber()
    );
    row.discount = this.currencyService.roundCurrencyValue(discount, this.purchaseOrderCurrency);
    row.percentDiscount = percentDiscount;
    this.rows = [...this.rows];
  }

  computeTotalGrossPrice(line: PurchaseOrderLine): void {
    const quantity = +this.tableControl.get(`${line.lineNumber}.quantity`).value;
    const row = this.rows.find(r => r.lineNumber === line.lineNumber);
    const discount = row.discount;
    const unitPriceWithoutTax = row.unitPriceWithoutTax;

    row.totalGrossPrice = RoundingUtil.roundLow(
      new Decimal(unitPriceWithoutTax ?? 0)
        .times(quantity ?? 0)
        .minus(discount ?? 0)
        .toNumber()
    );

    this.rows = [...this.rows];
  }

  getDeliveryDate(brand: Brand, supplier: Supplier): Date | number {
    const date = new Date();
    if (brand?.commercialModality && brand?.commercialModality.deliveryDelay) {
      return date.setDate(date.getDate() + brand.commercialModality.deliveryDelay);
    } else if (supplier && supplier.commercialModality && supplier.commercialModality.deliveryDelay) {
      return date.setDate(date.getDate() + supplier.commercialModality.deliveryDelay);
    }
    return date;
  }

  getPercentDiscount(item: AbstractItem, line: PurchaseOrderLine): number {
    if (!line.id) {
      const pm = item.purchaseModalities?.find(elem => elem.id === line.purchaseModalityId);
      const brand = this.brandList.find(elem => elem.id === item.brandId);
      const supplier = this.supplierList.find(elem => elem.id === pm.supplierId);

      if (brand && brand.commercialModality && brand.commercialModality.discountRate) {
        return RoundingUtil.roundHigh(brand.commercialModality.discountRate);
      }

      if (supplier && supplier.commercialModality && supplier.commercialModality.discountRate) {
        return RoundingUtil.roundHigh(supplier.commercialModality.discountRate);
      }
    }
    return RoundingUtil.roundHigh(line.percentDiscount, true);
  }

  getTotalMetalWeight(theoricalMetalWeight: TheoreticalMetalWeight[]): number {
    let itemMetalWeight = 0;
    if (Array.isArray(theoricalMetalWeight)) {
      theoricalMetalWeight.forEach((metalWeight: TheoreticalMetalWeight) => {
        itemMetalWeight += metalWeight.weight;
      });
    }
    return itemMetalWeight;
  }

  getSizeValue(valueId: number, sizeCatId: number): string {
    return this.sizeCategoryList.find(sC => sC.id === sizeCatId).elements.find(e => e.id === valueId).value;
  }

  getLineSizeValueId(valueStr: string, lineNumber: number): number {
    const opt = this.sizeValueOptions[lineNumber].find(option => option.label === valueStr);
    return opt ? opt.id : null;
  }

  getPurchaseUnitName(id: number): string {
    return this.purchaseUnitList.find(pu => pu.id === id).longName;
  }

  buildSizeValueOptions(item: AbstractItem, line: PurchaseOrderLine): void {
    if (!(item instanceof StandardItem) || !(item as StandardItem).sizeCategory) {
      return;
    }

    const numericSorter = new Intl.Collator(this.locale, { numeric: true });

    this.sizeValueOptions[line.lineNumber] = (item as StandardItem).sizeCategory?.elements
      .map(
        obj =>
          new Option(obj.id, this.getSizeValue(obj.sizeValueId, (item as StandardItem).sizeCategory?.sizeCategoryId))
      )
      .sort((a, b) => numericSorter.compare(a.label, b.label));
  }

  onCheckboxOnChanges(lineNumber: number): void {
    const engravingValue = this.tableControl.get(`${lineNumber}.engraving`).value;
    this.editedPurchaseOrder.lines.find(line => line.lineNumber === lineNumber).engraving = engravingValue;
    this.rows.find(row => row.lineNumber === lineNumber).engraving = engravingValue;
    this.allRows.find(row => row.lineNumber === lineNumber).engraving = engravingValue;
    this.applyFilters();
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  public updatePurchaseOrder(fromStepTwoToOne: boolean = false): boolean {
    this.checkInErrorRows(fromStepTwoToOne);

    if (this.tableControl.invalid) {
      this.tableControl.markAllAsTouched();
      return false;
    }

    this.applyModifications();
    return true;
  }

  public applyModifications(): void {
    this.editedPurchaseOrder.lines.forEach(line => {
      const lineControlGroup = this.tableControl.get(line.lineNumber.toString());

      line.deliveryStoreId = lineControlGroup.get("deliveryStore").value;
      line.itemName = lineControlGroup.get("itemName").value;

      if (lineControlGroup.get("sizeValue")) {
        const sizeValueId = lineControlGroup.get("sizeValue").value;
        line.sizeValue = this.sizeValueOptions[line.lineNumber].find(opt => opt.id === sizeValueId)
          ? this.sizeValueOptions[line.lineNumber].find(opt => opt.id === sizeValueId).label
          : null;
      }

      line.quantity = lineControlGroup.get("quantity").value;

      line.minQuantity = lineControlGroup.get("minQuantity").value ? lineControlGroup.get("minQuantity").value : null;

      line.maxQuantity = lineControlGroup.get("maxQuantity").value ? lineControlGroup.get("maxQuantity").value : null;

      line.unitPrice = lineControlGroup.get("unitPrice")?.value;

      line.unitPricePerWeight = lineControlGroup.get("unitPricePerWeight")?.value ?? 0;

      line.deliveryDate = lineControlGroup.get("deliveryDate").value
        ? lineControlGroup.get("deliveryDate").value.toDate()
        : null;

      line.engraving = lineControlGroup.get("engraving").value;

      if (line.purchaseType === PurchaseType.WITH_METAL_PRICE) {
        line.metalPrice = lineControlGroup.get("metalPrice")?.value;
      }

      line.percentDiscount = lineControlGroup.get("percentDiscount")?.value;

      // handle engraving
      if (!line.engraving) {
        line.engravingText = null;
        line.engravingFont = null;
        line.engravingLocation = null;
      }
      line.supplierTraceabilityNumber = lineControlGroup.get("supplierTraceabilityNumber")?.value;
    });
  }

  // -------------- POPUP -------------- //

  // ___ engraving popup ___

  openEngravingPopup(lineNumber: number): void {
    this.engravingPopupVisible = true;
    this.selectedPurchaseOrderLine = this.editedPurchaseOrder.lines.find(line => line.lineNumber === lineNumber);
    this.selectedMaxEngravingLength = this.rows.find(row => row.lineNumber === lineNumber).engravingLength
      ? this.rows.find(row => row.lineNumber === lineNumber).engravingLength
      : 0;
  }

  submitEngravingPopup(validatedPurchaseOrderLine: PurchaseOrderLine): void {
    const index = this.editedPurchaseOrder.lines.findIndex(
      line => line.lineNumber === validatedPurchaseOrderLine.lineNumber
    );
    this.editedPurchaseOrder.lines[index] = validatedPurchaseOrderLine;

    this.closeEngravingPopup();
  }

  closeEngravingPopup(): void {
    this.engravingPopupVisible = false;
    this.selectedPurchaseOrderLine = null;
    this.selectedMaxEngravingLength = null;
  }

  displayQuantityRange(purchaseModalityId: number): number {
    const item = this.getItem(purchaseModalityId);
    const pm = item.purchaseModalities?.find(elem => elem.id === purchaseModalityId);
    return pm.minQuantity || pm.maxQuantity;
  }

  displayComment(lineNumber: number): boolean {
    const line = this.editedPurchaseOrder.lines.find(elem => elem.lineNumber === lineNumber);

    if (line.comment || line.engravingFont || line.engravingLocation || line.engravingText) {
      return true;
    }
    return false;
  }

  getCommentToDisplay(lineNumber: number): string {
    const currentLine = this.editedPurchaseOrder.lines.find(elem => elem.lineNumber === lineNumber);
    const comment = currentLine.comment;
    if ((comment === null || comment === "") && currentLine.engraving) {
      return this.getTooltipToDisplay(lineNumber);
    } else {
      return this.editedPurchaseOrder.lines.find(line => line.lineNumber === lineNumber).comment;
    }
  }

  getTooltipToDisplay(lineNumber: number): string {
    const line = this.editedPurchaseOrder.lines.find(elem => elem.lineNumber === lineNumber);

    if (!line.engraving) {
      return line.comment;
    }
    const text = this.translateService.instant("engraving-popup.fields.text");
    const engravingText = line.engravingText ? line.engravingText : "";
    const font = this.translateService.instant("engraving-popup.fields.font");
    const engravingFont = line.engravingFont ? line.engravingFont : "";
    const location = this.translateService.instant("engraving-popup.fields.location");
    const engravingLocation = line.engravingLocation ? line.engravingLocation : "";
    const comment = this.translateService.instant("engraving-popup.fields.comment");
    const commentText = line.comment ?? "";
    if (line.engraving) {
      return `${text}: ${engravingText}\n${font}: ${engravingFont}\n${location}: ${engravingLocation}\n${comment}: ${commentText}`;
    }
    return null;
  }

  // ______ duplicate popup ______

  openDuplicatationPopup(row: any): void {
    // get the  last modifications on the rows to duplicate the correct values
    this.applyModifications();

    this.duplicationPopupVisible = true;

    // @input for purchaseModality
    this.duplicatePOL = this.editedPurchaseOrder.lines.find(line => line.lineNumber === row.lineNumber);
    this.duplicatePOL.supplierTraceabilityNumber = null;
    // @input for one sizecategory and relative sizeValues
    this.popInSizeValueOptions = this.sizeValueOptions[row.lineNumber];
  }

  closeDuplicationPopup(): void {
    // reset values
    this.duplicationPopupVisible = false;
    this.selectedPurchaseOrderLine = null;
  }

  submitDuplicationPopup(event: any): void {
    // --- bring the stores ---
    const selectedStores = event[0];

    // --- bring the metrics ---
    const selectedSizeValues = event[1];

    // --- create a clone for each store with map quantity at the end of table ---
    if (selectedStores && selectedStores.length > 0) {
      selectedStores.forEach((storeId: number) => {
        // clone the actual PM add add stores + new quantities of sizeValues
        if (selectedSizeValues && selectedSizeValues.size > 0) {
          selectedSizeValues.forEach((quantity: number, sizeValueId: number) => {
            const line = this.duplicatePOL;
            line.deliveryStoreId = storeId;
            // récupérer le label à partir de l'id
            line.sizeValue = this.popInSizeValueOptions.find((opt: Option) => opt.id === sizeValueId).label;
            line.quantity = quantity;
            this.duplicateAndAddLine(line);
          });
          // store(s) without new quantities of sizeValues
        } else {
          const line = this.duplicatePOL;
          line.deliveryStoreId = storeId;

          this.duplicateAndAddLine(line);
        }
      });
    }
    this.closeDuplicationPopup();
  }

  // ___ selection popup ___

  addPurchaseModality(): void {
    this.selectionPopupVisible = true;
  }

  submitSelectionPopup(selectedItemsAndPms: Map<any, any>): void {
    // event is the k,v map
    this.closeSelectionPopup();

    // make a list of PM ids...
    let receivedPurchaseModalityIdList = [];
    const itemToAddIdList = [];
    const pmToAddIdList = [];
    for (const [itemId, pmIds] of selectedItemsAndPms) {
      // list all PM ids received
      receivedPurchaseModalityIdList = receivedPurchaseModalityIdList.concat(pmIds);
      // check if we need to fetch new items depending on what we received from the popup and what we have already
      pmIds.forEach(id => {
        if (!this.purchaseModalityIdList.includes(id)) {
          // add the item id
          if (!this.retailItemList.find(item => item.id === itemId) && !itemToAddIdList.includes(itemId)) {
            itemToAddIdList.push(itemId);
          }
          // create a list of new PM to add
          pmToAddIdList.push(id);
          // update pm id list
          this.purchaseModalityIdList.push(id);
        } else {
          this.addNewLine(id);
        }
      });
    }

    // add line
    if (itemToAddIdList.length > 0) {
      this.fetchNewItems(pmToAddIdList);
    } else {
      pmToAddIdList.forEach(id => this.addNewLine(id));
    }
    this.updateFilters();
  }

  closeSelectionPopup(): void {
    if (this.purchaseModalitySelection) {
      this.purchaseModalitySelection.savePaginationToSession();
    }

    this.selectionPopupVisible = false;
  }

  public changeSortSettings(prop: string, dir: string): void {
    this.sorts = [{ prop, dir }];
    this.rows = [...this.rows];
    this.table.sorts = this.sorts;
  }

  // -------------- FILTERS -------------- //

  // handle filters
  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === PurchaseOrderLinesComponent.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter(
      "lineNumber",
      this.translateService.instant("purchase-order.lines.datatable.columns.line-number"),
      "string"
    );

    this.filterer.addFilter(
      "itemReference",
      this.translateService.instant("purchase-order.lines.datatable.columns.item-reference"),
      "string"
    );

    this.filterer.addFilter(
      "supplierRef",
      this.translateService.instant("purchase-order.lines.datatable.columns.supplier-ref"),
      "string"
    );

    this.filterer.addListFilter(
      "brandName",
      this.translateService.instant("purchase-order.lines.datatable.columns.brand-name"),
      [...new Set(this.allRows.map(row => row.brandName))]
        .map(brandName => {
          return { value: brandName, displayValue: brandName };
        })
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue)),
      null,
      null,
      null,
      null,
      true
    );

    this.filterer.addListFilter(
      "purchaseUnitName",
      this.translateService.instant("purchase-order.lines.datatable.columns.purchase-unit"),
      [...new Set(this.allRows.map(row => row.purchaseUnitName))].map(purchaseUnitName => {
        return { value: purchaseUnitName, displayValue: purchaseUnitName };
      })
    );

    this.filterer.addBooleanFilter(
      "engraving",
      this.translateService.instant("purchase-order.lines.datatable.columns.engraving"),
      false,
      false,
      true
    );

    this.filterer.addFilter(
      "discount",
      this.translateService.instant("purchase-order.lines.datatable.columns.discount"),
      "range"
    );

    this.filterer.addFilter(
      "totalGrossPrice",
      this.translateService.instant("purchase-order.lines.datatable.columns.total-gross-price"),
      "range"
    );
  }

  updateFilters(): void {
    this.filterer = undefined;
    this.initFilters();
  }

  applyFilters(): void {
    this.rows = this.filterer.filterList(this.allRows);

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        PurchaseOrderLinesComponent.LIST_ID
      ).subscribe()
    );
  }

  findItem(line: PurchaseOrderLine): AbstractItem {
    return PurchaseOrderUtil.findItem(line, this.retailItemList);
  }

  changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
  }
}
