<ng-container>
  <div class="main-container frozen-right">
    <div class="title">
      <ui-title titleText="{{ 'stocks-list.title' | translate }}"></ui-title>
    </div>
    <div class="top-buttons">
      <div class="maual-stok-entry-button">
        <ui-button
          [primary]="true"
          [btnIcon]="faStockEntry"
          (click)="createManualStockEntry()"
          text="{{ 'manual-stock-entry.title' | translate }}"
        ></ui-button>
      </div>
    </div>

    <div class="stock-table">
      <ui-container>
        <div class="filters-field" *ngIf="filterer">
          <ui-dynamic-filters
            #stockFilters
            [filters]="filterer.getFilters()"
            [filterValues]="filterer.filterValues"
            (filterValuesChange)="filterValuesChange($event)"
            (apply)="applyFilters()"
            [locale]="codeLanguage"
            dateFormat="{{ dateFormat }}"
            [label]="'filters.label' | translate"
            minLabel="{{ 'filters.min' | translate }}"
            maxLabel="{{ 'filters.max' | translate }}"
            applyLabel="{{ 'filters.apply' | translate }}"
            deleteLabel="{{ 'filters.delete' | translate }}"
            placeholderPrefix="{{ 'filters.prefix' | translate }} "
            mandatoryErrorMessage="{{ 'stocks-list.errors.filters' | translate }}"
          >
          </ui-dynamic-filters>
        </div>

        <div class="headband">
          <div class="selected-count">
            <span
              [innerHTML]="
                selectedRows.length === 0
                  ? ('global.datatable.no-items' | translate)
                  : (selectedRows.length
                    | i18nPlural
                      : {
                          '=1': 'global.datatable.n-selected-items',
                          other: 'global.datatable.n-selected-items_plural',
                        }
                    | translate: { selectedElements: selectedRows.length })
              "
            >
            </span>
          </div>
          <div class="print-button">
            <ui-button
              text="{{ 'stock-entry-label-list.datatable.buttons.print' | translate }}"
              (click)="showPrintSELPopup()"
              [disabled]="!selectedRows.length"
              [btnIcon]="faPrint"
              [primary]="true"
            >
            </ui-button>
          </div>
        </div>

        <div class="separator">
          <div class="pointer">
            <div class="arrow"></div>
          </div>
          <div class="line"></div>
        </div>

        <div [formGroup]="tableControl" class="stock-entry-table">
          <ngx-datatable
            #table
            [rows]="rows"
            [trackByProp]="'id'"
            [class]="'material'"
            [columnMode]="'force'"
            [headerHeight]="'auto'"
            [footerHeight]="50"
            [rowHeight]="40"
            [scrollbarH]="true"
            [externalSorting]="true"
            [externalPaging]="true"
            [rowClass]="getRowClass"
            [count]="pager.totalElements"
            (activate)="showDetails($event)"
            [offset]="pager.number"
            [limit]="pager.size"
            [cssClasses]="{
              sortAscending: 'fas fa-chevron-up',
              sortDescending: 'fas fa-chevron-down',
            }"
            [messages]="{ emptyMessage: 'global.no-data' | translate }"
            [sorts]="sorts"
            (sort)="changeSortSettings($event.column.prop, $event.newValue)"
          >
            <ngx-datatable-column
              width="50"
              [sortable]="false"
              [frozenLeft]="true"
              [draggable]="false"
              [resizeable]="false"
              [canAutoResize]="false"
              cellClass="center-align frozen-style"
            >
              <ng-template ngx-datatable-header-template>
                <div class="green-zone" (click)="$event.stopPropagation()">
                  <ui-checkbox
                    customClass="no-margin"
                    (change)="onHeaderCheckboxChange()"
                    [formControlName]="'headerCheckbox'"
                  >
                  </ui-checkbox>
                </div>
              </ng-template>

              <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id">
                <div *ngIf="row.actionnable" class="adjust green-zone" (click)="$event.stopPropagation()">
                  <ui-checkbox
                    customClass="no-margin"
                    (change)="onRowCheckboxChange()"
                    [formControlName]="getRowControlName(id)"
                  >
                  </ui-checkbox>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="getColumnTranslation('item-category') | translate"
              prop="itemCategory"
              [resizeable]="false"
              [width]="130"
              cellClass="item-category"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="getColumnTranslation('item-reference') | translate"
              prop="itemReference"
              [resizeable]="false"
              [width]="140"
              cellClass="item-reference"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="getColumnTranslation('location') | translate"
              prop="location"
              [resizeable]="false"
              [width]="180"
              cellClass="location"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="getColumnTranslation('item-name') | translate"
              prop="itemName"
              [resizeable]="false"
              [width]="180"
              cellClass="item-name"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="getColumnTranslation('serial-number') | translate"
              prop="serialNumber"
              [resizeable]="false"
              [width]="130"
              cellClass="serial-number"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="getColumnTranslation('batch-number') | translate"
              prop="batchNumber"
              [resizeable]="false"
              [width]="130"
              cellClass="batch-number"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="getColumnTranslation('quantity') | translate"
              prop="quantity"
              [resizeable]="false"
              [width]="130"
              cellClass="quantity"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div>{{ row.quantity }} {{ row.unit }}</div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="getColumnTranslation('size-value') | translate"
              prop="sizeValue"
              [resizeable]="false"
              [width]="150"
              cellClass="size-value"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              *ngIf="canReadPrices"
              [name]="getColumnTranslation('unit-price') | translate"
              prop="computedUnitPrice"
              cellClass="unit-price"
              [resizeable]="false"
              [width]="120"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="adjust" *ngIf="row.computedUnitPrice !== null">
                  {{
                    row.computedUnitPrice
                      | formatCurrency: this.defaultCurrency.codeISO : "symbol" : "0.2-5" : codeLanguage
                      | async
                  }}
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              *ngIf="canReadPrices"
              [sortable]="false"
              [canAutoResize]="false"
              [resizeable]="false"
              headerClass="narrow"
              [draggable]="false"
              width="50"
            >
              <ng-template ngx-datatable-header-template>
                <div #info><fa-icon [icon]="faInfo" class="info-icon"></fa-icon></div>

                <ui-tooltip maxWidth="300px" [element]="info">
                  <span style="font-weight: normal !important">
                    {{ "stocks-list.datatable.info.unit-price-info" | translate }}
                  </span>
                </ui-tooltip>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              *ngIf="canReadPrices"
              [name]="getColumnTranslation('wap') | translate"
              prop="wap"
              [resizeable]="false"
              [width]="150"
              cellClass="wap"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div class="adjust" *ngIf="row.wap !== null">
                  {{
                    row.wap | formatCurrency: this.defaultCurrency.codeISO : "symbol" : "0.2-2" : codeLanguage | async
                  }}
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="getColumnTranslation('entry-supplier-ref') | translate"
              prop="entrySupplierRef"
              [resizeable]="false"
              [width]="150"
              cellClass="entry-supplier-ref"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="getColumnTranslation('brand-name') | translate"
              prop="brandName"
              [resizeable]="false"
              [width]="130"
              cellClass="brand-name"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="getColumnTranslation('supplier-name') | translate"
              prop="supplierName"
              [resizeable]="false"
              [width]="130"
              cellClass="supplier-name"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="getColumnTranslation('supplier-reference') | translate"
              prop="itemSupplierRef"
              [resizeable]="false"
              [width]="130"
              cellClass="supplier-reference"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="getColumnTranslation('metal') | translate"
              prop="metal"
              [resizeable]="false"
              [sortable]="false"
              [width]="200"
              cellClass="metal ellipsis-counter"
              *ngIf="this.isConnectedToMainStore"
            >
              <ng-template ngx-datatable-cell-template let-row="row" let-metal="row.metal">
                <ng-container *ngIf="metal.length > 0">
                  <p class="ellipsis">{{ metal.join(", ") }}</p>
                  <div *ngIf="metal.length >= 2">
                    <div #metalCounter>
                      <ui-counter [number]="metal.length"></ui-counter>
                    </div>
                    <ui-tooltip maxWidth="300px" [element]="metalCounter">
                      <span style="font-weight: normal !important">{{ metal.join(", ") }}</span>
                    </ui-tooltip>
                  </div>
                </ng-container>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="getColumnTranslation('item-weight') | translate"
              prop="itemWeight"
              [resizeable]="false"
              [width]="130"
              cellClass="item-weight"
            >
              <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                <div class="adjust" *ngIf="value || value === 0">{{ value }} g</div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="getColumnTranslation('entry-date') | translate"
              prop="entryDate"
              [resizeable]="false"
              [width]="130"
              cellClass="entry-date"
            >
              <ng-template ngx-datatable-cell-template let-date="row.entryDate">
                <span>{{ date | dateTimeFormat: false }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="getColumnTranslation('delivery-reference') | translate"
              prop="deliveryReference"
              [resizeable]="false"
              [width]="130"
              cellClass="delivery-reference"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="getColumnTranslation('invoice-date') | translate"
              prop="invoiceDate"
              [resizeable]="false"
              [width]="130"
              cellClass="invoice-date"
            >
              <ng-template ngx-datatable-cell-template let-date="row.invoiceDate">
                <span *ngIf="date">{{ date | dateTimeFormat: false }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="getColumnTranslation('invoice-reference') | translate"
              prop="invoiceReference"
              [resizeable]="false"
              [width]="130"
              cellClass="invoice-reference"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="getColumnTranslation('creation-date') | translate"
              prop="creationDate"
              [resizeable]="false"
              [width]="170"
              cellClass="creation-date"
            >
              <ng-template ngx-datatable-cell-template let-date="row.creationDate">
                <span>{{ date | dateTimeFormat: false }}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="getColumnTranslation('movement-type') | translate"
              prop="type"
              [resizeable]="false"
              [width]="130"
              cellClass="type"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <ui-tag
                  [label]="'stock-entry-list.datatable.last-movement-type.' + row.movementType | translate"
                  [customClass]="row.typeClass"
                >
                </ui-tag>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [name]="getColumnTranslation('public-price-currency') | translate"
              cellClass="public-prices-currencies ellipsis-counter"
              prop="publicPricesCurrencies"
              [resizeable]="false"
              [sortable]="false"
              [width]="130"
            >
              <ng-template
                ngx-datatable-cell-template
                let-row="row"
                let-publicPricesCurrencies="row.publicPricesCurrencies"
              >
                <span class="ellipsis publicPricesCurrencies" title="{{ publicPricesCurrencies.join(' | ') }}">
                  {{ publicPricesCurrencies.join(" | ") }}
                </span>
                <div *ngIf="publicPricesCurrencies?.length >= 2" title="{{ row.counter }}">
                  <div #publicPricesCurrenciesCounter>
                    <ui-counter [number]="publicPricesCurrencies?.length"></ui-counter>
                  </div>
                  <ui-tooltip maxWidth="300px" [element]="publicPricesCurrenciesCounter">
                    <span style="font-weight: normal !important; white-space: break-spaces">{{
                      publicPricesCurrencies.join("\n")
                    }}</span>
                  </ui-tooltip>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              *ngIf="canReadPrices"
              [name]="getColumnTranslation('cost-price') | translate"
              prop="costPrice"
              [resizeable]="false"
              [sortable]="false"
              [width]="130"
              cellClass="cost-price"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              *ngIf="canReadPrices"
              [name]="getColumnTranslation('stock-valuation') | translate"
              prop="stockValuation"
              [resizeable]="false"
              [sortable]="false"
              [width]="130"
              cellClass="stock-valuation"
            >
            </ngx-datatable-column>

            <ngx-datatable-column
              *ngIf="canUpdate"
              name="{{ 'purchase-order-list.datatable.columns.actions' | translate }}"
              headerClass="center-align"
              cellClass="center-align frozen-style"
              [resizeable]="false"
              [frozenRight]="true"
              [sortable]="false"
              [width]="80"
            >
              <ng-template ngx-datatable-cell-template let-row="row">
                <div *ngIf="menuActions && row.actionnable" class="green-zone" (click)="$event.stopPropagation()">
                  <ui-menu-actions [menuActions]="menuActions" (actionCalled)="manageActions($event, row.id)">
                  </ui-menu-actions>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible"
              >
                <div class="page-count" *ngIf="rowCount">
                  {{
                    rowCount
                      | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                      | translate: { rowCount: rowCount }
                  }}
                </div>
                <datatable-pager
                  [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                  [pagerRightArrowIcon]="'fas fa-chevron-right'"
                  [pagerNextIcon]="'fas fa-chevron-double-right'"
                  [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                  [page]="curPage"
                  [size]="pageSize"
                  [count]="rowCount"
                  [hidden]="!(rowCount / pageSize > 1)"
                  (change)="changePage($event)"
                >
                </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </ui-container>
    </div>
  </div>

  <stock-entry-detail-popup
    [(paneVisible)]="this.detailsVisible"
    [(selectedStockEntryLocation)]="this.selectedStockEntryLocation"
    (close)="this.closeDetails()"
  >
  </stock-entry-detail-popup>

  <app-edit-stock-popup
    *ngIf="editPopupVisible"
    [stockEntryLocation]="selectedSELForMovement"
    [unit]="getUnit(selectedSELForMovement.stockEntry.uomId)"
    (close)="this.closePopup('edit')"
  >
  </app-edit-stock-popup>

  <app-create-stock-movement-popup
    *ngIf="createSMPopupVisible"
    [stockEntryLocation]="selectedSELForMovement"
    [storeName]="getStoreName(selectedSELForMovement.contextContactId)"
    [unit]="getUnit(selectedSELForMovement.stockEntry.uomId)"
    (close)="this.closePopup('in')"
  >
  </app-create-stock-movement-popup>

  <app-out-of-stock-popup
    *ngIf="outOfStockPopupVisible"
    [stockEntryLocation]="selectedSELForMovement"
    [unit]="getUnit(selectedSELForMovement.stockEntry.uomId)"
    (close)="this.closePopup('out')"
  >
  </app-out-of-stock-popup>

  <!--Chose slot number popup -->
  <app-stock-entry-label-print-settings-popup
    *ngIf="printSettingsPopupVisible"
    [stockEntryLabelList]="selectedRows"
    (validate)="validateSettingsPopup($event)"
    (close)="closeSettingsPopup()"
  ></app-stock-entry-label-print-settings-popup>
</ng-container>
