import { Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { MessageService } from "fugu-components";
import { SearchFilter, SearchFilterOperator, SubscriptionService } from "fugu-common";
import { IconDefinition, faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import {
  PurchaseOrder,
  Supplier,
  Currency,
  AbstractItem,
  Uom,
  MetalWeight,
  RetailItemService,
  UomService,
  CurrencyService,
  CaraUserService,
  PurchaseOrderLine,
  PaginatedList,
  Pagination,
  PurchaseType,
  PurchaseOrderUtil,
} from "center-services";
import { PurchaseOrderUtilService } from "app/service/purchase-order-util.service";
import { DatatableComponent } from "@siemens/ngx-datatable";

@Component({
  selector: "app-purchase-order-summary-list",
  templateUrl: "./purchase-order-summary-list.component.html",
  styleUrls: ["./purchase-order-summary-list.component.scss"],
  providers: [SubscriptionService],
})
export class PurchaseOrderSummaryListComponent implements OnInit {
  @ViewChild("table") table: DatatableComponent;

  @ViewChild("totalSummary")
    totalSummary: TemplateRef<any>;

  @ViewChild("quantitySummary")
    quantitySummary: TemplateRef<any>;

  @ViewChild("metalWeightSummary")
    metalWeightSummary: TemplateRef<any>;

  @ViewChild("totalGrossPriceSummary")
    totalGrossPriceSummary: TemplateRef<any>;

  @Input() purchaseOrder: PurchaseOrder;
  @Input() orderSupplier: Supplier;
  stringifyMetalWeightList: any = PurchaseOrderUtil.stringifyMetalWeightList;
  public rows: any[] = [];
  public purchaseModalityIdList: number[] = [];
  public purchaseUnitIdList: Set<number> = new Set();
  public purchaseOrderCurrency: Currency;
  public retailItemList: AbstractItem[] = [];
  public purchaseUnitList: Uom[] = [];
  public sumQuantity: number = 0;
  public sumTheoreticalWeight: number = 0;
  public sumTotalWeight: number = 0;
  public sumWeightAccountPrediction: MetalWeight[] = [];
  public sumTotalGrossPrice: number = 0;
  public locale: string;
  public warnIcon: IconDefinition = faExclamationTriangle;
  public sorts: any[] = [
    {
      prop: "lineNumber",
      dir: "asc",
    },
  ];
  private initObservables: Observable<any>[] = [];

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private retailItemService: RetailItemService,
    private uomService: UomService,
    private currencyService: CurrencyService,
    private userService: CaraUserService,
    private purchaseOrderUtilService: PurchaseOrderUtilService,
    private subscriptionService: SubscriptionService
  ) {}

  // the arrow function bellow is used to return the rows class
  getRowClass: any = (): any => ({ "not-clickable": true });

  ngOnInit(): void {
    this.purchaseOrder.lines.forEach((line: PurchaseOrderLine) => {
      // get all the purchase modality ids from each line
      this.purchaseModalityIdList.push(line.purchaseModalityId);
    });

    this.locale = this.userService.connectedUser.value.codeLanguage;

    if (this.purchaseModalityIdList.length > 0) {
      this.initObservables.push(this.fetchItems(this.purchaseModalityIdList));
    }
    this.initObservables.push(this.fetchPurchaseUnits());
    this.initObservables.push(this.fetchPurchaseOrderCurrency(this.purchaseOrder.currencyId));

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.rows = [];
        this.loadRows();
        this.multiplePurchaseUnit();
        this.computeTotals();
      })
    );
  }

  fetchItems(purchaseModalityIds: number[]): Observable<PaginatedList<AbstractItem>> {
    const pager = new Pagination({
      size: purchaseModalityIds.length,
      number: 0,
    });
    const filters = new SearchFilter("purchaseModalities.id", SearchFilterOperator.IN, purchaseModalityIds);

    return this.retailItemService.getAll(pager, [], [filters]).pipe(
      tap(
        (result: PaginatedList<AbstractItem>) => {
          // remove duplicates from item list
          const ids = result.data.map(obj => obj.id);
          this.retailItemList = result.data.filter(({ id }, index) => !ids.includes(id, index + 1));
        },
        error => {
          this.sendErrorAlert("retail-item-list.errors.get-retail-items", error.message);
        }
      )
    );
  }

  fetchPurchaseUnits(): Observable<Uom[]> {
    return this.uomService.getAll().pipe(
      tap(
        (uoms: Uom[]) => {
          this.purchaseUnitList = uoms;
        },
        error => {
          this.sendErrorAlert("uoms-list.errors.get-entities", error.message);
        }
      )
    );
  }

  fetchPurchaseOrderCurrency(id: number): Observable<Currency> {
    return this.currencyService.get(id).pipe(
      tap(
        (currency: Currency) => {
          this.purchaseOrderCurrency = currency;
        },
        error => {
          this.sendErrorAlert("purchase-order.lines.datatable.errors.get-currency", error.message);
        }
      )
    );
  }

  loadRows(): void {
    this.purchaseOrder.lines.forEach((purchaseOrderLine: PurchaseOrderLine) => this.addRow(purchaseOrderLine));
  }

  getPurchaseUnitName(id: number): string {
    return this.purchaseUnitList.find(pu => pu.id === id).longName;
  }

  multiplePurchaseUnit(): boolean {
    const unique = [...new Set(this.purchaseOrder.lines.map(row => row.purchaseUnitId))];
    return unique.length > 1;
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  changeSortSettings(prop: string, direction: string): void {
    this.sorts = [
      {
        prop,
        dir: direction,
      },
    ];

    this.rows = [...this.rows];
    this.table.sorts = this.sorts;
  }

  computeTotals(): void {
    this.sumQuantity = PurchaseOrderUtil.getTotalQuantity(this.purchaseOrder.lines);
    this.sumTheoreticalWeight = PurchaseOrderUtil.getTotalWeight(this.purchaseOrder.lines, true);
    this.sumTotalWeight = PurchaseOrderUtil.getTotalWeight(this.purchaseOrder.lines);
    this.sumTotalGrossPrice = PurchaseOrderUtil.getTotalPrice(this.purchaseOrder.lines, this.retailItemList);
  }

  private addRow(line: PurchaseOrderLine): void {
    const row = {
      id: line.id,
      lineNumber: line.lineNumber,
      itemReference: line.itemReference,
      supplierRef: line.supplierRef,
      itemName: line.itemName,
      quantity: line.quantity,
      purchaseUnitName: this.getPurchaseUnitName(line.purchaseUnitId),
      unitPriceWithoutTax: PurchaseOrderUtil.computeLineUnitPrice(line, this.retailItemList),
      totalWeight: line.weight * line.quantity,
      theoreticalWeight: PurchaseOrderUtil.getMetalWeight(line),
      weightAccountPrediction: null,
      purchaseType: line.purchaseType,
      totalGrossPrice: PurchaseOrderUtil.computeLineTotalPrice(line, this.retailItemList),
      supplierTraceabilityNumber: line.supplierTraceabilityNumber,
    };

    // set weightAccountPrediction value for PM WITH_METAL_ACCOUNT
    if (line.purchaseType === PurchaseType.WITH_METAL_ACCOUNT) {
      this.subscriptionService.subs.push(
        this.purchaseOrderUtilService
          .fetchWeightAccountPrediction(row.theoreticalWeight, this.getItemByName(line.itemName), this.orderSupplier)
          .subscribe((metalWeightList: MetalWeight[]) => {
            row.weightAccountPrediction = metalWeightList;

            // update total
            this.sumWeightAccountPrediction = this.purchaseOrderUtilService.getMergedMetalWeights(
              this.sumWeightAccountPrediction,
              metalWeightList
            );
          })
      );
    }

    this.rows.push(row);
    this.rows = [...this.rows];
  }

  private getItemByName(name: string): AbstractItem {
    return this.retailItemList.find(item => item.name === name);
  }
}
