/* eslint-disable no-magic-numbers */
import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { IconDefinition, faPrint, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { NotificationHandlerService } from "app/service/notification-handler.service";
import {
  StockEntryLabel,
  Light,
  Store,
  Pagination,
  AuthService,
  StockEntryLabelService,
  LightService,
  StoreService,
  CaraUserService,
  PaginatedList,
  StockType,
  Brand,
  Supplier,
  CaraUser,
  Sort,
  AsynchronousTaskCreation,
  AsynchronousTaskService,
} from "center-services";
import { Filter, FilterValue, Option, SearchFilter, SearchFilterOperator, SubscriptionService } from "fugu-common";
import { MessageService, ToastMessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import dayjs from "dayjs";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-stock-entry-label-list",
  templateUrl: "./stock-entry-label-list.component.html",
  styleUrls: ["./stock-entry-label-list.component.scss"],
  providers: [SubscriptionService],
})
export class StockEntryLabelListComponent extends FilteredTableListComponent implements OnInit {
  @ViewChild("table") table: any;

  public LIST_ID: string = "app-stock-entry-label-list.app-stock-entry-label-table";

  public stockEntryLabelList: StockEntryLabel[] = [];
  public rows: any[] = [];
  public sorts: any[] = [{ prop: "receiveDate", dir: "desc" }];
  public activeFilters: SearchFilter[] = [];
  public availableFilters: Filter[];
  public filterValues: FilterValue[] = [];

  public brandList: Light[] = [];
  public storeList: Light[] = [];
  public contextStore: Light;
  public mainStore: Store;
  public locale: string;
  public dateFormat: string;
  public brandOptions: Option[] = [];
  public supplierOptions: Option[] = [];

  public tableControl: UntypedFormGroup;
  public currentRowsId: number[] = [];
  public selectedRows: StockEntryLabel[] = [];
  public printSettingsPopupVisible: boolean = false;
  public deletePopupVisible: boolean = false;
  public afterPrint: boolean = false;
  faPrint: IconDefinition = faPrint;
  faTrashAlt: IconDefinition = faTrashAlt;
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  private initObservables: Observable<any>[] = [];
  private deleteObservables: Observable<any>[] = [];
  private firstLabelIndex: number;
  private readonly ITEM_REFERENCE_FILTER_ID: number = 0;
  private readonly ITEM_NAME_FILTER_ID: number = 1;
  private readonly DELIVERY_REFERENCE_FILTER_ID: number = 2;
  private readonly RECEIVE_DATE_FILTER_ID: number = 3;
  private readonly LOCATION_NAME_FILTER_ID: number = 4;
  private readonly SUPPLIER_REFERENCE_FILTER_ID: number = 5;
  private readonly SUPPLIER_NAME_FILTER_ID: number = 6;
  private readonly QUANTITY_FILTER_ID: number = 7;
  private readonly SIZE_VALUE_FILTER_ID: number = 8;
  private readonly SERIAL_NUMBER_FILTER_ID: number = 9;
  private readonly BATCH_NUMBER_FILTER_ID: number = 10;
  private readonly BRAND_NAME_FILTER_ID: number = 11;
  private readonly STORE_FILTER_ID: number = 12;

  constructor(
    private notificationHandlerService: NotificationHandlerService,
    translateService: TranslateService,
    messageService: MessageService,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private stockEntryLabelService: StockEntryLabelService,
    private lightService: LightService,
    private storeService: StoreService,
    protected userService: CaraUserService,
    private asynchronousTaskService: AsynchronousTaskService,
    private toastMessageService: ToastMessageService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
  }

  ngOnInit(): void {
    this.initSelectFormControl();

    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    } else {
      this.initObservables.push(this.fetchConnectedUserDetails());
    }

    this.initObservables.push(this.fetchBrands());
    this.initObservables.push(this.fetchSuppliers());
    this.initObservables.push(this.fetchStores());
    this.initObservables.push(this.fetchMainStore());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        const selectedStoreId = this.authService.getContextStoreId();
        this.contextStore = this.storeList.find((store: Light) => store.id === selectedStoreId);
        this.fetchStockEntryLabelList();
      })
    );
  }

  fetchStockEntryLabelList(): void {
    if (this.activeFilters.length === 0) {
      const storeOptions = this.storeList
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((store: Light) => new Option(store.id, store.name));

      const storeList = this.getElementFromList([this.contextStore.id], storeOptions, true);
      this.activeFilters.push(new SearchFilter("contextContact.id", SearchFilterOperator.IN, storeList));
    }
    this.subscriptionService.subs.push(
      this.stockEntryLabelService.getAll(this.pager, this.getSorter(), this.activeFilters).subscribe(
        (result: PaginatedList<StockEntryLabel>) => {
          this.rows = [];
          this.stockEntryLabelList = result.data;
          this.pager = result.page;
          this.currentRowsId = result.data.map((po: StockEntryLabel) => po.id);
          result.data.forEach((stockEntryLabel: StockEntryLabel) => {
            this.addRow(stockEntryLabel);
          });
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-stock-entries-label`, error.message);
        },
        () => {
          this.rows = [...this.rows];
          this.table.sorts = this.sorts;
          this.table.offset = this.pager.number;
          this.initFilters();
          this.updateSelection();
          this.updateHeaderPageCheckbox();
        }
      )
    );
  }

  addRow(stockEntryLabel: StockEntryLabel): void {
    const se = stockEntryLabel.stockEntry;
    this.rows.push({
      id: stockEntryLabel.id,
      itemReference: se.itemReference,
      itemName: se.itemName,
      deliveryRef: stockEntryLabel.deliveryRef,
      receiveDate: stockEntryLabel.receiveDate,
      locationName: stockEntryLabel.locationName,
      itemSupplierRef: se.itemSupplierRef,
      supplierName: se.supplierName,
      quantity: stockEntryLabel.receivedQuantity,
      sizeValue: se.sizeValue,
      serialNumber: se.stockType === StockType.SERIAL_NUMBER ? se.id : null,
      batchNumber: [StockType.BATCH, StockType.BULK].includes(se.stockType) ? se.id : null,
      brandName: se.itemBrandName,
    });
    this.tableControl.addControl(this.getRowControlName(stockEntryLabel.id.toString()), new UntypedFormControl(false));
  }

  public getRowControlName(id: number | string): string {
    return `checked_${id}`;
  }

  public onRowCheckboxChange(): void {
    this.updateHeaderPageCheckbox();
    this.updateSelection();
  }

  public onHeaderCheckboxChange(): void {
    this.updateRowsPageCheckbox();
    this.updateSelection();
  }

  updateHeaderPageCheckbox(): void {
    const currentPageRowsCheckedIds = this.currentRowsId.filter(id => {
      return this.tableControl.get(this.getRowControlName(id)).value;
    });
    const isSelected = this.currentRowsId.length > 0 && this.currentRowsId.length === currentPageRowsCheckedIds.length;
    this.tableControl.controls.headerCheckbox.patchValue(isSelected);
  }

  updateRowsPageCheckbox(): void {
    const controls = this.tableControl.controls;
    const isHeaderSelected = this.tableControl.controls.headerCheckbox.value;
    this.currentRowsId.forEach(id => {
      controls[this.getRowControlName(id)].patchValue(isHeaderSelected);
    });
  }

  public updateSelection(): void {
    this.rows.forEach(row => {
      const rowChecked: boolean = this.tableControl.controls[this.getRowControlName(row.id)].value;
      const stockEntryLabel: StockEntryLabel = this.stockEntryLabelList.find(elem => elem.id === row.id);
      const isPOSelected: boolean = this.selectedRows.findIndex(elem => elem.id === row.id) === -1 ? false : true;

      if (!isPOSelected && rowChecked) {
        this.selectedRows.push(stockEntryLabel);
      } else if (isPOSelected && !rowChecked) {
        const poIndex = this.selectedRows.findIndex(elem => elem.id === stockEntryLabel.id);
        this.selectedRows.splice(poIndex, 1);
      }
    });
  }

  // the arrow function bellow is used to return the rows class
  getRowClass: any = (): any => ({ "not-clickable": true });

  getTranslationPrefix(): string {
    return "stock-entry-label-list";
  }

  getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  public changeSortSettings(prop: string, dir: string): void {
    this.sorts = [];
    if (prop === "batchNumber") {
      this.sorts.push({
        prop: "stockEntry.stockType",
        dir: "desc",
      });
    } else if (prop === "serialNumber") {
      this.sorts.push({
        prop: "stockEntry.stockType",
        dir: "asc",
      });
    }
    this.sorts.push({
      prop,
      dir: dir,
    });
    this.fetchStockEntryLabelList();
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchStockEntryLabelList();
  }

  propToDto(prop: string): string {
    switch (prop) {
      case "itemReference":
        return "stockEntry.retailItem.reference";
      case "itemName":
        return "stockEntry.retailItem.name";
      case "itemSupplierRef":
        return "stockEntry.itemSupplierRef";
      case "supplierName":
        return "stockEntry.supplierName";
      case "sizeValue":
        return "stockEntry.sizeValue";
      case "serialNumber":
        return "stockEntry.id";
      case "batchNumber":
        return "stockEntry.id";
      case "brandName":
        return "stockEntry.retailItem.brand.name";
      case "quantity":
        return "receivedQuantity";
      default:
        return prop;
    }
  }

  initFilters(): void {
    if (this.availableFilters) {
      return;
    }

    this.availableFilters = [];

    this.availableFilters.push(
      new Filter(
        this.STORE_FILTER_ID,
        this.translateService.instant("stock-entry-label-list.datatable.columns.perimeter"),
        "list",
        [this.contextStore.id],
        this.contextStore.id === this.mainStore.id
          ? this.storeList
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((store: Light) => new Option(store.id, store.name))
          : [new Option(this.contextStore.id, this.contextStore.name)],
        true,
        this.isFilterSaved(this.LIST_ID, this.STORE_FILTER_ID)
      )
    );

    this.availableFilters.push(
      new Filter(
        this.ITEM_REFERENCE_FILTER_ID,
        this.translateService.instant(`${this.getTranslationPrefix()}.datatable.columns.item-ref`),
        "string",
        null,
        null,
        false,
        this.isFilterSaved(this.LIST_ID, this.ITEM_REFERENCE_FILTER_ID)
      )
    );

    this.availableFilters.push(
      new Filter(
        this.ITEM_NAME_FILTER_ID,
        this.translateService.instant("stock-entry-label-list.datatable.columns.item-name"),
        "string",
        null,
        null,
        false,
        this.isFilterSaved(this.LIST_ID, this.ITEM_NAME_FILTER_ID)
      )
    );

    this.availableFilters.push(
      new Filter(
        this.DELIVERY_REFERENCE_FILTER_ID,
        this.translateService.instant("stock-entry-label-list.datatable.columns.delivery-ref"),
        "string",
        null,
        null,
        false,
        this.isFilterSaved(this.LIST_ID, this.DELIVERY_REFERENCE_FILTER_ID)
      )
    );

    this.availableFilters.push(this.initDateFilter(this.RECEIVE_DATE_FILTER_ID, "receive-date"));

    this.availableFilters.push(
      new Filter(
        this.LOCATION_NAME_FILTER_ID,
        this.translateService.instant("stock-entry-label-list.datatable.columns.location-name"),
        "string",
        null,
        null,
        false,
        this.isFilterSaved(this.LIST_ID, this.LOCATION_NAME_FILTER_ID)
      )
    );

    this.availableFilters.push(
      new Filter(
        this.SUPPLIER_REFERENCE_FILTER_ID,
        this.translateService.instant("stock-entry-label-list.datatable.columns.supplier-ref"),
        "string",
        null,
        null,
        false,
        this.isFilterSaved(this.LIST_ID, this.SUPPLIER_REFERENCE_FILTER_ID)
      )
    );

    this.availableFilters.push(
      new Filter(
        this.SUPPLIER_NAME_FILTER_ID,
        this.translateService.instant("stock-entry-label-list.datatable.columns.supplier-name"),
        "list",
        null,
        this.supplierOptions,
        false,
        this.isFilterSaved(this.LIST_ID, this.SUPPLIER_NAME_FILTER_ID)
      )
    );

    this.availableFilters.push(
      new Filter(
        this.QUANTITY_FILTER_ID,
        this.translateService.instant("stock-entry-label-list.datatable.columns.quantity"),
        "string",
        null,
        null,
        false,
        this.isFilterSaved(this.LIST_ID, this.QUANTITY_FILTER_ID)
      )
    );

    this.availableFilters.push(
      new Filter(
        this.SIZE_VALUE_FILTER_ID,
        this.translateService.instant("stock-entry-label-list.datatable.columns.size-value"),
        "string",
        null,
        null,
        false,
        this.isFilterSaved(this.LIST_ID, this.SIZE_VALUE_FILTER_ID)
      )
    );

    this.availableFilters.push(
      new Filter(
        this.SERIAL_NUMBER_FILTER_ID,
        this.translateService.instant("stock-entry-label-list.datatable.columns.serial-number"),
        "string",
        null,
        null,
        false,
        this.isFilterSaved(this.LIST_ID, this.SERIAL_NUMBER_FILTER_ID)
      )
    );

    this.availableFilters.push(
      new Filter(
        this.BATCH_NUMBER_FILTER_ID,
        this.translateService.instant("stock-entry-label-list.datatable.columns.batch-number"),
        "string",
        null,
        null,
        false,
        this.isFilterSaved(this.LIST_ID, this.BATCH_NUMBER_FILTER_ID)
      )
    );

    this.availableFilters.push(
      new Filter(
        this.BRAND_NAME_FILTER_ID,
        this.translateService.instant("stock-entry-label-list.datatable.columns.brand"),
        "list",
        null,
        this.brandOptions,
        false,
        this.isFilterSaved(this.LIST_ID, this.BRAND_NAME_FILTER_ID)
      )
    );
  }

  applyFilters(): void {
    this.activeFilters = [];
    this.pager.number = 0;

    for (const filterValue of this.filterValues) {
      switch (filterValue.filterId) {
        case this.ITEM_REFERENCE_FILTER_ID:
          this.activeFilters.push(
            new SearchFilter("stockEntry.retailItem.reference", SearchFilterOperator.LIKE, filterValue.equal)
          );
          break;

        case this.ITEM_NAME_FILTER_ID:
          this.activeFilters.push(
            new SearchFilter("stockEntry.retailItem.name", SearchFilterOperator.LIKE, filterValue.equal)
          );
          break;

        case this.DELIVERY_REFERENCE_FILTER_ID:
          this.activeFilters.push(new SearchFilter("deliveryRef", SearchFilterOperator.LIKE, filterValue.equal));
          break;

        case this.LOCATION_NAME_FILTER_ID:
          this.activeFilters.push(new SearchFilter("location.name", SearchFilterOperator.LIKE, filterValue.equal));
          break;

        case this.SUPPLIER_REFERENCE_FILTER_ID:
          this.activeFilters.push(
            new SearchFilter("stockEntry.itemSupplierRef", SearchFilterOperator.LIKE, filterValue.equal)
          );
          break;

        case this.QUANTITY_FILTER_ID:
          this.activeFilters.push(new SearchFilter("receivedQuantity", SearchFilterOperator.LIKE, filterValue.equal));
          break;

        case this.SIZE_VALUE_FILTER_ID:
          this.activeFilters.push(
            new SearchFilter("stockEntry.sizeValue", SearchFilterOperator.LIKE, filterValue.equal)
          );
          break;

        case this.SERIAL_NUMBER_FILTER_ID:
          this.applyStockType(filterValue, StockType.SERIAL_NUMBER, this.SERIAL_NUMBER_FILTER_ID);
          break;

        case this.BATCH_NUMBER_FILTER_ID:
          this.applyStockType(filterValue, [StockType.BATCH, StockType.BULK], this.BATCH_NUMBER_FILTER_ID);
          break;

        case this.RECEIVE_DATE_FILTER_ID:
          this.applyDateFilter(filterValue, "receiveDate");
          break;

        case this.SUPPLIER_NAME_FILTER_ID: {
          const supplier = this.getElementFromList(filterValue.equal as number[], this.supplierOptions, false);
          this.activeFilters.push(new SearchFilter("stockEntry.supplier.name", SearchFilterOperator.IN, supplier));
          break;
        }

        case this.BRAND_NAME_FILTER_ID: {
          const brand = this.getElementFromList(filterValue.equal as number[], this.brandOptions, false);
          this.activeFilters.push(new SearchFilter("stockEntry.retailItem.brand.name", SearchFilterOperator.IN, brand));
          break;
        }

        case this.STORE_FILTER_ID: {
          const storeOptions = this.storeList
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((store: Light) => new Option(store.id, store.name));

          const storeList = this.getElementFromList(filterValue.equal as number[], storeOptions, true);
          this.activeFilters.push(new SearchFilter("contextContact.id", SearchFilterOperator.IN, storeList));
          break;
        }
        default:
          break;
      }
    }
    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterValues.map(fv => fv.filterId),
        this.LIST_ID
      ).subscribe(() => {
        this.fetchStockEntryLabelList();
      })
    );
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  showPrintSELPopup(): void {
    this.printSettingsPopupVisible = true;
  }

  showDeleteSELPopup(): void {
    this.deletePopupVisible = true;
  }

  validateChangeSettingsPopup(firstLabelIndex: number): void {
    this.firstLabelIndex = firstLabelIndex;
    this.closeSettingsPopup();
    this.afterPrint = true;
    this.deletePopupVisible = true;
  }

  closeSettingsPopup(): void {
    this.printSettingsPopupVisible = false;
  }

  public validateDeletePopup(deleteLabels: boolean): void {
    this.deleteObservables = [];
    if (!this.afterPrint) {
      if (!deleteLabels) {
        this.closeDeletePopup();
        return;
      }
      this.deleteObservables.push(this.deleteSelectedStockEntryLabels());
      this.subscriptionService.subs.push(
        combineLatest(this.deleteObservables).subscribe(() => {
          this.fetchStockEntryLabelList();
        })
      );
    } else {
      this.print(deleteLabels);
      // WARN: In this case, we cannot fetch the list of stock entry labels after the print because it's an asynchronous task.
    }
    this.pager.number = 0;
    this.selectedRows = [];
    this.closeDeletePopup();
  }

  closeDeletePopup(): void {
    this.deletePopupVisible = false;
    this.afterPrint = false;
  }

  print(deleteLabels: boolean): void {
    const search: SearchFilter = new SearchFilter(
      "id",
      SearchFilterOperator.IN,
      this.selectedRows.map(row => row.id).join("~")
    );

    const asyncCreationTask = {
      type: "generatePdf",
      params: `stock-entry-label;${search.toQuery()};${this.firstLabelIndex};${deleteLabels}`,
    };
    this.asynchronousTaskService.create(new AsynchronousTaskCreation(asyncCreationTask)).subscribe({
      next: () => {
        this.toastMessageService.generateMessage(
          "info",
          "task-notification.message.on-going-title",
          "task-notification.message.on-going-message"
        );
        this.notificationHandlerService.showHandler();
      },
      error: () =>
        this.toastMessageService.generateMessage("error", "message.title.api-errors", "message.content.data-errors"),
    });
  }

  protected fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.locale = connectedUser.codeLanguage;
        this.dateFormat = connectedUser.dateFormat;
      })
    );
  }

  protected getElementFromList(event: number[], optionList: any[], returnId: boolean): any[] {
    const list = [];
    if (event) {
      event.forEach(element => {
        returnId
          ? list.push(optionList.find(opt => opt.id === element).id)
          : list.push(optionList.find(opt => opt.id === element).label);
      });
    }
    return list;
  }

  // handle select checkbox
  private initSelectFormControl(): void {
    const control = new UntypedFormControl(false);
    this.tableControl = this.fb.group({
      headerCheckbox: control,
    });
  }

  private fetchBrands(): Observable<Light[]> {
    return this.lightService.getBrands().pipe(
      tap(
        (brands: Light[]) => {
          this.brandOptions = brands
            .filter((obj: Brand) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Brand) => new Option(obj.id, obj.name));
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-brands`, error.message);
        }
      )
    );
  }

  private fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap(
        (suppliers: Light[]) => {
          this.supplierOptions = suppliers
            .filter((obj: Supplier) => !obj.archived)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((obj: Supplier) => new Option(obj.id, obj.name));
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-suppliers`, error.message);
        }
      )
    );
  }

  private fetchStores(): Observable<Light[]> {
    return this.lightService.getStores().pipe(
      tap(
        (lightStores: Light[]) => {
          this.storeList = lightStores;
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-stores`, error.message);
        }
      )
    );
  }

  private fetchMainStore(): Observable<Store> {
    return this.storeService.getMain().pipe(
      tap(
        (mainStore: Store) => {
          this.mainStore = mainStore;
        },
        error => {
          this.sendErrorAlert(`${this.getTranslationPrefix()}.errors.get-main-store`, error.message);
        }
      )
    );
  }

  private initDateFilter(filterId: number, label: string): Filter {
    const date = new Date();
    const m = date.getMonth();
    const y = date.getFullYear();

    const lastDay = new Date(y, m + 1, 0);
    const firstDay = new Date(y, m, 1);
    const defaultValue = {
      from: dayjs(firstDay),
      to: dayjs(lastDay),
    };
    return new Filter(
      filterId,
      this.translateService.instant(`stock-entry-label-list.datatable.columns.${label}`),
      "date",
      defaultValue,
      null,
      false,
      this.isFilterSaved(this.LIST_ID, filterId)
    );
  }

  private applyDateFilter(dateFilter: any, label: string): void {
    if (dateFilter.to) {
      const toDate = dayjs(dateFilter.to);
      this.activeFilters.push(
        new SearchFilter(label, SearchFilterOperator.LOWER_EQUAL_THAN, toDate.add(1, "d").valueOf().toString())
      );
    }
    if (dateFilter.from) {
      this.activeFilters.push(
        new SearchFilter(label, SearchFilterOperator.GREATER_EQUAL_THAN, dateFilter.from.valueOf().toString())
      );
    }
  }

  private applyStockType(typeFilter: any, stockType: any, filterId: number): void {
    if (typeFilter.equal === null) {
      return;
    }
    // return no line if user types letters
    if (isNaN(+typeFilter.equal)) {
      typeFilter.equal = "0";
    }

    if ((typeFilter.equal !== "" && typeFilter.equal !== "0") || typeFilter.equal === "0") {
      this.activeFilters.push(
        new SearchFilter("stockEntry.id", SearchFilterOperator.LIKE, typeFilter.equal.toString(), filterId)
      );
      this.activeFilters.push(new SearchFilter("stockEntry.stockType", SearchFilterOperator.IN, stockType, filterId));
    }
  }

  private deleteSelectedStockEntryLabels(): Observable<object> {
    return this.stockEntryLabelService.delete(this.selectedRows.map(row => row.id)).pipe(
      tap(
        () => {
          const title = this.translateService.instant("message.title.save-success");
          const content = this.translateService.instant("stock-entry-label-list.success.label-deleted");
          this.messageService.success(content, { title });
        },
        () => {
          const title = this.translateService.instant("message.title.api-errors");
          const content = this.translateService.instant("stock-entry-label-list.delete-popup.errors.print");
          this.messageService.error(content, { title });
        }
      )
    );
  }
}
