<ng-container>
  <div class="main-container frozen-right">
    <div class="title">
      <ui-title titleText="{{ 'shipment-form-list.title' | translate }}"></ui-title>
    </div>
    <div class="top-buttons">
      <div class="button-create">
        <ui-button
          text="{{ 'shipment-form-list.new-delivery-forms' | translate }}"
          type="add"
          [primary]="true"
          *hasPermission="['SHIPMENT_FORM_CREATE']"
          (click)="newShipmentForm()"
        ></ui-button>
      </div>
      <div class="button-shipment">
        <ui-button
          text="{{ 'shipment-form-list.ship-delivery-forms' | translate }}"
          type="ship"
          [primary]="false"
          *hasPermission="['SHIPMENT_FORM_CREATE']"
          (click)="shipDeliveryForm()"
        ></ui-button>
      </div>
    </div>
    <div class="shipment-form-list">
      <ui-container>
        <div class="filters" *ngIf="filterer">
          <ui-dynamic-filters
            label="{{ 'filters.label' | translate }}"
            [filters]="filterer.getFilters()"
            [(filterValues)]="filterer.filterValues"
            (apply)="applyFilters()"
            [locale]="locale"
            dateFormat="{{ dateFormat }}"
            applyLabel="{{ 'filters.apply' | translate }}"
            minLabel="{{ 'filters.min' | translate }}"
            maxLabel="{{ 'filters.max' | translate }}"
            deleteLabel="{{ 'filters.delete' | translate }}"
            placeholderPrefix="{{ 'filters.prefix' | translate }} "
          >
          </ui-dynamic-filters>
        </div>

        <div class="headband">
          <div class="selected-count">
            <span
              [innerHTML]="
                selectedShipmentFormList.length === 0
                  ? ('global.datatable.no-items' | translate)
                  : (selectedShipmentFormList.length
                    | i18nPlural
                      : {
                          '=1': 'global.datatable.n-selected-items',
                          other: 'global.datatable.n-selected-items_plural',
                        }
                    | translate: { selectedElements: selectedShipmentFormList.length })
              "
            >
            </span>
          </div>
          <div class="print-button">
            <ui-button
              text="{{ 'shipment-form-list.datatable.buttons.print' | translate }}"
              (click)="printShipmentForm()"
              [disabled]="selectedShipmentFormList.length === 0 || !hasPrintableStatus"
              [btnIcon]="faPrint"
            >
            </ui-button>
          </div>
          <div class="tooltip" *ngIf="selectedShipmentFormList.length === 0 || !hasPrintableStatus">
            <span class="info-icon" #info>
              <fa-icon [icon]="faInfoCircle"></fa-icon>
            </span>
            <ui-tooltip maxWidth="200px" [element]="info">
              <div>{{ "shipment-form-list.print-tooltip" | translate }}</div>
            </ui-tooltip>
          </div>
          <div class="buttons">
            <div class="button-change">
              <ui-button
                text="{{ 'purchase-order-list.datatable.buttons.status-change' | translate }}"
                (click)="openChangeStatusPopup()"
                [disabled]="canChangeStatus()"
                [btnIcon]="faSync"
              >
              </ui-button>
            </div>
          </div>
          <div class="tooltip" *ngIf="canChangeStatus()">
            <span class="info-icon" #info>
              <fa-icon [icon]="faInfoCircle"></fa-icon>
            </span>
            <ui-tooltip maxWidth="200px" [element]="info">
              <div>{{ "shipment-form-list.status-tooltip" | translate }}</div>
            </ui-tooltip>
          </div>
          <div class="buttons">
            <div class="button-change">
              <ui-button
                text="{{ 'shipment-form-list.add-shipment-infos-popin.button' | translate }}"
                (click)="openAddShipmentInfoPopup()"
                [disabled]="this.selectedShipmentFormList.length === 0"
                [btnIcon]="faTruckFast"
              >
              </ui-button>
            </div>
          </div>
        </div>

        <div class="separator">
          <div class="pointer">
            <div class="arrow"></div>
          </div>
          <div class="line"></div>
        </div>

        <div [formGroup]="tableControl" class="shipment-form-table">
          <div>
            <ngx-datatable
              #table
              [rows]="rows"
              [trackByProp]="'id'"
              class="material"
              columnMode="force"
              headerHeight="auto"
              footerHeight="50"
              rowHeight="40"
              [cssClasses]="{
                sortAscending: 'fas fa-chevron-up',
                sortDescending: 'fas fa-chevron-down',
              }"
              (activate)="updateShipmentForm($event)"
              [messages]="{ emptyMessage: 'global.no-data' | translate }"
              [externalSorting]="true"
              [externalPaging]="true"
              [count]="pager.totalElements"
              [offset]="pager.number"
              [limit]="pager.size"
              [rowClass]="getRowClass"
              scrollbarH="true"
              [sorts]="sorts"
              (sort)="changeSortSettings($event.column.prop, $event.newValue)"
            >
              <ngx-datatable-column
                width="50"
                [sortable]="false"
                [frozenLeft]="true"
                [draggable]="false"
                [resizeable]="false"
                [canAutoResize]="false"
                cellClass="center-align frozen-style"
              >
                <ng-template ngx-datatable-header-template>
                  <div class="green-zone" (click)="$event.stopPropagation()">
                    <ui-checkbox
                      customClass="no-margin"
                      (change)="onHeaderCheckboxChange()"
                      [formControlName]="'headerCheckbox'"
                    >
                    </ui-checkbox>
                  </div>
                </ng-template>

                <ng-template ngx-datatable-cell-template let-id="row.id">
                  <div class="adjust green-zone" (click)="$event.stopPropagation()">
                    <ui-checkbox
                      customClass="no-margin"
                      (change)="onRowCheckboxChange()"
                      [formControlName]="getRowControlName(id)"
                    >
                    </ui-checkbox>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                cellClass="center-align frozen-style"
                [sortable]="false"
                [resizeable]="false"
                [draggable]="false"
                width="37"
                [frozenLeft]="true"
                [canAutoResize]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <ng-container *ngIf="row.stockDepleted">
                    <div #errorIcon>
                      <fa-icon class="error-icon" [icon]="faError"></fa-icon>

                      <ui-tooltip maxWidth="200px" [element]="errorIcon" customClass="error">
                        <span style="font-weight: normal !important">
                          {{
                            "shipment-form-list.errors.stock-depleted"
                              | translate: { deliveryRef: row.reference ? row.reference : "BL" }
                          }}
                        </span>
                      </ui-tooltip>
                    </div>
                  </ng-container>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'shipment-form-list.datatable.columns.reference' | translate }}"
                prop="reference"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'shipment-form-list.datatable.columns.created-at' | translate }}"
                prop="createdAt"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-date="row.createdAt">
                  <span *ngIf="date">{{ date | dateTimeFormat: false }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'shipment-form-list.datatable.columns.shipment-date' | translate }}"
                prop="shipmentDate"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-date="row.shipmentDate">
                  <span *ngIf="date">{{ date | dateTimeFormat: false }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'shipment-form-list.datatable.columns.expected-delivery-date' | translate }}"
                prop="expectedDeliveryDate"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-date="row.expectedDeliveryDate">
                  <span *ngIf="date">{{ date | dateTimeFormat: false }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'shipment-form-list.datatable.columns.receiver-reference' | translate }}"
                prop="receiverReference"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'shipment-form-list.datatable.columns.receiver' | translate }}"
                prop="receiver"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'shipment-form-list.datatable.columns.type' | translate }}"
                prop="type"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'shipment-form-list.datatable.columns.creation-type' | translate }}"
                prop="creationType"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                *hasPermission="['PURCHASING_PRICE']"
                name="{{ 'shipment-form-list.datatable.columns.total-price' | translate }}"
                prop="totalPrice"
                [resizeable]="false"
              >
                <ng-template ngx-datatable-cell-template let-currencyIsoCode="row.currencyIsoCode" let-value="value">
                  <div class="adjust">
                    {{ value | formatCurrency: currencyIsoCode : "symbol" : "0.2-2" : locale | async }}
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'shipment-form-list.datatable.columns.sender' | translate }}"
                prop="sender"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'shipment-form-list.datatable.columns.shipment-info' | translate }}"
                prop="shipmentInfo"
                [cellClass]="'ellipsis'"
                [resizeable]="false"
                [sortable]="false"
              >
                <ng-template ngx-datatable-cell-template let-row="row" let-shipmentInfo="row.shipmentInfo">
                  <div class="ellipsis" title="{{ shipmentInfo.join(', ') }}" #shipmentInfoElem>
                    <span>
                      {{ shipmentInfo.join(", ") }}
                    </span>
                  </div>
                  <ui-tooltip maxWidth="300px" [element]="shipmentInfoElem">
                    <span style="font-weight: normal !important; white-space: break-spaces">
                      {{ shipmentInfo.join("\n") }}
                    </span>
                  </ui-tooltip>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'shipment-form-list.datatable.columns.receive-status' | translate }}"
                prop="receiveStatus"
                [resizeable]="false"
              >
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'shipment-form-list.datatable.columns.shipment-status' | translate }}"
                prop="shipmentStatus"
                headerClass="left-align"
                cellClass="frozen-style"
                [resizeable]="false"
                [frozenRight]="true"
              >
                <ng-template ngx-datatable-cell-template let-row="row">
                  <ui-status-label [label]="row.shipmentStatus" [customClass]="row.shipmentStatusClass">
                  </ui-status-label>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-selectedCount="selectedCount"
                  let-curPage="curPage"
                  let-offset="offset"
                  let-isVisible="isVisible"
                >
                  <div class="page-count" *ngIf="rowCount">
                    {{
                      rowCount
                        | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                        | translate: { rowCount: rowCount }
                    }}
                  </div>
                  <datatable-pager
                    [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                    [pagerRightArrowIcon]="'fas fa-chevron-right'"
                    [pagerNextIcon]="'fas fa-chevron-double-right'"
                    [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!(rowCount / pageSize > 1)"
                    (change)="changePage($event)"
                  >
                  </datatable-pager>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </div>
      </ui-container>
    </div>
  </div>

  <!-- initiate status popup -->
  <app-shipment-form-status-popup
    *ngIf="statusPopupVisible"
    [shipmentFormList]="selectedShipmentFormList"
    (validate)="validateChangeStatusPopup()"
    (close)="closeChangeStatusPopup()"
  ></app-shipment-form-status-popup>

  <app-shipment-form-info-popup
    *ngIf="addShipmentInfoPopupVisible"
    [shipmentFormList]="selectedShipmentFormList"
    (validate)="validateAddShipmentInfoPopup()"
    (close)="closeAndResetAddShipmentInfo()"
  ></app-shipment-form-info-popup>

  <!-- initiate form creation popup -->
  <app-shipment-initiator-popup
    *ngIf="initiatorPopupVisible"
    (close)="closeInitiatorPopup()"
  ></app-shipment-initiator-popup>
</ng-container>
