import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IconDefinition, faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import {
  Brand,
  JobType,
  BrandService,
  CurrencyService,
  DocumentService,
  FormErrorService,
  CommercialModality,
  Currency,
  Address,
} from "center-services";
import { SessionPagination, SubscriptionService } from "fugu-common";
import { ColorPickerComponent, MessageService } from "fugu-components";
import { ComponentDirty, ErrorUtil, PopupSaveUtil, DottedPropertyUtil } from "generic-pages";
import { BrandCollectionListComponent } from "../brand-collection/brand-collection-list/brand-collection-list.component";
import { BrandSuppliersComponent } from "../brand-suppliers/brand-suppliers.component";

@Component({
  selector: "app-brand-form",
  templateUrl: "./brand-form.component.html",
  styleUrls: ["./brand-form.component.scss"],
  providers: [SubscriptionService],
})
export class BrandFormComponent implements OnInit, ComponentDirty {
  @ViewChild("tabHandler") tabHandler: any;
  @ViewChild("generalDatas") generalDatas: any;
  @ViewChild("brandSuppliers") brandSuppliers: any;
  @ViewChild("brandCollections") brandCollections: any;
  @ViewChild("commercialModality") commercialModality: any;

  public title: string;
  public subTitle: string;
  public unsavedFile: any;
  public editedBrand: Brand;
  public updatedBrand: Brand;
  public unsavedBrand: Brand;
  public faChevronLeft: IconDefinition = faChevronLeft;

  public shouldClose: boolean = false;

  public modifiedFile: any;

  public jobTypeExternalContact: JobType = JobType.EXTERNAL_CONTACT;

  public hasError: boolean = false;

  // eslint-disable-next-line no-magic-numbers
  protected readonly PAYLOAD_TOO_LARGE: number = 413;

  constructor(
    private brandService: BrandService,
    private currencyService: CurrencyService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private documentService: DocumentService,
    private formErrorService: FormErrorService,
    private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    const defaultCommercialModality = new CommercialModality({
      id: null,
      vatActivated: true,
      discountRate: null,
      paymentType: null,
      comment: null,
      minOrderPrice: null,
      marginCoef: null,
      deliveryDelay: null,
      quickPaymentDiscountType: null,
      quickPaymentPeriod: null,
      quickPaymentDiscount: null,
      maxPaymentType: null,
      maxPaymentPeriod: null,
      importation: null,
      currencyId: null,
      forwardingPersonId: null,
      forwardingPriceCurrencyId: null,
      forwardingPrice: null,
      downPaymentPercent: null,
      shippingFeeType: null,
      shippingFeeRanges: [],
      happyNewYearDiscounts: [],
    });

    const brandId = this.route.snapshot.params.id;
    this.title = brandId ? "brand.title.update" : "brand.title.new";
    if (brandId) {
      this.subscriptionService.subs.push(
        this.brandService.get(brandId).subscribe(
          (brand: Brand) => {
            if (!brand.commercialModality) {
              brand.commercialModality = defaultCommercialModality;
            }

            this.editedBrand = brand;
            this.updatedBrand = new Brand(this.editedBrand);
            this.subTitle = this.getSubtitle(brand);
          },
          () => {
            this.router.navigateByUrl("/brands-list");
          }
        )
      );
    } else {
      this.subscriptionService.subs.push(
        this.currencyService.getDefault().subscribe((currency: Currency) => {
          this.updatedBrand = new Brand({
            name: null,
            reference: null,
            email: null,
            phone: null,
            website: null,
            codeRCS: null,
            comment: null,
            logoId: null,
            archived: false,
            originCountryId: null,
            address: new Address({ byDefault: true }),
            color: ColorPickerComponent.DEFAULT_COLOR,
            commercialModality: defaultCommercialModality,
            currencyId: null,
            tagIds: [],
          });

          this.updatedBrand.currencyId = currency.id;
          this.editedBrand = new Brand(this.updatedBrand);
        })
      );
    }
  }

  getSubtitle(brand: Brand): string {
    const subtitleList = [];
    if (brand.reference) {
      subtitleList.push(brand.reference);
    }
    subtitleList.push(brand.name);

    return subtitleList.join(" - ");
  }

  isDirty(): boolean {
    if (!this.updatedBrand) {
      return false;
    }

    let initialFile = null;
    let newFile = null;

    if (this.generalDatas) {
      initialFile = this.generalDatas.previousFile;
      newFile = this.generalDatas.selectedFile;
      this.generalDatas.applyModifications();
    }

    if (
      (this.unsavedBrand && !this.unsavedBrand.equals(this.updatedBrand)) ||
      (this.unsavedFile && newFile && this.unsavedFile !== newFile)
    ) {
      this.shouldClose = false;
    }

    if (
      ((this.editedBrand && !this.editedBrand.equals(this.updatedBrand)) || initialFile !== newFile) &&
      !this.shouldClose
    ) {
      this.recordDatas();
      return true;
    } else if (!this.editedBrand && !this.shouldClose) {
      this.recordDatas();
      return true;
    }

    this.unsavedBrand = null;
    this.shouldClose = false;
    this.unsavedFile = null;
    return false;
  }

  recordDatas(): void {
    if (this.generalDatas) {
      this.unsavedFile = this.generalDatas.selectedFile;
    }
    this.unsavedBrand = new Brand(this.updatedBrand);
    this.shouldClose = true;
  }

  onTabClick(tab: any): void {
    if (this.brandCollections) {
      this.brandCollections.savePaginationToSession();
    }
    if (this.brandSuppliers) {
      this.brandSuppliers.savePaginationToSession();
    }
    if (this.updatedBrand) {
      this.submitBrand(tab);
    }
  }

  catchEvent(source: string, object: any): void {
    PopupSaveUtil.manageEvent(this.editedBrand, this.updatedBrand, object, source, this.saveBrandElement.bind(this));
  }

  submitBrand(nextTab: any = null): void {
    this.hasError = false;
    let fileUpdated = false;

    // handle generalDatas tab
    if (this.generalDatas) {
      if (!this.generalDatas.updateBrand()) {
        this.formErrorService.handleFormError();
        return;
      }

      // handle file
      this.modifiedFile = this.generalDatas.getFile();
      fileUpdated = this.generalDatas.hasFileChanged();
    }

    if (this.hasError) {
      return;
    }

    if (this.commercialModality) {
      if (!this.commercialModality.isValid()) {
        this.formErrorService.handleFormError();
        return;
      }
    }

    if (this.editedBrand) {
      // if nothing has changed, no need to call the API to save the brand
      if (this.editedBrand.equals(this.updatedBrand) && !fileUpdated) {
        if (nextTab && this.tabHandler) {
          this.tabHandler.changeTab(nextTab);
        }
        return;
      }
    }

    this.callApi(nextTab);
  }

  callApi(nextTab: any = null): void {
    if (!this.updatedBrand) {
      return;
    }

    // when form is valid, create or update brand
    const action = this.editedBrand && this.editedBrand.id ? "update" : "create";
    this.saveBrand(action, nextTab);
  }

  saveBrand(action: string, nextTab: any): void {
    this.subscriptionService.subs.push(
      this.brandService[action].call(this.brandService, this.updatedBrand).subscribe(
        responseBrand => {
          this.saveLogo(nextTab, responseBrand);
        },
        error => {
          this.hasError = true;
          this.handleApiError(error);
        }
      )
    );
  }

  saveBrandElement(action: string | number, propName: string): void {
    return this.brandService[action]
      .call(this.brandService, this.editedBrand)
      .toPromise()
      .then(responseBrand => {
        const title = this.translateService.instant("message.title.save-success");
        const content = this.translateService.instant("message.content.save-success");
        this.messageService.success(content, { title });

        return DottedPropertyUtil.getDottedProperty(responseBrand, propName);
      })
      .catch(error => {
        this.hasError = true;
        this.handleApiError(error);
      });
  }

  saveLogo(nextTab: any, responseBrand: Brand): void {
    if (!this.generalDatas || !this.generalDatas.hasFileChanged()) {
      this.finishSave(nextTab, responseBrand);
      return;
    }

    const formData = new FormData();
    formData.set("file", this.modifiedFile);
    this.subscriptionService.subs.push(
      this.documentService.uploadFile(formData, responseBrand.logoId).subscribe(
        () => {
          this.generalDatas.previousFile = this.generalDatas.selectedFile;
          this.finishSave(nextTab, responseBrand);
        },
        (error: HttpErrorResponse) => {
          this.hasError = true;
          const res =
            error.status === this.PAYLOAD_TOO_LARGE
              ? this.translateService.instant("global.form.error.payload-too-large")
              : this.translateService.instant("brand.errors.upload-logo");
          const title = this.translateService.instant("message.title.api-errors");
          this.messageService.warn(res, { title });
          this.finishSave(nextTab, responseBrand);
        }
      )
    );
  }

  finishSave(nextTab: any, responseBrand: Brand): void {
    this.editedBrand = responseBrand;
    this.updatedBrand = new Brand(this.editedBrand);
    if (this.route.snapshot.params.id) {
      this.subTitle = this.getSubtitle(responseBrand);
    }

    const title = this.translateService.instant("message.title.save-success");
    const content = this.translateService.instant("message.content.save-success");
    this.messageService.success(content, { title });
    if (nextTab) {
      this.tabHandler.changeTab(nextTab);
    }
  }

  backToBrandList(): void {
    SessionPagination.clear(BrandCollectionListComponent.LIST_ID);
    SessionPagination.clear(BrandSuppliersComponent.LIST_ID);

    this.router.navigateByUrl("/brands-list");
  }

  handleApiError(error: any): void {
    const attributeTranslations = {
      name: "brand.general-datas.name",
      reference: "brand.general-datas.reference",
    };

    const title = this.translateService.instant("message.title.form-errors");

    const result = ErrorUtil.getTranslationKey(error.error, attributeTranslations, this.translateService);
    const content = this.translateService.instant(result.message, result.params);
    this.messageService.error(content, { title });
  }
}
