<ng-container>
  <div class="main-container frozen-left">
    <div class="header">
      <div class="return-link">
        <ui-link
          text="{{ getBackButtonTranslation() | translate }}"
          (click)="backToPrevious()"
          [linkIcon]="faChevronLeft"
        ></ui-link>
      </div>

      <div class="title">
        <ui-title
          titleText="{{ title | translate: { invoiceCustomerNumber: invoiceCustomerNumber } }}"
          subTitleText="{{ subTitle }}"
        ></ui-title>
      </div>
    </div>
    <div class="top-buttons">
      <div class="button-validate" *ngIf="!this.isValidated && !readOnly">
        <ui-button
          text="{{ 'invoice-customer-form.buttons.validate-invoice-customer' | translate }}"
          (click)="openInvoiceCustomer()"
          type="validate"
          [primary]="true"
        >
        </ui-button>
      </div>
      <div class="button-save" *ngIf="!this.isValidated && !readOnly">
        <ui-button text="{{ 'global.validate' | translate }}" (click)="submitInvoiceCustomer(false, false)" type="save">
        </ui-button>
      </div>
    </div>
    <div class="invoice-customer-form">
      <app-invoice-form-header
        #invoiceHeader
        *ngIf="updatedInvoiceCustomer && customer"
        [quickPaymentType]="this.getCommercialModalityWithAttribute('quickPaymentDiscountType')"
        [quickPaymentPeriod]="this.getCommercialModalityWithAttribute('quickPaymentPeriod')"
        [maxPaymentType]="this.getCommercialModalityWithAttribute('maxPaymentType')"
        [maxPaymentPeriod]="this.getCommercialModalityWithAttribute('maxPaymentPeriod')"
        [editedInvoice]="updatedInvoiceCustomer"
        [readOnly]="readOnly"
        [showComment]="true"
        [modeIsConform]="this.fetcher.isConform"
      >
      </app-invoice-form-header>

      <div class="add-document-btn" *ngIf="!readOnly">
        <div class="button">
          <ui-button
            text="{{ 'invoice-customer-form.buttons.doc-add' | translate }} "
            [primary]="false"
            (click)="openInvoiceCustomerInitiatorPopup()"
            [btnIcon]="faPlus"
          ></ui-button>
        </div>
      </div>

      <ui-tab-handler
        #tabHandler
        *ngIf="this.invoiceCustomerTabs.length > 0 && this.currency"
        [tabByUrl]="false"
        [independent]="false"
        (tabClick)="onTabClick($event)"
        [closeable]="!readOnly"
        (close)="onCloseTab($event)"
      >
        <ui-tab
          [active]="i === 0"
          [id]="i"
          *ngFor="let tab of this.invoiceCustomerTabs; let i = index"
          title="{{
            this.getTabName(tab) === null
              ? ('invoice-customer-form.tabs.title-free' | translate)
              : ('invoice-customer-form.tabs.title' | translate: { ref: getTabName(tab) })
          }}"
        >
          <ng-template #tabTemplate>
            <div class="sender-receiver-infos">
              <span class="sender sender-receiver-align"> {{ "invoice-customer-form.tabs.sender" | translate }} </span>
              <span class="sender-receiver-align">{{ tab.senderName }}</span>
              <span class="arrow-content sender-receiver-align"><fa-icon [icon]="faArrow"></fa-icon></span>
              <span class="receiver sender-receiver-align">
                {{ "invoice-customer-form.tabs.receiver" | translate }}</span
              >
              <span class="sender-receiver-align"> {{ tab.receiverName }}</span>
            </div>
            <div class="border-bottom-sender-receiver"></div>

            <div class="filters border-bottom-filters" *ngIf="filterer">
              <ui-dynamic-filters
                [locale]="fetcher.locale"
                [filters]="filterer.getFilters()"
                [(filterValues)]="filterer.filterValues"
                (apply)="applyFilters(tab)"
                [label]="'filters.label' | translate"
                minLabel="{{ 'filters.min' | translate }}"
                maxLabel="{{ 'filters.max' | translate }}"
                applyLabel="{{ 'filters.apply' | translate }}"
                deleteLabel="{{ 'filters.delete' | translate }}"
                placeholderPrefix="{{ 'filters.prefix' | translate }} "
              >
              </ui-dynamic-filters>
            </div>

            <div class="add-free-line-btn" *ngIf="!readOnly">
              <ui-button
                text="{{ 'invoice-customer-form.buttons.add-free-line' | translate }}"
                type="add"
                (click)="addFreeLine(tab)"
              ></ui-button>
            </div>

            <div class="{{ getTableClass() }}">
              <div class="datatable">
                <ngx-datatable
                  #table
                  [rows]="tab.rows"
                  rowHeight="40"
                  class="material vertical-scroll"
                  columnMode="force"
                  headerHeight="auto"
                  [rowClass]="getRowClass"
                  [scrollbarH]="true"
                  [summaryRow]="true"
                  [summaryHeight]="45"
                  [trackByProp]="'lineNumber'"
                  [summaryPosition]="'bottom'"
                  [sorts]="sorts"
                  [count]="pager.totalElements"
                  [offset]="pager.number"
                  [limit]="pager.size"
                  [cssClasses]="{
                    sortAscending: 'fas fa-chevron-up',
                    sortDescending: 'fas fa-chevron-down',
                  }"
                  [messages]="{ emptyMessage: 'global.no-data' | translate }"
                  (sort)="changeSortSettings($event.column.prop, $event.newValue, tab.id)"
                >
                  <ngx-datatable-column
                    name="{{ 'invoice-customer-form.datatable.columns.line-number' | translate }}"
                    cellClass="center-align frozen-style line-number"
                    [summaryTemplate]="totalSummary"
                    [resizeable]="false"
                    [draggable]="false"
                    prop="lineNumber"
                    [width]="80"
                    [canAutoResize]="false"
                    headerClass="frozen-left line-number"
                    [frozenLeft]="true"
                  >
                    <ng-template ngx-datatable-cell-template let-value="value">
                      <div>{{ value }}</div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-customer-form.datatable.columns.item-supplier-ref' | translate }}"
                    cellClass="center-align frozen-style item-customer-ref"
                    [resizeable]="false"
                    [draggable]="false"
                    prop="itemSupplierRef"
                    [frozenLeft]="true"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                      <ui-input-text
                        type="text"
                        class="input-fields"
                        [formControl]="row.rowForm.get('itemSupplierRef')"
                        sizeMode="small"
                        *ngIf="!readOnly && row.rowForm.get('itemSupplierRef')"
                      >
                      </ui-input-text>
                      <div *ngIf="readOnly || !row.rowForm.get('itemSupplierRef')">
                        {{ value }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-customer-form.datatable.columns.item-reference' | translate }}"
                    cellClass="item-reference"
                    [resizeable]="false"
                    [draggable]="false"
                    prop="itemRef"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                      <ui-input-text
                        type="text"
                        class="input-fields"
                        [formControl]="row.rowForm.get('itemRef')"
                        sizeMode="small"
                        *ngIf="!readOnly && row.rowForm.get('itemRef')"
                      >
                      </ui-input-text>
                      <div *ngIf="readOnly || !row.rowForm.get('itemRef')">{{ value }}</div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    [name]="'invoice-customer-form.datatable.columns.serial-number' | translate"
                    [canAutoResize]="false"
                    [summaryFunc]="null"
                    [resizeable]="false"
                    [draggable]="false"
                    prop="serialNumber"
                    [width]="95"
                    cellClass="serial-number ellipsis-counter"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row">
                      <ui-input-text
                        type="text"
                        class="input-fields"
                        [formControl]="row.rowForm.get('serialNumber')"
                        sizeMode="small"
                        *ngIf="!readOnly && row.rowForm.get('serialNumber')"
                      >
                      </ui-input-text>
                      <p class="ellipsis" *ngIf="readOnly || !row.rowForm.get('serialNumber')">
                        {{ row.serialNumber }}
                      </p>
                      <div
                        *ngIf="
                          (readOnly || !row.rowForm.get('serialNumber')) && transformSN(row.serialNumber).length >= 2
                        "
                      >
                        <div #serialNumberCounter>
                          <ui-counter [number]="transformSN(row.serialNumber).length"></ui-counter>
                        </div>
                        <ui-tooltip maxWidth="300px" [element]="serialNumberCounter" fallbackPlacement="left">
                          <span style="font-weight: normal !important; white-space: break-spaces">{{
                            transformSN(row.serialNumber).join(", ")
                          }}</span>
                        </ui-tooltip>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    [name]="'invoice-customer-form.datatable.columns.batch-number' | translate"
                    [canAutoResize]="false"
                    [summaryFunc]="null"
                    [resizeable]="false"
                    [draggable]="false"
                    prop="batchNumber"
                    [width]="95"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                      <ui-input-text
                        type="text"
                        class="input-fields"
                        [formControl]="row.rowForm.get('batchNumber')"
                        sizeMode="small"
                        *ngIf="!readOnly && row.rowForm.get('batchNumber')"
                      >
                      </ui-input-text>
                      <div *ngIf="readOnly || !row.rowForm.get('batchNumber')">{{ value }}</div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-customer-form.datatable.columns.item-name' | translate }}"
                    cellClass="item-name"
                    [resizeable]="false"
                    [draggable]="false"
                    prop="itemName"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                      <ui-input-text
                        type="text"
                        class="input-fields"
                        [formControl]="row.rowForm.get('itemName')"
                        sizeMode="small"
                        *ngIf="!readOnly && row.rowForm.get('itemName')"
                      >
                      </ui-input-text>
                      <div #itemElement class="ellipsis" *ngIf="readOnly || !row.rowForm.get('itemName')">
                        {{ value }}

                        <ui-tooltip [element]="itemElement">
                          <span style="font-weight: normal !important; white-space: break-spaces">
                            {{ value }}
                          </span>
                        </ui-tooltip>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-customer-form.datatable.columns.quantity' | translate }}"
                    cellClass="quantity"
                    [summaryTemplate]="quantitySummary"
                    [resizeable]="false"
                    [draggable]="false"
                    [sortable]="false"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row">
                      <ui-input-text
                        type="text"
                        class="input-fields"
                        [thousandSeparator]="' '"
                        [maskPattern]="decimalDigit"
                        [formControl]="row.rowForm.get('quantity')"
                        [status]="row.rowForm.get('quantity') | formStatus"
                        [errorMessage]="
                          row.rowForm.get('quantity') | formErrorMessage | translate: { digit: HIGH_INTEGER }
                        "
                        sizeMode="small"
                        *ngIf="!readOnly && row.rowForm.get('quantity')"
                      >
                      </ui-input-text>
                      <ui-input-text
                        type="text"
                        class="input-fields"
                        [formControl]="row.rowForm.get('unitName')"
                        sizeMode="small"
                        *ngIf="!readOnly && row.rowForm.get('unitName')"
                      >
                      </ui-input-text>

                      <span *ngIf="readOnly || !row.rowForm.get('quantity')">{{ row.quantity }}</span>
                      <span *ngIf="readOnly || !row.rowForm.get('unitName')"> {{ row.unitName }}</span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-customer-form.datatable.columns.size-value' | translate }}"
                    cellClass="size-value"
                    [resizeable]="false"
                    [draggable]="false"
                    prop="sizeValue"
                  >
                    <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                      <ui-input-text
                        type="text"
                        class="input-fields"
                        [formControl]="row.rowForm.get('sizeValue')"
                        sizeMode="small"
                        *ngIf="!readOnly && row.rowForm.get('sizeValue')"
                      >
                      </ui-input-text>
                      <div *ngIf="readOnly || !row.rowForm.get('sizeValue')">{{ value }}</div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-customer-form.datatable.columns.unit-price' | translate }}"
                    cellClass="unit-price"
                    [resizeable]="false"
                    [draggable]="false"
                    [sortable]="readOnly"
                    prop="unitPrice"
                    [summaryFunc]="null"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                      <ui-input-text
                        type="text"
                        class="input-fields"
                        [thousandSeparator]="' '"
                        [maskPattern]="decimalDigit"
                        [allowNegative]="row.rowForm.get('sizeValue')"
                        [suffix]="' ' + currency?.symbol"
                        [formControl]="row.rowForm.get('unitPrice')"
                        [status]="row.rowForm.get('unitPrice') | formStatus"
                        [errorMessage]="
                          row.rowForm.get('unitPrice') | formErrorMessage | translate: { digit: HIGH_INTEGER }
                        "
                        sizeMode="small"
                        *ngIf="!readOnly"
                      >
                      </ui-input-text>
                      <div *ngIf="readOnly">
                        {{ value | formatCurrency: currency?.codeISO : "symbol" : "0.2-5" : fetcher.locale | async }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-customer-form.datatable.columns.discount' | translate }}"
                    cellClass="discount sticky-right"
                    [resizeable]="false"
                    [draggable]="false"
                    [sortable]="readOnly"
                    prop="discount"
                    [summaryFunc]="null"
                    [width]="95"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                      <ui-input-text
                        *ngIf="!readOnly"
                        type="text"
                        class="input-fields input-fusion"
                        [thousandSeparator]="' '"
                        [maskPattern]="decimalDigit"
                        [formControl]="row.rowForm.get('discount')"
                        [status]="row.rowForm.get('discount') | formStatus"
                        errorMessage="{{
                          row.rowForm.get('discount')
                            | formErrorMessage
                              : {
                                  customPrefix: 'invoice-customer-form.errors.',
                                  useCustomPrefixFor: ['discountGreaterThanPriceOrPercent', 'max'],
                                }
                            | translate: { digit: HIGH_INTEGER }
                        }}"
                        sizeMode="small"
                      >
                      </ui-input-text>
                      <span *ngIf="readOnly"
                        >{{ value | number: "0.2-5" : fetcher.locale }}
                        {{ displayDiscountUnit(row.discountType) }}</span
                      >
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    *ngIf="!readOnly"
                    name=""
                    cellClass="discountType sticky-left"
                    [resizeable]="false"
                    [draggable]="false"
                    [sortable]="readOnly"
                    prop="discountType"
                    [summaryFunc]="null"
                    [width]="95"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                      <ui-list
                        *ngIf="!readOnly"
                        class="input-fields list-fusion"
                        [formControl]="row.rowForm.get('discountType')"
                        [status]="row.rowForm.get('discountType') | formStatus"
                        [options]="this.discountOptions"
                        errorMessage="row.rowForm.get('discountType') | formErrorMessage | translate"
                        [search]="false"
                        sizeMode="small"
                      ></ui-list>
                      <span *ngIf="readOnly"
                        >{{ value | number: "0.2-2" : fetcher.locale }}
                        {{ displayDiscountUnit(row.discountType) }}</span
                      >
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-customer-form.datatable.columns.total-gross-price' | translate }}"
                    cellClass="total-gross-price"
                    [resizeable]="false"
                    [draggable]="false"
                    prop="totalGrossPrice"
                    [summaryTemplate]="priceSummary"
                  >
                    <ng-template ngx-datatable-cell-template let-value="value">
                      <div>
                        {{ value | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : fetcher.locale | async }}
                      </div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-customer-form.datatable.columns.vat-rate' | translate }}"
                    cellClass="vat-rate"
                    [resizeable]="false"
                    [draggable]="false"
                    [sortable]="false"
                    [width]="95"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row">
                      <ui-input-text
                        *ngIf="!readOnly"
                        type="text"
                        class="input-fields"
                        [thousandSeparator]="' '"
                        [maskPattern]="decimalDigit"
                        [formControl]="row.rowForm.get('vatRate')"
                        [status]="row.rowForm.get('vatRate') | formStatus"
                        errorMessage="{{
                          row.rowForm.get('vatRate')
                            | formErrorMessage
                              : { customPrefix: 'invoice-customer-form.errors.', useCustomPrefixFor: ['max'] }
                            | translate
                        }}"
                        sizeMode="small"
                        [suffix]="' %'"
                      >
                      </ui-input-text>
                      <span *ngIf="readOnly"> {{ row.vatRate | number: "0.2-2" : fetcher.locale }} %</span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-customer-form.datatable.columns.metal-weight' | translate }}"
                    cellClass="metalWeight"
                    [resizeable]="false"
                    [draggable]="false"
                    [sortable]="readOnly"
                    prop="metalWeight"
                    [summaryFunc]="null"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row">
                      <ui-input-text
                        *ngIf="!readOnly && row.rowForm.get('metalWeight')"
                        type="text"
                        class="input-fields"
                        [thousandSeparator]="' '"
                        [maskPattern]="decimalDigit"
                        [formControl]="row.rowForm.get('metalWeight')"
                        [status]="row.rowForm.get('metalWeight') | formStatus"
                        [errorMessage]="
                          row.rowForm.get('metalWeight') | formErrorMessage | translate: { digit: HIGH_INTEGER }
                        "
                        sizeMode="small"
                        [suffix]="' g'"
                      >
                      </ui-input-text>
                      <span *ngIf="readOnly || !row.rowForm.get('metalWeight')">
                        {{
                          row.metalWeight === undefined || row.metalWeight === null ? "" : row.metalWeight + " g"
                        }}</span
                      >
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-customer-form.datatable.columns.tare' | translate }}"
                    cellClass="tare"
                    [resizeable]="false"
                    [draggable]="false"
                    [sortable]="readOnly"
                    prop="tare"
                    [summaryFunc]="null"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row">
                      <ui-input-text
                        *ngIf="!readOnly && row.rowForm.get('tare')"
                        type="text"
                        class="input-fields"
                        [thousandSeparator]="' '"
                        [maskPattern]="decimalDigit"
                        [formControl]="row.rowForm.get('tare')"
                        [status]="row.rowForm.get('tare') | formStatus"
                        [errorMessage]="row.rowForm.get('tare') | formErrorMessage | translate: { digit: HIGH_INTEGER }"
                        sizeMode="small"
                        [suffix]="' g'"
                      >
                      </ui-input-text>
                      <span *ngIf="readOnly || !row.rowForm.get('tare')">
                        {{ row.tare === undefined || row.tare === null ? "" : row.tare + " g" }}</span
                      >
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-customer-form.datatable.columns.weight' | translate }}"
                    cellClass="weight"
                    [resizeable]="false"
                    [draggable]="false"
                    [sortable]="readOnly"
                    prop="weight"
                    [summaryFunc]="null"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row">
                      <span> {{ !row.weight ? "" : row.weight + " g" }}</span>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-customer-form.datatable.columns.brand-name' | translate }}"
                    cellClass="brand-name"
                    [resizeable]="false"
                    [draggable]="false"
                    prop="brandName"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                      <ui-input-text
                        type="text"
                        class="input-fields"
                        [formControl]="row.rowForm.get('brandName')"
                        sizeMode="small"
                        *ngIf="!readOnly && row.rowForm.get('brandName')"
                      >
                      </ui-input-text>
                      <div *ngIf="readOnly || !row.rowForm.get('brandName')">{{ value }}</div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-customer-form.datatable.columns.supplier-name' | translate }}"
                    cellClass="supplier-name"
                    [resizeable]="false"
                    [draggable]="false"
                    prop="supplierName"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                      <ui-input-text
                        type="text"
                        class="input-fields"
                        [formControl]="row.rowForm.get('supplierName')"
                        sizeMode="small"
                        *ngIf="!readOnly && row.rowForm.get('supplierName')"
                      >
                      </ui-input-text>
                      <div *ngIf="readOnly || !row.rowForm.get('supplierName')">{{ value }}</div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-customer-form.datatable.columns.supplier-ref' | translate }}"
                    cellClass="supplier-ref"
                    [resizeable]="false"
                    [draggable]="false"
                    prop="supplierRef"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                      <ui-input-text
                        type="text"
                        class="input-fields"
                        [formControl]="row.rowForm.get('supplierRef')"
                        sizeMode="small"
                        *ngIf="!readOnly && row.rowForm.get('supplierRef')"
                      >
                      </ui-input-text>
                      <div *ngIf="readOnly || !row.rowForm.get('supplierRef')">{{ value }}</div>
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column
                    name="{{ 'invoice-customer-form.datatable.columns.actions' | translate }}"
                    headerClass="center-align"
                    cellClass="center-align frozen-style"
                    [resizeable]="false"
                    [frozenRight]="true"
                    [sortable]="false"
                    [width]="80"
                    *ngIf="!readOnly"
                  >
                    <ng-template ngx-datatable-cell-template let-row="row">
                      <div (click)="$event.stopPropagation()">
                        <ui-menu-actions [menuActions]="menuActions" (actionCalled)="manageActions($event, row, tab)">
                        </ui-menu-actions>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-footer>
                    <ng-template
                      ngx-datatable-footer-template
                      let-rowCount="rowCount"
                      let-pageSize="pageSize"
                      let-selectedCount="selectedCount"
                      let-curPage="curPage"
                      let-offset="offset"
                      let-isVisible="isVisible"
                    >
                      <div class="page-count" *ngIf="rowCount">
                        {{
                          rowCount
                            | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                            | translate: { rowCount: rowCount }
                        }}
                      </div>
                      <datatable-pager
                        [pagerLeftArrowIcon]="'fas fa-chevron-left'"
                        [pagerRightArrowIcon]="'fas fa-chevron-right'"
                        [pagerNextIcon]="'fas fa-chevron-double-right'"
                        [pagerPreviousIcon]="'fas fa-chevron-double-left'"
                        [page]="curPage"
                        [size]="pageSize"
                        [count]="rowCount"
                        [hidden]="!(rowCount / pageSize > 1)"
                        (change)="changePage($event)"
                      >
                      </datatable-pager>
                    </ng-template>
                  </ngx-datatable-footer>
                </ngx-datatable>
              </div>

              <ng-template #priceSummary>
                <div class="total-price summary-fields">
                  {{
                    tab.totalGrossPrice
                      | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : fetcher.locale
                      | async
                  }}
                </div>
              </ng-template>

              <ng-template #totalSummary>
                <div class="total-summary summary-fields">
                  {{ "invoice-customer-form.datatable.summary.total" | translate }}
                </div>
              </ng-template>

              <ng-template #quantitySummary>
                <div class="total-quantity summary-fields">
                  {{ tab.totalQuantity }}
                  <span class="summary-fields" *ngIf="multipleUnit(tab)">
                    <span #warningIcon>
                      <fa-icon class="warn-icon" [icon]="faWarn"></fa-icon>
                    </span>

                    <ui-tooltip maxWidth="160px" [element]="warningIcon">
                      <span style="font-weight: normal">{{
                        "invoice-customer-form.datatable.summary.warning-message" | translate
                      }}</span>
                    </ui-tooltip>
                  </span>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </ui-tab>
      </ui-tab-handler>

      <div *ngIf="this.currency" class="footer-total">
        <ui-container>
          <div class="footer-container" [formGroup]="form">
            <div class="footer-element">
              <div class="label total-gross-price">
                {{ "invoice-customer-form.footer.labels.total-gross-price" | translate }}
              </div>
              <div class="value total-gross-price">
                {{
                  this.totalGrossPrice | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : fetcher.locale | async
                }}
              </div>
            </div>
            <div class="footer-element">
              <div class="label total-quantity">
                {{ "invoice-customer-form.footer.labels.total-quantity" | translate }}
              </div>
              <div class="value total-quantity">
                {{ this.totalQuantity }}
                <span class="summary-fields" *ngIf="totalMultipleUnit()">
                  <span #warningIcon>
                    <fa-icon class="warn-icon" [icon]="faWarn"></fa-icon>
                  </span>

                  <ui-tooltip maxWidth="160px" [element]="warningIcon">
                    <span style="font-weight: normal">{{
                      "invoice-customer-form.datatable.summary.warning-message" | translate
                    }}</span>
                  </ui-tooltip>
                </span>
              </div>
            </div>
            <div class="footer-element">
              <ui-input-text
                label="{{ 'invoice-customer-form.footer.labels.total-shipping-fee-price' | translate }}"
                type="text"
                class="input-fields"
                [thousandSeparator]="' '"
                [maskPattern]="decimalDigit"
                [suffix]="' ' + currency?.symbol"
                formControlName="shippingFeePrice"
                [status]="this.form.get('shippingFeePrice') | formStatus"
                infoLabel="{{ 'invoice-customer-form.footer.infos.standard-vat-rate' | translate }}"
                [errorMessage]="
                  this.form.get('shippingFeePrice') | formErrorMessage | translate: { digit: HIGH_INTEGER }
                "
                customClass="no-margin"
                *ngIf="!readOnly"
              >
              </ui-input-text>
              <div class="total-shipping-fee-price" *ngIf="readOnly">
                <div class="footer-label-with-info">
                  <div>{{ "invoice-customer-form.footer.labels.total-shipping-fee-price" | translate }}</div>
                  <ui-info-field
                    infoLabel="{{ 'invoice-customer-form.footer.infos.standard-vat-rate' | translate }}"
                  ></ui-info-field>
                </div>
                <div class="total-shipping-fee-price value">
                  {{
                    this.shippingFeePrice
                      | formatCurrency: currency?.codeISO : "symbol" : "0.2-5" : fetcher.locale
                      | async
                  }}
                </div>
              </div>
            </div>
            <div class="footer-element">
              <ui-input-text
                label="{{ 'invoice-customer-form.footer.labels.total-extra-price' | translate }}"
                type="text"
                class="input-fields"
                [thousandSeparator]="' '"
                [maskPattern]="decimalDigit"
                [suffix]="' ' + currency?.symbol"
                formControlName="extraPrice"
                [status]="this.form.get('extraPrice') | formStatus"
                infoLabel="{{ 'invoice-customer-form.footer.infos.standard-vat-rate' | translate }}"
                [errorMessage]="this.form.get('extraPrice') | formErrorMessage | translate: { digit: HIGH_INTEGER }"
                customClass="no-margin"
                *ngIf="!readOnly"
              >
              </ui-input-text>
              <div class="total-extra-price" *ngIf="readOnly">
                <div class="footer-label-with-info">
                  <div>{{ "invoice-customer-form.footer.labels.total-extra-price" | translate }}</div>
                  <ui-info-field
                    infoLabel="{{ 'invoice-customer-form.footer.infos.standard-vat-rate' | translate }}"
                  ></ui-info-field>
                </div>
                <div class="total-extra-price value">
                  {{
                    this.extraPrice | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : fetcher.locale | async
                  }}
                </div>
              </div>
            </div>
            <div class="footer-element">
              <div class="total-tax-price">
                <div>{{ "invoice-customer-form.footer.labels.total-tax-price" | translate }}</div>
                <div class="total-tax-price value">
                  {{ this.taxPrice | formatCurrency: currency?.codeISO : "symbol" : "0.2-5" : fetcher.locale | async }}
                </div>
              </div>
            </div>
            <div class="footer-element">
              <div class="label total-quick-payment-discount">
                {{ "invoice-customer-form.footer.labels.total-quick-payment-discount" | translate }}
              </div>
              <div class="value total-quick-payment-discount">
                {{
                  this.totalQuickPaymentDiscount
                    | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : fetcher.locale
                    | async
                }}
              </div>
            </div>
            <div class="footer-element">
              <div class="label total-price">{{ "invoice-customer-form.footer.labels.total-price" | translate }}</div>
              <div class="value total-price">
                {{ this.totalPrice | formatCurrency: currency?.codeISO : "symbol" : "0.2-2" : fetcher.locale | async }}
              </div>
            </div>
          </div>
        </ui-container>
      </div>
    </div>
  </div>

  <app-invoice-customer-initiator-popup
    *ngIf="this.invoiceCustomerInitiatorPopupVisible"
    [tabNameList]="this.tabNameList"
    [contactId]="inUpdateState() ? this.updatedInvoiceCustomer.contactId : null"
    [deliveryFormList]="this.selectedDeliveryFormList"
    [deletingDeliveryForms]="this.deletingDeliveryFormList"
    [isOneInvoicePerStoreEnabled]="this.oneInvoicePerStore"
    (close)="this.onCloseInvoiceCustomerInitiatorPopup()"
    (validate)="this.onValidateInvoiceCustomerInitiatorPopup($event)"
  >
  </app-invoice-customer-initiator-popup>

  <app-free-invoice-customer-initiator-popup
    *ngIf="this.freeInvoiceCustomerInitiatorPopupVisible"
    (close)="this.onCloseFreeInvoiceCustomerInitiatorPopup($event)"
    (validate)="this.onValidateFreeInvoiceCustomerInitiatorPopup($event)"
  >
  </app-free-invoice-customer-initiator-popup>

  <app-batch-generate-popup
    *ngIf="this.batchGeneratePopupVisible"
    [deliveryFormList]="this.selectedDeliveryFormList"
    [(oneInvoicePerStore)]="this.oneInvoicePerStore"
    (close)="this.onCloseBatchGeneratePopup($event)"
    (selectedDeliveryFormsChange)="onSelectedDeliveryFormsChange($event)"
  >
  </app-batch-generate-popup>
</ng-container>
<div class="popup">
  <ui-popup
    title="{{ 'invoice-customer-form.delete-popup.title' | translate }}"
    (close)="closeDeleteTabConfirmationPopup()"
    *ngIf="this.deleteTabConfirmationPopupVisible"
  >
    <div content>
      <div
        class="body-message"
        [innerHTML]="
          'invoice-customer-form.delete-popup.body'
            | translate
              : {
                  ref:
                    getTabName(this.invoiceCustomerTabs[+this.tabToDelete.id]) === null
                      ? ('invoice-customer-form.tabs.title-free' | translate)
                      : ('invoice-customer-form.tabs.title'
                        | translate: { ref: getTabName(this.invoiceCustomerTabs[+this.tabToDelete.id]) }),
                }
        "
      ></div>
    </div>
    <div footer>
      <div class="align-buttons">
        <div class="button-popup-validate">
          <div class="button">
            <ui-button
              text="{{ 'invoice-customer-form.delete-popup.validate' | translate }}"
              [primary]="true"
              type="validate"
              (click)="deleteTab()"
            ></ui-button>
          </div>
        </div>
        <div class="button-popup-refuse">
          <div class="button">
            <ui-button
              text="{{ 'invoice-customer-form.delete-popup.refuse' | translate }}"
              type="cancel"
              (click)="closeDeleteTabConfirmationPopup()"
            ></ui-button>
          </div>
        </div>
      </div>
    </div>
  </ui-popup>
</div>
<div class="popup" *ngIf="validatePopupVisible">
  <app-invoice-customer-validation
    (validate)="validateInvoiceCustomerEvent($event)"
    (close)="closeInvoiceCustomerValidation()"
  >
  </app-invoice-customer-validation>
</div>
