<div class="popup-container">
  <ui-popup *ngIf="popupVisible" [title]="'release-popup.title' | translate" (close)="closePopup()">
    <div content>
      <div class="release-link">
        <img src="./assets/images/release.png" alt="release image" />
        <div class="release-link__content">
          <p>{{ "release-popup.sub-title" | translate }}</p>
          <ui-button
            text="{{ 'release-popup.button.new-release' | translate }}"
            [btnIcon]="btnIcon"
            primary="true"
            (click)="openReleaseNotes()"
          ></ui-button>
        </div>
      </div>

      <div class="release-help">
        <div class="release-help__icon">
          <fa-icon class="info-icon" [icon]="faInfoCircle"></fa-icon>
        </div>
        <div class="release-help__content">
          <span>{{ "release-popup.help.first" | translate }}</span>
          <span>{{ "release-popup.help.second" | translate }}</span>
        </div>
      </div>

      <div class="release-menu">
        <div class="release-menu__content">
          <div class="release-menu__content-icon">
            <fa-icon class="info-icon" [icon]="faInfoCircle"></fa-icon>
          </div>
          <div class="release-menu__content-text">
            <span>{{ "release-popup.menu.content" | translate }}</span>
          </div>
        </div>
        <div class="release-menu__image">
          <img src="{{ this.imageLink }}" alt="release menu image" />
        </div>
      </div>
    </div>
  </ui-popup>
</div>
