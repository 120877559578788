import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { OperatingTimeDay, UserService, CaraUser } from "center-services";
import dayjs from "dayjs";
import { SubscriptionService } from "fugu-common";

@Component({
  selector: "app-opening-times",
  templateUrl: "./opening-times.component.html",
  styleUrls: ["./opening-times.component.scss"],
  providers: [SubscriptionService],
})
export class OpeningTimesComponent implements OnInit {
  @Input() openingTimesList: OperatingTimeDay[];
  @Output() openingTimesListChange: EventEmitter<OperatingTimeDay[]> = new EventEmitter<OperatingTimeDay[]>();

  public locale: string;
  public dateFormat: string;
  public openingTimesForm: UntypedFormArray;
  public dayLabels: string[];
  public days: OperatingTimeDay[];

  public totals: { h: string; m: string }[] = [];

  constructor(
    private translateService: TranslateService,
    private fb: UntypedFormBuilder,
    private userService: UserService<CaraUser>,
    private subscriptionService: SubscriptionService
  ) {
    this.prepareData();
  }

  ngOnInit(): void {
    this.getLocaleAndDateFormat();
    // subscribe to form changes
    this.subscriptionService.subs.push(
      this.openingTimesForm.valueChanges.subscribe(controlValues => {
        const activatedDays = [];
        controlValues.forEach(controlValue => {
          if (controlValue.activated) {
            const day = new OperatingTimeDay({
              id: controlValue.id,
              dayIndex: controlValue.dayIndex,
              startTime1: controlValue.startTime1
                ? controlValue.startTime1.set("second", 0).set("millisecond", 0).format("HH:mm:ss")
                : null,
              endTime1: controlValue.endTime1
                ? controlValue.endTime1.set("second", 0).set("millisecond", 0).format("HH:mm:ss")
                : null,
              startTime2: controlValue.startTime2
                ? controlValue.startTime2.set("second", 0).set("millisecond", 0).format("HH:mm:ss")
                : null,
              endTime2: controlValue.endTime2
                ? controlValue.endTime2.set("second", 0).set("millisecond", 0).format("HH:mm:ss")
                : null,
            });
            activatedDays.push(day);
          }
        });
        this.openingTimesList = activatedDays;
        if (this.openingTimesForm.invalid) {
          return;
        }
        this.openingTimesListChange.emit(this.openingTimesList);
      })
    );

    if (this.openingTimesList.length > 0) {
      this.loadEditedData();
    }
  }

  prepareData(): void {
    this.days = [];
    this.dayLabels = [];
    const nb = 7;

    for (let step = 0; step < nb; step++) {
      const day = new OperatingTimeDay({
        id: null,
        dayIndex: step,
        startTime1: null,
        endTime1: null,
        startTime2: null,
        endTime2: null,
      });
      this.days.push(day);
      // prepare day labels
      this.dayLabels.push(this.translateService.instant(`opening-times.label.${day.dayIndex}`));
    }
    this.prepareForm();
  }

  prepareForm(): void {
    this.openingTimesForm = new UntypedFormArray([]);
    this.days.forEach(day => {
      // prepare form
      const dayForm = this.fb.group({
        id: [day.id],
        dayIndex: [day.dayIndex],
        startTime1: [null],
        endTime1: [null],
        startTime2: [null],
        endTime2: [null],
        activated: [false],
      });
      this.openingTimesForm.push(dayForm);
      dayForm.setValidators([this.timeValidator()]);

      this.subscriptionService.subs.push(
        dayForm.valueChanges.subscribe(() => {
          this.computeTotal(day.dayIndex, dayForm);
        })
      );
    });
  }

  timeValidator(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      const startTime1 = group.controls.startTime1;
      const endTime1 = group.controls.endTime1;
      const startTime2 = group.controls.startTime2;
      const endTime2 = group.controls.endTime2;
      const activated = group.controls.activated;

      startTime1.setErrors(null);
      endTime1.setErrors(null);
      startTime2.setErrors(null);
      endTime2.setErrors(null);

      if (!activated.value) {
        return;
      }

      // required fields
      if (!startTime1.value) {
        startTime1.setErrors({ required: true });
        return;
      }
      if (!endTime1.value) {
        endTime1.setErrors({ required: true });
        return;
      }

      // missing fields
      if (startTime2.value && !endTime2.value) {
        endTime2.setErrors({ missingTime: true });
        return;
      }
      if (!startTime2.value && endTime2.value) {
        startTime2.setErrors({ missingTime: true });
        return;
      }

      // next time set must be greater than previous one
      if (endTime1.value.set({ second: 0, millisecond: 0 }) < startTime1.value.set({ second: 0, millisecond: 0 })) {
        endTime1.setErrors({ invalidTime: true });
      }
      if (
        startTime2.value &&
        startTime2.value.set({ second: 0, millisecond: 0 }) < endTime1.value.set({ second: 0, millisecond: 0 })
      ) {
        startTime2.setErrors({ invalidTime: true });
      }
      if (
        endTime2.value &&
        endTime2.value.set({ second: 0, millisecond: 0 }) < startTime2.value.set({ second: 0, millisecond: 0 })
      ) {
        endTime2.setErrors({ invalidTime: true });
      }
    };
  }

  getLocaleAndDateFormat(): void {
    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    }
    this.subscriptionService.subs.push(
      this.userService.connectedUser.subscribe(user => {
        this.locale = user.codeLanguage;
        this.dateFormat = user.dateFormat;
      })
    );
  }

  loadEditedData(): void {
    const activatedDaysIndex = [];
    this.openingTimesList.forEach((day: OperatingTimeDay) => {
      const dayControl = this.openingTimesForm.controls[day.dayIndex];

      dayControl.patchValue(
        {
          id: day.id,
          dayIndex: day.dayIndex,
          startTime1: this.convertStringToDayjs(day.startTime1),
          endTime1: this.convertStringToDayjs(day.endTime1),
          startTime2: this.convertStringToDayjs(day.startTime2),
          endTime2: this.convertStringToDayjs(day.endTime2),
          activated: true,
        },
        { emitEvent: false, onlySelf: true }
      );

      activatedDaysIndex.push(day.dayIndex);
      this.computeTotal(day.dayIndex, dayControl);
    });
    // deactivate some days
    this.openingTimesForm.controls.forEach((control, index) => {
      if (!activatedDaysIndex.includes(index)) {
        control.get("activated").setValue(false, { emitEvent: false });
      }
    });
  }

  convertStringToDayjs(value: string): dayjs.Dayjs {
    if (value) {
      const [h1, m1, s1] = value.split(":");
      const dayjsDate = dayjs();
      dayjsDate
        .set("hour", +h1)
        .set("minute", +m1)
        .set("second", +s1);
      return dayjsDate;
    } else {
      return null;
    }
  }

  computeTotal(dayIndex: number, control: AbstractControl): void {
    const minPerHour = 60;
    const singleDigitThreshold = 10;
    let totalMinutes = 0;
    let totalHours = 0;
    if (control.value.startTime1 && control.value.endTime1) {
      const diffMinutes = control.value.endTime1.diff(control.value.startTime1, "minutes");
      totalHours += Math.floor(diffMinutes / minPerHour);
      totalMinutes += diffMinutes % minPerHour;
    }
    if (control.value.startTime2 && control.value.endTime2) {
      const diffMinutes = control.value.endTime2.diff(control.value.startTime2, "minutes");
      totalHours += Math.floor(diffMinutes / minPerHour);
      totalMinutes += diffMinutes % minPerHour;
    }

    this.totals[dayIndex] = {
      h: totalHours.toString(),
      m: totalMinutes < singleDigitThreshold ? `0${totalMinutes}` : totalMinutes.toString(),
    };
  }

  isFormValid(): boolean {
    this.openingTimesForm.markAllAsTouched();
    return this.openingTimesForm.valid;
  }
}
