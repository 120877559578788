import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { IconDefinition, faChevronLeft, faPen, faLink } from "@fortawesome/pro-solid-svg-icons";
import { PurchaseModalitySelectionComponent } from "app/common/purchase-modality-selection/purchase-modality-selection.component";
import { PurchaseOrder, PurchaseOrderService, PurchaseOrderStatus } from "center-services";
import { SessionPagination, SubscriptionService } from "fugu-common";
import { PurchaseOrderLinesDetailComponent } from "../purchase-order-lines-detail/purchase-order-lines-detail.component";
import { PurchaseOrderLinesComponent } from "../purchase-order-lines/purchase-order-lines.component";

@Component({
  selector: "app-purchase-order-detail",
  templateUrl: "./purchase-order-detail.component.html",
  styleUrls: ["./purchase-order-detail.component.scss"],
  providers: [SubscriptionService],
})
export class PurchaseOrderDetailComponent implements OnInit {
  @ViewChild("purchaseOrderLinesDetail") purchaseOrderLinesDetail: any;
  public faChevronLeft: IconDefinition = faChevronLeft;
  public purchaseOrder: PurchaseOrder;
  public canUpdate: boolean = false;
  public canLinkFollowUp: boolean = false;
  public faPen: IconDefinition = faPen;
  public faLink: IconDefinition = faLink;
  private readonly LINK_FOLLOWUP_STATUSES: PurchaseOrderStatus[] = [
    PurchaseOrderStatus.SENT,
    PurchaseOrderStatus.PARTIALLY_RECEIVED,
    PurchaseOrderStatus.RECEIVED,
    PurchaseOrderStatus.SETTLED,
  ];

  constructor(
    private purchaseOrderService: PurchaseOrderService,
    private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {}

  public ngOnInit(): void {
    const purchaseOrderId = this.route.snapshot.params.id;
    this.fetchPurchaseOrder(purchaseOrderId);
  }

  public getStatusClass(): string {
    return `status-${this.purchaseOrder.status.toLowerCase().replace("_", "-")}`;
  }

  public goBackToList(): void {
    SessionPagination.clear(PurchaseOrderLinesDetailComponent.LIST_ID);
    SessionPagination.clear(PurchaseModalitySelectionComponent.LIST_ID);
    SessionPagination.clear(PurchaseOrderLinesComponent.LIST_ID);

    this.router.navigateByUrl("/purchase-order-list");
  }

  public goToEditPage(): void {
    if (this.canUpdate) {
      if (this.purchaseOrderLinesDetail) {
        this.purchaseOrderLinesDetail.savePaginationToSession();
      }

      const navigationExtras: NavigationExtras = {
        state: { backUrl: `/purchase-order-detail/${this.purchaseOrder.id}` },
      };
      this.router.navigate([`/purchase-order/update/${this.purchaseOrder.id}`], navigationExtras);
    }
  }

  public goToLinkPage(): void {
    if (this.canLinkFollowUp) {
      window.open((this.purchaseOrder as PurchaseOrder).followupUri, "_blank");
    }
  }

  private fetchPurchaseOrder(id: number): void {
    this.subscriptionService.subs.push(
      this.purchaseOrderService.get(id).subscribe(
        (purchaseOrder: PurchaseOrder) => {
          this.canUpdate =
            purchaseOrder.status === PurchaseOrderStatus.DRAFT ||
            purchaseOrder.status === PurchaseOrderStatus.CONFIRMED;
          this.canLinkFollowUp =
            purchaseOrder?.followupUri && this.LINK_FOLLOWUP_STATUSES.includes(purchaseOrder.status);
          this.purchaseOrder = purchaseOrder;
        },
        () => {
          this.goBackToList();
        }
      )
    );
  }
}
