import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import {
  IconDefinition,
  faExclamationCircle,
  faInfoCircle,
  faPen,
  faPrint,
  faSync,
  faLink,
} from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { NotificationHandlerService } from "app/service/notification-handler.service";
import { EnumTranslationUtil } from "app/util/enum-translation-util";
import {
  PurchaseOrder,
  Currency,
  Light,
  Pagination,
  PurchaseOrderService,
  CurrencyService,
  LightService,
  CaraUserService,
  PurchaseOrderType,
  PurchaseOrderStatus,
  PaginatedList,
  CaraUser,
  Sort,
  AsynchronousTaskService,
  AsynchronousTaskCreation,
} from "center-services";
import {
  Filterer,
  FilterValue,
  PaginableComponent,
  SearchFilter,
  SearchFilterOperator,
  SessionPagination,
  SubscriptionService,
} from "fugu-common";
import { MenuAction, MessageService, ToastMessageService } from "fugu-components";
import { FilteredTableListComponent } from "generic-pages";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-purchase-order-list",
  templateUrl: "./purchase-order-list.component.html",
  styleUrls: ["./purchase-order-list.component.scss"],
  providers: [SubscriptionService],
})
export class PurchaseOrderListComponent
  extends FilteredTableListComponent
  implements OnInit, OnDestroy, PaginableComponent {
  @ViewChild("table") table: any;

  public LIST_ID: string = "app-purchase-order-list.purchase-order-list";

  public purchaseOrderList: PurchaseOrder[] = [];
  public rows: any[] = [];
  public sorts: any[] = [{ prop: "createdAt", dir: "desc" }];
  public activeFilters: SearchFilter[] = [];
  public filterer: Filterer;
  public currencyList: Currency[] = [];
  public locale: string;
  public dateFormat: string;
  public supplierList: Light[] = [];
  public faExclamationCircle: IconDefinition = faExclamationCircle;
  public faPen: IconDefinition = faPen;
  public faPrint: IconDefinition = faPrint;
  public menuActions: MenuAction[] = [];
  public tableControl: UntypedFormGroup;
  public currentRowsId: number[] = [];
  public selectedPurchaseOrderList: PurchaseOrder[] = [];
  public isStatusUnique: boolean = false;
  public hasMixedLines: boolean = false;
  public statusPopupVisible: boolean = false;
  public faInfoCircle: IconDefinition = faInfoCircle;
  public faSync: IconDefinition = faSync;
  public faLink: IconDefinition = faLink;
  public infoLabel: string = "";
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  private orderTypeTranslations: { [key: string]: string } = {};
  private statusTypeTranslations: { [key: string]: string } = {};
  private sessionPagination: SessionPagination;
  private initObservables: Observable<any>[] = [];
  private readonly EDIT_ACTION_ID: number = 0;
  private readonly LINK_FOLLOWUP_ACTION_ID: number = 1;
  private readonly EDIT_ACTION: MenuAction = new MenuAction(
    this.EDIT_ACTION_ID,
    this.translateService.instant("purchase-order-list.actions.edit"),
    this.faPen
  );
  private readonly LINK_FOLLOWUP_ACTION: MenuAction = new MenuAction(
    this.LINK_FOLLOWUP_ACTION_ID,
    this.translateService.instant("purchase-order-list.actions.link"),
    this.faLink
  );
  private readonly LINK_FOLLOWUP_STATUSES: PurchaseOrderStatus[] = [
    PurchaseOrderStatus.SENT,
    PurchaseOrderStatus.PARTIALLY_RECEIVED,
    PurchaseOrderStatus.RECEIVED,
    PurchaseOrderStatus.SETTLED,
  ];

  constructor(
    private notificationHandlerService: NotificationHandlerService,
    protected translateService: TranslateService,
    protected messageService: MessageService,
    private purchaseOrderService: PurchaseOrderService,
    private currencyService: CurrencyService,
    private lightService: LightService,
    private router: Router,
    protected userService: CaraUserService,
    private fb: UntypedFormBuilder,
    private asynchronousTaskService: AsynchronousTaskService,
    private toastMessageService: ToastMessageService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);

    EnumTranslationUtil.buildTranslations(
      this.translateService,
      PurchaseOrderType,
      "purchase-order.header.order-options",
      this.orderTypeTranslations
    );
    EnumTranslationUtil.buildTranslations(
      this.translateService,
      PurchaseOrderStatus,
      "purchase-order-list.order-status-options",
      this.statusTypeTranslations
    );

    this.sessionPagination = new SessionPagination(this);
  }

  getPageNumber(_listId: string): number {
    return this.pager.number;
  }

  getFilters(_listId: string): FilterValue[] {
    return this.filterer.filterValues;
  }

  getSorts(_listId: string): any[] {
    return this.sorts;
  }

  setFilters(_listId: string, filters: FilterValue[]): void {
    this.filterer.filterValues = [...filters];
  }

  setSorts(_listId: string, sorts: any[]): void {
    this.sorts = [...sorts];
  }

  setPageNumber(_listId: string, pageNumber: number): void {
    this.pager.number = pageNumber;
  }

  ngOnInit(): void {
    this.initSelectFormControl();

    if (this.userService.connectedUser.value) {
      this.locale = this.userService.connectedUser.value.codeLanguage;
      this.dateFormat = this.userService.connectedUser.value.dateFormat;
    } else {
      this.initObservables.push(this.fetchConnectedUserDetails());
    }
    this.initObservables.push(this.fetchSuppliers());
    this.initObservables.push(this.fetchCurrencies());

    this.subscriptionService.subs.push(
      combineLatest(this.initObservables).subscribe(() => {
        this.initFilters();
        this.sessionPagination.loadFromSession(this.LIST_ID);
        this.computeSearchFilters();
        this.fetchPurchaseOrderList();
        this.infoLabel = this.translateService.instant("purchase-order-list.info");
      })
    );
  }

  public ngOnDestroy(): void {
    if (this.filterer) {
      this.sessionPagination.saveToSession(this.LIST_ID);
    }
  }

  addMenuActions(row: any): MenuAction[] {
    this.menuActions = [];
    if (row.actionnable) {
      this.menuActions.push(this.EDIT_ACTION);
    }
    if (row?.followupUri && this.LINK_FOLLOWUP_STATUSES.includes(row.status)) {
      this.menuActions.push(this.LINK_FOLLOWUP_ACTION);
    }
    return this.menuActions;
  }

  manageActions(actionId: number, row: any): void {
    switch (actionId) {
      case this.EDIT_ACTION_ID:
        this.router.navigateByUrl(`/purchase-order/update/${row.id}`);
        break;
      case this.LINK_FOLLOWUP_ACTION_ID:
        window.open((row as PurchaseOrder).followupUri, "_blank");
        break;
      default:
        console.error(`Don't know how to handle action : ${actionId}`);
        break;
    }
  }

  fetchPurchaseOrderList(): void {
    this.subscriptionService.subs.push(
      this.purchaseOrderService.getAll(this.pager, this.getSorter(), this.activeFilters).subscribe(
        (result: PaginatedList<PurchaseOrder>) => {
          this.rows = [];
          this.purchaseOrderList = result.data;
          this.pager = result.page;
          this.currentRowsId = result.data.map((po: PurchaseOrder) => po.id);
          result.data.forEach((purchaseOrder: PurchaseOrder) => {
            this.addRow(purchaseOrder);
          });
        },
        error => {
          this.sendErrorAlert("purchase-order-list.errors.get-purchase-orders", error.message);
        },
        () => {
          this.rows = [...this.rows];
          this.table.sorts = this.sorts;
          this.table.offset = this.pager.number;
          this.updateSelection();
          this.updateHeaderPageCheckbox();
        }
      )
    );
  }

  fetchConnectedUserDetails(): Observable<CaraUser> {
    return this.userService.connectedUser.pipe(
      tap(connectedUser => {
        this.locale = connectedUser.codeLanguage;
        this.dateFormat = connectedUser.dateFormat;
      })
    );
  }

  fetchCurrencies(): Observable<Currency[]> {
    return this.currencyService.getAll().pipe(
      tap(
        (currencies: Currency[]) => {
          this.currencyList = currencies;
        },
        error => {
          this.sendErrorAlert("purchase-order-list.errors.get-currencies", error.message);
        }
      )
    );
  }

  getCurrency(row: any): Currency {
    return this.currencyList.find(currency => currency.id === row.currencyId);
  }

  fetchSuppliers(): Observable<Light[]> {
    return this.lightService.getSuppliers().pipe(
      tap(
        (lightSuppliers: Light[]) => {
          this.supplierList = lightSuppliers;
        },
        error => {
          this.sendErrorAlert("purchase-order-list.errors.get-suppliers", error.message);
        }
      )
    );
  }

  addRow(purchaseOrder: PurchaseOrder): void {
    const statusLabel = this.translateService.instant(
      `purchase-order-list.order-status-options.${purchaseOrder.status}`
    );

    const statusClass = this.getStatusClass(purchaseOrder.status);
    const quantityClass = this.getQuantityClass(purchaseOrder);

    const displayUnreceivedPrice =
      purchaseOrder.status === PurchaseOrderStatus.RECEIVED ||
      purchaseOrder.status === PurchaseOrderStatus.PARTIALLY_RECEIVED;

    this.rows.push({
      id: purchaseOrder.id,
      orderRef: purchaseOrder.orderRef,
      supplierRef: this.supplierList.find(supplier => supplier.id === purchaseOrder.supplierId).reference,
      brandRefs: [...new Set(purchaseOrder.lines.map(line => line.brandRef))],
      orderType: this.translateService.instant(`purchase-order.header.order-options.${purchaseOrder.orderType}`),
      createdAt: purchaseOrder.createdAt,
      quantity: this.getQuantity(purchaseOrder, true),
      receivedQuantity: this.getQuantity(purchaseOrder, false),
      deliveryDate: purchaseOrder.deliveryDate,
      totalPrice: purchaseOrder.totalPrice,
      unreceivedPrice: purchaseOrder.unreceivedPrice,
      comment: purchaseOrder.comment,
      currencyId: purchaseOrder.currencyId,
      status: purchaseOrder.status,
      statusLabel,
      statusClass,
      quantityClass,
      displayUnreceivedPrice,
      actionnable:
        purchaseOrder.status === PurchaseOrderStatus.DRAFT || purchaseOrder.status === PurchaseOrderStatus.CONFIRMED,
      link: purchaseOrder.status !== PurchaseOrderStatus.DRAFT && purchaseOrder.status !== PurchaseOrderStatus.CANCELED,
      ...(purchaseOrder.followupUri ? { followupUri: purchaseOrder.followupUri } : {}),
    });
    this.tableControl.addControl(this.getRowControlName(purchaseOrder.id.toString()), new UntypedFormControl(false));
  }

  public getRowControlName(id: string | number): string {
    return `checked_${id}`;
  }

  public onRowCheckboxChange(): void {
    this.updateHeaderPageCheckbox();
    this.updateSelection();
  }

  public onHeaderCheckboxChange(): void {
    this.updateRowsPageCheckbox();
    this.updateSelection();
  }

  updateHeaderPageCheckbox(): void {
    const currentPageRowsCheckedIds = this.currentRowsId.filter(id => {
      return this.tableControl.get(this.getRowControlName(id)).value;
    });
    const isSelected = this.currentRowsId.length > 0 && this.currentRowsId.length === currentPageRowsCheckedIds.length;
    this.tableControl.controls.headerCheckbox.patchValue(isSelected);
  }

  updateRowsPageCheckbox(): void {
    const controls = this.tableControl.controls;
    const isHeaderSelected = this.tableControl.controls.headerCheckbox.value;
    this.currentRowsId.forEach(id => {
      controls[this.getRowControlName(id)].patchValue(isHeaderSelected);
    });
  }

  public updateSelection(): void {
    this.rows.forEach(row => {
      const rowChecked: boolean = this.tableControl.controls[this.getRowControlName(row.id)].value;
      const purchaseOrder: PurchaseOrder = this.purchaseOrderList.find(elem => elem.id === row.id);
      const isPOSelected: boolean =
        this.selectedPurchaseOrderList.findIndex(elem => elem.id === row.id) === -1 ? false : true;

      if (!isPOSelected && rowChecked) {
        this.selectedPurchaseOrderList.push(purchaseOrder);
      } else if (isPOSelected && !rowChecked) {
        const poIndex = this.selectedPurchaseOrderList.findIndex(elem => elem.id === purchaseOrder.id);
        this.selectedPurchaseOrderList.splice(poIndex, 1);
      }
    });
    this.checkPurchaseOrderListStatus();
  }

  checkPurchaseOrderListStatus(): void {
    this.isStatusUnique = [...new Set(this.selectedPurchaseOrderList.map(po => po.status))].length === 1;
    const emptyLinesOrders = this.selectedPurchaseOrderList.filter(po => po.lines.length === 0);
    const filledLinesOrders = this.selectedPurchaseOrderList.filter(po => po.lines.length >= 1);

    if (this.isStatusUnique && this.selectedPurchaseOrderList[0].status === "DRAFT") {
      this.hasMixedLines = emptyLinesOrders.length > 0 && filledLinesOrders.length > 0;
    } else {
      this.hasMixedLines = false;
    }
  }

  openChangeStatusPopup(): void {
    this.statusPopupVisible = true;
  }

  closeChangeStatusPopup(): void {
    this.closeAndReset();
    this.isStatusUnique = false;
    this.hasMixedLines = false;
  }

  printPurchaseOrder(): void {
    const search = new SearchFilter(
      "id",
      SearchFilterOperator.IN,
      this.selectedPurchaseOrderList.map(po => po.id)
    );
    const asyncCreationTask = {
      type: "generatePdf",
      params: `purchase-order;${search.toQuery()};`,
    };
    this.asynchronousTaskService.create(new AsynchronousTaskCreation(asyncCreationTask)).subscribe({
      next: () => {
        this.toastMessageService.generateMessage(
          "info",
          "task-notification.message.on-going-title",
          "task-notification.message.on-going-message"
        );
        this.notificationHandlerService.showHandler();
      },
      error: () =>
        this.toastMessageService.generateMessage("error", "message.title.api-errors", "message.content.data-errors"),
    });
  }

  validateChangeStatusPopup(): void {
    this.closeAndReset();
    this.fetchPurchaseOrderList();
  }

  closeAndReset(): void {
    this.statusPopupVisible = false;
    this.selectedPurchaseOrderList = [];
    for (const key in this.tableControl.controls) {
      if (this.tableControl.controls.hasOwnProperty(key)) {
        this.tableControl.controls[key].setValue(false);
      }
    }
  }

  getQuantity(purchaseOrder: PurchaseOrder, total: boolean): number {
    let quantitySum = 0;
    purchaseOrder.lines.forEach(line => {
      total ? (quantitySum += line.quantity) : (quantitySum += line.receivedQuantity);
    });
    return quantitySum;
  }

  getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  public changeSortSettings(prop: string, dir: string): void {
    this.sorts = [{ prop, dir }];
    this.fetchPurchaseOrderList();
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.fetchPurchaseOrderList();
  }

  propToDto(prop: string): string {
    switch (prop) {
      case "statusLabel":
        return "status";
      case "supplierRef":
        return "supplier.reference";
      default:
        return prop;
    }
  }

  initFilters(): void {
    if (this.filterer) {
      return;
    }
    const componentFilterPref = this.userPreferences.filterComponents.find(
      filterPrefComponent => filterPrefComponent.component === this.LIST_ID
    );
    this.filterer = new Filterer(componentFilterPref?.filters);

    this.filterer.addFilter(
      "orderRef",
      this.translateService.instant("purchase-order-list.datatable.columns.order-ref"),
      "string"
    );

    this.filterer.addFilter(
      "supplier.reference",
      this.translateService.instant("purchase-order-list.datatable.columns.supplier-ref"),
      "string"
    );

    this.filterer.addListFilter(
      "orderType",
      this.translateService.instant("purchase-order-list.datatable.columns.order-type"),
      Object.keys(PurchaseOrderType).map(key => ({ value: key, displayValue: this.orderTypeTranslations[key] }))
    );

    this.filterer.addFilter(
      "createdAt",
      this.translateService.instant("purchase-order-list.datatable.columns.created-at"),
      "date"
    );

    this.filterer.addFilter(
      "deliveryDate",
      this.translateService.instant("purchase-order-list.datatable.columns.delivery-date"),
      "date"
    );

    this.filterer.addFilter(
      "totalPrice",
      this.translateService.instant("purchase-order-list.datatable.columns.total-gross-price"),
      "range"
    );

    this.filterer.addFilter(
      "unreceivedPrice",
      this.translateService.instant("purchase-order-list.datatable.columns.unreceived-price"),
      "range"
    );

    this.filterer.addFilter(
      "comment",
      this.translateService.instant("purchase-order-list.datatable.columns.comment"),
      "string"
    );

    this.filterer.addListFilter(
      "status",
      this.translateService.instant("purchase-order-list.datatable.columns.status"),
      Object.keys(PurchaseOrderStatus).map(key => ({ value: key, displayValue: this.statusTypeTranslations[key] }))
    );
  }

  applyFilters(): void {
    this.pager.number = 0;

    this.computeSearchFilters();

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterer.filterValues.map(fv => fv.filterId),
        this.LIST_ID
      ).subscribe(() => {
        this.changePage({ page: 1 });
      })
    );
  }

  public createPurchaseOrder(): void {
    if (!this.userService.canDo("PURCHASE_ORDER_CREATE")) {
      return;
    }
    this.router.navigateByUrl("/purchase-order/add");
  }

  public checkPurchaseOrder(event: any): void {
    if (event.type === "click") {
      if (!this.userService.canDo("PURCHASE_ORDER_READ")) {
        return;
      }
      const filteredList = this.purchaseOrderList.filter(po => po.id === event.row.id);
      if (filteredList.length <= 0) {
        console.error(`can't find purchase order with id ${event.row.id}`);
        return;
      }
      this.router.navigateByUrl(`/purchase-order-detail/${event.row.id}`);
    }
  }

  sendErrorAlert(errorType: string, message: string): void {
    const title = this.translateService.instant("message.title.data-errors");
    const content = this.translateService.instant(errorType, { message });
    this.messageService.warn(content, { title });
  }

  protected computeSearchFilters(): void {
    this.activeFilters = this.filterer.getSearchFilters();
  }

  // handle select checkbox
  private initSelectFormControl(): void {
    const control = new UntypedFormControl(false);
    this.tableControl = this.fb.group({
      headerCheckbox: control,
    });
  }

  private getStatusClass(status: PurchaseOrderStatus): string {
    return `status-${status.toLowerCase().replace("_", "-")}`;
  }

  private getQuantityClass(purchaseOrder: PurchaseOrder): string {
    const quantity = this.getQuantity(purchaseOrder, true);
    const receivedQuantity = this.getQuantity(purchaseOrder, false);
    if (receivedQuantity === 0) {
      return "quantity-wrapper none";
    } else if (receivedQuantity < quantity) {
      return "quantity-wrapper partial";
    } else {
      return "quantity-wrapper full";
    }
  }
}
