<ng-container>
  <span class="subtitle">
    <b>
      {{ this.inventory.name }} - {{ this.inventory.beginDate | dateTimeFormat: false }} -
      {{ this.inventory.endDate | dateTimeFormat: false }}
    </b>
  </span>
  <div class="stock-entry-locations-table">
    <div class="filters-field" *ngIf="filterer">
      <ui-dynamic-filters
        [filters]="filterer?.getFilters()"
        [(filterValues)]="filterer.filterValues"
        (apply)="applyFilters()"
        dateFormat="{{ dateFormat }}"
        [label]="'filters.label' | translate"
        minLabel="{{ 'filters.min' | translate }}"
        maxLabel="{{ 'filters.max' | translate }}"
        applyLabel="{{ 'filters.apply' | translate }}"
        deleteLabel="{{ 'filters.delete' | translate }}"
        placeholderPrefix="{{ 'filters.prefix' | translate }} "
        mandatoryErrorMessage="{{ 'inventory-criteria.errors.filters' | translate }}"
      >
      </ui-dynamic-filters>
    </div>

    <ngx-datatable
      #table
      [rows]="rows"
      [trackByProp]="'id'"
      [class]="'material'"
      [columnMode]="'force'"
      [headerHeight]="'auto'"
      [rowClass]="getRowClass"
      [scrollbarH]="true"
      [footerHeight]="50"
      [rowHeight]="40"
      [cssClasses]="{
        sortAscending: 'fas fa-chevron-up',
        sortDescending: 'fas fa-chevron-down',
      }"
      [messages]="{ emptyMessage: 'global.no-data' | translate }"
      [externalSorting]="true"
      [sorts]="sorts"
      (sort)="changeSortSettings($event.column.prop, $event.newValue)"
      [externalPaging]="true"
      [count]="pager.totalElements"
      [offset]="pager.number"
      [limit]="pager.size"
    >
      <ngx-datatable-column
        name="{{ 'inventory-criteria.datatable.columns.item-category' | translate }}"
        prop="itemCategory"
        [resizeable]="false"
        cellClass="item-category"
        [draggable]="false"
      >
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{ 'inventory-criteria.datatable.columns.item-internal-code' | translate }}"
        prop="itemInternalCode"
        [resizeable]="false"
        cellClass="item-internal-code"
        [draggable]="false"
      >
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{ 'inventory-criteria.datatable.columns.item-supplier-reference' | translate }}"
        prop="itemSupplierRef"
        [resizeable]="false"
        cellClass="supplier-reference"
        [draggable]="false"
      >
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{ 'inventory-criteria.datatable.columns.name' | translate }}"
        prop="name"
        [resizeable]="false"
        cellClass="name"
        [draggable]="false"
      >
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{ 'inventory-criteria.datatable.columns.serial-number' | translate }}"
        width="80"
        prop="serialNumber"
        [resizeable]="false"
        cellClass="serial-number"
        [draggable]="false"
      >
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{ 'inventory-criteria.datatable.columns.batch-number' | translate }}"
        width="80"
        prop="batchNumber"
        [resizeable]="false"
        cellClass="batch-number"
        [draggable]="false"
      >
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{ 'inventory-criteria.datatable.columns.supplier' | translate }}"
        prop="supplier"
        [resizeable]="false"
        cellClass="supplier"
        [draggable]="false"
      >
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{ 'inventory-criteria.datatable.columns.universe' | translate }}"
        prop="universe"
        [resizeable]="false"
        cellClass="universe top-align ellipsis-counter"
        [draggable]="false"
        [sortable]="false"
      >
        <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id" let-universe="row.universe">
          <p class="ellipsis">{{ universe.join(", ") }}</p>
          <div *ngIf="universe.length >= 2">
            <div #universeCounter>
              <ui-counter [number]="universe.length"></ui-counter>
            </div>
            <ui-tooltip maxWidth="300px" [element]="universeCounter">
              <span style="font-weight: normal !important">{{ universe.join(", ") }}</span>
            </ui-tooltip>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{ 'inventory-criteria.datatable.columns.item-group-name' | translate }}"
        prop="itemGroupName"
        [resizeable]="false"
        cellClass="item-group-name top-align ellipsis-counter"
        [draggable]="false"
        [sortable]="false"
      >
        <ng-template ngx-datatable-cell-template let-row="row" let-id="row.id" let-itemGroupName="row.itemGroupName">
          <p class="ellipsis">{{ itemGroupName.join(", ") }}</p>
          <div *ngIf="itemGroupName.length >= 2">
            <div #itemGroupNameCounter>
              <ui-counter [number]="itemGroupName.length"></ui-counter>
            </div>
            <ui-tooltip maxWidth="300px" [element]="itemGroupNameCounter">
              <span style="font-weight: normal !important">{{ itemGroupName.join(", ") }}</span>
            </ui-tooltip>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{ 'inventory-criteria.datatable.columns.public-price-currency' | translate }}"
        cellClass="public-prices-currencies ellipsis-counter"
        prop="publicPricesCurrencies"
        [resizeable]="false"
        [sortable]="false"
        [width]="130"
      >
        <ng-template ngx-datatable-cell-template let-row="row" let-publicPricesCurrencies="row.publicPricesCurrencies">
          <span class="ellipsis publicPricesCurrencies" title="{{ publicPricesCurrencies.join(' | ') }}">
            {{ publicPricesCurrencies.join(" | ") }}
          </span>
          <div *ngIf="publicPricesCurrencies?.length >= 2" title="{{ row.counter }}">
            <div #publicPricesCurrenciesCounter>
              <ui-counter [number]="publicPricesCurrencies?.length"></ui-counter>
            </div>
            <ui-tooltip maxWidth="300px" [element]="publicPricesCurrenciesCounter">
              <span style="font-weight: normal !important; white-space: break-spaces">{{
                publicPricesCurrencies.join("\n")
              }}</span>
            </ui-tooltip>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{ 'inventory-criteria.datatable.columns.location' | translate }}"
        prop="location"
        [resizeable]="false"
        cellClass="location"
        [draggable]="false"
      >
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{ 'inventory-criteria.datatable.columns.last-inventory-date' | translate }}"
        prop="lastInventoryDate"
        [resizeable]="false"
        cellClass="last-inventory-date not-clickable"
        [draggable]="false"
      >
        <ng-template ngx-datatable-cell-template let-lastInventoryDate="row.lastInventoryDate">
          <span *ngIf="lastInventoryDate !== null">{{ lastInventoryDate | dateTimeFormat: false }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-footer>
        <ng-template
          ngx-datatable-footer-template
          let-rowCount="rowCount"
          let-pageSize="pageSize"
          let-selectedCount="selectedCount"
          let-curPage="curPage"
          let-offset="offset"
          let-isVisible="isVisible"
        >
          <div class="page-count" *ngIf="rowCount">
            {{
              rowCount
                | i18nPlural: { "=1": "global.datatable.n-items", other: "global.datatable.n-items_plural" }
                | translate: { rowCount: rowCount }
            }}
          </div>
          <datatable-pager
            [pagerLeftArrowIcon]="'fas fa-chevron-left'"
            [pagerRightArrowIcon]="'fas fa-chevron-right'"
            [pagerNextIcon]="'fas fa-chevron-double-right'"
            [pagerPreviousIcon]="'fas fa-chevron-double-left'"
            [page]="curPage"
            [size]="pageSize"
            [count]="rowCount"
            [hidden]="!(rowCount / pageSize > 1)"
            (change)="changePage($event)"
          >
          </datatable-pager>
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
  </div>
</ng-container>
