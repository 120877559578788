/* eslint-disable no-magic-numbers */
import {
  CommonValidatorsUtil,
  Filter,
  FilterValue,
  Option,
  SearchFilter,
  SearchFilterOperator,
  SubscriptionService,
} from "fugu-common";
import { MenuAction, MessageService } from "fugu-components";
import { FilteredTableListComponent, PrecisionUtil } from "generic-pages";
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import {
  ValidatorFn,
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { Router } from "@angular/router";
import {
  IconDefinition,
  faCircleCheck,
  faCircleMinus,
  faCirclePlus,
  faArrowRight,
  faArrowsRotate,
  faRotateLeft,
} from "@fortawesome/pro-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import {
  Inventory,
  Pagination,
  InventoryFinal,
  FutureLocationMovement,
  ItemCategory,
  Currency,
  InventoryService,
  StockLocationService,
  CaraUserService,
  CurrencyService,
  InventoryResult,
  StockType,
  PaginatedList,
  Sort,
  StockLocation,
  InventoryStatus,
} from "center-services";

@Component({
  selector: "app-inventory-result",
  templateUrl: "./inventory-result.component.html",
  styleUrls: ["./inventory-result.component.scss"],
  providers: [SubscriptionService],
})
export class InventoryResultComponent extends FilteredTableListComponent implements OnInit {
  @Input() inventory: Inventory;
  @Output() inventoryChange: EventEmitter<Inventory> = new EventEmitter<Inventory>();
  @ViewChild("table") table: any;

  public HIGH_INTEGER: number = PrecisionUtil.HIGH_INTEGER;
  readonly decimalDigit: string = `separator.${PrecisionUtil.HIGH_DECIMAL}`;
  readonly faCircleCheck: IconDefinition = faCircleCheck;
  readonly faCircleMinus: IconDefinition = faCircleMinus;
  readonly faCirclePlus: IconDefinition = faCirclePlus;
  readonly faArrowRight: IconDefinition = faArrowRight;
  readonly faArrowsRotate: IconDefinition = faArrowsRotate;
  public pager: Pagination = new Pagination({
    number: 0,
    size: 15,
  });
  public rows: any[] = [];
  public sorts: any[] = [];
  public formGroup: UntypedFormGroup;
  public submitted: boolean = false;
  public popupVisible: boolean = false;
  public locationOptions: Option[] = [];
  public categoryOptions: Option[] = [];
  public finalizeBtnDisabled: boolean = false;
  public menuActions: MenuAction[] = [];
  public availableFilters: Filter[];
  public activeFilters: SearchFilter[] = [];
  public LIST_ID: string = "app-inventory-result.results-table";
  public invalidBulkRowsMap: Map<any, any> = new Map<any, any>();
  public invalidNoBulkRowsMap: Map<any, any> = new Map<any, any>();
  public inMovements: FutureLocationMovement[] = [];
  public outMovements: FutureLocationMovement[] = [];
  public outInMovements: FutureLocationMovement[] = [];
  public categoryList: ItemCategory[] = [];
  public defaultCurrency: Currency;
  public filterValues: FilterValue[] = [];
  public locale: string;
  private readonly ITEM_CATEGORY_FILTER_ID: number = 1;
  private readonly ITEM_REFERENCE_FILTER_ID: number = 2;
  private readonly ITEM_SUPPLIER_REFERENCE_FILTER_ID: number = 3;
  private readonly ITEM_NAME_FILTER_ID: number = 4;
  private readonly SERIAL_NUMBER_FILTER_ID: number = 5;
  private readonly BATCH_NUMBER_FILTER_ID: number = 6;
  private readonly INITIAL_QUANTITY_FILTER_ID: number = 7;
  private readonly LOCATION_FILTER_ID: number = 8;
  private readonly PRICE_DIFFERENCE_ID: number = 9;
  private readonly RESET_QUANTITY_ACTION_ID: number = 1;
  private readonly zeroDeltaFilter: SearchFilter = new SearchFilter(
    "quantityDifference",
    SearchFilterOperator.NOT_EQUAL,
    "0"
  );
  private readonly LOCATION_ID_POSITION: number = 3;
  private readonly STOCK_ENTRY_ID_POSITION: number = 2;
  private digitValidator: ValidatorFn = CommonValidatorsUtil.digitLimitationValidator(PrecisionUtil.HIGH_INTEGER);
  private initObservables: Observable<any>[];
  private initialInventoryFinals: InventoryFinal[] = [];
  private updatedInventoryFinals: InventoryFinal[] = [];
  private currentInventoryFinals: InventoryFinal[] = [];

  constructor(
    private inventoryService: InventoryService,
    private stockLocationService: StockLocationService,
    private router: Router,
    private fb: UntypedFormBuilder,
    protected translateService: TranslateService,
    protected userService: CaraUserService,
    protected messageService: MessageService,
    private currencyService: CurrencyService,
    private subscriptionService: SubscriptionService
  ) {
    super(userService, translateService, messageService);
    this.formGroup = new UntypedFormGroup({});
  }

  ngOnInit(): void {
    this.initObservables = [];
    this.initObservables.push(this.fetchInventoryResults());
    this.initObservables.push(this.fetchStockLocations());
    this.initObservables.push(this.fetchDefaultCurrency());

    this.locale = this.userService.connectedUser.value.codeLanguage;

    this.subscriptionService.subs.push(combineLatest(this.initObservables).subscribe());
    // To put back when itemCategory filter will be put back in LIST
    // this.fetchCategories().subscribe(() => {
    //   this.fetchInventoryResults();
    //   this.fetchStockLocations();
    // });

    this.prepareForm();
    this.addMenuActions();
  }

  addMenuActions(): void {
    this.menuActions.push(
      new MenuAction(
        this.RESET_QUANTITY_ACTION_ID,
        this.translateService.instant("inventory-result.datatable.actions.reset-quantity"),
        faRotateLeft
      )
    );
  }

  addRow(inventoryResult: InventoryResult): void {
    const row: any = {
      id: `${inventoryResult.stockEntryId}_${inventoryResult.locationId}`,
      inventoryId: inventoryResult.inventoryId,
      stockEntryId: inventoryResult.stockEntryId,
      locationId: inventoryResult.locationId,
      inventoryEntryId: inventoryResult.inventoryEntryId,
      initialQuantity: inventoryResult.initialQuantity,
      inventoryQuantity: inventoryResult.inventoryQuantity,
      finalQuantity: inventoryResult.finalQuantity,
      quantityDifference: inventoryResult.quantityDifference,
      locationName: inventoryResult.locationName,
      stockType: inventoryResult.stockType,
      itemCategory: inventoryResult.itemCategory,
      itemRef: inventoryResult.itemRef,
      itemName: inventoryResult.itemName,
      itemSupplierRef: inventoryResult.itemSupplierRef,
      serialNumber: inventoryResult.stockType === StockType.SERIAL_NUMBER ? inventoryResult.stockEntryId : null,
      batchNumber: [StockType.BATCH, StockType.BULK].includes(inventoryResult.stockType)
        ? inventoryResult.stockEntryId
        : null,
      priceDifference: inventoryResult.priceDifference,
    };

    this.rows.push(row);
    this.addRowFormControls(row);
  }

  // To put back when itemCategory filter will be put back in LIST
  // fetchCategories(): Observable<ItemCategory[]> {
  //   return this.itemCategoryService.getAll().pipe(
  //     tap(
  //       (categories: ItemCategory[]) => {
  //         this.categoryList = categories;
  //         this.categoryOptions = categories
  //           .filter((obj: ItemCategory) => !obj.archived && obj.type === CategoryType.STANDARD)
  //           .sort((a, b) => a.name.localeCompare(b.name))
  //           .map((obj: ItemCategory) => new Option(obj.id, obj.name));
  //       },
  //       error => {
  //         this.translateService
  //           .get("item-categories-list.errors.get-items-categories", { message: error.message })
  //           .subscribe((res: string) => {
  //             const title = this.translateService.instant("message.title.data-errors");
  //             this.messageService.warn(res, { title });
  //           });
  //       }
  //     )
  //   );
  // }

  fetchInventoryResults(): Observable<PaginatedList<InventoryResult>> {
    return this.inventoryService
      .getInventoryResults(this.inventory.id, this.pager, this.getSorter(), this.activeFilters)
      .pipe(
        tap(
          (result: PaginatedList<InventoryResult>) => {
            this.rows = [];
            result.data.forEach(ir => {
              this.addRow(ir);
              const isNewInventoryResult = !this.initialInventoryFinals.some(
                fi => fi.stockEntryId === ir.stockEntryId && fi.locationId === ir.locationId
              );
              if (isNewInventoryResult) {
                this.initialInventoryFinals.push(
                  new InventoryFinal({
                    locationId: ir.locationId,
                    stockEntryId: ir.stockEntryId,
                    finalQuantity: ir.finalQuantity,
                  })
                );
              }
            });

            this.pager = result.page;
            this.table.sorts = this.sorts;
            this.table.offset = this.pager.number;
          },
          error => {
            this.displayErrorMessage("inventory-result.errors.get-inventory-results", error.message);
          }
        )
      );
  }

  displayErrorMessage(errorType: string, message?: any, isWarning: boolean = true): void {
    const title = isWarning
      ? this.translateService.instant("message.title.data-errors")
      : this.translateService.instant("message.title.form-errors");
    const content = this.translateService.instant(errorType, { message });
    isWarning ? this.messageService.warn(content, { title }) : this.messageService.error(content, { title });
  }

  public changePage(pageInfo: any): void {
    this.pager.number = pageInfo.page - 1;
    this.subscriptionService.subs.push(this.fetchInventoryResults().subscribe());
  }

  getRowClass(row: any): any {
    return { inError: row.inError, "not-clickable": true };
  }

  changeSortSettings(prop: string, direction: string): void {
    this.sorts = [];
    if (prop === "batchNumber") {
      this.sorts.push({
        prop: "stockType",
        dir: "desc",
      });
    } else if (prop === "serialNumber") {
      this.sorts.push({
        prop: "stockType",
        dir: "asc",
      });
    }
    this.sorts.push({
      prop,
      dir: direction,
    });
    this.subscriptionService.subs.push(this.fetchInventoryResults().subscribe());
  }

  prepareForm(): void {
    this.formGroup = this.fb.group({
      nonZeroDeltaQuantity: [false],
      stockCorrection: [false],
    });

    this.subscriptionService.subs.push(
      this.formGroup.get("nonZeroDeltaQuantity").valueChanges.subscribe(isActivated => {
        if (isActivated) {
          this.activeFilters.push(this.zeroDeltaFilter);
        } else {
          this.activeFilters.splice(this.activeFilters.indexOf(this.zeroDeltaFilter), 1);
        }

        this.subscriptionService.subs.push(
          this.updatePreferences(
            this.filterValues.map(fv => fv.filterId),
            this.LIST_ID
          ).subscribe(() => {
            this.subscriptionService.subs.push(this.fetchInventoryResults().subscribe());
          })
        );
      })
    );
  }

  addRowFormControls(row: any): void {
    if (!this.formGroup.contains(`final_quantity_${row.id}`)) {
      const addRowFormControls = new UntypedFormControl(row.finalQuantity, [
        Validators.required,
        this.digitValidator,
        this.forbiddenQuantityValidator(row.stockType),
      ]);
      this.formGroup.addControl(`final_quantity_${row.id}`, addRowFormControls);

      const quantityControl = this.formGroup.get(`final_quantity_${row.id}`);

      this.subscriptionService.subs.push(
        quantityControl.valueChanges.subscribe(() => {
          if (quantityControl.invalid) {
            row.stockType !== StockType.BULK
              ? this.invalidNoBulkRowsMap.set(row.id, { seId: row.stockEntryId })
              : this.invalidBulkRowsMap.set(row.id, { itemRef: row.itemRef });
          } else {
            row.stockType !== StockType.BULK
              ? this.invalidNoBulkRowsMap.delete(row.id)
              : this.invalidBulkRowsMap.delete(row.id);
          }
        })
      );
    }
    this.rows = [...this.rows];
  }

  forbiddenQuantityValidator(stockType: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = +control.value > 1 && stockType === StockType.SERIAL_NUMBER;
      return forbidden ? { addSerialNumberStockEntry: true } : null;
    };
  }

  manageActions(actionId: number, row: any): void {
    switch (actionId) {
      case this.RESET_QUANTITY_ACTION_ID:
        this.formGroup.get(`final_quantity_${row.id}`).setValue(row.inventoryQuantity);
        break;
      default:
        break;
    }
  }

  applyFilters(): void {
    this.pager.number = 0;
    this.activeFilters = this.activeFilters.filter(
      (searchFilter: SearchFilter) => searchFilter === this.zeroDeltaFilter
    );

    for (const filterValue of this.filterValues) {
      switch (filterValue.filterId) {
        case this.ITEM_CATEGORY_FILTER_ID:
          // transform option id en label
          this.activeFilters.push(new SearchFilter("itemCategory", SearchFilterOperator.LIKE, filterValue.equal));
          break;

        case this.ITEM_REFERENCE_FILTER_ID:
          this.activeFilters.push(new SearchFilter("itemRef", SearchFilterOperator.LIKE, filterValue.equal));
          break;

        case this.ITEM_SUPPLIER_REFERENCE_FILTER_ID:
          this.activeFilters.push(new SearchFilter("itemSupplierRef", SearchFilterOperator.LIKE, filterValue.equal));
          break;

        case this.ITEM_NAME_FILTER_ID:
          this.activeFilters.push(
            new SearchFilter("itemName", SearchFilterOperator.LIKE, filterValue.equal, this.ITEM_NAME_FILTER_ID)
          );
          break;

        case this.SERIAL_NUMBER_FILTER_ID:
          this.activeFilters.push(new SearchFilter("stockEntryId", SearchFilterOperator.EQUAL, filterValue.equal));
          break;

        case this.BATCH_NUMBER_FILTER_ID:
          this.activeFilters.push(new SearchFilter("stockEntryId", SearchFilterOperator.EQUAL, filterValue.equal));
          break;

        case this.INITIAL_QUANTITY_FILTER_ID:
          this.applyRangeFilter(filterValue, "initialQuantity", this.INITIAL_QUANTITY_FILTER_ID);
          break;

        case this.LOCATION_FILTER_ID:
          this.activeFilters.push(
            new SearchFilter("locationId", SearchFilterOperator.IN, filterValue.equal, this.LOCATION_FILTER_ID)
          );
          break;

        case this.PRICE_DIFFERENCE_ID:
          this.applyRangeFilter(filterValue, "priceDifference", this.PRICE_DIFFERENCE_ID);
          break;

        default:
          console.error(`Can't handle filter ID : ${filterValue}`);
          break;
      }
    }

    this.subscriptionService.subs.push(
      this.updatePreferences(
        this.filterValues.map(fv => fv.filterId),
        this.LIST_ID
      ).subscribe(() => {
        this.subscriptionService.subs.push(this.fetchInventoryResults().subscribe());
      })
    );
  }

  initFilters(): void {
    this.availableFilters = [];

    this.availableFilters.push(
      new Filter(
        this.ITEM_CATEGORY_FILTER_ID,
        this.translateService.instant("inventory-result.datatable.columns.item-category"),
        "string",
        null,
        null,
        false,
        this.isFilterSaved(this.LIST_ID, this.ITEM_CATEGORY_FILTER_ID)
      )
    );

    this.availableFilters.push(
      new Filter(
        this.ITEM_REFERENCE_FILTER_ID,
        this.translateService.instant("inventory-result.datatable.columns.item-reference"),
        "string",
        null,
        null,
        false,
        this.isFilterSaved(this.LIST_ID, this.ITEM_REFERENCE_FILTER_ID)
      )
    );
    this.availableFilters.push(
      new Filter(
        this.ITEM_SUPPLIER_REFERENCE_FILTER_ID,
        this.translateService.instant("inventory-result.datatable.columns.item-supplier-reference"),
        "string",
        null,
        null,
        false,
        this.isFilterSaved(this.LIST_ID, this.ITEM_SUPPLIER_REFERENCE_FILTER_ID)
      )
    );
    this.availableFilters.push(
      new Filter(
        this.ITEM_NAME_FILTER_ID,
        this.translateService.instant("inventory-result.datatable.columns.item-name"),
        "string",
        null,
        null,
        false,
        this.isFilterSaved(this.LIST_ID, this.ITEM_NAME_FILTER_ID)
      )
    );
    this.availableFilters.push(
      new Filter(
        this.SERIAL_NUMBER_FILTER_ID,
        this.translateService.instant("inventory-result.datatable.columns.serial-number"),
        "string",
        null,
        null,
        false,
        this.isFilterSaved(this.LIST_ID, this.SERIAL_NUMBER_FILTER_ID)
      )
    );
    this.availableFilters.push(
      new Filter(
        this.BATCH_NUMBER_FILTER_ID,
        this.translateService.instant("inventory-result.datatable.columns.batch-number"),
        "string",
        null,
        null,
        false,
        this.isFilterSaved(this.LIST_ID, this.BATCH_NUMBER_FILTER_ID)
      )
    );
    this.availableFilters.push(
      new Filter(
        this.INITIAL_QUANTITY_FILTER_ID,
        this.translateService.instant("inventory-result.datatable.columns.initial-quantity"),
        "range",
        null,
        null,
        false,
        this.isFilterSaved(this.LIST_ID, this.INITIAL_QUANTITY_FILTER_ID)
      )
    );

    this.availableFilters.push(
      new Filter(
        this.PRICE_DIFFERENCE_ID,
        this.translateService.instant("inventory-result.datatable.columns.price-difference"),
        "range",
        null,
        null,
        false,
        this.isFilterSaved(this.LIST_ID, this.PRICE_DIFFERENCE_ID)
      )
    );

    this.availableFilters.push(
      new Filter(
        this.LOCATION_FILTER_ID,
        this.translateService.instant("inventory-result.datatable.columns.location"),
        "list",
        null,
        this.locationOptions,
        false,
        this.isFilterSaved(this.LIST_ID, this.LOCATION_FILTER_ID)
      )
    );
  }

  /* eslint-disable consistent-return */
  saveEditedInventory(): boolean {
    // get serial number/ bulk se ids
    const noBulkSEIds: Set<number> = new Set<number>();
    [...this.invalidNoBulkRowsMap.values()].forEach(value => noBulkSEIds.add(value.seId));

    if (noBulkSEIds.size > 0) {
      this.displayErrorMessage(
        "inventory-result.errors.default-invalid-datatable-form",
        { seIds: [...noBulkSEIds].join(", ") },
        false
      );
    }
    // bulk case
    const bulkItemRefs: Set<string> = new Set<string>();
    [...this.invalidBulkRowsMap.values()].forEach(value => bulkItemRefs.add(value.itemRef));

    if (bulkItemRefs.size > 0) {
      this.displayErrorMessage(
        "inventory-result.errors.bulk-invalid-datatable-form",
        { bulkItemRefs: [...bulkItemRefs].join(", ") },
        false
      );
    }
    this.rows.forEach(row => {
      row.inError = this.formGroup.get(`final_quantity_${row.id}`)?.invalid;
    });
    this.rows = [...this.rows];

    if (this.formGroup.invalid) {
      return false;
    }
    this.applyModifications();
    if (this.isSameList(this.initialInventoryFinals, this.currentInventoryFinals)) {
      return true;
    }

    this.subscriptionService.subs.push(
      this.inventoryService.updateFinalQuantities(this.inventory.id, this.currentInventoryFinals).subscribe(
        () => {
          this.initialInventoryFinals = [...this.currentInventoryFinals];
          this.subscriptionService.subs.push(this.fetchInventoryResults().subscribe());
        },
        error => {
          if (error.error.exception.name === "DuplicateInventoryEntryForSerialNumberException") {
            this.displayErrorMessage("inventory-result.errors.item-present-two-times", error.message);
          } else {
            this.displayErrorMessage("inventory-result.errors.update-final-quantities", error.message);
          }
          return false;
        },
        () => {
          this.displaySuccessMessage();
          return true;
        }
      )
    );
  }

  applyModifications(): void {
    this.updatedInventoryFinals = [...this.currentInventoryFinals];
    this.currentInventoryFinals = Object.keys(this.formGroup.controls)
      .filter(key => key !== "nonZeroDeltaQuantity" && key !== "stockCorrection")
      .map(
        key =>
          new InventoryFinal({
            locationId: isNaN(+key.split("_")[this.LOCATION_ID_POSITION])
              ? null
              : +key.split("_")[this.LOCATION_ID_POSITION],
            stockEntryId: isNaN(+key.split("_")[this.STOCK_ENTRY_ID_POSITION])
              ? null
              : +key.split("_")[this.STOCK_ENTRY_ID_POSITION],
            finalQuantity: isNaN(+this.formGroup.get(key).value) ? null : +this.formGroup.get(key).value,
          })
      );
  }

  isDirty(): boolean {
    this.applyModifications();
    return (
      !this.isSameList(this.currentInventoryFinals, this.updatedInventoryFinals) &&
      !this.isSameList(this.currentInventoryFinals, this.initialInventoryFinals)
    );
  }

  isSameList(inventoryFinals1: InventoryFinal[], inventoryFinals2: InventoryFinal[]): boolean {
    return inventoryFinals1.every(sm1 => inventoryFinals2.some(sm2 => sm2.equals(sm1)));
  }

  openValidationPopup(): void {
    this.fetchFutureLocationMovements();
  }

  closePopup(): void {
    this.popupVisible = false;
    this.finalizeBtnDisabled = false;
  }

  displaySuccessMessage(): void {
    const title = this.translateService.instant("message.title.save-success");
    const content = this.translateService.instant("message.content.save-success");
    this.messageService.success(content, { title });
  }

  public finalizeInventory(): void {
    this.finalizeBtnDisabled = true;
    this.inventory.status = InventoryStatus.FINISHED;
    this.subscriptionService.subs.push(
      this.inventoryService.update(this.inventory).subscribe(
        () => {
          this.displaySuccessMessage();
          this.router.navigateByUrl("/inventory-list");
        },
        error => {
          this.displayErrorMessage("inventory-result.errors.update-inventory", error.message);
        }
      )
    );
  }

  private fetchDefaultCurrency(): Observable<Currency> {
    return this.currencyService.getDefault().pipe(
      tap(
        (currency: Currency) => {
          this.defaultCurrency = currency;
        },
        () => {
          const content = this.translateService.instant("inventory-result.errors.get-currencies");
          const title = this.translateService.instant("message.title.data-errors");
          this.messageService.warn(content, { title });
        }
      )
    );
  }

  private getSorter(): Sort[] {
    const sorter = [];
    for (const s of this.sorts) {
      sorter.push(new Sort(this.propToDto(s.prop), s.dir));
    }
    return sorter;
  }

  private propToDto(prop: string): string {
    switch (prop) {
      case "serialNumber":
      case "batchNumber":
        return "stockEntryId";
      default:
        return prop;
    }
  }

  private applyRangeFilter(attr: any, searchFilterStr: string, filterId: number): void {
    const min = attr.min ?? "";
    const max = attr.max ?? "";
    this.activeFilters.push(
      new SearchFilter(searchFilterStr, SearchFilterOperator.GREATER_EQUAL_THAN, min.toString(), filterId)
    );
    this.activeFilters.push(
      new SearchFilter(searchFilterStr, SearchFilterOperator.LOWER_EQUAL_THAN, max.toString(), filterId)
    );
  }

  private fetchStockLocations(): Observable<StockLocation[]> {
    return this.stockLocationService.getAll(["withChildren", "onlyOrigin"], this.inventory.storeId).pipe(
      tap(
        (stockLocations: StockLocation[]) => {
          this.locationOptions = this.stockLocationService
            .getFlattenedLocations(stockLocations)
            .filter(stockLocation => this.inventory.selectedLocationIds.includes(stockLocation.id))
            .map((obj: StockLocation) => new Option(obj.id, obj.name));

          this.locationOptions.sort((a, b) => a.label.localeCompare(b.label));
        },
        error => {
          this.displayErrorMessage("inventory-result.errors.get-stock-locations", error.message);
        },
        () => {
          this.initFilters();
        }
      )
    );
  }

  private fetchFutureLocationMovements(): void {
    this.inMovements = [];
    this.outMovements = [];
    this.outInMovements = [];

    this.subscriptionService.subs.push(
      this.inventoryService.getFutureLocationMovements(this.inventory.id).subscribe(
        (futureLocationMovements: FutureLocationMovement[]) => {
          for (const locationMovement of futureLocationMovements) {
            if (locationMovement.srcLocationName !== null) {
              if (locationMovement.destLocationName !== null) {
                this.outInMovements.push(locationMovement);
                continue;
              }
              this.outMovements.push(locationMovement);
              continue;
            }
            this.inMovements.push(locationMovement);
          }

          this.popupVisible = true;
          this.finalizeBtnDisabled = false;
        },
        error => {
          this.displayErrorMessage("inventory-result.errors.get-future-location-movements", error.message);
        }
      )
    );
  }
}
